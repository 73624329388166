import {
    Ctrl,
    CtrlArgs,
    ValuePipe,
    ContainerCtrl,
    FieldKey,
    FieldCtrlTypes,
    FieldCtrlState,
    ContainerState,
    CreateStateArgs,
} from "./types";
import { onUnmounted } from "vue";
import {
    createStringValidation,
    createNumberValidation,
    inputTypeToType,
    inputTypeToFormat,
    getCtrlId,
} from "./helpers";
import { useFiledCtrlState } from "./FieldState";

export type KeyValMeta = {
    autocomplete?: string[];
    keyAutocomplete?: string[];
    valAutocomplete?: string[];
    inputProps?: any;
    icon?: string;
    keyInputProps?: any;
    valInputProps?: any;
};

export type InputMeta = {
    autocomplete?: string[];
    inputProps?: any;
};

export type SelectMeta = {
    options?: (string | { name: string; value: string | number })[];
    placeholder?: string;
};

export type FieldCtrlMeta = KeyValMeta &
    InputMeta &
    SelectMeta & {
        validation?: ValuePipe<any>;
        category?: string;
        groups?: string[];
    };

export type FieldCtrl<T> = Ctrl<T, FieldCtrlMeta> & {
    title?: string;
    description?: string;
    reset(): void;
    required: boolean;
    disabled: boolean;
    hidden: boolean;
    dirty: boolean;
    err: string;
    info: string;
};

export type FieldCtrlArgTypes = string | number | boolean;

export type FieldCtrlArgs<T> = CtrlArgs & {
    type?: FieldCtrlTypes; // default "string"
    default?: T;
    disabled?: boolean;
    editable?: boolean;
    pipe?: ValuePipe<T>[];
    required?: boolean;
    canEdit?: boolean;
    parent: ContainerState;
    meta?: FieldCtrlMeta;
    onInputChange?: (value: any, oldValue: any) => void;
};

type Args = {
    key?: FieldKey;
    type?: FieldCtrlTypes;
    parent?: ContainerCtrl;
    props: any;
    pipeAndMeta?: { meta: FieldCtrlMeta; pipe: ValuePipe[] };
};

export function calcInputFormatAndType(type: string, format: string) {
    return [(inputTypeToType as any)[type] || "string", format || (inputTypeToFormat as any)[type]];
}

const validationFuncFromInputProps = (props: any) => {
    const [type, format] = calcInputFormatAndType(props.type, props.format);
    switch (type) {
        case "string":
            return createStringValidation({
                maxLength: props.maxLength,
                minLength: props.minLength,
                pattern: props.pattern,
                format: props.format,
                enum: (props.options || []).map((o: any) => o?.value || o),
            });

        case "number":
            return createNumberValidation({
                max: props.max,
                min: props.min,
                format: props.format,
                step: props.step,
            });
        default:
            return undefined;
    }
};
export function useInputPipeAndMeta(props: FieldCtrlMeta) {
    return {
        meta: {
            get groups() {
                return props.groups;
            },
            get category() {
                return props.category;
            },
            get placeholder() {
                return props.placeholder;
            }, // for select
            get options() {
                return props.options;
            }, // for select
            get autocomplete() {
                return props.autocomplete;
            }, // for input string
        },
        get pipe(): ValuePipe[] {
            return [validationFuncFromInputProps(props), props.validation].filter((f) => f) as ValuePipe[];
        },
    };
}

export function useFieldCtrlArgsFromProps<T = FieldCtrlArgTypes>(args: Args): FieldCtrlArgs<T> {
    return {
        get type() {
            return args.type || (args.props.type as FieldCtrlTypes);
        },
        get key() {
            return args.key || args.props.name;
        },
        get parent() {
            return args.parent || args.props.ctrl;
        },
        get title() {
            return args.props.label || args.props.name;
        },
        get description() {
            return args.props.description;
        },
        get required() {
            return args.props.required;
        },
        get default() {
            return args.props.default;
        },
        get meta() {
            return args.pipeAndMeta?.meta;
        },
        get pipe() {
            return args.pipeAndMeta?.pipe;
        }
    };
}

export function useFieldCtrl<T = unknown>(args: FieldCtrlArgs<T>): FieldCtrl<T> {
    const id = getCtrlId();
    let state: FieldCtrlState<T>;

    onUnmounted(() => {
        args.parent.remove(self);
    });

    const self = {
        get type() {
            return args.type || "string";
        },
        get id() {
            return id;
        },
        get key() {
            return args.key;
        },
        get title() {
            return args.title;
        },
        get description() {
            return args.description;
        },
        get onInputChange() {
            return args.onInputChange;
        },
        get declaration() {
            return args;
        },
        get editable() {
            return state.editable;
        },
        get hidden() {
            return state.context.hidden || !!args.parent.isChildrenHidden(args.key);
        },

        get meta() {
            return args.meta;
        },
        get disabled() {
            return state.editable == false || !!args.disabled || (args.canEdit != undefined && !args.canEdit);
        },
        get value() {
            return state.value as T;
        },
        set value(v: T) {
            state.value = v;
        },
        get required() {
            return !!state.required;
        },
        get dirty() {
            return state.dirty;
        },
        get err() {
            return state.err;
        },
        get info() {
            return state.info;
        },
        get valid() {
            return state?.valid;
        },
        get context() {
            return state.context;
        },
        get reset() {
            return state.reset;
        },
        createState(parentArgs: CreateStateArgs) {
            return useFiledCtrlState<T>({
                get key() {
                    return args.key;
                },
                get default() {
                    return args.default;
                },
                get type() {
                    return args.type;
                },
                get required() {
                    return args.required;
                },
                get pipe() {
                    return args.pipe;
                },
                get editable() {
                    return args.editable;
                },
                get defaultEditable() {
                    return parentArgs.defaultEditable;
                },
            });
        },
        set state(_state: FieldCtrlState<T>) {
            state = _state;
        },
        get state() {
            return state;
        },
    };

    args.parent.add(self);
    return self;
}
