import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import cookieService, { ResearcherCookie } from "./cookie.service";
import authStore from "@/stores/authStore";
import { activeClusterConnectionInfoStore } from "@/stores/activeClusterConnectionInfoStore";

type APIClientProps = {
    baseUrl?: string;
    connected?: boolean;
    version?: string;
    jwt: string;
    externalToken: string;
    inferenceAvailability?: string;
};

export function baseUrlFromCookies() {
    return cookieService.get(ResearcherCookie.RESEARCHER_BASE_URL);
}

class APIClient {
    constructor(private props: APIClientProps) {}

    get baseUrl(): URL {
        return new URL(
            this.props.baseUrl && this.props.baseUrl.length > 1
                ? this.props.baseUrl
                : `${location.protocol}//${location.host}`,
        );
    }

    get connected() {
        return this.props.connected;
    }

    get version() {
        return this.props.version;
    }

    get jwt() {
        return this.props.jwt;
    }

    get externalToken() {
        return this.props.externalToken;
    }

    get inferenceAvailabilityStatus(): string {
        return this.props.inferenceAvailability!;
    }

    updateRequestHeaders(config: AxiosRequestConfig = {}): AxiosRequestConfig {
        let useExternalToken = authStore.getUseExternalTokenInStorage()
        console.debug(`updating request headers. using ${useExternalToken? "external": "internal"} token`)

        const headers: Record<string, string> = {};
        if (useExternalToken && !this.externalToken) {
            // this should not happen!!
            throw new Error("unable to perform call. missing external token for cluster");
        } else if (useExternalToken && this.externalToken) { // use external token
            headers["Authorization"] = `Bearer ${this.externalToken}`;
            headers["AuthInfo"] = `Bearer ${this.jwt}`;
        } else {
            headers["Authorization"] = `Bearer ${this.jwt}`;
        }
        config.headers = {
            ...(config.headers ? config.headers : {}),
            ...headers,
        };
        return config;
    }

    updateProps(props: APIClientProps) {
        this.props = props;
    }

    adaptUrl(path = "", hasOnlyDomain = false): string {
        const baseUrl = hasOnlyDomain ? this.baseUrl.origin : this.props.baseUrl;
        const url = baseUrl + path;
        try {
            new URL(url);
        } catch (e) {
            console.warn(`${url} is invalid`);
        }
        return url;
    }

    async get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig, hasOnlyDomain = false): Promise<R> {
        config = this.updateRequestHeaders(config);
        return axios.get(this.adaptUrl(url, hasOnlyDomain), config);
    }

    async del<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        config = this.updateRequestHeaders(config);
        return axios.delete(this.adaptUrl(url), config);
    }

    async post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        config = this.updateRequestHeaders(config);
        return axios.post(this.adaptUrl(url), data, config);
    }

    async put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        config = this.updateRequestHeaders(config);
        return axios.put(this.adaptUrl(url), data, config);
    }

    async patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        config = this.updateRequestHeaders(config);
        return axios.patch(this.adaptUrl(url), data, config);
    }
}

export const clusterApi = new APIClient({jwt: activeClusterConnectionInfoStore.jwt, externalToken: activeClusterConnectionInfoStore.externalToken});
