
import { defineComponent, ref, computed } from "vue";
import { formatMarkdown } from "@/utils/markdownUtil";

export default defineComponent({
    props: {
        action: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const popover = ref(false as boolean);
        const actionInfo = computed(() => (props.action.info ? formatMarkdown(props.action.info) : null));
        return {
            popover,
            actionInfo,
        };
    },
});
