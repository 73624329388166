import { clusterApi } from "@/cluster-ui/services/apiClient";
import { activeClusterConnectionInfoStore } from "@/stores/activeClusterConnectionInfoStore";

export default {
    cliUrl(os: string): string {
        return `${clusterApi.baseUrl.origin}/cli/${os}`;
    },
    WindowsCliAvailable(): boolean {
        return activeClusterConnectionInfoStore.WindowsCliAvailable
    }
};
