import apiClient from "./api-client";
import { AuthUser } from "@/types/user";
import jwt_decode, { JwtPayload } from "jwt-decode";


export class idTokenResponse {
    idToken = "";
    externalToken = "";
    refreshToken = "";
}


class AuthService {
    private _userInfoPromise: Promise<any> = null as any;

    getUserAuth(email: string) {
        return apiClient.asyncRequestWithResponseAndError(`v1/k8s/tenantFromEmail/${encodeURIComponent(email)}`, "GET");
    }

    getUserInfo(): Promise<AuthUser> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/auth/me`, "GET");
    }

    refreshToken(refreshToken: string): Promise<idTokenResponse> {
            return apiClient.asyncRequestWithResponse(
                `v1/k8s/auth/oauth/tokens/refresh?refresh_token=${refreshToken}`,
                "POST", undefined, false);
    }

    hasRole(role: string): Promise<boolean> {
        return this.getUserInfo().then(({ roles }) => roles.includes(role));
    }

    changePassword(newPassword: string, currentPassword: string) {
        return apiClient.asyncRequestWithResponse(`v1/k8s/auth/me/password`, "PUT", { newPassword, currentPassword });
    }

    clearCache() {
        this._userInfoPromise = null as any;
    }

    isJwtAboutToExpired(token: string): boolean {
        const tokenPayload: JwtPayload = jwt_decode(token);
        if(tokenPayload.exp){
            // convert current time in milliseconds to seconds since exp is NumericDate
            const currentTime = new Date().getTime() / 1000;
            // exp time is in NumericDate format, which is seconds since epoch
            const tokenExpirationSec = (tokenPayload.exp - currentTime);
            return tokenExpirationSec < 80;
        }
        return false;        
    }


    exchangeCode(code: string, redirectUri: string): Promise<idTokenResponse> {
        const params = Object({'code': code, 'redirect_uri': redirectUri});
        const queryParams = Object.keys(params).map(k => `${k}=${params[k]}`).join('&');
        return apiClient.asyncRequestWithResponse(`v1/k8s/auth/token/exchange?${queryParams}`, "GET");
    }
}


const authService = new AuthService();
export default authService;
