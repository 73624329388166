import { storageService } from "@/core-ui/data-grid/services";
import { DisplayDensityOptions } from "../types";
import { ref } from "vue";

const DISPLAY_DENSITY_KEY = "DISPLAY_DENSITY_KEY";

const DISPLAY_DENSITY_DEFAULT = DisplayDensityOptions.Compact;

export type DisplayDensity = {
    value: DisplayDensityOptions;
};

export function useDisplayDensity(): DisplayDensity {
    const size = ref(storageService.getStr(DISPLAY_DENSITY_KEY, DISPLAY_DENSITY_DEFAULT) as DisplayDensityOptions);

    return {
        get value(): DisplayDensityOptions {
            return size.value;
        },
        set value(val: DisplayDensityOptions) {
            // if (!(val in RowSizeOptions )) throw new Error(`Unknown row size: ${val}`);
            size.value = val;
            storageService.setStr(DISPLAY_DENSITY_KEY, val);
        },
    };
}
