
import clustersStore from "@/stores/clusters-store";
import tenantStore from "@/stores/tenantStore";
import SignEula from "@/components/onboarding/SignEula.vue";
import adminStore from "@/stores/admin-store";
import { defineComponent, ref } from "vue";

export default defineComponent({
    components: { SignEula },
    setup() {
        const isWizardOpened = ref(false);
        clustersStore.fetchThenUpdateCheckedAndActiveClusters();

        const self = {
            get contractType() {
                return tenantStore.state.contractType;
            },

            get notSignedEula() {
                return !(adminStore.userIsRunAiEmp || tenantStore.state.signedEula);
            },

            checkTenantData() {
                return tenantStore.readTenantData(true);
            },
        };
        return self;
    },
});
