
import { defineComponent } from "vue";
import { customCtrlArgs, variant } from "@/core-ui/forms/components/common";
import Box from "@/core-ui/forms/components/Box.vue";
import BoxInput from "@/core-ui/forms/components/BoxInput.vue";
import BoxToggle from "@/core-ui/forms/components/BoxToggle.vue";
import IconButton from "@/core-ui/IconButton.vue";
import { ContainerCtrlMeta, useObjCtrl } from "@/core-ui/forms/compositions";
import BoxInputCtrl from "@/core-ui/forms/components/BoxInputCtrl.vue";
import BoxToggleCtrl from "@/core-ui/forms/components/BoxToggleCtrl.vue";

type meta = ContainerCtrlMeta;

export default defineComponent({
    props: {
        variant,
        ctrlArgs: customCtrlArgs<meta>(),
        canEdit: { type: Boolean, default: true },
        canRemove: { type: Boolean, default: true },
    },
    components: { Box, IconButton, BoxInputCtrl, BoxToggleCtrl },
    setup(props) {
        const ctrl = useObjCtrl(props.ctrlArgs!);
        function getArgs(key: string): any {
            return ctrl.meta.fields[key](ctrl.state, key).args;
        }
        return {
            itemCanEdit: props.canEdit,
            itemCanRemove: props.canRemove,
            ctrl,
            sourcePath: getArgs("sourcePath"),
            targetPath: getArgs("targetPath"),
            readOnly: getArgs("readOnly"),
            nfsServer: getArgs("nfsServer"),

            get showSrc() {
                return true;
            },
        };
    },
});
