import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoxSelect = _resolveComponent("BoxSelect")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_form_focus = _resolveDirective("form-focus")!

  return (_openBlock(), _createBlock(_component_Modal, {
    hideClose: "",
    staticBackdrop: "",
    modalBodyClass: "add-template-model-body",
    title: "Select a project for the loaded global template:"
  }, {
    body: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("form", {
        class: "form-class",
        action: "",
        id: _ctx.formCtrl.key,
        onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.formCtrl.onSubmit && _ctx.formCtrl.onSubmit(...args)))
      }, [
        _createVNode(_component_BoxSelect, {
          required: "",
          name: "project",
          options: _ctx.projects,
          variant: "column",
          label: "Project",
          ctrl: _ctx.formCtrl
        }, null, 8, ["options", "ctrl"])
      ], 40, _hoisted_1)), [
        [_directive_form_focus]
      ])
    ]),
    buttons: _withCtx(() => [
      _createVNode(_component_Button, {
        disabled: _ctx.formCtrl.valid !== 'valid',
        "button-type": "main",
        aid: "template-modal-save-btn",
        form: _ctx.formCtrl.key,
        text: "apply"
      }, null, 8, ["disabled", "form"])
    ]),
    _: 1
  }))
}