
import { defineComponent, ref } from "vue";
import IconButton from "@/core-ui/IconButton.vue";
import { useAutocomplete } from "../compositions/Autocomplate";

export default defineComponent({
    components: { IconButton },
    props: {
        theme: String,
        tooltipText: String,
        dirty: { type: Boolean, default: true },
        containerProps: Object,
        modelValue: {},
        invalid: Boolean,
        suggestions: { type: Array, defeult: [] },
    },
    setup(props, ctx) {
        const shwoPassword = ref(false);
        return {
            shwoPassword,
            ac: useAutocomplete({
                onSelect(item) {
                    ctx.emit("update:modelValue", item.value);
                },
                get suggestions() {
                    return props.suggestions || [];
                },
            }),
            get isPassword() {
                return ctx.attrs.type == "password";
            },
            handleInput(event: any) {
                ctx.emit("update:modelValue", event.target.value);
            },
            get inputAutocomplete() {
                return !!props.suggestions;
            },
            get inputType() {
                if (ctx.attrs.type == "password" && shwoPassword.value) {
                    return "text";
                }
                return ctx.attrs.type;
            },
        };
    },
});
