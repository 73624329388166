import { Column } from "@/core-ui/types/column";

export function columnKeyMap(columns: Column[]) {
    return columns.reduce((o: any, column) => {
        o[column.key] = column.reverseKey || column.dataKey;
        o[column.dataKey] = column.reverseKey || column.dataKey;
        return o;
    }, {});
}

export function mapUiColumnsToApi(mapper: { [key: string]: string }, filters: any = {}): { [key: string]: string } {
    // map UI column keys into the api column keys:
    return Object.entries(filters).reduce((res: any, [key, value]) => {
        const v = `${value instanceof Array ? value.join("|") : value}`;
        if (v.trim()) res[mapper[key] || key] = v;
        return res;
    }, {});
}
