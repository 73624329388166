
import { defineComponent } from "vue";

import { createDynamicMsg } from "./compositions/DynamicMsg";

export default defineComponent({
    setup() {
        return createDynamicMsg();
    },
});
