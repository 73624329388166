import { Fields, Settings } from "../types/template";
import { clusterApi } from "./apiClient";
import { Workload, WorkloadResponse } from "../components/submit/types";
import { storageService } from "@/core-ui/data-grid/services";
import { isEmpty } from "lodash";
import { setCurrentTemplateName, settingsTypeToWorkLoadKind } from "./../helpers/commonFunctions";
import { projectNameToWorkloadNameSpace } from "./../helpers/commonFunctions";
import { Deployment } from "@/types/deployments";
import { InferenceJob } from "@/cluster-ui/helpers/const";
import authStore from "@/stores/authStore";
import {convertBackendResponseEnvParamAsFieldTypeArray} from "@/cluster-ui/components/submit/submitUtil";

const WORKLOAD_API_PREFIX = "/api/v1/workload";

export default {
    async getWorkloadsMerged(
        type: string,
        templateName?: string,
        namespace?: string,
        targetProject?: string,
    ): Promise<Fields> {
        const submitType = type.toLocaleLowerCase();
        const data: any = {
            type: submitType,
            namespace: await projectNameToWorkloadNameSpace(namespace || ""),
            template: templateName,
        };

        // used for global template
        if (targetProject) data["targetNamespace"] = await projectNameToWorkloadNameSpace(targetProject);

        if (isEmpty(templateName)) {
            delete data["template"];
        }
        return clusterApi.patch(`${WORKLOAD_API_PREFIX}/merged`, data).then((res: any) => {

            convertBackendResponseEnvParamAsFieldTypeArray(res)

            storageService.setStr(submitType, res.data);
            if (!isEmpty(res.data) && !isEmpty(res.data.name) && !isEmpty(res.data.name.value)) {
                delete res.data.name.value;
            }
            setCurrentTemplateName(templateName || "");
            return res.data;
        });
    },

    async getWorkloadsByType(workloadType: string, projectName: string): Promise<Workload[]> {
        const namespace = (await projectNameToWorkloadNameSpace(projectName)) || "runai";
        const res = await clusterApi.get(`${WORKLOAD_API_PREFIX}/proxy/namespaces/${namespace}/${workloadType}`);
        return res.data?.items;
    },

    //
    // delete Workload template for specific project and template name
    //
    async deleteWorkloadTemplatesProjectTemplate(
        project: string,
        templateName: string,
        settingsType: string,
    ): Promise<Workload[]> {
        const namespace = await projectNameToWorkloadNameSpace(project);
        return this.deleteWorkload(namespace, templateName, settingsType);
    },

    async deleteInferenceWorkload(d: Deployment) {
        return this.deleteWorkload(d.namespace, d.inferenceworkloadName || "undefined", InferenceJob);
    },

    async deleteWorkload(namespace: string, name: string, type: string): Promise<Workload[]> {
        const workloadType = settingsTypeToWorkLoadKind(type);
        return (await clusterApi.del(`${WORKLOAD_API_PREFIX}/proxy/namespaces/${namespace}/${workloadType}/${name}`))
            .data;
    },

    //
    // submit Workload template for specific project and template name
    //
    async submitWorkLoad(
        projectName: string,
        data: any,
        settingsType: string,
        namespace?: string,
    ): Promise<WorkloadResponse> {
        if (isEmpty(namespace)) {
            namespace = await projectNameToWorkloadNameSpace(projectName);
        }
        data.spec.username = {
            value: authStore.userEmail,
        };
        const workloadType = settingsTypeToWorkLoadKind(settingsType);
        return await clusterApi.post(`${WORKLOAD_API_PREFIX}/proxy/namespaces/${namespace}/${workloadType}`, data);
    },

    async submitWorkloadByType(project: string, settingsType: string, data: string): Promise<WorkloadResponse> {
        const namespace = await projectNameToWorkloadNameSpace(project);
        const workloadType = settingsTypeToWorkLoadKind(settingsType);
        return clusterApi.put(`${WORKLOAD_API_PREFIX}/proxy/namespaces/${namespace}/${workloadType}`, data);
    },

    async getWorkLoadAvailability(settingsType: string) {
        return (await clusterApi.get(`${WORKLOAD_API_PREFIX}/availability?type=${settingsType}`)).data;
    },
};
