
import ProfileDropdown from "./ProfileDropdown.vue";
import ClustersDropdown from "./ClustersDropdown.vue";
import HelpDropdown from "./HelpDropdown.vue";
import { ROUTES_ENUM } from "@/router/routes";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { POST_MESSAGE_EVENTS } from "@/utils/postmessageEvents";
import frontendV2Service from "@/services/frontend-v2-service";
import SettingsDropdown from "@/components/core/SettingsDropdown.vue";
import settingStore, { SettingKeys } from "@/stores/setting-store";
import clustersStore from "@/stores/clusters-store";

const BASE64_LOGO_PREFIX = "data:image/png;base64, ";

const CLUSTERS_ROUTES = [
    ROUTES_ENUM.CLUSTERS,
    ROUTES_ENUM.PERMISSIONS,
    ROUTES_ENUM.GENERAL_SETTINGS,
    ROUTES_ENUM.CLUSTERS_DASHBOARD,
];
const HIDE_CLUSTER_FOR_ROUTES = [
    ROUTES_ENUM.CLUSTERS,
    ROUTES_ENUM.CLUSTERS_NEW,
    ROUTES_ENUM.SUBMIT_DEPARTMENT,
    ROUTES_ENUM.EDIT_DEPARTMENT,
    ROUTES_ENUM.SUBMIT_WORKSPACES,
    ROUTES_ENUM.ROLES,
    ROUTES_ENUM.LICENSES,
];

export default defineComponent({
    components: {
        SettingsDropdown,
        ClustersDropdown,
        ProfileDropdown,
        HelpDropdown,
    },
    emits: ["toggleSidebar"],
    props: {
        pageTitle: String,
        userEmail: String,
        tenantName: String,
        fullWidthPage: Boolean,
        supportBack: Boolean,
        closeIcon: Boolean,
        readOnly: Boolean,
        logo: String,
    },
    setup(props, ctx) {
        const route = useRoute();
        const tooltipLeaveThisPage = ref(false);
        const tooltipViewOnly = ref(false);

        return {
            BASE64_LOGO_PREFIX,
            tooltipLeaveThisPage,
            tooltipViewOnly,
            toggleSideBar() {
                ctx.emit("toggleSidebar");
            },
            get isNotClusterRoute(): boolean {
                return (
                    !CLUSTERS_ROUTES.includes(route.name as ROUTES_ENUM) &&
                    !HIDE_CLUSTER_FOR_ROUTES.includes(route.name as ROUTES_ENUM)
                );
            },
            get allowedToLeave(): boolean {
                return !!clustersStore.state.clusters?.length
            },

            async closeBtnClicked() {
                tooltipLeaveThisPage.value = false;

                if (props.supportBack) {
                    frontendV2Service.send(POST_MESSAGE_EVENTS.ROUTE_BACK, { back: true, close: props.closeIcon });
                } else {
                    const res: any = await frontendV2Service.get(POST_MESSAGE_EVENTS.ON_ROUTE_LEAVE);
                    if (res.allowToLeave) {
                        frontendV2Service.send(POST_MESSAGE_EVENTS.ROUTE_BACK, null);
                    }
                }
            },
            get isTopSettingsEnabled(): boolean {
                return settingStore.state.kv[SettingKeys.TopSettings];
            },
        };
    },
});
