import { reactive } from "vue";
import { dispatcher } from "@/core-ui/services/dispatcher";
import tenantService, { AuthProviderData } from "@/services/tenant-service";
import { ContractTypes } from "@/types/contract-types";
import { WaitFor } from "@/helpers/WaitFor";
import registryIntegrationService, { DEFAULT_CREDENTIAL_KIND } from "@/services/registry-integration-service";
import { RegistrySpec } from "@/types/registry-types";
import { licenseService } from "@/services/license-service";
import { ILicense } from "@/types/license";

const DEFAULT_SERVER_CLIENT_ID = "runai";
const DEFAULT_REGISTRY_SPEC_VALUE: RegistrySpec = {
    credentialKind: DEFAULT_CREDENTIAL_KIND,
    user: "",
    url: "",
};
export type TenantStoreState = {
    contractType: ContractTypes;
    name: string;
    displayName: string;
    signedEula: boolean;
    id: number;
    authClientID: string;
    cliClientId: string;
    authRealm: string;
    ssoEntityId: string;
    ssoRedirectUri: string;
    smgEnabled: boolean;
    ssoEnabled: boolean;
    logo?: string;
    registryId: string;
    registrySpec: RegistrySpec;
    licenses: Array<ILicense>;
    inUseGPUs: number;
};

enum TenantType {
    SAAS_KEYCLOAK,
    SAAS_SSO,
    SELF_HOSTED,
    SELF_HOSTED_SSO,
}

export type TenantClientConfigInfo = {
    airgapped: boolean;
    authFlow: string;
    realm: string;
    clientId: string;
    idpIssuerUrl: string;
    redirectUri?: string;
};

export type TenantServerConfigInfo = {
    clientId: string;
    issuerUrl: string;
    groupsClaim?: string;
};

export type TenantConfigInfo = {
    clientConfigInfo: TenantClientConfigInfo;
    serverConfigInfo: TenantServerConfigInfo;
};

class TenantStore extends WaitFor<TenantStoreState> {
    state: TenantStoreState = reactive({
        displayName: "",
        signedEula: true,
        name: "",
        contractType: ContractTypes.Normal,
        id: -1,
        authClientID: "",
        authRealm: "",
        cliClientId: "",
        ssoEntityId: "",
        ssoRedirectUri: "",
        smgEnabled: false,
        ssoEnabled: false,
        logo: "",
        registryId: "",
        registrySpec: DEFAULT_REGISTRY_SPEC_VALUE,
        licenses: [],
        inUseGPUs: 0,
    });

    async readRegistries(): Promise<void> {
        try {
            const registries = await registryIntegrationService.getRegistries();

            if (registries && registries.entries.length > 0) {
                const registrySpec = registries.entries[0].spec;
                this.state.registryId = registries.entries[0].meta.id;
                this.state.registrySpec = registrySpec;
            }
        } catch (err) {
            console.error("Failed to get registries", err);
        }
    }

    async readLicenses(): Promise<void> {
        try {
            this.state.licenses = await licenseService.list(this.state.id.toString());
        } catch (err) {
            console.error("Failed to get licenses", err);
        }
        try {
            this.state.inUseGPUs = await licenseService.getTotalUsedGPUs();
        } catch (err) {
            console.error("Failed to get total gpus in use", err);
        }
    }

    resetRegistryFields(): void {
        this.state.registryId = "";
        this.state.registrySpec = DEFAULT_REGISTRY_SPEC_VALUE;
    }
    async readTenantData(hard = false) {
        const {
            name,
            displayName,
            eulaSigningUser,
            eula,
            contractType,
            authClientID,
            authRealm,
            id,
            ssoEntityId,
            ssoRedirectUri,
            cliClientId,
            smgEnabled,
        } = await dispatcher.executeWithErrorHandling(tenantService.getTenantData(hard));
        const logo = await dispatcher.executeWithErrorHandling(tenantService.getTenantLogo(id));
        this.state.name = name;
        this.state.displayName = displayName;
        this.state.signedEula = !!(eulaSigningUser || eula);
        this.state.contractType = contractType;
        this.state.id = id;
        this.state.authClientID = authClientID;
        this.state.authRealm = authRealm;
        this.state.cliClientId = cliClientId;
        this.state.ssoEntityId = ssoEntityId;
        this.state.ssoRedirectUri = ssoRedirectUri;
        this.state.smgEnabled = !!smgEnabled;
        if (logo) {
            this.state.logo = logo.logo;
        }
        this.state.ssoEnabled = !!ssoRedirectUri;
        await this.readRegistries();
        // await this.readLicenses();
        this.done(this.state);
    }

    async getTenantAuthProviderData(tenantName: string): Promise<AuthProviderData> {
        return tenantService.getTenantAuthProviderData(tenantName);
    }

    computeTenantType(authData: AuthProviderData): TenantType {
        if (authData.isSelfHosted) {
            if (authData.ssoEnabled) {
                return TenantType.SELF_HOSTED_SSO;
            } else {
                return TenantType.SELF_HOSTED;
            }
        } else {
            if (authData.ssoEnabled) {
                return TenantType.SAAS_SSO;
            } else {
                return TenantType.SAAS_KEYCLOAK;
            }
        }
    }

    getClientConfigInfo(tenantType: TenantType, authData: AuthProviderData): TenantClientConfigInfo {
        switch (tenantType) {
            case TenantType.SAAS_KEYCLOAK:
                return {
                    airgapped: true,
                    authFlow: "cli",
                    realm: this.state.authRealm,
                    clientId: "runai-cli",
                    idpIssuerUrl: authData.issuerUrl,
                    redirectUri: undefined,
                };
            case TenantType.SELF_HOSTED:
                return {
                    airgapped: true,
                    authFlow: "cli",
                    realm: "runai",
                    clientId: "runai",
                    idpIssuerUrl: authData.issuerUrl,
                    redirectUri: undefined,
                };
            case TenantType.SAAS_SSO:
            case TenantType.SELF_HOSTED_SSO:
                return {
                    airgapped: true,
                    authFlow: "remote-browser",
                    realm: this.state.authRealm,
                    clientId: "runai-cli",
                    idpIssuerUrl: authData.issuerUrl,
                    redirectUri: `${window.location.origin}/oauth-code`,
                };
        }
    }

    getServerConfigInfo(tenantType: TenantType, authData: AuthProviderData): TenantServerConfigInfo {
        switch (tenantType) {
            case TenantType.SAAS_KEYCLOAK:
            case TenantType.SELF_HOSTED:
                return {
                    clientId: DEFAULT_SERVER_CLIENT_ID,
                    issuerUrl: authData.issuerUrl,
                    groupsClaim: undefined,
                };
            case TenantType.SAAS_SSO:
            case TenantType.SELF_HOSTED_SSO:
                return {
                    clientId: DEFAULT_SERVER_CLIENT_ID,
                    issuerUrl: authData.issuerUrl,
                    groupsClaim: "groups",
                };
        }
    }

    async getTenantConfigInfo(): Promise<TenantConfigInfo> {
        await this.readTenantData();
        const authData = await this.getTenantAuthProviderData(this.state.name);
        const tenantType = this.computeTenantType(authData);
        const clientConfigInfo = this.getClientConfigInfo(tenantType, authData);
        const serverConfigInfo = this.getServerConfigInfo(tenantType, authData);
        return { clientConfigInfo, serverConfigInfo };
    }
}

const tenantStore = new TenantStore();
export default tenantStore;
