import { reactive } from "vue";
import axios from "axios";
import apiClient from "@/services/api-client";
import tenantStore from "@/stores/tenantStore";

class GrafanaStore {
    state: { grafanaLoggedIn: boolean } = reactive({
        grafanaLoggedIn: false,
    });

    loadedGrafanaIFrame() {
        this.state.grafanaLoggedIn = true;
    }

    async init() {
        if (tenantStore.state.smgEnabled) {
            await this.smgLogin();
        }
    }

    /*
        This call to the SMG (self-managed Grafana) does 2 things:
        1. sets the grafana cookie
        2. sets the grafana loggedIn flag
        The SMG will always be in the /grafana path of the current location (test.run.ai/grafana, app.run.ai/grafana, etc).
    */
    async smgLogin(): Promise<any> {
        console.debug(`reloading grafana token to cookie for smg tenant: ${tenantStore.state.authRealm}`)
        const headers: Record<string, string> = {};
        headers["Authorization"] = `Bearer ${apiClient.access_token}`;
        headers["Realm-Name"] = `${tenantStore.state.authRealm}`;
        return axios.get(location.origin + `/grafana`, { headers }).then(() => {
            this.loadedGrafanaIFrame();
        });
    }
}

const grafanaStore = new GrafanaStore();
export default grafanaStore;