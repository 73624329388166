import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e66ee594"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fields-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Expend = _resolveComponent("Expend")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
      return (_openBlock(), _createBlock(_component_Expend, {
        default: !category.title,
        key: category.key,
        title: category.title,
        "not-valid": category.notValid()
      }, {
        default: _withCtx(() => [
          (!!_ctx.$slots[category.key])
            ? _renderSlot(_ctx.$slots, category.key, {
                key: 0,
                fields: _ctx.getCategoryFields(category.key, false)
              }, undefined, true)
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.getCategoryFields(category.key, false), (field) => {
                return (_openBlock(), _createBlock(_resolveDynamicComponent(field.component), _mergeProps({
                  key: field.key,
                  focousable: !field.hidden
                }, field.args, { variant: "column" }), null, 16, ["focousable"]))
              }), 128))
        ]),
        _: 2
      }, 1032, ["default", "title", "not-valid"]))
    }), 128))
  ]))
}