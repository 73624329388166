
import { FetcherState, isLoading } from "@/core-ui/data-grid/compositions";
import IconButton from "@/core-ui/IconButton.vue";
import ProgressBar from "@/core-ui/ProgressBar.vue";
import { orderBy } from "lodash";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
    components: { ProgressBar, IconButton },
    props: {
        itemId: { type: String, require: true },
        state: String as PropType<FetcherState>,
        items: Array,
        groupBy: String,
        open: Boolean,
        filter: [Function, Array],
        selected: String,
    },
    setup(props, ctx) {
        const groups = computed(() => {
            if (!props.items?.length) {
                return [];
            }
            if (!props.groupBy) {
                return [{ items: props.items }];
            }
            const defaultGroup = [];
            const groups: any = {};

            for (const item of props.items!) {
                const key = (item as any)[props.groupBy];
                if (key) {
                    let group = groups[key];
                    if (!group) {
                        group = { key, title: key, items: [] };
                        groups[key] = group;
                    }
                    group.items.push(item);
                } else {
                    defaultGroup.push(item);
                }
            }
            const orderedGroup = orderBy(Object.values(groups), "title");
            if (defaultGroup.length) {
                orderedGroup.unshift({ key: "__def__", items: defaultGroup });
            }
            return orderedGroup;
        });

        const receiveRectMap = new Map();
        const sendRectMap = new Map();

        return {
            groups,
            toggle() {
                ctx.emit("update:open", !props.open);
            },
            get isLoading() {
                return isLoading(props.state!);
            },

            get isZeroItems(): boolean {
                return !!props.items?.length;
            },

            beforeEnter(el: any) {
                el.style.opacity = 0;
            },
            leave(el: any, done: any) {
                const rect = el.getBoundingClientRect();
                sendRectMap.set(el.dataset.key, rect);
                el.style.display = "none";
            },
            enter(el: any, done: any): void {
                const to = el.getBoundingClientRect();
                const key = el.dataset.key;
                receiveRectMap.set(key, to);

                requestAnimationFrame(() => {
                    const key = el.dataset.key;
                    const to = receiveRectMap.get(key);
                    const from = sendRectMap.get(key);
                    if (from) {
                        const dx = from.left - to.left;
                        const dy = from.top - to.top;

                        el.style.transform = `translate(${dx}px, ${dy}px)`;
                    }
                    el.style.transition = "all 0ms";
                    requestAnimationFrame(() => {
                        el.style.transition = "all 800ms";
                        el.style.transform = "";
                        el.style.opacity = 1;
                        el.style.display = "block";
                    });
                });
            },
        };
    },
});
