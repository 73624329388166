import apiClient from "./api-client";
import { Registry, RegistryResponse } from "@/types/registry-types";
import { RepositoriesListResponse, TagsListResponse } from "@/cluster-ui/components/submit/types";
import { clusterApi } from "@/cluster-ui/services/apiClient";
import tenantStore from "@/stores/tenantStore";

export const DEFAULT_CREDENTIAL_KIND = "password";
export const REGISTRY_SCOPE = "tenant";
class RegistryIntegrationService {
    getRegistries(): Promise<RegistryResponse> {
        return apiClient.asyncRequestWithResponse(`api/v1/asset/registries`, "GET");
    }

    createRegistry(payload: Registry): Promise<RegistryResponse> {
        return apiClient.asyncRequestWithResponse(`api/v1/asset/registries`, "POST", payload);
    }

    deleteRegistry(id: string): Promise<void> {
        return apiClient.asyncRequestWithResponse(`api/v1/asset/registries/${id}`, "DELETE");
    }

    async getRepositories(query: string): Promise<RepositoriesListResponse> {
        const registryId = tenantStore.state.registryId;
        if (!registryId) {
            return { repositories: [] };
        }
        const response = await clusterApi.get(
            `/api/v1/asset/registries/${registryId}/repositories?searchName=${query}`,
            {},
            true,
        );

        return response.data;
    }

    async getTags(repository: string, query: string): Promise<TagsListResponse> {
        const registryId = tenantStore.state.registryId;
        if (!registryId) {
            return { tags: [] };
        }
        const response = await clusterApi.get(
            `/api/v1/asset/registries/${registryId}/repositories/tags?repository=${repository}&searchName=${query}`,
            {},
            true,
        );
        return response.data;
    }
}

const registryIntegrationService = new RegistryIntegrationService();
export default registryIntegrationService;
