import Vue from "vue";
import authStore from "@/stores/authStore";
import clustersStore from "@/stores/clusters-store";
import tenantStore from "@/stores/tenantStore";

class AdminStore {
    get userIsRunAiEmp() {
        const email = authStore.userInfo.email || "";
        return email.split("@")[1] === "run.ai";
    }

    get shouldBlockScreen() {
        const isAdmin = this.isAdmin;
        const needToSignEula = !(this.userIsRunAiEmp || tenantStore.state.signedEula);

        return isAdmin && needToSignEula;
    }

    get isAdmin() {
        return authStore.isAdmin;
    }
}

const adminStore = new AdminStore();

export default adminStore;
