
import { defineComponent } from "vue";
import { variant, fieldCtrl } from "./common";

export default defineComponent({
    props: {
        theme: String,
        variant,
        valueFirst: Boolean,
        ctrl: fieldCtrl(),
    },
    setup: (props, ctx) => ({
        get invalid(): boolean {
            return props.ctrl?.valid == "invalid";
        },
        get isEmpty() {
            let v = props.ctrl!.value as any;
            return v == undefined || v == null || v == "";
        },
    }),
});
