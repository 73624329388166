import { reactive } from "vue";
import clustersService, { Dashboards } from "../services/clusters-service";
import prometheusService from "../services/prometheus-service";
import { Cluster } from "@/types/cluster";
import { storageService } from "@/core-ui/data-grid/services";
import frontendV2Service from "@/services/frontend-v2-service";
import { POST_MESSAGE_EVENTS } from "@/utils/postmessageEvents";
import authStore, { LocalKeys } from "@/stores/authStore";
import axios, { AxiosRequestConfig } from "axios";


const ACTIVE_CLUSTER_STORAGE = "currentActiveCluster";

export interface ClustersState {
    clusters: Array<any>;
    hasActiveClusters: boolean;
    checkedCluster: any;
    isCheckedClusterActive: boolean;
    autoCheckTimeout: number;
    autoCheck: boolean;
    activeClusterUUID: string;
    dashboards: Dashboards;
}

class ClustersStore {
    private clustersPromise = null as any as Promise<Array<Cluster>>;

    state = reactive<ClustersState>({
        clusters: null as any,
        hasActiveClusters: true,
        checkedCluster: null,
        isCheckedClusterActive: true,
        autoCheckTimeout: 0,
        autoCheck: false,
        activeClusterUUID: null as any,
        dashboards: {},
    });

    constructor() {
        const storedSelectedCluster = storageService.getStr(ACTIVE_CLUSTER_STORAGE, null);
        if (storedSelectedCluster) {
            this.setActiveCluster(storedSelectedCluster);
        }
    }

    get activeClusterUUID() {
        return this.state.activeClusterUUID;
    }

    get activeCluster() {
        const clusters = this.state.clusters || [];
        return clusters.find((cluster: Cluster) => cluster.uuid === clustersStore.state.activeClusterUUID);
    }

    getClusterByUuid(uuid: string): Cluster | undefined {
        return this.state.clusters.find((cluster: Cluster) => cluster.uuid === uuid);
    }

    fetchClusters(isPostLoginAction: boolean = false): Promise<Array<Cluster>> {
        if (!this.clustersPromise) {
            this.clustersPromise = clustersService.query().then(async (clusters) => {
                this.state.clusters = clusters;
                await this.verifyActiveCluster(clusters, isPostLoginAction);
                this.clustersPromise = null as any as Promise<Array<Cluster>>;
                return clusters;
            });
        }
        return this.clustersPromise;
    }

    async fetchThenUpdateCheckedAndActiveClusters() {
        const clusters = await this.fetchClusters();
        if (clusters.length === 0) {
            this.state.checkedCluster = null;
            this.state.hasActiveClusters = false;
            return;
        }
        this.state.checkedCluster = clusters[0];
        this.state.hasActiveClusters = await this.checkPendingCluster();
    }

    async setClusterDashboards(uuid?: string) {
        // If no cluster exists, clusterUuid will be undefined and the dashboards will be the default one ()
        let clusterUuid: string | undefined;

        if (uuid) clusterUuid = uuid;
        else clusterUuid = this.state.activeClusterUUID ? this.state.activeClusterUUID : undefined;

        const clusterDashboards = await clustersService.getGrafanaDashboards(clusterUuid);
        this.state.dashboards = clusterDashboards;
    }

    async checkPendingCluster(clusterId?: string): Promise<boolean> {
        const connectedNodes = await prometheusService.getFilteredResultMultipleQueries(
            {
                general: `sum(kube_node_status_condition${clusterId ? `{clusterId='${clusterId}'}` : ""}) by (node)`,
            },
            "node",
        );

        return !!connectedNodes.length;
    }

    stopCheckPendingActivity() {
        this.state.autoCheckTimeout = 0;
        this.state.autoCheck = false;
        clearTimeout(this.state.autoCheckTimeout);
    }

    async setActiveCluster(uuid: string) {
        this.state.activeClusterUUID = uuid;
        storageService.setStr(ACTIVE_CLUSTER_STORAGE, uuid);
        this.stopCheckPendingActivity();
        frontendV2Service.send(POST_MESSAGE_EVENTS.CURRENT_CLUSTER_CHANGED, {});
        await this.check(uuid);
    }

    isClusterExist(uuid: string): boolean {
        if (!this.state.clusters) return false;
        return !!this.state.clusters.find(cluster => cluster.uuid === uuid);
    }

    private async verifyActiveCluster(clusters: Array<Cluster>, isPostLoginAction: boolean): Promise<void> {
        const cluster = this.getSelectedClusterFromLogin(clusters);
        if (cluster) {
            this.setActiveCluster(cluster.uuid); 
        }
        if (!isPostLoginAction) {
            const activeCluster = this.getActiveClusterFromStore(clusters);
            if (activeCluster) {
                return
            }
        }
        if (clusters.length === 1) {
            this.setActiveCluster(clusters[0].uuid);
        }
        if (clusters.length > 1) {
            this.getFirstActiveCluster(clusters);
        }
    }

    private async check(clusterUuid?: string) {
        if (!this.state.autoCheck) return;
        try {
            const isActive = await this.checkPendingCluster(this.state.checkedCluster.uuid);
            this.state.isCheckedClusterActive = isActive;
            this.state.checkedCluster.active = isActive;
            if (!clusterUuid) {
                // change also global check for active clusters
                this.state.hasActiveClusters = isActive;
            }
        } catch (e) {
            //
        }
        this.state.autoCheckTimeout = setTimeout(() => this.check(this.state.activeClusterUUID), 5000) as any as number;
    }

    private getSelectedClusterFromLogin(clusters: Array<Cluster>): Cluster | undefined {
        return clusters.find((cluster) => cluster.name === authStore.userInfo.identityProvider)
    }

    private getActiveClusterFromStore(clusters: Array<Cluster>): Cluster | undefined {
        return clusters.find((cluster) => cluster.uuid === this.state.activeClusterUUID)
    }

    private async getFirstActiveCluster(clusters: Array<Cluster>) {
        for (const cluster of clusters) {
            const responseStatus = await this.checkClusterConnection(cluster.domain)
            if (responseStatus === 200) {
                this.setActiveCluster(cluster.uuid);
                break;
            }
        }
    }

    private async checkClusterConnection(clusterDomain: string): Promise<number> {
        const jwt = storageService.getStr(LocalKeys.accessToken);
        const extrnalToken = storageService.getStr(LocalKeys.externalToken);
        const headers = this.getRequestHeaders(extrnalToken,jwt);
        let requestOptions: AxiosRequestConfig = {
            url:`${clusterDomain}/researcher/api/v1/whoami`,
            method: 'GET',
            headers: headers,
        };
        try {
            //call whoami with external token (if exists)
            const response = await axios(requestOptions)
            return response.status
        } catch {
            try {
                //call whoami with internal token
                requestOptions.headers = this.getRequestHeaders("", jwt)
                const response = await axios(requestOptions)
                return response.status
            } catch {
                return 401
            }
        }
    }

    private getRequestHeaders(extrnalToken: string, jwt: string) {
        return extrnalToken ? {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': `Bearer ${extrnalToken}`,
            'AuthInfo': `Bearer ${jwt}`,
        } : {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': `Bearer ${jwt}`,
        }
    }


}

const clustersStore = new ClustersStore();
export default clustersStore;
