const cmp = require("semver-compare");

export function isNewerVersion(versionToTest: string, baseVersion: string): boolean {
    try {
        // this is a duplicate code from frontend-v2 version.service.ts
        if (versionToTest.slice(0, 1) == "v") versionToTest = versionToTest.slice(1);
        if (versionToTest.includes("-rc")) versionToTest = versionToTest.slice(0, versionToTest.search("-rc"));
        if (versionToTest.includes("-staging")) versionToTest = versionToTest.slice(0, versionToTest.search("-staging"));
        if (baseVersion.slice(0, 1) == "v") baseVersion = baseVersion.slice(1);

        const compareVersionsResult = cmp(versionToTest, baseVersion);
        return compareVersionsResult >= 0;
    } catch (e) {
        console.warn("version check failed, err: ", e);
        return false;
    }
}
