
import Page from "@/core-ui/Page.vue";
import Submit from "@/cluster-ui/views/Submit.vue";
import { defineComponent } from "vue";
import { clusterApi } from "@/cluster-ui/services/apiClient";
import { activeClusterConnectionInfoStore } from "@/stores/activeClusterConnectionInfoStore";
import Modal from "@/core-ui/Modal.vue";
import { isNewerVersion } from "@/utils/versionUtil";
import { MIN_CLI_VERSION, TEST_ENV_VERSION } from "@/common/version.constant";

export default defineComponent({
    components: {
        Page,
        Modal,
        Submit,
    },
    setup() {
        try {
            clusterApi.updateProps({
                get baseUrl() {
                    return `${activeClusterConnectionInfoStore.domain}/researcher`;
                },
                get connected() {
                    return !!activeClusterConnectionInfoStore.connected;
                },
                get jwt() {
                    return activeClusterConnectionInfoStore.jwt;
                },
                get externalToken() {
                    return activeClusterConnectionInfoStore.externalToken;
                },
            });
        } catch (e) {
            console.log("error ", e);
        }

        const self = {
            get isOldUI() {
                return !(isNewerVersion(activeClusterConnectionInfoStore.activeClusterVersion, MIN_CLI_VERSION) || activeClusterConnectionInfoStore.activeClusterVersion.includes(TEST_ENV_VERSION));
            },
        };
        return self;
    },
});
