
import { computed, defineComponent, ref } from "vue";
import CodeBlock from "@/components/CodeBlock.vue";

import cliService from "@/services/cli-service";

export default defineComponent({
    name: "ResearcherDownloadCli",
    components: {
        CodeBlock,
    },
    setup() {
        const osModel = ref("");
        const downloadLink = computed(() => cliService.cliUrl(osModel.value));
        const copylUrl = computed(() => `wget --content-disposition ${cliService.cliUrl(osModel.value)}`);
        const showWindows = computed(() => cliService.WindowsCliAvailable());

        return {
            osModel,
            copylUrl,
            downloadLink,
            showWindows
        };
    },
});
