import { ref } from "vue";
import { useObjCtrl } from "./ObjCtrl";
import { ContainerCtrl, DisplayGroupsSelector, FieldKey, FieldValidStatus, ValuePipe } from "./types";

export type FormCtrl<T extends Record<string, any> = Record<string, any>> = ContainerCtrl<T> & {
    onSubmit: (e: Event) => void;
    isChildrenValid(key: FieldKey): FieldValidStatus;
    isCategoryOrGroupsValid(category?: string, groups?: string[]): FieldValidStatus;
    submitted: boolean;
    error: string;
    info: string;
};

export type FormCtrlArgs<T> = {
    key: string;
    groupsSelector?: DisplayGroupsSelector;
    action: (value: T | unknown) => Promise<any> | void;
    customMessages?: ValuePipe;
    mapValue?(value: T): unknown;
};

export function useFormCtrl<T>(args: FormCtrlArgs<T>): FormCtrl<T> {
    const objCtrl = useObjCtrl<T>(args);
    const submitted = ref(false);
    const error = ref("");
    const info = ref("");

    objCtrl.state = objCtrl.createState({
        defaultEditable: true,
    });

    function valid(): boolean {
        return objCtrl.valid == "valid";
    }

    return {
        type: "obj",
        get id() {
            return objCtrl.id;
        },
        get declaration() {
            return args;
        },
        get value(): T {
            return objCtrl.value as T;
        },
        set value(v: T) {
            objCtrl.value = v;
        },
        get valid() {
            return objCtrl.valid;
        },
        get hidden() {
            return objCtrl.hidden;
        },
        get editable() {
            return objCtrl.editable;
        },
        get key() {
            return args.key;
        },
        get children() {
            return objCtrl.children;
        },
        get submitted() {
            return submitted.value;
        },
        get error() {
            return error.value;
        },
        get info() {
            return info.value;
        },
        get dirty() {
            return objCtrl.dirty;
        },
        context: objCtrl.context,
        clear: objCtrl.clear,
        add: objCtrl.add,
        remove: objCtrl.remove,
        isChildrenValid: objCtrl.isChildrenValid,
        isCategoryOrGroupsValid: objCtrl.isCategoryOrGroupsValid,
        isChildrenHidden: objCtrl.isChildrenHidden,
        async onSubmit(e?) {
            if (e?.preventDefault) {
                e.preventDefault();
            }

            if (submitted.value || !valid()) {
                return;
            }
            submitted.value = true;
            let value: any = objCtrl.value as T;
            if (args.mapValue) {
                value = args.mapValue(value);
            }
            try {
                await args.action(value);
            } catch (e) {
                error.value = "Failed to submit";
                submitted.value = false;
            }
        },
        get state() {
            return objCtrl.state;
        },
        createState: objCtrl.createState,
    };
}
