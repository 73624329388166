import { useModalAction } from './ModalAction';
import { ModelMeta } from './DataGridModel';
import { ActionMsg } from '../types';
import { capitalize } from '@/utils/textUtil';

export type DeleteModalAction<T = unknown> = {
    isOpening: boolean;
    isDeleting: boolean;
    displayName: string;
    entityType: string;
    note?: string;
    isMulti: boolean;
    count: number;
    handleData: T[];
    handle(data: T | undefined): void;
    cancel(): void;
    delete(data: any): void;
};

type DeleteModalActionProps<T> = {
    note?: string;
    modelMeta: ModelMeta<T>;
    delete: (data: T[]) => Promise<void>;
};

export function useDeleteModalAction<T = any>(props: DeleteModalActionProps<T>): DeleteModalAction<T> {
    const am = useModalAction({
        mapHandleData: (d) => (d instanceof Array ? d : [d]),
        submit: handleDelete,
    });

    function successMsg() {
        return {
            type: 'success',
            msg: `${capitalize(props.modelMeta.singular)} ${displayName()} was deleted`,
        };
    }

    function errorMsg(err: string) {
        const errorStr = `Failed to delete ${isMulti() ? `${count()} ${props.modelMeta.plural}` : displayName()}`;
        const lowerCaseErr = err.toLowerCase();
        const isAccessDenied = lowerCaseErr.includes("access denied") || lowerCaseErr.includes("not allowed");
        return {
            type: 'failure',
            msg: isAccessDenied ? `${errorStr}: Access Denied` : errorStr,
        };
    }

    async function handleDelete(): Promise<ActionMsg> {
        const data = am.handleData!;
        const sm = successMsg();
        try {
            await props.delete(data);
            return sm as ActionMsg;
        } catch (err: any) {
            if (typeof err === "string") {
                const em = errorMsg(err);
                return em as ActionMsg;
            } else if (typeof err === "object" && err.status === 409) { 
                return {
                    type: 'failure',
                    msg: err.description
                } as ActionMsg;
            } else {
                return {
                    type: 'failure',
                    msg: `Could not delete the ${props.modelMeta.singular}`
                } as ActionMsg;
            }
        }
    }

    function displayName() {
        return am.handleData && am.handleData.length == 1 ? props.modelMeta.display(am.handleData[0]) : '';
    }

    function count() {
        return am.handleData ? am.handleData.length : 0;
    }

    function isMulti() {
        return am.handleData && am.handleData.length > 1;
    }

    return {
        get isOpening() {
            return am.isOpening;
        },
        get isDeleting() {
            return am.isSubmitting;
        },
        get displayName() {
            return displayName();
        },
        get entityType() {
            return props.modelMeta.type;
        },
        get isMulti() {
            return !!isMulti();
        },
        get count() {
            return count();
        },
        get note() {
            return props.note;
        },
        get handleData() {
            return am.handleData as T[];
        },
        handle: am.handle as any,
        cancel: am.cancel,
        delete: am.submit,
    };
}
