
import Button from "@/core-ui/Button.vue";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        required: Boolean,
        label: String,
        modelValue: String,
        options: { type: Array, require: true },
    },
    components: { Button },

    setup(props, ctx) {
        return {
            select: (value: any) => {
                if (props.modelValue == value) {
                    if (!props.required) {
                        ctx.emit("update:modelValue", "");
                    }
                } else {
                    ctx.emit("update:modelValue", value);
                }
            },
        };
    },
});
