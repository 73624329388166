
import { useFieldCtrlArgsFromProps, useInputPipeAndMeta } from "@/core-ui/forms/compositions";
import { defineComponent, PropType, ref } from "vue";
import { fieldCtrl, inputName, variant } from "./common";
import BoxButtonSelectCtrl from "@/core-ui/forms/components/BoxButtonSelectCtrl.vue";

export default defineComponent({
    components: { BoxButtonSelectCtrl },
    props: {
        theme: String,
        variant,
        name: inputName,
        label: String,
        description: String,
        default: String,
        required: Boolean,
        category: String,
        options: { type: Array, require: true },
        ctrl: fieldCtrl<string>(),
    },

    setup: (props, ctx) => {
        const ctrlArgs = useFieldCtrlArgsFromProps({
            props,
            pipeAndMeta: useInputPipeAndMeta(props as any),
        });

        return {
            ctrlArgs,
        };
    },
});
