import { reactive, ref } from "vue";
import { createValueProxy } from "./helpers";
import { Ctrl, CtrlArgs, FieldKey, FieldValidStatus, DisplayGroupsSelector, ContainerState } from "./types";

export type ObjState<T extends Record<string, any>> = ContainerState<T> & {
    childrenCtrlArgs: Map<FieldKey, CtrlArgs>;
    cachedValue: Map<FieldKey, any>;
    isCachedValueQuite: Set<string>;
    editable?: boolean;
    isCategoryOrGroupsValid(category?: string, groups?: string[]): FieldValidStatus;
    isChildrenValid(key: FieldKey): FieldValidStatus;
};

export type ObjStateArgs<T> = {
    key: FieldKey;
    parent?: ContainerState;
    editable?: boolean;
    defaultEditable: boolean;
    groupsSelector?: DisplayGroupsSelector;
};

export function useObjState<T extends Record<string, any>>(args: ObjStateArgs<T>): ObjState<T> {
    const cachedValue = new Map();
    const isCachedValueQuite = new Set<string>();
    const childrenStates = new Map();
    const childrenControls = new Map();
    const childrenDecelerations = new Map();
    const childrenCount = ref(0);
    const declarationCount = ref(0);
    const context = reactive({
        hidden: false,
        editable: null,
    });

    // proxy value
    const proxyValue = createValueProxy(
        childrenStates,
        cachedValue,
        () => childrenCount.value,
        () => childrenCount.value++,
    );

    // hidden fields

    const hiddenChildren = ref(new Set());
    // const displayGroup = ref(args.groupsSelector || { and: new Set<string>(), or: new Set<string>() })

    // messages
    // todo: for future use
    // const error = ref("");
    // const info = ref("");

    // const childrenErr = reactive({});
    // const childrenInfo = reactive({});

    function isHidden() {
        return !!(context.hidden || (args.parent && args.parent.isChildrenHidden(args.key)));
    }

    function setValue(v: T, quite = false): void {
        const setKeys = new Set();
        for (const [key, value] of Object.entries(v || {})) {
            setKeys.add(key);
            const state = childrenStates.get(key);
            if (state) {
                if (quite) {
                    state.setQuite(value);
                } else {
                    state.value = value;
                }
            } else {
                if (quite) {
                    isCachedValueQuite.add(key);
                }
                cachedValue.set(key, value);
            }
        }

        for (const [key, state] of [...childrenStates]) {
            if (!setKeys.has(key)) {
                if (quite) {
                    state.setQuite(undefined);
                } else {
                    state.value = undefined;
                }
            }
        }
    }

    const self: ObjState<T> = {
        type: "obj",
        context,
        cachedValue,
        isCachedValueQuite,
        clear() {
            cachedValue.clear();
            isCachedValueQuite.clear();

            childrenStates.clear();
            childrenControls.clear();
            childrenDecelerations.clear();
            childrenCount.value = 0;
            declarationCount.value = 0;
        },
        get dirty() {
            childrenCount.value;
            return [...childrenStates.values()].some((s) => s.dirty);
        },
        get hidden() {
            return isHidden();
        },
        get editable() {
            return args.editable ?? context.editable ?? args.defaultEditable;
        },

        get children() {
            // calling it for reactivation
            childrenCount.value;
            return childrenStates;
        },
        get childrenCtrlArgs() {
            // calling it for reactivation
            declarationCount.value;
            return childrenDecelerations;
        },
        get value(): T {
            // calling it for reactivation
            //childrenCount.value
            return proxyValue;
        },
        set value(v: T) {
            setValue(v);
        },

        setQuite(v: T) {
            setValue(v, true);
        },

        get changedValue(): any {
            if (!self.dirty) {
                return undefined;
            }
            const result: any = {};
            for (const [key, state] of [...childrenStates]) {
                if (state.dirty) {
                    result[key] = state.changedValue;
                }
            }
            return result;
        },
        apply(value?: Record<string, any>) {
            if (value == undefined || value == null) {
                return;
            }
            for (const [key, v] of Object.entries(value)) {
                const state = childrenStates.get(key);
                if (state) {
                    state.apply(v);
                }
            }
        },
        async reset() {
            cachedValue.clear();
            isCachedValueQuite.clear();
            // todo
            // if (args.default) {
            //     self.value = args.default
            // } else {}
            for (const state of childrenStates.values()) {
                await state.reset();
            }
        },
        get defaultEditable() {
            return self.editable ?? args.defaultEditable ?? true;
        },
        get valid() {
            // calling it for reactivation
            childrenCount.value;
            let isCheck = false;
            for (const v of childrenStates.values()) {
                const isValid = v.valid;
                if (isValid == "invalid") {
                    return isValid;
                } else if (isValid == "check") {
                    isCheck = true;
                }
            }
            return isCheck ? "check" : "valid";
        },
        isChildrenHidden(key: FieldKey) {
            if (isHidden()) {
                return true;
            }
            childrenCount.value;
            const state = childrenStates.get(key);
            if (!state) {
                return false;
            }
            if (hiddenChildren.value.has(key)) {
                return true;
            }

            const { meta: { groups } = {} as any } = state;
            if (!groups || !groups.length) {
                return false;
            }
            const { or, and } = args.groupsSelector || ({} as any);
            let isOr = !or?.size;
            let isAnd = !and?.size;

            if (!isOr) {
                for (const group of groups) {
                    if (or.has(group)) {
                        isOr = true;
                    }
                }
            }

            if (!isAnd) {
                let count = 0;
                for (const group of groups) {
                    if (and.has(group)) {
                        count++;
                    }
                }
                isAnd = count === and.size;
            }

            return !(isOr && isAnd);
        },
        isChildrenValid(key: FieldKey) {
            childrenCount.value;
            return childrenStates.get(key)?.valid;
        },
        isCategoryOrGroupsValid(category: string, groups = []) {
            childrenCount.value;
            let isCheck = false;
            for (const cv of childrenControls.values()) {
                for (const v of cv.values()) {
                    if (v.meta?.category !== category && !v.meta?.groups?.some((g: string) => groups.indexOf(g) > -1)) {
                        continue;
                    }
                    const isValid = v.valid;
                    if (isValid == "invalid") {
                        return isValid;
                    } else if (isValid == "check") {
                        isCheck = true;
                    }
                }
            }
            return isCheck ? "check" : "valid";
        },
        add(ctrl: Ctrl<unknown>) {
            const key = ctrl.key;
            const id = ctrl.id;
            const currentState = childrenStates.get(key);
            if (currentState) {
                if (currentState.type == ctrl.type) {
                    ctrl.state = currentState;
                    childrenControls.get(key).set(id, ctrl);
                    childrenCount.value += 1;
                    return;
                }
            }
            if (!childrenDecelerations.has(key)) {
                declarationCount.value += 1;
            }
            childrenDecelerations.set(ctrl.key, ctrl.declaration);
            const state = ctrl.createState({
                get defaultEditable() {
                    return args.editable ?? context.editable ?? args.defaultEditable;
                },
            });
            ctrl.state = state;
            childrenStates.set(ctrl.key, state);
            childrenControls.set(key, new Map([[id, ctrl]]));
            if (cachedValue.has(key)) {
                if (isCachedValueQuite.delete(key as string)) {
                    state.setQuite(cachedValue.get(key));
                } else {
                    state.value = cachedValue.get(key);
                }
                cachedValue.delete(key);
            }
            // do it after adding the children
            childrenCount.value += 1;
        },
        remove(ctrl: Ctrl<unknown>): boolean {
            const oldState = childrenStates.get(ctrl.key);
            if (oldState && childrenControls.get(ctrl.key).has(ctrl.id)) {
                childrenControls.get(ctrl.key).delete(ctrl.id);
                if (childrenControls.get(ctrl.key).size == 0) {
                    childrenStates.delete(ctrl.key);
                    childrenCount.value -= 1;
                }
            }
            if (childrenDecelerations.has(ctrl.key) && childrenControls.get(ctrl.key).size == 0) {
                declarationCount.value -= 1;
                childrenDecelerations.delete(ctrl.key);
            }
            return !!oldState;
        },
        removeByKey(key: FieldKey) {
            childrenStates.delete(key) && childrenCount.value--;
            childrenControls.delete(key) && declarationCount.value--;
            childrenDecelerations.delete(key);
        },
        addDeclaration(args: CtrlArgs) {
            if (!childrenDecelerations.has(args.key)) {
                declarationCount.value += 1;
                childrenDecelerations.set(args.key, args);
            }
        },
    };

    return self;
}
