import "@/core-ui/style";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Toast, { POSITION, PluginOptions } from "vue-toastification";
import DataGridDirectives from "@/core-ui/data-grid/directives";
import "@/core-ui/style";
import vueTippy from "vue-tippy";
import authStore from "./stores/authStore";
import grafanaStore from "./stores/grafanaStore";
import configService from "./services/config-service";
import tenantStore from "./stores/tenantStore";
import pendoService from "@/services/pendo-service";
import { registerCustomComponents } from "@/cluster-ui/compositions/declarativeCustomFields";
import * as mdb from "mdb-vue-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "@/core-ui/style/icons";
import "./plugins";

const options: PluginOptions = {
    position: POSITION.BOTTOM_RIGHT,
    hideProgressBar: true, // until the vue-toastification bug (https://github.com/Maronato/vue-toastification/issues/157) fixed, we should hide the progress bar
    timeout: 3000, // after re-showing the progress bar we can extend the timeout
};

const InitPendoDOMElement = () => {
    (function (apiKey) {
        (function (p: any, e?: any, n?: any, d?: any, o?: any) {
            let w: any, x: any;
            o = p[d] = p[d] || {};
            o._q = [];
            const v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
            for (w = 0, x = v.length; w < x; ++w)
                (function (m) {
                    o[m] =
                        o[m] ||
                        function (...args: any[]) {
                            o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(args, 0)));
                        };
                })(v[w]);
            const y = e.createElement(n);
            y.async = !0;
            y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
            const z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo");
    })("864fbd80-1c56-4f6b-40ed-ac85929e4c18");
};

registerCustomComponents();

window.addEventListener("load", () => {
    history.pushState(null, "", document.URL);
    window.onpopstate = function () {
        if (history.state != null && history.state.back == null) {
            history.pushState(null, "", document.URL);
        }
    };
});

configService.init().then(() => {
    if (!configService.config.selfHosted) {
        InitPendoDOMElement();
    }

    let initApplication = async () => {
        const app = createApp(App).use(router).use(DataGridDirectives);

        app.mount("#app");
        app.component("FontAwesomeIcon", FontAwesomeIcon);
        app.use(Toast, options);
        app.use(vueTippy, {
            defaultProps: {
                theme: "ra-theme",
            },
        });

        for (const component in mdb) {
            app.component(component, mdb[component]);
        }

        const loaderEl = document.querySelector(".ra-loading-container");
        loaderEl?.parentNode?.removeChild(loaderEl);
        // app.config.productionTip = false;

        if (!configService.config.selfHosted) {
            try {
                await tenantStore.wait();
                pendoService.initUser(authStore.userInfo, tenantStore.state);
            } catch (e) {
                // failed to load the pendo
            }
        }
    }

    authStore.init().then(() => grafanaStore.init()).then(initApplication);
});
