import { ContractTypes } from "@/types/contract-types";
import apiClient from "./api-client";

type Data = {
    contractType: ContractTypes;
    grafanaMetricsAuth: any;
    grafanaUrl: string;
    name: string;
    displayName: string;
    eulaSigningUser: string;
    eula: string;
    id: number;
    authClientID: string;
    authRealm: string;
    ssoEntityId: string;
    ssoRedirectUri: string;
    cliClientId: string;
    smgEnabled?: boolean;
};

export type AuthProviderData = {
    ssoEnabled: boolean;
    isSelfHosted: boolean;
    issuerUrl: string;
};

export type Logo = {
    logo?: string;
};

class TenantService {
    private tenantDataPromise: Promise<Data> = undefined as any;

    getTenantData(hard = false) {
        if (hard || !this.tenantDataPromise) {
            this.tenantDataPromise = apiClient.asyncRequestWithResponse(`v1/k8s/tenant`, "GET") as Promise<any>;
        }
        return this.tenantDataPromise;
    }

    getTenantKubeUser() {
        return apiClient.asyncRequestWithResponse(`v1/k8s/tenant/kube-user`, "GET");
    }

    signEULA() {
        return apiClient.request(`v1/k8s/tenant/sign-eula`, "POST");
    }

    async getTenantAuthProviderData(tenantName: string): Promise<AuthProviderData> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/tenant/${tenantName}/auth`, "GET");
    }

    async getTenantLogo(tenantId: number): Promise<Logo> {
        return apiClient.asyncRequestWithEmptyResponse(`v1/k8s/tenant/${tenantId}/logo`, "GET");
    }
}

const tenantService = new TenantService();
export default tenantService;
