import { nextTick, ref } from "@vue/runtime-core";
import { makeID } from "./helpers";
import { useObjState } from "./ObjState";
import { FieldKey, ContainerState } from "./types";

export const DEFAULT_ITEM_PREFIX = "DEFAULT_ITEM_";

export type MapState<T> = ContainerState<Record<string, T>> & {
    removeByKey(key: FieldKey): void;
    pairsCtrlArgs: T[];
    addPairCtrlArgs(): void;
};

export type MapStateArgs<T> = {
    key: FieldKey;
    default?: Record<string, T>;
    parent?: ContainerState;
    editable?: boolean;
    defaultEditable: boolean;
    pair: any; // todo
};

export function useMapState<T>(args: MapStateArgs<any>): MapState<T> {
    const objState = useObjState(args);
    let lastId: null | string = null;
    let self: MapState<T> = {} as any; // declare self as any to avoid error. it assinged in the next lines
    const removedDefault = new Set<string>();
    const addedItem = new Set<string>();
    const removedDefaultCount = ref(0);
    const addedItemCount = ref(0);

    function onItemRemoved(key: string) {
        if (!addedItem.has(key as string)) {
            removedDefault.add(key as string);
            removedDefaultCount.value = removedDefault.size;
        } else {
            addedItem.delete(key);
        }
    }

    function onItemAdded(key: string, quite = false) {
        if (quite) {
            return;
        }
        addedItem.add(key);
        addedItemCount.value = removedDefault.size;
    }

    function addPairCtrlArgs(key = "", quite = false) {
        lastId = makeID();
        key = key ? key : lastId;
        objState.addDeclaration(args.pair(self, key));
        onItemAdded(key, quite);
    }

    function sync(data: Record<string, T> | T[], quite = false) {
        const keyMap = Object.fromEntries(Object.entries(objState.value || {}).map(([k, v]) => [v.key, k]));

        if (typeof data == "object") {
            for (let [key, value] of Object.entries(data)) {
                const akey = keyMap[key] || key;
                if (value === null) {
                    value = undefined as any;
                }
                if (objState.value.hasOwnProperty(akey) && value == undefined) {
                    // delete
                    self.removeByKey(akey);
                } else if (objState.value.hasOwnProperty(akey)) {
                    // update
                    (objState.value as any)[akey] = { key, value };
                } else if (value !== undefined) {
                    // add
                    addPairCtrlArgs(key, quite);
                    objState.cachedValue.set(akey, { key, value });
                    if (quite) {
                        objState.isCachedValueQuite.add(key);
                    }
                }
            }
        }
    }

    function addDefault() {
        if (Array.isArray(args.default)) {
            sync(Object.fromEntries(args.default.map((v, i) => [DEFAULT_ITEM_PREFIX + i, v])), true);
        } else if (typeof args.default == "object") {
            sync(args.default, true);
        }
    }
    function clear() {
        lastId = null;
        removedDefault.clear();
        removedDefaultCount.value = 0;
        addedItem.clear();
        addedItemCount.value = 0;

        objState.clear();
    }

    function intoKeyValue(value: any) {
        return [value?.key, value?.value];
    }
    function isKey(value: any): boolean {
        return value?.key != undefined;
    }
    self = {
        type: "array",
        get hidden() {
            return !!(objState.hidden || args.parent?.isChildrenHidden(args.key));
        },
        get dirty() {
            removedDefaultCount.value;
            addedItemCount.value;

            return objState.dirty || !!removedDefault.size || !!addedItem.size;
        },
        get value(): Record<string, T> {
            return Object.fromEntries(Object.values(objState.value).filter(isKey).map(intoKeyValue)) as any;
        },
        set value(v: Record<string, T>) {
            clear();
            for (const item of (v as any) || []) {
                addPairCtrlArgs();
                objState.cachedValue.set(lastId as string, item);
            }
        },
        setQuite(v) {
            // todo
            self.value = v;
        },
        get valid() {
            return objState.valid;
        },

        get editable() {
            return objState.editable;
        },
        get defaultEditable() {
            return objState.defaultEditable;
        },
        context: objState.context,
        get changedValue(): any {
            if (!self.dirty) {
                return undefined;
            }

            let data: any = objState.changedValue || {};
            data = Object.fromEntries(Object.values(data).map(intoKeyValue));
            for (const key of [...removedDefault]) {
                data[key] = null;
            }
            for (const addedKey of [...addedItem]) {
                const v = objState.value as any;
                if (isKey(v[addedKey])) {
                    const [key, value] = intoKeyValue(v[addedKey]);
                    data[key] = value;
                }
            }
            if (!Object.keys(data).length) {
                return undefined;
            }
            return data;
        },
        apply(value?: any) {
            if (value == undefined) {
                return;
            }
            sync(value as any[]);
        },
        async reset() {
            self.clear();
            await objState.reset();
            await nextTick();
            addDefault();
        },
        clear,
        addDeclaration: objState.addDeclaration,
        isChildrenHidden: objState.isChildrenHidden,

        add: (ctrl: any) => {
            const lastCount = objState.children.size;
            const isStoreInTheCache = objState.cachedValue.has(ctrl.key);
            lastId = makeID();
            objState.add(ctrl);
            // check if added a new state and it did not existed in the cache
            if (lastCount != objState.children.size && !isStoreInTheCache) {
                onItemAdded(ctrl.key);
            }
        },
        remove: (ctrl): boolean => {
            const key = ctrl.key;
            const removed = objState.remove(ctrl);
            if (removed && !objState.children.has(key)) {
                onItemRemoved(key as string);
            }
            return removed;
        },
        removeByKey: (key: string) => {
            objState.removeByKey(key);
            onItemRemoved(key);
        },
        get children() {
            return objState.children;
        },
        get pairsCtrlArgs() {
            return [...objState.childrenCtrlArgs.values()] as any as T[];
        },
        addPairCtrlArgs,
    };
    addDefault();

    return self;
}
