export enum MessageType {
    success = "success",
    info = "info",
    process = "process",
    pending = "pending",
    failure = "failure",
}

type FailureMsg = {
    type: MessageType.failure | "failure";
    msg: string;
    err: Error;
};

export type ProcessMsg = {
    type: MessageType.process | "process";
    msg: string;
    // future:
    // stop?: ActionTrigger
    // status?: { total: number, current: number }
};

export type InfoMsg = {
    type: MessageType.info | "info";
    msg: string;
    // future:
    // more?: () => void
};

export type SuccessMsg = {
    type: MessageType.success | "success";
    msg: string;
    // future:
    // undo?: ActionTrigger
};

export type PendingMsg = {
    type: MessageType.pending | "pending";
};

export type ActionMsg = SuccessMsg | PendingMsg | FailureMsg | InfoMsg | ProcessMsg;
export type ActionTriggerResult =
    | Generator<ActionMsg>
    | AsyncGenerator<ActionMsg>
    | Promise<ActionMsg>
    | ActionMsg
    | void;
export type ActionTrigger = () => ActionTriggerResult;
