import { BaseDisplayColumn } from "@/core-ui/types/column";

export function getCellProps(column: BaseDisplayColumn, item: any, ctx?: unknown) {
    return column.mapProps
        ? column.mapProps(item[column.dataKey], item)
        : {
              key: column.key,
              value: item[column.dataKey],
              row: item,
              ctx,
          };
}
