import { reactive } from "vue";
import { StringSource, Or } from "../utils/string-source";

class StorageService extends StringSource {
    state = reactive<any>({});

    _get<T>(key: string, or: Or<T>, parse?: Function): T {
        if (!this.state[key]) {
            const val: any = localStorage.getItem(key);
            this.state[key] = this._parseOr(val, parse, or);
        }
        return this.state[key];
    }

    _set<T>(key: string, val: T, stringify?: Function) {
        this.state[key] = val;
        localStorage.setItem(key, this._stringify(val, stringify));
    }

    clearKeys(keys: string[]) {
        keys.forEach((key) => {
            localStorage.removeItem(key);
            this.state[key] = undefined;
        });
    }

    clearKey(key: string) {
        this.clearKeys([key]);
    }

    clear() {
        this.clearKeys(Object.keys(localStorage));
    }
}

export const storageService = new StorageService();
