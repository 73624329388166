
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import Page from "@/core-ui/Page.vue";
import LogConsole from "../components/jobs/LogConsoleV2.vue";
import PodService from "@/services/pods-service";
import { arrayEquals } from "@/utils/ArrayUtil";

const UPDATE_PODS_TIME_INTERVAL = 10 * 1000;

export default defineComponent({
    components: { Page, LogConsole },
    setup() {
        const route = useRoute();

        return {
            tail: ref(100),
            podName: ref(""),
            get jobParams() {
                return route.params;
            },
        };
    },
    data() {
        let pods : string[] = [];
        return {
            pods: pods
        }
    },
    mounted() {
        this.updatePods();
        //The pods on a deployment might change as time progresses.
        setInterval(()=> { this.updatePods() }, UPDATE_PODS_TIME_INTERVAL); //If any pods are created
    },
    methods: {
        async updatePods() {
            const updatedPods: string[] = (await PodService.get(String(this.jobParams.jobId), ""))
                .map((pod) => pod.name);

            if (arrayEquals(this.pods, updatedPods)) {  //Update the pods list only if it has changed
                return;
            }

            this.pods.splice(0,this.pods.length); //clear old pods
            for (let updatedPodName of updatedPods) {
                this.pods.push(updatedPodName);
            }

            //Update pod for logView
            //If we can't find any pods matching the job, no logs should be printed to the log console
            if (this.pods.length <= 0) {
                this.podName = "";
            }
            //Set new pod only if it doesn't have one currently or the current pod isn't part of the current job
            else if (this.podName == undefined || this.podName == "" || !this.pods.includes(this.podName)) {
                this.podName = this.pods[0];
            }
        }
    }
});
