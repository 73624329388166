 import { reactive, watchEffect } from "vue";
import apiClient from "../services/api-client";
import { clusterApi } from "@/cluster-ui/services/apiClient";
import clustersStore from "./clusters-store";
import { Cluster } from "@/types/cluster";
import { ClusterStatus } from "@/services/clusters-service";
import workloadService from "@/cluster-ui/services/workload.service";
import { isNewerVersion } from "@/utils/versionUtil";
import {
    FEATURE_BRANCH_VERSION, MIN_2_10_VERSION,
    MIN_CLI_VERSION,
    MIN_WINDOWS_CLI_VERSION,
    TEST_ENV_VERSION,
} from "@/common/version.constant";
 import authStore from "@/stores/authStore";
 import settingStore, { SettingKeys } from "@/stores/setting-store";

export const INVALID_CLUSTER_TOKEN = "InvalidClusterToken"

function isCLIAvailable(clusterVersion: string): boolean {
    return (
        isNewerVersion(clusterVersion, MIN_CLI_VERSION) ||
        clusterVersion.includes(TEST_ENV_VERSION) ||
        clusterVersion.toLowerCase().includes(FEATURE_BRANCH_VERSION)
    );
}
function isWindowsCLIAvailable(clusterVersion: string) : boolean{
    return (
        isNewerVersion(clusterVersion, MIN_WINDOWS_CLI_VERSION) ||
        clusterVersion.includes(TEST_ENV_VERSION) ||
        clusterVersion.toLowerCase().includes(FEATURE_BRANCH_VERSION)
    );
}
 export function isSubmitParamAvailableForCluster_2_10(clusterVersion: string) : boolean {
    // is submit params available for cluster (cluster version >= 2.10.0)
     return (
      isNewerVersion(clusterVersion, MIN_2_10_VERSION) ||
      clusterVersion.includes(TEST_ENV_VERSION) ||
      clusterVersion.toLowerCase().includes(FEATURE_BRANCH_VERSION)
     );
 }

export enum activeClusterStatus {
    loading = "loading",
    connected = "connected",
    notConnected = "not-connected",
}

export const activeClusterConnectionInfoStore = reactive({
    connected: null as boolean | null,
    inferenceAvailability: undefined,
    domain: null,
    selectedCluster: null,
    cliAvailable: false,
    WindowsCliAvailable: false,


    get selectedClusterVersion(): string {
        return (activeClusterConnectionInfoStore.selectedCluster || {version: ""}).version;
    },

    get isAgentConnected(): boolean | null {
        return clustersStore.state.isCheckedClusterActive ?? null;
    },

    get activeClusterVersion() {
        return (
            (clustersStore.state.clusters || {}).find(
                (cluster: Cluster) => cluster.uuid === clustersStore.state.activeClusterUUID,
            ).version || {}
        );
    },

    get status() {
        if (activeClusterConnectionInfoStore.connected === null || !clustersStore.state.clusters) {
            return activeClusterStatus.loading;
        }
        return activeClusterConnectionInfoStore.connected
            ? activeClusterStatus.connected
            : activeClusterStatus.notConnected;
    },

    get jwt() {
        return apiClient.access_token;
    },

    get externalToken() {
        return apiClient.external_token;
    },
});

async function checkInferenceAvailability() {
    try {
        if (activeClusterConnectionInfoStore.domain) {
            activeClusterConnectionInfoStore.inferenceAvailability = await workloadService.getWorkLoadAvailability(
                "inference",
            );
        } else {
            activeClusterConnectionInfoStore.inferenceAvailability = undefined;
        }
    } catch (e) {
        activeClusterConnectionInfoStore.inferenceAvailability = undefined;
    }
    updateApiClient();
}

export async function checkConnection() {
    try {
        if (activeClusterConnectionInfoStore.selectedCluster != null) {
            const selectedCluster: Cluster = activeClusterConnectionInfoStore.selectedCluster;
            activeClusterConnectionInfoStore.connected = selectedCluster.status === ClusterStatus.Connected;
        } else {
            console.log(`failed to check connection no active cluster`);
        }
    } catch (e) {
        console.log("catch", e);
    }
}

 async function setTokenToUse() {
     if (!activeClusterConnectionInfoStore.externalToken) {
         authStore.setUseExternalTokenInStorage(false)
         return
     }
     console.debug("initiating call to cluster to determine what token to use");
     try {
         authStore.setUseExternalTokenInStorage(true)
         await clusterApi.get("/api/v1/whoami")
     } catch (e) {
         console.debug(`failed to query cluster with external token.`, e);
         authStore.setUseExternalTokenInStorage(false)
         try {
            await clusterApi.get("/api/v1/whoami")
         } catch (e: any) {
            console.log("unable to determine what token to use.", e);
            if (e.response.status === 401) {
                throw(new Error(INVALID_CLUSTER_TOKEN))
            }
        }
     }
 }

 export async function updateApiClient() {
    console.debug("refresh api client")
    try {
        clusterApi.updateProps({
            get baseUrl() {
                return `${activeClusterConnectionInfoStore.domain}/researcher`;
            },
            get connected() {
                return !!activeClusterConnectionInfoStore.connected;
            },
            get jwt() {
                return activeClusterConnectionInfoStore.jwt;
            },
            get inferenceAvailability() {
                return activeClusterConnectionInfoStore.inferenceAvailability;
            },
            get externalToken() {
                return activeClusterConnectionInfoStore.externalToken;
            },
        });
    } catch (e) {
        console.log("catch", e);
    }
    await setTokenToUse()
    .then(() => console.debug("successfully set token to use for cluster api")); 
}

function checkActiveClusterVersionFeatures() {
    if (activeClusterConnectionInfoStore.selectedCluster != null) {
        const selectedCluster: Cluster = activeClusterConnectionInfoStore.selectedCluster;
        activeClusterConnectionInfoStore.cliAvailable = isCLIAvailable(selectedCluster?.version || "");
        activeClusterConnectionInfoStore.WindowsCliAvailable = isWindowsCLIAvailable(selectedCluster?.version || "");
    }
}

function updateSelectedCluster() {
    const clusters = clustersStore.state.clusters;
    activeClusterConnectionInfoStore.selectedCluster = clusters?.find(
        (c: Cluster) => c.uuid == clustersStore.state.activeClusterUUID,
    );
}

function checkActiveClusterDomain() {
    if (activeClusterConnectionInfoStore.selectedCluster != null) {
        const selectedCluster: Cluster = activeClusterConnectionInfoStore.selectedCluster;
        activeClusterConnectionInfoStore.domain = selectedCluster.domain || null;
        console.log(`active cluster domain set to ${activeClusterConnectionInfoStore.domain}`);
    } else {
        console.warn(`failed to set active cluster domain`);
    }
}


const MINUTE = 60 * 1000;

setInterval(async () => {
    await clustersStore.fetchThenUpdateCheckedAndActiveClusters();
}, 0.5 * MINUTE);

watchEffect(async () => {
    if (!clustersStore.state.clusters) return;
    try {
        await checkCluster();
    } 
    catch (e: any) {
        if (shouldLogoutOnExpiredToken(e) ) {
            console.log(`external token is expired. logging out`);
            authStore.logout(true);
        }
    }
});

function shouldLogoutOnExpiredToken(e: any): boolean {
    return activeClusterConnectionInfoStore.externalToken != "" && authStore.userInfo.identityProvider === "openshift-v4" && e.message === INVALID_CLUSTER_TOKEN;
}

export async function checkCluster() {
    // clean current cluster data
    activeClusterConnectionInfoStore.connected = null;
    activeClusterConnectionInfoStore.inferenceAvailability = undefined;
    activeClusterConnectionInfoStore.cliAvailable = false;
    activeClusterConnectionInfoStore.WindowsCliAvailable = false;

    updateSelectedCluster();
    await checkConnection();
    checkActiveClusterVersionFeatures();
    checkActiveClusterDomain();
    await updateApiClient();

    if (!settingStore.state.kv || !settingStore.state.kv[SettingKeys.DisableInferenceChecks]) {
        await checkInferenceAvailability();
    }
}