import {} from "vue";

export const isString = (value: unknown) => {
    return typeof value === "string";
};

export const isBoolean = (value: unknown) => {
    return typeof value === "boolean";
};

export const isObject = (value: unknown) => {
    return typeof value === "object";
};

export const has = (object: Record<string, unknown>, key: string) => {
    return isObject(object) && object.hasOwnProperty(key);
};

export const get = (object: Record<string, unknown>, key: string, defaultValue: unknown) => {
    return has(object, key) ? object[key] : defaultValue;
};

export const px = (value: number) => {
    return `${value}px`;
};

export const translate = (x: number | string, y: number | string) => {
    return `translate(${x}, ${y})`;
};
