export interface UserCore {
    name: string;
    email: string;
    userId?: string;
    username?: string;
}

export interface User extends UserCore {
    last_login?: Date | string;
    password?: string;
    roles?: string[];
    permittedClusters: string[];
    permitAllClusters: boolean;
    needToChangePassword?: boolean;
    userType?: string
}

export interface Group {
    name: string;
    userId?: string;
    username?: string;
    roles?: string[];
    permittedClusters: string[];
    permitAllClusters: boolean;
    password?: string; //UNUSED
    needToChangePassword?: boolean; //UNUSED
}

export interface AuthUser {
    userId: string;

    tenantId: number;

    permittedClusters: string[];

    roles: string[];

    permitAllClusters: boolean;

    needToChangePassword: boolean;

    entityType?: string;
}

export enum Tabs {
    groups = "Groups",
    users = "Users",
    ssoUsers = "SSO Users"
}

export enum EntityType {
    REGULAR_USER = 'regular-user',
    SSO_USER = 'sso-user',
}

export type FullAuthUser = AuthUser & User;
