import { ref } from "@vue/reactivity";
import { useDynamicMsg } from "./DynamicMsg";
import WarnModel from "../WarnModel.vue";
import { storageService } from "../data-grid/services";

export type Args = {
    title?: string;
    msg: string;
    ok?: string;
    cancel?: string;
};

export type WarnModel = {
    isOpen: boolean;
    title: string;
    msg: string;
    okText: string;
    cancelText: string;
    ok(): void;
    cancel(): void;
    open(): Promise<void>;
};

const DONT_SHOW_AGAIN_KEY = "don'tShowWarnAgain";

export function useWarnModel() {
    const model = useDynamicMsg();
    const isOpen = ref({} as any);

    return {
        warn(key: string, args: Args) {
            if (isOpen.value[key] || storageService.getObj(DONT_SHOW_AGAIN_KEY, {} as any)[key]) {
                return;
            }

            isOpen.value = { ...isOpen.value, key: true };
            return new Promise((resolve, reject) => {
                model.add(key, WarnModel, {
                    ...args,
                    onOk(isShowAgain: boolean) {
                        close();
                        storageService.setObj(DONT_SHOW_AGAIN_KEY, {
                            ...storageService.getObj(DONT_SHOW_AGAIN_KEY, {} as any),
                            [key]: isShowAgain,
                        });
                        resolve(isShowAgain);
                    },
                    onCancel() {
                        close();
                        resolve({isDialogCanceled: true});
                    },
                });
            });

            function close() {
                isOpen.value = { ...isOpen.value, key: false };
                model.remove(key);
            }
        },
    };
}
