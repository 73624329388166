import apiClient from "./api-client";

export class SettingService {
    set(key: string, value: any) {
        return apiClient.asyncRequestWithResponse(`v1/k8s/setting`, "PUT", {
            key,
            value,
        });
    }

    get() {
        return apiClient.asyncRequestWithResponse(`v1/k8s/setting`, "GET");
    }
}

const settingService = new SettingService();
export default settingService;
