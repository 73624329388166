import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4933b5b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_button = _resolveComponent("icon-button")!

  return (_openBlock(), _createElementBlock("section", _mergeProps({ class: "from-section-container" }, _ctx.$attrs), [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("section", {
          key: 0,
          class: "expend-header",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
        }, [
          _createVNode(_component_icon_button, { style: {"box-shadow":"unset"} }, {
            default: _withCtx(() => [
              _createElementVNode("i", {
                class: _normalizeClass(["raicon-slide-down icon", { 'expand-close': !_ctx.expended, error: _ctx.notValid }])
              }, null, 2)
            ]),
            _: 1
          }),
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("h2", {
                key: 0,
                innerHTML: _ctx.title
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", {
      class: _normalizeClass(["expend-body", { show: _ctx.expended }])
    }, [
      _renderSlot(_ctx.$slots, "default", {
        expended: _ctx.expended,
        toggle: _ctx.toggle
      }, undefined, true)
    ], 2)
  ], 16))
}