import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f61645b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ra-list-item-container" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  key: 0,
  class: "ra-list-item-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_actions = _resolveComponent("side-actions")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _renderSlot(_ctx.$slots, "start", {}, undefined, true),
    _createElementVNode("div", {
      class: _normalizeClass(["ra-list-item", { selected: _ctx.selected, clickable: true }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.label)
          ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ])), [
              [_directive_tippy, _ctx.tooltip]
            ])
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ], 2),
    _createVNode(_component_side_actions, {
      class: "list-item-actions",
      actions: _ctx.actions
    }, null, 8, ["actions"])
  ]))
}