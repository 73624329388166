import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleButton = _resolveComponent("ToggleButton")!

  return (_openBlock(), _createBlock(_component_ToggleButton, _mergeProps(_ctx.$attrs, {
    "css-colors": true,
    modelValue: _ctx.modelValue,
    "onUpdate:modelValue": _ctx.onValueChange,
    sync: true,
    class: [_ctx.modelValue ? _ctx.$style.toggleActive : _ctx.$style.toggleNotActive, _ctx.$style.toggle]
  }), null, 16, ["modelValue", "onUpdate:modelValue", "class"]))
}