import { newPassTemp } from "@/components/users/user-email-template";
import authStore, { RolesEnum } from "@/stores/authStore";
import { RouteLocationNormalized } from "vue-router";
import { ROUTES_ENUM } from "./routes";

export async function routePermissionsGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) {
    if (!requiredRegularPermissions(to)) {
        return next();
    } else {
        if (!((authStore.authStore != undefined) && await authStore.authStore.isAuthenticated())) {
            return next({
                name: ROUTES_ENUM.LOGIN,
                query: { redirectTo: to.fullPath },
            });
        }
    }

    const allowedRolesArr: Array<RolesEnum> | null = allowedRoles(to);
    if (!allowedRolesArr || allowedRolesArr.some((role) => authStore.hasRole(role))) {
        return next();
    }

    // If we got here, the user doesn't have any relevant role
    return next(
        { name: ROUTES_ENUM.NO_ROLES }, // ToDo: Handle error gracefully
    );
}

export function loadView(view: string) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

function requiredRegularPermissions(route: RouteLocationNormalized): boolean {
    return route.matched.some((record) => record.meta.requiresAuth);
}

// If a route has no allowedRoles defined in meta, assume that it will allow any role. This is not the same than if a route has a `allowedRoles = any`, which effectively should block the route.
function allowedRoles(route: RouteLocationNormalized): Array<RolesEnum> | null {
    if (route.meta.allowedRoles == undefined) {
        return null;
    }

    return route.meta.allowedRoles as Array<RolesEnum>;
}
