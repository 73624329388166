
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        buttonType: {
            type: String as PropType<"main" | "secondary" | "danger">,
            default: "secondary",
        },
    },
});
