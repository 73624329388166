import { ref } from "vue";
import { isActionMsg } from "./Action";
import { ActionTriggerResult, ActionMsg } from "../types";

type ModalActionProps<T> = {
    notShowDefaultMsg?: boolean;
    submit: (data?: any | T) => void | Promise<unknown>;
    mapHandleData?: (data: T | any) => T;
};

export type ModalAction<T = unknown> = {
    handleData: null | T;
    handle(data?: T): ActionTriggerResult;
    isOpening: boolean;
    cancel: () => void;
    submit: <T>(data?: T) => void;
    isSubmitting: boolean;
};

export function useModalAction<T = any>(props: ModalActionProps<T>): ModalAction<T> {
    const handleData = ref<T | null>(null);
    const isSubmitting = ref(false);
    const isOpening = ref(false);
    let submit: Function | null = null;
    let cancel: Function | null = null;

    async function next() {
        isOpening.value = true;
        return new Promise((resolve, reject) => {
            submit = resolve;
            cancel = reject;
        });
    }
    function resetNext() {
        isOpening.value = false;
        submit = null;
        cancel = null;
    }

    // handle is a single generator function that will handle with all the events (triggers)
    async function* handle(data: T | undefined): AsyncGenerator<ActionMsg> {
        handleData.value = props.mapHandleData ? (props.mapHandleData(data) as any) : (data as T);
        let submittedData;
        try {
            yield {
                type: "pending",
            };
            // wait for submit or cancel
            submittedData = await next();
            if (!props.notShowDefaultMsg) {
                yield {
                    type: "process",
                    msg: "Saving...",
                };
            }
            try {
                // close the dialog while the data submitted
                isOpening.value = false;
                const result = await props.submit(submittedData);
                // the action finish remove the handle data
                handleData.value = null;
                yield isActionMsg(result)
                    ? (result as ActionMsg)
                    : !props.notShowDefaultMsg &&
                      ({
                          type: "success",
                          msg: "Saved",
                      } as any);
                resetNext();
            } catch {
                // the submitted failed, open the dialog again
                isOpening.value = true;
            }
        } catch (error) {
            // the modal was canceled
            handleData.value = null;
            resetNext();
        }
    }

    return {
        get isOpening() {
            return isOpening.value;
        },
        get isSubmitting() {
            return isSubmitting.value;
        },
        get handleData() {
            return handleData.value as T;
        },
        handle,
        cancel: () => {
            cancel && cancel();
        },
        submit: (data) => submit && submit(data),
    };
}
