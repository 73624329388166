import { TimePeriod } from "@/core-ui/types/time-period";

const secondsInMinute = 60;
const secondsInHour = 60 * 60;
const secondsInDay = 60 * 60 * 24;

export const toTimePeriod = (seconds = 0): TimePeriod => ({
    minutes: Math.round(seconds / secondsInMinute) % 60,
    hours: Math.floor(seconds / secondsInHour) % 24,
    days: Math.floor(seconds / secondsInDay),
});

export const fromTimePeriod = ({ days = 0, hours = 0, minutes = 0 }: Partial<TimePeriod>): number =>
    days * secondsInDay + hours * secondsInHour + minutes * secondsInMinute;

export const stringifyTimePeriod = (tp: TimePeriod): string => {
    return (
        [nn(tp.days, "day", "days"), nn(tp.hours || 0, "hour", "hours"), nn(tp.minutes || 0, "minute", "minutes")]
            .filter((t) => t)
            .join(", ") || "0 minutes"
    );
};

function nn(num: number, singular: string, plural: string) {
    return !num ? "" : `${num} ${num == 1 ? singular : plural}`;
}
