import { Link } from "@/types/link";
import { ROUTES_ENUM } from "@/router/routes";
import settingStore, { SettingKeys } from "@/stores/setting-store";
import authStore from "@/stores/authStore";
import clustersStore from "@/stores/clusters-store";

export const PAGE_LINKS: Link[] = [
    {
        title: "Dashboards",
        aid: "menu-icon-Dashboards",
        icon: "menu-icon-dashboards-menu",
        pageName: ROUTES_ENUM.NOW_DASHBOARD,
        subLinks: [
            {
                title: "overview",
                aid: "menu-icon-overview",
                icon: "menu-icon-overview-dashboard",
                pageName: ROUTES_ENUM.NOW_DASHBOARD,
                topBorder: true,
            },
           {
                title: "Quota management",
                aid: "menu-icon-resources",
                icon: "menu-icon-resources",
                pageName: ROUTES_ENUM.RESOURCES,
                topBorder: true,
               filter: () => settingStore.state.kv[SettingKeys.QuotaManagement],
            },
            {
                title: "analytics",
                aid: "menu-icon-analytics",
                icon: "menu-icon-analytics-dashboard",
                pageName: ROUTES_ENUM.ANALYTICS_DASHBOARD,
            },
            {
                title: "Consumption",
                aid: "menu-icon-consumption",
                icon: "menu-icon-consumption-dashboard",
                adminRequired: true,
                pageName: ROUTES_ENUM.CONSUMPTION_DASHBOARD,
                filter: () => settingStore.state.kv[SettingKeys.ShowConsumptionReport],
            },
            {
                title: "Multi-Cluster Overview",
                aid: "menu-icon-Multi",
                icon: "menu-icon-multi-cluster-dashboard",
                adminRequired: true,
                pageName: ROUTES_ENUM.CLUSTERS_DASHBOARD,
                filter: () => clustersStore.state?.clusters?.length > 1,
            },
            {
                title: "analytics-old",
                aid: "menu-icon-analytics",
                icon: "menu-icon-analytics-dashboard",
                pageName: ROUTES_ENUM.OLD_ANALYTICS,
                filter: () => settingStore.state.kv[SettingKeys.DashboardShowOld],
            },
        ],
    },
    // new section
    {
        title: "clusters",
        icon: "menu-icon-clusters",
        pageName: ROUTES_ENUM.CLUSTERS,
        aid: "menu-icon-clusters",
        topBorder: true,
    },
    {
        title: "nodes",
        icon: "menu-icon-nodes",
        pageName: ROUTES_ENUM.NODES,
        aid: "menu-icon-nodes",
        filter: () => authStore.canViewClusterManagementResources,
    },
    {
        title: "departments",
        aid: "menu-icon-departments",
        icon: "menu-icon-departments",
        pageName: ROUTES_ENUM.DEPARTMENTS,
        filter: () =>
            settingStore.state.kv[SettingKeys.DepartmentsUse] && authStore.canViewDepartmentManagementResources,
    },
    {
        title: "New department",
        aid: "menu-icon-departments",
        icon: "menu-icon-departments",
        pageName: ROUTES_ENUM.SUBMIT_DEPARTMENT,
        filter: () => false,
    },
    {
        title: "edit department",
        aid: "menu-icon-departments",
        icon: "menu-icon-departments",
        pageName: ROUTES_ENUM.EDIT_DEPARTMENT,
        filter: () => false,
    },
    {
        title: "projects",
        aid: "menu-icon-projects",
        icon: "menu-icon-projects",
        pageName: ROUTES_ENUM.PROJECTS,
    },
    // new section
    {
        title: "jobs",
        aid: "menu-icon-jobs",
        icon: "menu-icon-jobs",
        pageName: ROUTES_ENUM.JOBS,
        filter: () => authStore.canViewJobResources,
        topBorder: true,
    },
    {
        title: "workspaces",
        aid: "menu-icon-workspaces",
        icon: "menu-icon-workspaces",
        pageName: ROUTES_ENUM.WORKSPACES,
        filter: () => settingStore.state.kv[SettingKeys.ShowWorkspaces],
    },
    {
        title: "trainings",
        aid: "menu-icon-trainings",
        icon: "menu-icon-trainings",
        pageName: ROUTES_ENUM.TRAININGS,
        filter: () => settingStore.state.kv[SettingKeys.ShowWorkspaces],
    },
    {
        title: "New workspace",
        aid: "menu-icon-workspace-new",
        icon: "menu-icon-workspaces",
        pageName: ROUTES_ENUM.SUBMIT_WORKSPACES,
        filter: () => false,
    },
    {
        title: "deployments",
        aid: "menu-icon-deployments",
        icon: "menu-icon-deployments",
        pageName: ROUTES_ENUM.DEPLOYMENTS,
        filter: () => authStore.canViewDeploymentResources,
    },
    // new section
    {
        title: "environments",
        aid: "menu-icon-environments",
        icon: "menu-icon-environments",
        pageName: ROUTES_ENUM.ENVIRONMENTS,
        filter: () => settingStore.state.kv[SettingKeys.ShowWorkspaces],
        topBorder: true,
    },
    {
        title: "data sources",
        aid: "menu-icon-data-sources",
        icon: "menu-icon-data-sources",
        pageName: ROUTES_ENUM.DATA_SOURCES,
        filter: () => settingStore.state.kv[SettingKeys.ShowWorkspaces],
    },
    {
        title: "compute resources",
        aid: "menu-icon-compute-resource",
        icon: "menu-icon-compute-resource",
        pageName: ROUTES_ENUM.COMPUTE_RESOURCE,
        filter: () => settingStore.state.kv[SettingKeys.ShowWorkspaces],
    },
    {
        title: "templates",
        aid: "menu-icon-templates",
        icon: "menu-icon-templates",
        pageName: ROUTES_ENUM.WORKSPACE_TEMPLATES,
        filter: () => settingStore.state.kv[SettingKeys.ShowWorkspaces],
    },

    // new section
    {
        title: "Event history",
        icon: "menu-icon-event-history",
        pageName: ROUTES_ENUM.AUDIT,
        aid: "menu-icon-events",
        filter: () => authStore.isAdmin,
        topBorder: true,
    },
    {
        title: "credentials",
        icon: "menu-icon-credentials",
        pageName: ROUTES_ENUM.CREDENTIAL,
        aid: "menu-icon-credentials",
        bottomBorder: true,
        filter: () => settingStore.state.kv[SettingKeys.ShowWorkspaces] && !authStore.isAdmin,
    },
    {
        title: "settings",
        icon: "menu-icon-settings-menu",
        aid: "menu-icon-settings",
        filter: () => authStore.isAdmin || authStore.isDepartmentAdmin,
        subLinks: [
            {
                title: "general",
                icon: "menu-icon-general-settings",
                pageName: ROUTES_ENUM.GENERAL_SETTINGS,
                aid: "general",
                filter: () => authStore.isAdmin,
                topBorder: true,
            },
            {
                title: "users", //TODO:     check if we want to change it to be users , if use what about the route ?
                icon: "menu-icon-users",
                pageName: ROUTES_ENUM.PERMISSIONS,
                aid: "menu-icon-users",
                filter: () => authStore.isAdmin || authStore.isDepartmentAdmin,
            },
            {
                title: "applications",
                icon: "menu-icon-applications",
                pageName: ROUTES_ENUM.APPLICATIONS,
                aid: "menu-icon-application",
                filter: () => authStore.isAdmin || authStore.isDepartmentAdmin,
            },
            {
                title: "credentials",
                icon: "menu-icon-credentials",
                pageName: ROUTES_ENUM.CREDENTIAL,
                aid: "menu-icon-credentials",
                bottomBorder: true,
                filter: () => settingStore.state.kv[SettingKeys.ShowWorkspaces] && authStore.isAdmin,
            },
        ],
    },
];
