
import { mixin as clickaway } from "@/core-ui/directives/clickaway";
import MenuArrow from "@/core-ui/MenuArrow.vue";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

const MARGIN_FROM_BOUNDING = 10;
export default defineComponent({
    components: { MenuArrow },
    mixins: [clickaway],
    props: {
        closeOnSelect: Boolean,
        useAbsolute: Boolean,
    },

    created() {
        this.nextTick = this.$nextTick;
    },

    setup(props, ctx) {
        // props

        const opened = ref(false);
        const positions = ref<{ left: string; top: string } | null>({
            left: "",
            top: "",
        });
        const arrowLeft = ref<null | string>(null);
        const openerRef = ref<null | HTMLElement>(null);
        const menuRef = ref<null | HTMLElement>(null);

        onMounted(() => {
            window.addEventListener("resize", self.calcPositions);
        });

        onUnmounted(() => {
            window.removeEventListener("resize", self.calcPositions);
        });
        const self = {
            nextTick: null as any,
            opened,
            positions,
            arrowLeft,
            openerRef,
            menuRef,
            menuClicked() {
                if (props.closeOnSelect) {
                    opened.value = false;
                }
            },

            toggleMenu() {
                opened.value = !opened.value;
                self.calcPositions();
            },

            async calcPositions() {
                if (props.useAbsolute) {
                    positions.value = null;
                } else if (opened.value) {
                    await self.nextTick();
                    arrowLeft.value = null;
                    const bounding = (openerRef.value as HTMLElement).getBoundingClientRect();
                    const menuWidth = (menuRef.value as HTMLElement).offsetWidth;
                    let left = bounding.left + bounding.width / 2;
                    const endOfLeft = left + menuWidth / 2;
                    if (endOfLeft + MARGIN_FROM_BOUNDING > window.innerWidth) {
                        const gap = endOfLeft - window.innerWidth + MARGIN_FROM_BOUNDING;
                        left -= gap;

                        arrowLeft.value = -(menuWidth / 2 - gap) + "px";
                    }
                    positions.value = {
                        top: bounding.top + bounding.height + "px",
                        left: left + "px",
                    };
                }
            },
        };
        return self;
    },
});
