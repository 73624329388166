import Vue, { Directive, VNode, warn, DirectiveBinding } from "vue";

// @SECTION: implementation

const HANDLER = "_vue_clickaway_handler";

function bind(el: HTMLElement, binding: DirectiveBinding, vnode?: VNode) {
    unbind(el);

    // var vm = vnode?.ref;

    const callback = binding.value;
    if (typeof callback !== "function") {
        if (process.env.NODE_ENV !== "production") {
            warn("expects a function value, " + "got " + callback);
        }
        return;
    }

    // @NOTE: Vue binds directives in microtasks, while UI events are dispatched
    //        in macrotasks. This causes the listener to be set up before
    //        the "origin" click event (the event that lead to the binding of
    //        the directive) arrives at the document root. To work around that,
    //        we ignore events until the end of the "initial" macrotask.
    // @REFERENCE: https://jakearchibald.com/2015/tasks-microtasks-queues-and-schedules/
    // @REFERENCE: https://github.com/simplesmiler/vue-clickaway/issues/8
    let initialMacrotaskEnded = false;
    setTimeout(function () {
        initialMacrotaskEnded = true;
    }, 0);

    (el as any)[HANDLER] = function (ev: any) {
        // @NOTE: this test used to be just `el.containts`, but working with path is better,
        //        because it tests whether the element was there at the time of
        //        the click, not whether it is there now, that the event has arrived
        //        to the top.
        // @NOTE: `.path` is non-standard, the standard way is `.composedPath()`
        const path = ev.path || (ev.composedPath ? ev.composedPath() : undefined);
        if (initialMacrotaskEnded && (path ? path.indexOf(el) < 0 : !el.contains(ev.target))) {
            return callback(ev);
        }
    };

    document.documentElement.addEventListener("click", (el as any)[HANDLER], false);
}

function unbind(el: HTMLElement) {
    document.documentElement.removeEventListener("click", (el as any)[HANDLER], false);
    delete (el as any)[HANDLER];
}

export const directive: Directive = {
    created: bind,
    updated: function (el: any, binding: DirectiveBinding, vn: VNode) {
        if (binding.value === binding.oldValue) return;
        bind(el, binding, vn);
    },
    beforeUnmount: unbind,
};

export var mixin = {
    directives: { onClickaway: directive },
};
