
import { defineComponent, ref, watch } from 'vue';
import { nextTick } from '@vue/runtime-core';

export default defineComponent({
    emits: ['cancel'],
    props: {
        title: String,
        modalClass: String,
        size: String,
        modalBodyClass: String,
        modalButtonClass: String,
        fullscreen: Boolean,
        scrollable: { type: Boolean, default: true },
        staticBackdrop: { type: Boolean, default: false },
        hideClose: { type: Boolean, default: false },
    },
    created() {
        this.style = (this as any).$style;
    },
    async mounted() {
        await nextTick();
        this.isOpen = true;
    },
    setup(props, ctx) {
        const style = ref<any>(null);

        let isOpen = ref(false);
        watch(isOpen, async (val) => {
            if (!val) {
                await nextTick();
                ctx.emit('cancel');
            }
        });
        return {
            isOpen,
            style,
            get modalClasses() {
                if (!style.value) return [];

                return props.modalClass ? [props.modalClass, style.value.modalContainer] : style.value.modalContainer;
            },

            get modalBodyClasses() {
                if (!style.value) return [];

                return props.modalBodyClass ? [props.modalBodyClass, style.value.modalBody] : style.value.modalBody;
            },

            get modalButtonsClasses() {
                if (!style.value) return [];

                return props.modalButtonClass
                    ? [props.modalButtonClass, style.value.modalButtons]
                    : style.value.modalButtons;
            },
        };
    },
});
