import { computed, reactive, ref, watch, watchEffect } from "vue";
import { useSyncRunner } from "./SyncRunner";
import { isEmpty } from "./helpers";
import { FieldKey, FieldValidStatus, FieldCtrlTypes, ValuePipe, FiledCtrlValue, FieldCtrlState } from "./types";

export type FiledCtrlStateArgs<T = FiledCtrlValue> = {
    key?: FieldKey;
    default?: T;
    type?: FieldCtrlTypes;
    required?: boolean;
    editable?: boolean;
    defaultEditable: boolean;
    pipe?: ValuePipe<T>[];
};

export function useFiledCtrlState<T = FiledCtrlValue>(args: FiledCtrlStateArgs<T>): FieldCtrlState<T> {
    let self: FieldCtrlState<T> = {} as any; // just to avoid lint error, will be assigned in the next lines
    const value = ref<T>(args.default as T);
    const err = ref<string>();
    const info = ref<string>();
    const pipeValid = ref<FieldValidStatus>("valid");
    const dirty = ref(false);
    const context: any = reactive({
        // todo type FieldCtrlContext
        required: false,
        error: null,
        hidden: false,
        editable: null,
        tooltipText: null,
    });
    const valid = computed(() => {
        return context.error ? "invalid" : pipeValid.value;
    });

    function transform(v: any): any | undefined {
        if (v === undefined || typeof v == args.type) {
            return v;
        } else {
            switch (args.type || "string") {
                case "string":
                    if (v == null) return undefined
                    return String(v);
                case "number":
                    return v == "" ? undefined : parseFloat(v);
                case "boolean":
                    return !!v;

                default:
                    throw new Error("[FieldCtrl: Unknown type]");
            }
        }
    }

    const validator = useSyncRunner(async (is_need_to_check_again: () => boolean) => {
        pipeValid.value = "check";
        const results = await Promise.all((args.pipe || []).map((f) => f(value.value as T)));

        if (self.required && isEmpty(value.value)) {
            results.push("* Required");
        }

        if (is_need_to_check_again()) {
            return;
        }
        let isValid = true;
        let errMessage = "";
        let infoMessage = "";
        for (const result of results as any[]) {
            if (result && typeof result == "string") {
                isValid = false;
                errMessage = result as string;
                continue;
            }

            if (result?.err) {
                isValid = false;
                errMessage = result.err;
            }
            if (result?.info) {
                infoMessage = result.info;
            }
        }
        err.value = errMessage;
        info.value = infoMessage;

        pipeValid.value = isValid ? "valid" : "invalid";
    });

    function reset() {
        dirty.value = false;
        value.value = transform(args.default);
    }

    function getDefault() {
        switch (args.type || "string") {
            case "string":
                return "";
            case "number":
            case "boolean":
                return null;
        }
    }

    self = {
        get type() {
            return args.type || "string";
        },
        get value() {
            return value.value as T;
        },
        set value(v: T) {
            if (!self.editable) {
                console.warn(`Attempt to set not editable input(${args.key}) with value: ${v}`);
                return;
            }
            dirty.value = true;
            value.value = transform(v);
        },
        setQuite(v: T) {
            value.value = transform(v);
        },
        get required() {
            return args.required ?? !!context.required;
        },
        get editable() {
            return args.editable ?? context.editable ?? args.defaultEditable;
        },
        get dirty() {
            return dirty.value;
        },
        get err() {
            return err.value || context.error || "";
        },
        get info() {
            return info.value || "";
        },
        get valid() {
            return valid.value;
        },
        get context() {
            return context;
        },
        get changedValue(): any {
            if (!self.dirty) {
                return undefined;
            }
            return self.value ?? getDefault();
        },
        apply(value?: T) {
            if ((value as any) === getDefault()) {
                self.value = undefined as any as T;
            } else {
                self.value = transform(value);
            }
        },
        reset,
    };

    watch(
        [() => context.required, () => args.required, () => args.pipe, value],
        () => {
            validator.run();
        },
        { immediate: true },
    );

    return self;
}
