import { storageService } from "@/core-ui/data-grid/services";
import { FieldsByKeys } from "@/core-ui/forms/types/declarative-fields";
import { computed, ref } from "vue";
import { applyJobSettings, getJobArgs } from "./args";
import workloadService from "../../services/workload.service";
import whoamiService from "../../services/whoami.service";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { FormCtrl } from "@/core-ui/forms/compositions";
import { useWarnModel } from "@/core-ui/compositions/WarnModel";
import _, { isEmpty } from "lodash";
import { selectedProjectKey } from "../../../cluster-ui/compositions/ProjectKey";
import {
    getCurrentSettingsName,
    getTargetProject,
    setTargetProject
} from "../../../cluster-ui/helpers/commonFunctions";
import projectService from "../../../cluster-ui/services/project.service";
import { Project } from "../../../cluster-ui/models/project.model";
import { Fields } from "../../types/template";
import {
    ClusterConnectionError,
    buildClusterConnectionError,
} from "@/cluster-ui/components/submit/clusterConnectionError";
import { InferenceJob, InteractiveJob, TrainingJob } from "@/cluster-ui/helpers/const";
import { getDeploymentArgs } from "@/components/deployments/deploymentFields";
import { DynamicMsg, useDynamicMsg } from "@/core-ui/compositions/DynamicMsg";
import ProjectModel from "@/cluster-ui/components/submit/ProjectModel.vue";

type Status = "loading" | "success" | "failed";

interface SubmitQueryParams {
    JobName: string;
    JobProject: string;
    JobType: string;
}

export type SubmitFieldsLoader = {
    state: Status;
    clusterConnectionError: ClusterConnectionError;
    ready: boolean;
    isTemplateLoading: boolean;
    isSettingsLoading: boolean;
    isLdapMode: boolean;
    fields: FieldsByKeys;
    settings: any[];
    fetchWorkload: any;
    init(settingName?: string): Promise<void>;
    reset(): Promise<boolean>;
    loadSettings(name: string): void;
    loadTemplate(
        values: any,
        templateName: string,
        projectName: string,
        skipChangesMsg?: boolean,
        settingsName?: string,
    ): Promise<boolean | Error>;
};

const OVERRIDE_JOB_FROM_VALUES_WARNING_KEY = "OVERRIDE_JOB_FROM_VALUES_WARNING_KEY";
const NAVIGATION_FROM_JOB_FORM_WARN_KEY = "NAVIGATION_FROM_JOB_FROM_WARN_KEY";

export function extractDefaultSettingsAndValueFromUrl(): SubmitQueryParams {
    const route = useRoute();
    let values: SubmitQueryParams = {} as SubmitQueryParams;
    try {
        values.JobName = route.query.JobName as string;
        values.JobProject = route.query.JobProject as string;
        switch (route.query.JobType as string) {
            case "Train":
                values.JobType = TrainingJob;
                break;
            case "Interactive":
                values.JobType = InteractiveJob;
                break;
            case "Interactive-Preemptible":
                values.JobType = InteractiveJob;
                break;
            default:
                values.JobType = "";
        }
    } catch (e) {
        console.error("extractDefaultSettingsAndValueFromUrl e", e);
    }

    return values;
}

type Args = {
    formCtrl: FormCtrl;
    isThereUnsavedChanges(): boolean;
    forceUpdate: () => void;
    onInputChange: (value: any, oldValue: any) => void;
};

export async function getProjectAndNameSpace(skipCache?: boolean): Promise<Project[]> {
    let projectArray: Project[];
    if (skipCache) {
        projectArray = await projectService.get();
        storageService.setObj("projects", projectArray);
        return projectArray;
    } else {
        projectArray = storageService.getObj("projects") as Project[];
    }
    return projectArray;
}

async function addProjectAndImageFieldSettings(
    fields: Record<string, any>,
    onInputChange: (value: any, oldValue: any) => void,
) {
    await getProjectAndNameSpace().then((projects: any) => {
        try {
            // add "image" field
            if (fields["image"]) fields["image"].rules = { ...fields["image"].rules, required: true };
            else fields["image"] = { rules: { required: true } };

            // add "project" field
            fields["project"] = {
                value: undefined,
                rules: { options: [], required: true },
                onInputChange,
            };

            // with this code policy did not load for project
            // disable this logic for now
            // // set default project if there is only 1 project
            // if (projects.length == 1) {
            //     fields["project"].value = projects[0].name;
            // }

            // add project options list
            for (const project in projects) {
                fields["project"].rules.options.push({
                    name: projects[project].name,
                    value: projects[project].name,
                });
            }
        } catch (e) {
            console.log(e);
        }
    });
}

export function useSubmitFieldsLoader(args: Args): SubmitFieldsLoader {
    const fields = ref<any>({});
    const isTemplateLoading = ref(false);
    const isSettingsLoading = ref(false);
    const isWhoAmILoading = ref(false);
    const isLdapMode = ref(false);
    const areYouSure = useWarnModel();
    const model = useDynamicMsg();
    const resetMsg = {
        title: "Clear",
        msg: "Are you sure you want to clear your changes?",
    };

    const templateMsg = {
        title: "Are you sure?",
        msg: "Are you sure you want to clear your changes and continue?",
    };

    const switchSettingsMsg = {
        title: "Change Job Type",
        msg: "Changing job type will clear your changes. <br/><br/>Are you sure you want to change job type?",
    };

    const navigationMsg = {
        title: "Confirm Navigation",
        msg: "Navigating away from this page will cause any changes you made to be lost. <br/><br/>Are you sure you want to leave this page?",
        ok: "Leave",
        cancel: "Stay",
    };

    onBeforeRouteLeave(async (to, from, next) => {
        try {
            if (!args.formCtrl.submitted && args.isThereUnsavedChanges()) {
                const response = await areYouSure.warn(NAVIGATION_FROM_JOB_FORM_WARN_KEY, navigationMsg);
                if (response && (response as {isDialogCanceled: boolean}).isDialogCanceled) {
                    next(false);
                } else {
                    next()
                }
            } else {
                next()
            }
        } catch (e) {
            next(false);
        }
    });

    window.onbeforeunload = function () {
        if (args.isThereUnsavedChanges()) {
            return true; // native browser message
        }
        return undefined;
    };

    const ready = computed(() => {
        return state.value == "success" && !isTemplateLoading.value;
    });
    const state = ref<Status>("loading");
    const clusterConnectionError = ref<ClusterConnectionError>({} as ClusterConnectionError);
    const settings = ref<any[]>([]);

    // targetProject
    // used only in global template
    // ...

    async function loadTemplate(
        values: any,
        templateName: string,
        projectName: string,
        skipChangesMsg?: boolean,
        settingsName?: string,
    ): Promise<boolean | Error> {
        if (isTemplateLoading.value || isSettingsLoading.value) {
            return false;
        }
        try {
            let targetProject = "";
            if (projectName == "runai") {
                targetProject = (await tryOpenModal({ model }))?.project;
                setTargetProject(targetProject);
            }
            const shouldLoadTemplate = await fetchWorkload(templateName, projectName, skipChangesMsg, targetProject, settingsName);
            if (shouldLoadTemplate) {
                args.forceUpdate();
            }
            isTemplateLoading.value = shouldLoadTemplate;
            return shouldLoadTemplate;

        } catch (e) {
            throw e;
        } finally {
            isTemplateLoading.value = false;
        }
    }

    //
    //   load who-am-i information from the server. this information is taken from the
    //   token which the user sends to the RS, including his gid and uid, which determine
    //   if he is working in LDAP mode or not.
    //
    async function loadWhoAmIInfo(): Promise<boolean> {
        if (isWhoAmILoading.value) {
            return false;
        }

        try {
            isWhoAmILoading.value = true;
            const whoAmIInfo = await whoamiService.get();
            //
            //   for ldap mode we need the token to include the uid the user
            //
            isLdapMode.value = whoAmIInfo != null && whoAmIInfo.uid != null;
            return true;
        } finally {
            isWhoAmILoading.value = false;
        }
    }

    function getSettingArgsBySettings(selected: string): FieldsByKeys {
        switch (selected) {
            case InferenceJob: {
                return getDeploymentArgs();
            }
            case InteractiveJob:
            case TrainingJob: {
                const options = {
                    sweep: selected === TrainingJob
                }
                return getJobArgs(options);
            }
        }
        return {};
    }

    async function openAddModel(model: DynamicMsg, project: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const key = "chose_project_after_click_on_global_template";
            model.add(key, ProjectModel, {
                project,
                onSave: (name: string) => {
                    model.remove(key);
                    resolve(name);
                },
            });
        });
    }

    async function tryOpenModal(args: any): Promise<any> {
        try {
            return await openAddModel(args.model, args.project);
        } catch (e) {
            console.warn(e);
            // the model is closed
            return;
        }
    }

    async function loadSettings(
        selected: string,
        msg: any,
        skipChangesMsg = false,
        templateProjectName?: string,
    ): Promise<boolean> {
        try {
            if (!skipChangesMsg && args.isThereUnsavedChanges()) {
                const response = await areYouSure.warn(OVERRIDE_JOB_FROM_VALUES_WARNING_KEY, msg);
                if (response && (response as {isDialogCanceled: boolean}).isDialogCanceled) {
                    return false;
                }
            }

            isSettingsLoading.value = true;
            fields.value = getSettingArgsBySettings(selected);
            let currentSettings = settings.value.find((s) => s.name == selected);
            if (!currentSettings) {
                currentSettings = settings.value[0];
            }

            await getProjectAndNameSpace(true);
            await addProjectAndImageFieldSettings(currentSettings.fields, args.onInputChange);

            const targetProject = getTargetProject();

            // add project name from template (on template click)
            if (templateProjectName && templateProjectName != "runai") {
                currentSettings.fields["project"].value = templateProjectName;
            }

            if (templateProjectName && templateProjectName == "runai" && targetProject) {
                currentSettings.fields["project"].value = targetProject;
                setTargetProject("");
            }

            applyJobSettings(fields.value, currentSettings.fields);

            return true;
        } catch (err) {
            if (err) {
                console.error(err);
            }
            return false;
        } finally {
            isSettingsLoading.value = false;
        }
    }

    async function resetForm(msg: any) {
        if (args.isThereUnsavedChanges()) {
            await areYouSure.warn(OVERRIDE_JOB_FROM_VALUES_WARNING_KEY, msg);
        }
        return await args.formCtrl.state.reset();
    }

    function addClusterIpAsDefaultIfPortsExistsInTemplate(interactiveWorkloads: Fields) {
        if (isEmpty(interactiveWorkloads.serviceType.value) && !isEmpty(interactiveWorkloads.ports.items)) {
            interactiveWorkloads.serviceType.value = "ClusterIP";
        }
    }

    async function fetchWorkload(
        templateName?: string,
        namespace?: string,
        skipChangesMsg?: boolean,
        targetProject?: string,
        settingsName?: string,
    ) {
        const workload: any[] = [];
        await workloadService
            .getWorkloadsMerged(getCurrentSettingsName(), templateName, namespace, targetProject)
            .then((workloads) => {
                if (workloads)
                    workload.push({
                        name: getCurrentSettingsName(),
                        fields: workloads,
                    });
            });

        const currentSettings = settings.value.find((item) => item.name == getCurrentSettingsName());
        if (currentSettings) {
            currentSettings.name = workload[0].name;
            currentSettings.fields = workload[0].fields;
            const isLoadAcceptedOrSucceeded = await loadSettings(getCurrentSettingsName(), templateMsg, skipChangesMsg, namespace);
            if (!isLoadAcceptedOrSucceeded) {
                return false;
            }
        }
        state.value = "success";
        return true;
    }

    async function fetchAllWorkload(namespace?: string) {
        const workload: any[] = [];
        await Promise.all([
            workloadService.getWorkloadsMerged(InteractiveJob, "", namespace),
            workloadService.getWorkloadsMerged(TrainingJob, "", namespace),
            workloadService.getWorkloadsMerged(InferenceJob, "", namespace),
        ]).then(([interactiveWorkloads, trainingWorkloads, inferenceWorkloads]) => {
            if (interactiveWorkloads) {
                addClusterIpAsDefaultIfPortsExistsInTemplate(interactiveWorkloads);
                workload.push({
                    name: InteractiveJob,
                    fields: interactiveWorkloads,
                });
            }
            if (trainingWorkloads) {
                workload.push({
                    name: TrainingJob,
                    fields: trainingWorkloads,
                });
            }
            if (inferenceWorkloads) {
                workload.push({
                    name: InferenceJob,
                    fields: inferenceWorkloads,
                });
            }
        });
        settings.value = _.orderBy(workload, "name");
        await loadSettings(getCurrentSettingsName(), templateMsg, true, namespace);
        state.value = "success";
    }

    async function init() {
        state.value = "loading";
        try {
            const projectName = storageService.getStr(selectedProjectKey);
            await fetchAllWorkload(projectName);
            await loadWhoAmIInfo();
            state.value = "success";
        } catch (err: any) {
            console.error("Failed to load submit form", err);
            clusterConnectionError.value = buildClusterConnectionError(err);
            state.value = "failed";
        }
    }

    return {
        get fields() {
            return fields.value;
        },
        get state() {
            return state.value;
        },
        get clusterConnectionError() {
            return clusterConnectionError.value;
        },
        get ready() {
            return ready.value;
        },
        get settings() {
            return settings.value || [];
        },
        get isSettingsLoading() {
            return isSettingsLoading.value;
        },
        get isTemplateLoading() {
            return isTemplateLoading.value;
        },
        get isLdapMode() {
            return isLdapMode.value;
        },
        fetchWorkload,
        loadSettings(settingsName) {
            loadSettings(settingsName, switchSettingsMsg);
        },
        loadTemplate,
        async reset() {
            try {
                isTemplateLoading.value = true;
                await resetForm(resetMsg);
                return true;
            } catch (e) {
                if (e) {
                    console.error(e);
                }

                return false;
            } finally {
                isTemplateLoading.value = false;
            }
        },
        init,
    };
}
