
import ProgressBar from "@/core-ui/ProgressBar.vue";
import Box from "./Box.vue";
import BoxInputMessages from "./BoxInputMessages.vue";
import { useSyncRunner } from "@/core-ui/forms/compositions/SyncRunner";

import { useFieldCtrl } from "@/core-ui/forms/compositions";
import { fieldCtrlArgs, variant } from "./common";
import { defineComponent, ref, watchEffect, watch } from "vue";

type InputValueTypes = string | number;
type Option = { name: string; value: string | number };
type O = Option[] | string[] | number[];
type LoaderStatus = "loading" | "falied" | "ready" | "empty";

function mapOption(value: string | number | Option): Option {
    if ((value as Option)?.name) {
        return value as Option;
    }
    return {
        name: value as string,
        value: value as string | number,
    };
}

export default defineComponent({
    components: { Box, BoxInputMessages, ProgressBar },
    props: {
        variant,
        ctrlArgs: fieldCtrlArgs<InputValueTypes>(),
    },
    setup: (props, ctx) => {
        const optionsData = ref<Option[]>([]);
        const optionsStatus = ref<LoaderStatus>("empty");

        const fieldCtrl = useFieldCtrl(props.ctrlArgs!);
        let oldValue = ref<string>("");

        const optionLoader = useSyncRunner(async () => {
            let optionsArg = (fieldCtrl.meta as any)?.options;
            let optionsArray = [];
            if (typeof optionsArg == "function") {
                try {
                    optionsStatus.value = "loading";
                    optionsArray = await optionsArg();
                } catch {
                    optionsStatus.value = "falied";
                }
            } else if (Array.isArray(optionsArg)) {
                optionsArray = optionsArg;
            }
            if (Array.isArray(optionsArray)) {
                optionsData.value = optionsArray.map(mapOption);
                optionsStatus.value = "ready";
            } else {
                optionsData.value = [];
                optionsStatus.value = "empty";
            }
        });

        watchEffect(() => {
            optionLoader.run();
        });

        return {
            fieldCtrl,
            optionLoader,
            onFocus(event: any) {
                oldValue = event.target.value;
            },
            onChange(event: any) {
                const onInputChange = props.ctrlArgs!.onInputChange;
                if (onInputChange) {
                    onInputChange(event.target.value, oldValue);
                }
            },
            get optionsData() {
                return optionsData.value.map(mapOption);
            },
            optionsStatus,

            get inputArgs(): any {
                const { ctrlArgs, variant, autocomplete, ...others } = ctx.attrs;
                return { ...others, ...(fieldCtrl.meta?.inputProps || {}) };
            },

            get placeholder() {
                return (fieldCtrl.meta as any)?.placeholder;
            },

            get invalid() {
                return fieldCtrl.valid == "invalid";
            },
            get isLoading() {
                return optionsStatus.value == "loading";
            },
        };
    },
});
