import { loadView } from "./utils";
import { ROUTES_ENUM } from "./routes";
import NotAuthenticated from "@/views/NonAuthenticated.vue";
import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import authStore from "../stores/authStore";

const loginRedirect = (toRoute: RouteLocationNormalized) => {
    return (toRoute.query.redirectTo as string) || "/";
};

const children = [
    {
        path: "/oauth/login",
        name: ROUTES_ENUM.LOGIN,
        component: loadView("Login"),
        beforeEnter: async (to: any, _from: any, next: any) => {
            const redirectTo = loginRedirect(to);
            if(!await authStore.initialized()) {
                next();
            }
            if (await authStore.authStore.isAuthenticated()) {
                next(redirectTo);
            } else {
                await authStore.login(redirectTo);
            }
        },
        props: (route: any) => {
            return {
                redirectTo: loginRedirect(route),
            };
        },
    },
];

const router: RouteRecordRaw = {
    path: "/",
    component: NotAuthenticated,
    redirect: "/oauth/login",
    children,
};
export default router;
