import apiClient from "./api-client";
import { getTenantNameFromHostname } from "@/helpers/is-production-env";

type config = {
    authClientID: string;
    authURL: string;
    authRealm: string;
    isStaging: boolean;
    selfHosted: boolean;
    tenantName: string;
    externalTokenPath: string;
};

class ConfigService {
    public config: config = null as any as config;

    async init() {
        let path = `v1/k8s/config`;
        const tenantName = getTenantNameFromHostname();
        if (tenantName) {
            path += `?tenantName=${tenantName}`;
        }
        this.config = (await apiClient.asyncRequestWithResponseAndError(path, "GET")) as config;
    }
}

const configService = new ConfigService();
export default configService;
