import { InferenceJob, InteractiveJob, jsonParseKeys, notMappedKeys, TrainingJob, ignoredKeys } from "./../../helpers/const";
import { getCurrentSettingsName } from "@/cluster-ui/helpers/commonFunctions";
import { ImagePullPolicy, mapTtl, parseCommand } from "@/cluster-ui/components/submit/paramsUtil";

type BasicSettings = {
    name: string;
    type: string;
    project: string;
};

type ContainerDefinitionSettings = {
    command: Array<string>;
    arguments: Array<string>;
    environment: { [key: string]: string }; // comma-delimeted of "<key>:<val>" (make sure in the client no null) // todo impl
    labels: { [key: string]: string };
    annotations: { [key: string]: string };
    image: string;
    imagePullPolicy: ImagePullPolicy;
    stdin: boolean;
    runAsUser: boolean;
    supplementalGroups: string;
    runAsUid: number;
    runAsGid: number;
    tty: boolean;
    workingDir: string; // todo - check any regex format here?
    createHomeDir: boolean;
    gitSync: { [key: string]: string };
    tolerations: { [key: string]: string };
    capabilities: { [key: string]: string };
    extendedResources: { [key: string]: string };
    exposedUrls: { [key: string]: string };
};

type ResourceAllocationSettings = {
    gpu: number;
    gpuLimit: number;
    cpu: number;
    cpuLimit: number;
    gpuMemory: string;
    migProfile: string;
    memory: string;
    memoryLimit: string;
    largeShm: boolean;
};

type StorageSettings = {
    volumes: { [key: string]: string };
    pvcs: { [key: string]: string };
    s3: { [key: string]: string };
};

type portMap = {
    container: number;
    external: number;
    autoGenerate: boolean;
    protocol: string;
};

type NetworkSettings = {
    hostIpc: boolean;
    hostNetwork: boolean;
    ports: Array<portMap>;
};

type JobLifecycleSettings = {
    backoffLimit: number;
    completions: number;
    parallelism: number;
    preemptible: boolean;
    serviceType: string;
    ttlAfterFinish: string;
};

type AccessControlSettings = {
    allowPrivilegeEscalation: boolean;
};

type SchedulingSettings = {
    nodeType: string;
};

type MiscellaneousSettings = {
    jobNamePrefix: string;
};

export type JobSettings = BasicSettings &
    ContainerDefinitionSettings &
    ResourceAllocationSettings &
    StorageSettings &
    NetworkSettings &
    JobLifecycleSettings &
    AccessControlSettings &
    SchedulingSettings &
    MiscellaneousSettings;

export type SubmitJobParams = {
    job: JobSettings;
};

export function mapJobValue(value: { [key: string]: any }): SubmitJobParams {
    const job: JobSettings = {} as JobSettings;
    Object.keys(value || {}).forEach((k) => {
        if (notMappedKeys.has(k)) {
            (job as any)[k] = value[k];
        }

        // convert from """arg1", "arg2", ..." to [arg1, arg2, ...]
        else if (k == "command") {
            if (value[k]) {
                parseCommand(job, value);
            }
        } else if (jsonParseKeys.includes(k)) {
            (job as any)[k] = JSON.parse(JSON.stringify(value[k]));
        } else if (k == "ttlAfterFinish") {
            const ttl = mapTtl(value[k]);
            if (ttl) {
                job[k] = ttl;
            }
        } else {
            console.warn(`Unidentified key: ${k}`);
        }
    });
    return {
        job,
    };
}

export function fromJobTemplate(value: JobSettings) {
    const result: any = {};
    Object.keys(value || {}).forEach((k) => {
        if (notMappedKeys.has(k)) {
            (result as any)[k] = (value as any)[k];
        }
        // convert from array of <key>:<value> strings to map object
        else if (k == "command" ) {
            result[k] = value[k];
        } else if (k == "ttlAfterFinish") {
            const ttl = mapTtl(value[k]);
            if (ttl) {
                result[k] = ttl;
            }
        } else {
            console.warn(`Unidentified key: ${k}`);
        }
    });
    return result;
}

export function toJobTemplate(value: { [key: string]: any }): {
    [key: string]: any;
} {
    const temp: { [key: string]: any } = {} as JobSettings;
    Object.keys(value || {}).forEach((k) => {
        if (notMappedKeys.has(k)) {
            (temp as any)[k] = value[k];
        }
        // convert from array of <key>:<value> strings to map object
        else if (k == "command") {
            temp[k] = value[k];
        } else if (jsonParseKeys.includes(k)) {
            temp[k] = JSON.parse(JSON.stringify(value[k]));
        } else if (k == "ttlAfterFinish") {
            const ttl = mapTtl(value[k]);
            if (ttl) {
                temp[k] = ttl;
            }
        } else if (!ignoredKeys.has(k)) {
            console.warn(`Unidentified key: ${k}`);
        }
    });
    return temp;
}
