import { Validation } from "@/core-ui/types/validation";
import { ref } from "vue";

export const kubernetesValidation: Validation = {
    inputType: "text",
    test(str: string) {
        return !!str && !!str.match(/^[a-z][-a-z0-9]*[a-z0-9]?$/) && !str.endsWith("-");
    },
    description: "Please use only standard characters: a-z, 0-9, - or . No spaces are allowed",
};

export const nameValidation: Validation = {
    inputType: "text",
    test(str: string) {
        return !!str && !!str.match(/^[A-z0-9]*((-|\s)*[A-z0-9])*$/);
    },
    description: "Please use only standard characters: a-z, 0-9, - or spaces",
};

export const emailValidation: Validation = {
    inputType: "text",
    test(str: string) {
        return (
            !!str &&
            !!str.match(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            )
        );
    },
};

export const passwordValidation: Validation = {
    inputType: "password",
    test(str?: string) {
        return !!str && !!str.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/);
    },
    description: "Please use lower case (a-z), upper case (A-Z), numbers (0-9) and special characters (!@#$%^&*)",
};

export const quantityValidation: Validation<number> = {
    inputType: "number",
    test(num) {
        return num >= 0;
    },
    attrs: {
        min: 0,
    },
};

export const numberValidation = ({ min, max, step = 1 }: any): Validation<number> => {
    const defaultErr = "Invalid Number";
    const err = ref(defaultErr);
    return {
        inputType: "number",
        test(num) {
            err.value = defaultErr;
            if (min !== undefined && num < min) {
                err.value = `Number must be no smaller than ${min}`;
                return false;
            }
            if (max !== undefined && num > max) {
                err.value = `Number must be no greater than ${max}`;
                return false;
            }
            if (num / step !== parseInt((num / step) as any)) {
                err.value = `Value must be integer`;
                if (step != 0) {
                    err.value = `Please use number divisible by ${step} (e.g ${step}, ${step * 2}, ${step * 3} ...)`;
                }
                return false;
            }
            return true;
        },
        get errMsg() {
            return err.value;
        },
        attrs: {
            min: 0,
        },
    };
};
