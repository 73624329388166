
import { defineComponent } from "vue";
import { fieldCtrl, variant } from "./common";

type InputValueTypes = string | number | boolean;

export default defineComponent({
    props: {
        variant,
        ctrl: fieldCtrl<InputValueTypes>(),
    },
});
