import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, toHandlers as _toHandlers, withKeys as _withKeys, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c64aefbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "checked", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _mergeProps({ class: _ctx.className }, _toHandlers(_ctx.$attrs), {
    tabindex: "0",
    role: "checkbox",
    onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.keyToggle && _ctx.keyToggle(...args)), ["prevent"]), ["space"]))
  }), [
    _createElementVNode("input", {
      type: "checkbox",
      class: "v-switch-input",
      name: _ctx.name,
      checked: _ctx.modelValue,
      disabled: _ctx.disabled,
      tabindex: "-1",
      onChange: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)), ["stop"]))
    }, null, 40, _hoisted_1),
    _createElementVNode("div", {
      class: "v-switch-core",
      style: _normalizeStyle(_ctx.coreStyle)
    }, [
      _createElementVNode("div", {
        class: "v-switch-button",
        style: _normalizeStyle(_ctx.buttonStyle)
      }, null, 4)
    ], 4),
    (_ctx.labels)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.toggled)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "v-switch-label v-left",
                style: _normalizeStyle(_ctx.labelStyle)
              }, [
                _renderSlot(_ctx.$slots, "checked", {}, () => [
                  _createElementVNode("template", null, [
                    _createTextVNode(_toDisplayString(_ctx.labelChecked), 1)
                  ])
                ], true)
              ], 4))
            : (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "v-switch-label v-right",
                style: _normalizeStyle(_ctx.labelStyle)
              }, [
                _renderSlot(_ctx.$slots, "unchecked", {}, () => [
                  _createElementVNode("template", null, [
                    _createTextVNode(_toDisplayString(_ctx.labelUnchecked), 1)
                  ])
                ], true)
              ], 4))
        ], 64))
      : _createCommentVNode("", true)
  ], 16))
}