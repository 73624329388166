import { computed, ref, watch } from "@vue/runtime-core";
import { ToastInterface, useToast } from "vue-toastification";
import _, { debounce, isEmpty, orderBy } from "lodash";
import { useCacheFetch } from "@/core-ui/data-grid/compositions";
import exportService from "@/core-ui/services/export-service";
import { storageService } from "@/core-ui/data-grid/services";
import * as yaml from "js-yaml";
import { getCurrentTemplateName, handelWorkLoadErrors, setCurrentTemplateList } from "../helpers/commonFunctions";
import {
    getCurrentSettingsName,
    projectNameWithoutRunAiString,
    settingsTypeToWorkLoadKind,
    workLoadNameToSettingsName,
} from "../helpers/commonFunctions";
import { DynamicMsg, useDynamicMsg } from "@/core-ui/compositions/DynamicMsg";
import { toTemplate } from "../components/submit/paramsUtil";
import { Workload, WorkloadError } from "../../cluster-ui/components/submit/types";
import { usageOptions, STARE_KEY, ALL_PROJECTS, InferenceJob } from "../../cluster-ui/helpers/const";
import TemplateModel from "../components/submit/TemplateModel.vue";
import { Template } from "../types/template";
import workloadService from "../../cluster-ui/services/workload.service";
import { projectNameToWorkloadNameSpace } from "../../cluster-ui/helpers/commonFunctions";
import { createSubmitTemplateDataForWorkLoadController } from "../../cluster-ui/components/submit/submitUtil";

storageService.setObj(STARE_KEY, storageService.getObj(STARE_KEY, {}));

type QuickLoadArgs = {
    project: string;
    loading: boolean;
    load(data: any, templateName: string, projectName: string): Promise<boolean | Error>;
    currentValue: any;
};

type Loaders = "file" | "job" | "template";

type LoaderArgs = {
    project: string;
    loading: boolean;
    currentValue: any;
    load(loader: Loaders, data: any, templateName: string, projectName: string): Promise<boolean | Error>;
    makeActive(loader: Loaders): void;
};

export type MuteTemplateParams = {
    toast: ToastInterface;
    model: DynamicMsg;
    project: string;
    template?: Template;
    templateGenerator?: any;
    jobToSave?: Workload;
};

async function openAddModel(model: DynamicMsg, project: string): Promise<string> {
    return new Promise((resolve, reject) => {
        const key = "add_new_template";
        model.add(key, TemplateModel, {
            project,
            onCancel: () => {
                model.remove(key);
                reject();
            },
            onSave: (name: string) => {
                model.remove(key);
                resolve(name);
            },
        });
    });
}

export async function updateWLTemplate(args: any): Promise<string | undefined> {
    const name = getCurrentTemplateName();
    const project = args.project;
    let template = args.template;
    if (isEmpty(template)) {
        template = await args.templateGenerator(name, project);
    }
    // mark the template kind as "template" and not "submit"
    template.spec.usage = usageOptions.Template;
    try {
        const type = getCurrentSettingsName();
        await workloadService.submitWorkloadByType(project, type, template);
        args.toast.success(`Template: ${name} updated`);
        return name;
    } catch (e) {
        const err = handelWorkLoadErrors(e as WorkloadError);
        if (err) {
            args.toast.error(`Failed to update template : ` + err);
            return;
        }
        args.toast.error(`Failed to update template`);
    }
}

async function tryOpenModal(args: MuteTemplateParams): Promise<any> {
    try {
        return await openAddModel(args.model, args.project);
    } catch (e) {
        console.warn(e);
        // the model is closed
        return;
    }
}

export async function openSaveAsPopUpAndSaveTemplate(args: MuteTemplateParams): Promise<string | undefined> {
    const modalValues: any = await tryOpenModal(args);
    if (isEmpty(modalValues)) return;

    const name = modalValues.name;
    let project = modalValues.project;
    const isGlobal = project == ALL_PROJECTS;

    let dataToSubmit;
    if (args.jobToSave) {
        dataToSubmit = args.jobToSave;
        dataToSubmit.metadata.name = name;
    } else if (isEmpty(args.jobToSave) && isEmpty(args.template)) {
        if (isGlobal) project = "runai";
        dataToSubmit = await args.templateGenerator(name, project);
    } else {
        dataToSubmit = args.template;
    }
    try {
        // update usage as Template
        // and
        // remove project (no need - we use it to create the namespace value under the metadata)
        dataToSubmit.spec.usage = usageOptions.Template;
        delete dataToSubmit.spec["project"];

        if (isGlobal) {
            await saveAsGlobal(name, getCurrentSettingsName(), dataToSubmit);
        } else {
            const type = getCurrentSettingsName();
            await workloadService.submitWorkLoad(project, dataToSubmit, type);
            args.toast.success(`Template: ${name} added`);
            return name;
        }
    } catch (e) {
        const err = handelWorkLoadErrors(e as WorkloadError);
        if (err) {
            args.toast.error(`Failed to add/create template : ` + err);
            return;
        }
        args.toast.error(`Failed to add/create template `);
    }
}

function saveAsGlobal(name: string, settingsName: string, template: any) {
    try {
        const data = JSON.parse(JSON.stringify(template));
        const yamlData = yaml.safeDump(data);
        exportService.toDownload("template-" + name, "yaml", yamlData);
    } catch (e) {
        console.error(e);
        return;
    }
}

export function useTemplates(args: LoaderArgs) {
    const toast = useToast();
    const model = useDynamicMsg();
    const lastActiveTemplateId = ref("");

    const lastActiveTemplate = computed(() => {
        const id = lastActiveTemplateId.value;
        return templates.data.find((t) => t.project == args.project && t.id == id);
    });

    function makeActive(id: string) {
        args.makeActive("template");
        lastActiveTemplateId.value = id;
    }

    const templates = useCacheFetch({
        defaultData: [],
        fetch: async () => {
            const res = await handelWorkLoadDataByUsage(usageOptions.Template, args.project);
            setCurrentTemplateList(res);
            return res;
        },
    });

    const data = computed(() => {
        const staredTemplates: any = storageService.getObj(STARE_KEY, {});
        const t = templates.data.map((t) => ({
            ...t,
            stared: !!staredTemplates[`${t.project}-${t.name}`],
        }));
        return orderBy(t, ["stared", "name"], ["desc", "asc"]);
    });

    return {
        get data() {
            return data.value;
        },
        get state() {
            return templates.state;
        },
        lastActiveTemplate,

        async addNew(templateGenerator: any) {
            const res = await openSaveAsPopUpAndSaveTemplate({
                model,
                toast,
                project: args.project,
                templateGenerator: templateGenerator,
            });

            if (res) {
                makeActive(`${args.project || "global"}-${res}`);
                templates.refresh();
            }
        },

        toggleStar(project: string, name: string) {
            const id = `${project}-${name}`;
            const staredTemplates: any = storageService.getObj(STARE_KEY, {});
            staredTemplates[id] = !staredTemplates[id];
            storageService.setObj(STARE_KEY, staredTemplates);
        },

        refreshData() {
            templates.refresh();
        },

        async save(templateGenerator: any) {
            const res = await updateWLTemplate({
                toast,
                project: args.project,
                templateGenerator: templateGenerator,
            });
            if (res) {
                makeActive(`${args.project || "global"}-${res}`);
                templates.refresh();
            }
        },

        async remove(project: string, name: string) {
            const type = getCurrentSettingsName();
            try {
                await workloadService.deleteWorkloadTemplatesProjectTemplate(project, name, type);
                toast.success("Deleted");
                templates.refresh();
            } catch (e) {
                toast.error(`Failed to add template`);
            }
        },

        async onClickTemplate(template: any) {
            if (!template) return;

            try {
                const shouldLoadTemplate = await args.load("template", template.values, template.name, template.project || "runai");
                if (shouldLoadTemplate) {
                    if (template.project) {
                        makeActive(`${template.name}`);
                    } else {
                        makeActive(`global-${template.name}`);
                    }
                    toast.success(`Loaded template: ${template.name}`);
                }
            } catch (e) {
                const err = handelWorkLoadErrors(e as WorkloadError);
                if (err) {
                    toast.error(`Failed to load template : ` + err);
                    return;
                }
                toast.error(`Failed to load template: ${template.name}`);
            }
        },
    };
}

// TODO: use template from file (quick load- we dont support for now)
function useTemplateFile(args: LoaderArgs, onClickTemplate: (template: any) => Promise<void>) {
    const toast = useToast();

    // validation basic file fields before uploading
    const basicFileValidation = (tmp: Workload): string => {
        let basicFileValidationError = "";
        if (isEmpty(tmp.metadata.namespace)) {
            basicFileValidationError = "Malformed import file: metadata.namespace is not specified.";
        } else if (isEmpty(tmp.metadata.name)) {
            basicFileValidationError = "Malformed import file: metadata.namespace is not specified.";
        } else if (tmp.metadata.name == "runai") {
            basicFileValidationError =
                "Malformed import file: invalid metadata.namespace: workload must reside in a project.";
        } else if (isEmpty(tmp.spec.usage) || tmp.spec.usage != usageOptions.Template) {
            basicFileValidationError = "Malformed import file: spec.usage must be ‘Template’.";
        }
        return basicFileValidationError;
    };

    const self = {
        import() {
            const input = document.createElement("input");
            input.type = "file";
            input.addEventListener("change", function () {
                const file = (input as any).files[0] as File;
                self.load(file);
            });
            // add onchange handler if you wish to get the file :)
            input.click(); // opening dialog
        },

        async load(file: File) {
            if (file.name.match(/\.(json)$/)) {
                const reader = new FileReader();

                reader.onload = async function () {
                    const tmp: Workload = JSON.parse(reader.result as string);
                    toast.success("file loaded");
                    const basicFileValidationError = basicFileValidation(tmp);

                    if (!isEmpty(basicFileValidationError)) {
                        toast.error(basicFileValidationError);
                        return;
                    }

                    let response;
                    try {
                        response = await workloadService.submitWorkLoad("", tmp, tmp.kind, tmp.metadata.namespace);
                    } catch (error: any) {
                        const toastErrorMsg = handelWorkLoadErrors(error as WorkloadError);
                        toast.error(toastErrorMsg);
                        return;
                    }

                    await onClickTemplate({
                        values: {},
                        settingsName: workLoadNameToSettingsName(response?.data.kind),
                        name: response?.data.metadata.name,
                        project: await projectNameWithoutRunAiString(response?.data.metadata.namespace),
                    });
                };

                reader.readAsText(file);
            } else {
                toast.error("File not supported, .json file only");
            }
        },
        async export() {
            const data = await createSubmitTemplateDataForWorkLoadController(
                args.currentValue,
                "",
                "",
                usageOptions.Submit,
                false
            );
            const namespace = await projectNameToWorkloadNameSpace(args.project);
            if (namespace) data.metadata.namespace = namespace;

            // mark the request usage as template
            data.spec.usage = usageOptions.Template;
            exportService.toDownload("job", "json", JSON.stringify(data, null, 2));
        },
    };
    return self;
}

async function handelWorkLoadDataByUsage(usage: string, project: string) {
    const resDataArray: Template[] = [];
    let projectData: Workload[] = [];

    const type = getCurrentSettingsName();
    const globalData = await workloadService.getWorkloadsByType(settingsTypeToWorkLoadKind(type), "");
    if (!_.isEmpty(project))
        projectData = await workloadService.getWorkloadsByType(settingsTypeToWorkLoadKind(type), project);

    const workloadTemplatesByProject = [...globalData, ...projectData];
    await Promise.all(
        workloadTemplatesByProject?.map(async (item) => {
            let project = "";
            const settingsName = workLoadNameToSettingsName(item.kind);
            if (item.metadata.namespace != "runai") {
                project = await projectNameWithoutRunAiString(item.metadata.namespace);
            }

            let originalName = item?.spec?.name?.value;

            if (!originalName) { // when submitting a job using the cli, the name does not exist on the spec
                try {
                    const createdResourcesList = item.status["createdResources"]
                    createdResourcesList.filter((item: any) => {
                        return item.kind == "ConfigMap" || item.kind == "RunaiJob" || item.kind == "MpiJob"
                    })
                    originalName = createdResourcesList[0].name
                } catch (err) {
                    console.warn("Can't display template or previous_job (name doesn't exist under spec/createdResources )", item)
                }
            }

            if (item.spec.usage == usage && originalName) {
                const templateItem: Template = {
                    id: item.metadata.uid as string,
                    name: item.metadata.name,
                    originalName,
                    project,
                    settingsName,
                    values: {},
                };
                resDataArray.push(templateItem);
            }
        }),
    );
    return [...resDataArray];
}

function useTemplateJobs(args: LoaderArgs) {
    const toast = useToast();

    const lastActiveJobId = ref("");

    const jobs = useCacheFetch({
        defaultData: [],
        fetch: async () => {
            const res = await handelWorkLoadDataByUsage(usageOptions.Submit, args.project);
            return res;
        },
    });

    watch(
        [() => getCurrentSettingsName()],
        () => {
            if (args.loading) {
                return;
            }
            jobs.refresh();
        },
        { immediate: true },
    );

    return {
        get lastActiveJobId() {
            return lastActiveJobId.value;
        },
        get data() {
            return jobs.data;
        },
        get state() {
            return jobs.state;
        },
        async onClickJob(template: any) {
            const loadTemMerged = async () => {
                return args.load("template", template.values, template.name, template.project);
            };

            if (template && (await loadTemMerged())) {
                const type = getCurrentSettingsName() == InferenceJob ? "Deployment" : "Job";
                toast.success(`Loaded ${type}: ${template.originalName || template.name}`);
            }
        },
        refreshData() {
            jobs.refresh();
        },
        async load(job: any) {
            const type = getCurrentSettingsName() == InferenceJob ? "Deployment" : "Job";
            if (job && (await args.load("job", job.template.values, "", ""))) {
                lastActiveJobId.value = job.id;
                toast.success(`Loaded ${type}: ${job.name}`);
            }
        },
    };
}

export function useQuickLoad(args: QuickLoadArgs) {
    const lastActiveLoader = ref("");
    const lastLoadedValues = ref({});

    const lastActiveValues = computed(() => {
        if (lastActiveLoader.value == "template") {
            return self.templates.lastActiveTemplate?.value?.values ?? {};
        } else {
            return lastLoadedValues.value;
        }
    });

    const loadersArgs: LoaderArgs = {
        get project() {
            return args.project;
        },
        get currentValue() {
            return toTemplate(args.currentValue);
        },
        get loading() {
            return args.loading;
        },

        async load(loader, values, templateName, projectName) {
            return args.load({}, templateName, projectName);
        },

        makeActive(loader) {
            lastActiveLoader.value = loader;
        },
    };

    const isLastActiveChange = ref(false);
    watch(
        [lastActiveValues, lastLoadedValues, () => args.currentValue],
        debounce(() => {
            const v = { ...toTemplate(args.currentValue) };
            const lv = { ...(lastActiveValues.value || {}) };
            delete (v as any).project;
            delete (lv as any).project;
            delete (v as any).name;
            delete (lv as any).name;
            isLastActiveChange.value = !_.isEqual(lv, v);
        }, 500),
    );
    const jobs = useTemplateJobs(loadersArgs);
    const templates = useTemplates(loadersArgs);
    const file = useTemplateFile(loadersArgs, templates.onClickTemplate);
    const self = {
        jobs,
        file,
        templates,
        get project() {
            return args.project;
        },
        get lastActiveLoader() {
            return lastActiveLoader.value;
        },
        get activeJobId() {
            return args.project && self.lastActiveLoader == "job" ? self.jobs.lastActiveJobId : "";
        },
        get activeTemplateId() {
            return self.lastActiveLoader == "template" ? self.templates.lastActiveTemplate.value?.id : "";
        },
        get activeTemplateName() {
            return self.lastActiveLoader == "template" ? self.templates.lastActiveTemplate.value?.name : "";
        },
        get isLastActiveChange() {
            return isLastActiveChange.value;
        },
        get isActiveTemplateGlobal() {
            return self.activeTemplateId?.startsWith("global-");
        },
    };
    return self;
}
