export class WaitFor<T> {
    _data: T = undefined as any;
    waiters: any = [];
    done(d: T) {
        this._data = d;
        const ws = this.waiters;
        this.waiters = [];
        ws.forEach(([resolve, _]: any) => resolve(d));
    }
    failed(e?: any) {
        const ws = this.waiters;
        this.waiters = [];
        ws.forEach(([_, reject]: any) => reject(e));
    }
    wait(): Promise<T> {
        return new Promise((resolve: any, reject: any) =>
            this._data !== undefined ? resolve(this._data) : this.waiters.push([resolve, reject]),
        );
    }
}
