import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "profile-dropdown" }
const _hoisted_2 = { class: "avatar-in-options" }
const _hoisted_3 = { class: "avatar-in-options-email" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBDropdownToggle = _resolveComponent("MDBDropdownToggle")!
  const _component_MDBTooltip = _resolveComponent("MDBTooltip")!
  const _component_MDBDropdownItem = _resolveComponent("MDBDropdownItem")!
  const _component_MDBDropdownMenu = _resolveComponent("MDBDropdownMenu")!
  const _component_MDBDropdown = _resolveComponent("MDBDropdown")!
  const _component_ChangePasswordModal = _resolveComponent("ChangePasswordModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MDBDropdown, {
      modelValue: _ctx.isOpen,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isOpen) = $event)),
      offset: "0, 9"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_MDBTooltip, {
          modelValue: _ctx.tooltip,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tooltip) = $event)),
          direction: "bottom"
        }, {
          reference: _withCtx(() => [
            _createVNode(_component_MDBDropdownToggle, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen)),
              color: "link",
              aid: "profile-btn",
              class: "profile-btn"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.shortName), 1)
              ]),
              _: 1
            })
          ]),
          tip: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.userEmail), 1)
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_MDBDropdownMenu, { dark: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_MDBDropdownItem, {
              tag: "div",
              class: "profile-options-avatar"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.shortName), 1),
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.userEmail), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profileOptions, (item) => {
              return (_openBlock(), _createBlock(_component_MDBDropdownItem, {
                tag: "button",
                key: item.id,
                aid: item.id,
                class: "profile-options",
                onClick: ($event: any) => (_ctx.optionClicked(item.id))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["aid", "onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.changePasswordOpened)
      ? (_openBlock(), _createBlock(_component_ChangePasswordModal, {
          key: 0,
          onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changePasswordOpened = false))
        }))
      : _createCommentVNode("", true)
  ]))
}