
import { computed, defineComponent } from "vue";

enum NodeTypes {
    error = "error",
    info = "info",
    warn = "warn",
    success = "success",
}

const iconsNames = {
    [NodeTypes.error]: "alert",
    [NodeTypes.info]: "info",
    [NodeTypes.warn]: "alert",
    [NodeTypes.success]: "success",
};

export default defineComponent({
    props: { type: { type: String, validator: (t: string) => t in NodeTypes } },
    setup: (props) => ({
        get iconName(): string {
            return (iconsNames as any)[props.type!];
        },
    }),
});
