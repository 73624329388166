
import { defineComponent } from "vue";
import { customCtrlArgs, variant } from "@/core-ui/forms/components/common";
import Box from "@/core-ui/forms/components/Box.vue";
import IconButton from "@/core-ui/IconButton.vue";
import { ContainerCtrlMeta, useObjCtrl } from "@/core-ui/forms/compositions";
import BoxInputCtrl from "@/core-ui/forms/components/BoxInputCtrl.vue";

type meta = ContainerCtrlMeta;

export default defineComponent({
    props: {
        variant,
        ctrlArgs: customCtrlArgs<meta>(),
        canEdit: { type: Boolean, default: true },
        canRemove: { type: Boolean, default: true },
    },
    components: { Box, IconButton, BoxInputCtrl },
    setup(props) {
        const ctrl = useObjCtrl(props.ctrlArgs!);
        function getArgs(key: string): any {
            return ctrl.meta.fields[key](ctrl.state, key).args;
        }

        return {
            itemCanEdit: props.canEdit,
            itemCanRemove: props.canRemove,

            ctrl,
            key: getArgs("key"),
            secret: getArgs("secret"),
            bucket: getArgs("bucket"),
            url: getArgs("url"),
            path: getArgs("path"),
        };
    },
});
