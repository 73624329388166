import frontendV2Service from "./frontend-v2-service";
import { POST_MESSAGE_EVENTS } from "../utils/postmessageEvents";
import { storageService } from "@/core-ui/data-grid/services";
import { debounce } from "lodash";

const second = 1000;
const minute = 60 * second;
interface EventListeners {
    event: string;
    callback: EventListenerOrEventListenerObject;
}

interface Function {
    (...argArray: any[]): any;
}

function setPrivateInterval(callback: Function, timeout: number) {
    const INTERVAL_NAME = "idle-timer-intervalId";
    const oldIntervalId: number = storageService.getNum(INTERVAL_NAME, -1);
    if (oldIntervalId !== -1) {
        window.clearInterval(oldIntervalId);
        storageService.clearKey(INTERVAL_NAME);
    }

    const intervalId = window.setInterval(callback, timeout);
    storageService.setNum(INTERVAL_NAME, intervalId);

    return intervalId;
} 

export class IdleTimer {
    timeout: number = 60 * minute;
    onTimeout: Function | undefined;
    interval: number | undefined;
    timeoutTracker: number | undefined;
    v2Listener: string | undefined;
    eventHandler: Function;

    expiredTimeName: string = "_expiredTime"

    // if you change the events list here, please make sure you udpate the
    // list in v2 as well
    events: Array<string> = ["mousemove", "scroll", "keydown", "click"];
    callbacks: Record<string, Array<EventListeners>> = {};

    constructor() {
        this.eventHandler = this.updateExpiredTime.bind(this);
        this.cleanUp();
    }

    track(minutesToLogout: number, onTimeout: Function): void {
        this.cleanUp();
        
        this.timeout = minutesToLogout * minute;
        this.onTimeout = onTimeout;

        // add default listeners
        this.addListener("default", window.document);
        this.v2Listener = frontendV2Service.addListener(
            POST_MESSAGE_EVENTS.UPDATE_EXPRIED_TIMEOUT,
            this.getHandler() as Function,
        );

        this.updateExpiredTime();
        this.startInterval();
    }

    cleanUp(): void {
        // remove default listeners
        this.removeListener("default", window.document);
        this.v2Listener && frontendV2Service.removeListener(POST_MESSAGE_EVENTS.UPDATE_EXPRIED_TIMEOUT, this.v2Listener);
        
        window.clearInterval(this.interval);
        storageService.clearKey(this.expiredTimeName);
    }

    addListener(name: string, doc: Document) {
        if (this.callbacks[name]) return;

        this.callbacks[name] = this.events.map((event: string) => {
            const callback: EventListenerOrEventListenerObject = this.getHandler();
            doc.addEventListener(event, callback);
            return { event, callback };
        });
    }

    removeListener(name: string, doc: Document) {
        if (!this.callbacks[name]) return;

        const eventListeners: Array<EventListeners> = this.callbacks[name];
        eventListeners.forEach((listener: { event: string; callback: EventListenerOrEventListenerObject }) => {
            doc.removeEventListener(listener.event, listener.callback);
        });

        delete this.callbacks[name];
    }

    private getHandler(): EventListenerOrEventListenerObject {
        const timeDelay = second;
        return debounce(this.eventHandler, timeDelay);
    }

    private startInterval(): void {
        this.interval = setPrivateInterval(() => {
            // the default  `${Date.now() + 60 * minute}` is for the edge case
            // when you clear the value from local storage to set new expired time
            // and the interval cycle is trying to load the expired time before the new one is set
            const expiredTime = parseInt(storageService.getStr(this.expiredTimeName) || `${Date.now() + 60 * minute}`, 10);
            if (expiredTime < Date.now()) {
                if (this.onTimeout) {
                    this.cleanUp();
                    this.onTimeout && this.onTimeout();
                }
            }
        }, minute);
    }

    private updateExpiredTime(): void {
        if (this.timeoutTracker) {
            window.clearTimeout(this.timeoutTracker);
        }
        this.timeoutTracker = window.setTimeout(() => {
            const minutesUntilExpiry: number = Date.now() + this.timeout;
            storageService.setStr(this.expiredTimeName, `${minutesUntilExpiry}`);
        }, 300);
    }
}

export default new IdleTimer();
