import { useFetcher, useCache, Cache, Fetcher } from "@/core-ui/data-grid/compositions";
import settingService from "@/services/settings-service";
import { ToastInterface } from "vue-toastification";
import tenantStore from "@/stores/tenantStore";
import { handelWorkLoadErrors } from "@/cluster-ui/helpers/commonFunctions";
import { WorkloadError } from "@/cluster-ui/components/submit/types";
import registryIntegrationService from "@/services/registry-integration-service";

export const enum SettingKeys {
    DashboardShowOld = "dashboard.show_old",
    DepartmentsUse = "departments.use",
    ProjectPermissionUse = "project.permissions.use",
    ProjectManualOverQuota = "project.quota.manual_over_quota",
    CPUResourcesQuota = "project.quota.cpu_resources",
    GPULimitForOpportunisticJobs = "tenant.allow_gpu_limit",
    IsFederationAuth = "tenant.is_federation_auth",
    LoginWithSSO = "tenant.login_with_sso",
    AllowSwap = "tenant.allow_swap",
    ShowWorkspaces = "tenant.show_workspaces",
    UseOldInference = "tenant.use_old_inference",
    DeploymentsSidecarContainerRegex = "deployments.side_car_container_regex",
    EnableNodePools = "tenant.enable_node_pools",
    LogoutURI = "tenant.logout_uri",
    EnableInactivityLogout = "tenant.enable_inactivity_logout",
    InactivityMinuetsTillLogout = "tenant.inactivity_minutes_till_logout",
    ExcludeViewerInactivityLogout = "tenant.exclude_viewers_from_inactivity_logout",
    ShowConsumptionReport = "tenant.show_consumption_report",
    RegistryIntegration = "tenant.enable_registry_integration",
    TopSettings = "tenant.top_settings",
    EnableWandbSweeps = "tenant.enable_wandb_sweeps",
    EnableLegacyMpi = "tenant.enable_legacy_mpi",
    WandbHost = "tenant.wandb_host",
    EnableAdvancedGPUMetrics = "tenant.enable_advanced_gpu_metrics",
    QuotaManagement = "tenant.quota_management",
    DisableInferenceChecks = "tenant.disable_inference_checks",
}

export enum SettingStage {
    Alpha = "Alpha",
    Beta = "Beta",
}

export type SettingType<T = unknown> = {
    label: string;
    source: string;
    category: string;
    type: string;
    key: string;
    value: T;
    active: boolean;
    disabled?: boolean;
    description?: string;
};

export type SettingState = {
    kv: { [key: string]: any };
    settings: Array<SettingType>;
};

export class SettingStore {
    private cache: Cache<SettingState> = undefined as any;
    fetcher: Fetcher = undefined as any;

    get state(): SettingState {
        return this.cache?.data;
    }

    get inactivityMinutesTillLogout() {
        return this.state.kv[SettingKeys.InactivityMinuetsTillLogout];
    }

    get categories() {
        const categories: Record<string, any[]> = {};
        const settings = this.state.settings;

        for (const setting of settings) {
            const categoryName = setting.category;
            let category = categories[categoryName];
            if (!category) {
                category = [];
                categories[categoryName] = category;
            }
            category.push(setting);
        }
        return categories;
    }

    // need to init in the app setup component
    setup() {
        if (this.fetcher) {
            this.fetcher.fetch();
            return;
        }

        this.cache = useCache({
            defaultValue: { settings: [], kv: {} },
            map: (data) => ({
                settings: data,
                kv: data.reduce((o: any, { key, value }: any) => {
                    o[key] = value;
                    return o;
                }, {}),
            }),
        });
        this.fetcher = useFetcher({
            cache: this.cache,
            fetch: settingService.get,
        });
        this.fetcher.fetch();
        return this;
    }

    async save(key: string, value: unknown, toast?: ToastInterface): Promise<boolean> {
        const setting = this.state.settings.find(({ key: k }) => k == key);

        if (key == SettingKeys.LoginWithSSO) {
            await tenantStore.readTenantData(true);
            if (value) {
                return true;
            }
        }

        if (key === SettingKeys.RegistryIntegration) {
            if (value && !tenantStore.state.registryId) {
                return true;
            } else if (!value && tenantStore.state.registryId) {
                const registryId = tenantStore.state.registryId;
                tenantStore.resetRegistryFields();
                try {
                    await registryIntegrationService.deleteRegistry(registryId);
                } catch (e) {
                    console.error(e);
                }
            }
        }

        if (!setting) return false;

        try {
            await settingService.set(key, value);
            toast?.success(`${setting.label} saved`);
            return true;
        } catch (err) {
            const error = handelWorkLoadErrors(err as WorkloadError);
            if (error) {
                toast?.error(`Failed to save '${setting.label} : ${error}`);
                return false;
            }

            toast?.error(`Failed to save '${setting.label}'`);
            return false;
        } finally {
            this.fetcher.fetch();
        }
    }
}

export default new SettingStore();
