import { ref } from "vue";
import { ID } from "../types";

type ListDisplayProps = {
    itemId: ID;
};

export type ListDisplay<T> = {
    set: (data: T[]) => void;
    has(id: ID): boolean;
    getItem(id: ID): T;
    items: T[];
};

/**
 * ListDisplay response to know the state of every displayed item
 *
 * @export
 */
export function useListDisplay<T>(props: ListDisplayProps): ListDisplay<T> {
    const itemsObj = ref<{ [key: string]: T }>({});
    const items = ref<T[]>([] as any);
    return {
        get items() {
            return items.value as T[];
        },
        set(newItems: T[]) {
            const id = props.itemId;
            items.value = newItems as any;
            if (id) {
                itemsObj.value = Object.fromEntries(newItems.map((i: any) => [i[id], i]));
            }
        },
        getItem(id: ID) {
            return itemsObj.value[id] as T;
        },
        has(id: ID) {
            id = String(id);
            return id !== undefined && id in itemsObj.value;
        },
    };
}
