import { loadView } from "./utils";
import { ROUTES_ENUM } from "./routes";
import Authenticated from "@/views/Authenticated.vue";
import { RouteRecordRaw } from "vue-router";
import { dashboardsRoutes } from "@/router/dashboards";
import Clusters from "@/views/Clusters.vue";
import * as clusterViews from "@/cluster-ui/views";

import { RolesEnum, rolesMap } from "@/stores/authStore";

export const children: Array<RouteRecordRaw> = [
    ...dashboardsRoutes,
    {
        path: "jobs",
        name: ROUTES_ENUM.JOBS,
        component: loadView("Jobs"),
        meta: {
            requiresAuth: true,
            allowedRoles: [
                RolesEnum.ADMIN,
                RolesEnum.EDITOR,
                RolesEnum.VIEWER,
                RolesEnum.RESEARCHER,
                RolesEnum.RESEARCH_MANAGER,
            ],
        },
    },
    {
        path: "jobs/history",
        name: ROUTES_ENUM.JOBS_HISTORY,
        component: loadView("JobsHistory"),
        meta: {
            requiresAuth: true,
            allowedRoles: [
                RolesEnum.ADMIN,
                RolesEnum.EDITOR,
                RolesEnum.VIEWER,
                RolesEnum.RESEARCHER,
                RolesEnum.RESEARCH_MANAGER,
            ],
        },
    },
    {
        path: "workspaces",
        name: ROUTES_ENUM.WORKSPACES,
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.allRoles,
        },
    },
    {
        path: "workspaces/new",
        name: ROUTES_ENUM.SUBMIT_WORKSPACES,
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.workspaceEditRoles,
        },
        children: [
            {
                path: ":pathMatch(.*)*",
                redirect: (_) => ({ name: ROUTES_ENUM.SUBMIT_WORKSPACES }),
            },
        ],
    },
    {
        path: "trainings",
        name: ROUTES_ENUM.TRAININGS,
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.allRoles,
        },
    },
    {
        path: "trainings/new",
        name: ROUTES_ENUM.SUBMIT_TRAINING,
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.trainingEditRoles,
        },
        children: [
            {
                path: ":pathMatch(.*)*",
                redirect: (_) => ({ name: ROUTES_ENUM.SUBMIT_TRAINING }),
            },
        ],
    },
    {
        path: "environments",
        name: ROUTES_ENUM.ENVIRONMENTS,
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.allRoles,
        },
    },
    {
        path: "environments/new",
        name: ROUTES_ENUM.ENVIRONMENTS_NEW,
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.assetEditRoles,
        },
    },
    {
        path: "data-sources",
        name: ROUTES_ENUM.DATA_SOURCES,
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.allRoles,
        },
        children: [
            {
                path: "nfs/new",
                name: ROUTES_ENUM.DATA_SOURCES_NFS_NEW,
                component: loadView("IframePage"),
                meta: {
                    requiresAuth: true,
                    allowedRoles: rolesMap.assetEditRoles,
                },
            },
            {
                path: "pvc/new",
                name: ROUTES_ENUM.DATA_SOURCES_PVC_NEW,
                component: loadView("IframePage"),
                meta: {
                    requiresAuth: true,
                    allowedRoles: rolesMap.assetEditRoles,
                },
            },
            {
                path: "git/new",
                name: ROUTES_ENUM.DATA_SOURCES_GIT_NEW,
                component: loadView("IframePage"),
                meta: {
                    requiresAuth: true,
                    allowedRoles: rolesMap.assetEditRoles,
                },
            },
            {
                path: "s3/new",
                name: ROUTES_ENUM.DATA_SOURCES_S3_NEW,
                component: loadView("IframePage"),
                meta: {
                    requiresAuth: true,
                    allowedRoles: rolesMap.assetEditRoles,
                },
            },
            {
                path: "hostpath/new",
                name: ROUTES_ENUM.DATA_SOURCES_HOST_PATH_NEW,
                component: loadView("IframePage"),
                meta: {
                    requiresAuth: true,
                    allowedRoles: rolesMap.assetEditRoles,
                },
            },
        ],
    },
    {
        path: "workspace-templates",
        name: ROUTES_ENUM.WORKSPACE_TEMPLATES,
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.allRoles,
        },
    },
    {
        path: "workspace-templates/new",
        name: ROUTES_ENUM.WORKSPACE_TEMPLATES_NEW,
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.assetEditRoles,
        },
        children: [
            {
                path: ":pathMatch(.*)*",
                redirect: (_) => ({ name: ROUTES_ENUM.WORKSPACE_TEMPLATES_NEW }),
            },
        ],
    },
    {
        path: "deployments",
        name: ROUTES_ENUM.DEPLOYMENTS,
        component: loadView("Deployments"),
        meta: {
            requiresAuth: true,
            allowedRoles: [RolesEnum.ADMIN, RolesEnum.EDITOR, RolesEnum.VIEWER, RolesEnum.ML_ENGINEER],
        },
    },
    {
        path: "deployments/history",
        name: ROUTES_ENUM.DEPLOYMENTS_HISTORY,
        component: loadView("DeploymentsHistory"),
        meta: {
            requiresAuth: true,
            allowedRoles: [RolesEnum.ADMIN, RolesEnum.EDITOR, RolesEnum.VIEWER, RolesEnum.ML_ENGINEER],
        },
    },
    {
        path: "deployments/new",
        name: ROUTES_ENUM.SUBMIT_DEPLOYMENTS,
        component: loadView("SubmitDeployment"),
        meta: { requiresAuth: true, allowedRoles: [RolesEnum.ADMIN, RolesEnum.ML_ENGINEER] },
    },
    {
        path: "nodes",
        name: ROUTES_ENUM.NODES,
        component: loadView("IframePage"),
        meta: { requiresAuth: true },
        children: [
            {
                path: "node-pool",
                component: loadView("IframePage"),
                meta: { requiresAuth: true },
            },
        ],
    },
    {
        path: "departments",
        name: "departments",
        meta: { requiresAuth: true },
        component: loadView("IframePage"),
    },
    {
        path: "departments/new",
        name: "departments/new",
        component: loadView("IframePage"),
        meta: { requiresAuth: true },
    },
    {
        path: "departments/edit/:id",
        name: "departments/edit",
        component: loadView("IframePage"),
        meta: { requiresAuth: true },
    },
    {
        path: "departments/view/:id",
        name: "departments/view",
        component: loadView("IframePage"),
        meta: { requiresAuth: true },
    },
    {
        path: "compute-resources",
        name: ROUTES_ENUM.COMPUTE_RESOURCE,
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.allRoles,
        },
        component: loadView("IframePage"),
    },
    {
        path: "compute-resources/new",
        name: ROUTES_ENUM.COMPUTE_RESOURCE_NEW,
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.assetEditRoles,
        },
        component: loadView("IframePage"),
    },
    {
        path: "credentials",
        name: ROUTES_ENUM.CREDENTIAL,
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.allRoles,
        },
        component: loadView("IframePage"),
        children: [
            {
                path: "userpass/new",
                name: ROUTES_ENUM.CREDENTIAL_USERPASS_NEW,
                component: loadView("IframePage"),
                meta: {
                    requiresAuth: true,
                    allowedRoles: rolesMap.assetEditRoles,
                },
            },
            {
                path: "access-key/new",
                name: ROUTES_ENUM.CREDENTIAL_ACCESS_KEY_NEW,
                component: loadView("IframePage"),
                meta: {
                    requiresAuth: true,
                    allowedRoles: rolesMap.assetEditRoles,
                },
            },
            {
                path: "docker-registry/new",
                name: ROUTES_ENUM.CREDENTIAL_DOCKER_REGISTRY_NEW,
                component: loadView("IframePage"),
                meta: {
                    requiresAuth: true,
                    allowedRoles: rolesMap.assetEditRoles,
                },
            },
        ],
    },
    {
        path: "audit",
        name: "audit",
        meta: { requiresAuth: true },
        component: loadView("IframePage"),
    },
    {
        path: "roles",
        name: "roles",
        meta: {
            requiresAuth: true,
            allowedRoles: [RolesEnum.ADMIN],
        },
        component: loadView("IframePage"),
        children: [
            {
                path: "review/:id",
                name: ROUTES_ENUM.ROLES_REVIEW,
                component: loadView("IframePage"),
                meta: {
                    requiresAuth: true,
                    allowedRoles: [RolesEnum.ADMIN],
                },
            },
            {
                path: "assignments/role-review/:id",
                name: ROUTES_ENUM.ASSIGNMENT_ROLE_VIEW,
                component: loadView("IframePage"),
                meta: {
                    requiresAuth: true,
                    allowedRoles: [RolesEnum.ADMIN],
                },
            },
            {
                path: "assignments",
                name: ROUTES_ENUM.ASSIGNMENTS,
                component: loadView("IframePage"),
                meta: {
                    requiresAuth: true,
                    allowedRoles: [RolesEnum.ADMIN],
                },
            },
        ],
    },
    {
        path: "users",
        name: "users",
        meta: {
            requiresAuth: true,
            allowedRoles: [RolesEnum.ADMIN],
        },
        component: loadView("IframePage"),
    },
    {
        path: "licenses",
        name: ROUTES_ENUM.LICENSES,
        meta: {
            requiresAuth: true,
            allowedRoles: [RolesEnum.ADMIN],
        },
        component: loadView("IframePage"),
    },
    {
        path: "projects",
        name: ROUTES_ENUM.PROJECTS,
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.allRoles,
        },
    },
    {
        path: "projects/new",
        name: "project-new",
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.allRoles,
        },
    },
    {
        path: "projects/edit/:id",
        name: "project-edit",
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.allRoles,
        },
    },
    {
        path: "permissions",
        name: ROUTES_ENUM.PERMISSIONS,
        meta: { requiresAuth: true },
        component: loadView("Permissions"),
    },
    {
        path: "applications",
        name: ROUTES_ENUM.APPLICATIONS,
        meta: { requiresAuth: true },
        component: loadView("Applications"),
    },
    {
        path: "applications-v2",
        name: ROUTES_ENUM.APPLICATIONS_V2,
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: [RolesEnum.ADMIN],
        },
    },
    {
        path: "general-settings",
        name: ROUTES_ENUM.GENERAL_SETTINGS,
        meta: { requiresAuth: true, allowedRoles: ["admin"] },
        component: loadView("GeneralSettings"),
    },
    {
        path: "clusters",
        name: ROUTES_ENUM.CLUSTERS,
        meta: { requiresAuth: true },
        component: loadView("IframePage"),
    },
    {
        path: "clusters/new",
        name: ROUTES_ENUM.CLUSTERS_NEW,
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: [RolesEnum.ADMIN],
        },
    },
    {
        path: "resources",
        name: ROUTES_ENUM.RESOURCES,
        component: loadView("IframePage"),
        meta: {
            requiresAuth: true,
            allowedRoles: rolesMap.allRoles,
        },
    },

    { ...clusterViews.jobLogs(), meta: { requiresAuth: true } } as RouteRecordRaw,
    {
        ...clusterViews.submit({
            base: "jobs",
            name: ROUTES_ENUM.SUBMIT_JOB,
        }),
        meta: { requiresAuth: true },
    } as RouteRecordRaw,
];

const router: RouteRecordRaw = {
    path: "/",
    component: Authenticated,
    redirect: "/dashboards/now",
    children,
};
export default router;
