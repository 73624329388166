import { TableColumn } from "@/core-ui/types/column";
import { reactive, Ref, ref, watch } from "vue";
import { storageService } from "../services";

const FIRST_KEY = "__FIRST_COLUMN__";
const DEFAULT_KEY = "";

export interface ColumnsOrderState {
    value: string[];
}

export interface ColumnsOrderSourceProps {
    key: string;
    ordering?: string[];
}

export class ColumnsOrderSource implements ColumnsOrderState {
    columns: any;
    private ordering: Ref<string[]>;

    constructor(private props: ColumnsOrderSourceProps) {
        const syncOrder = () =>
            (this.ordering.value = this._reduce(storageService.getArray(this.key, []), props.ordering as any));
        this.ordering = ref([]);
        watch([() => props.ordering, () => props.key], syncOrder, {
            immediate: true,
        });
    }

    get key(): string {
        return `table-columns-order/v1/${this.props.key || DEFAULT_KEY}`;
    }

    private _reduce(first = [], second = []) {
        return Array.from(new Set([...first, ...second]));
    }

    set value(ordering: string[]) {
        this.ordering.value = this._reduce(ordering as any, this.value as any);
        storageService.setArray(this.key, this.value);
    }

    get value() {
        return this.ordering.value;
    }
}
export interface ColumnsOrderer {
    order(columns: TableColumn[]): void;
    handleDrop(): void;
    handleDraggedOver(key: string): void;
    handleDragged(key: string): void;
    FIRST_KEY: string;
    draggedOver: string | null;
    dragged: string | null;
}

export function useColumnsOrderer({ state }: { state: ColumnsOrderState }): ColumnsOrderer {
    // temp state
    const temps = reactive({
        draggedColumnKey: null,
        draggedOverColumnKey: null,
    });

    function handleDragged(key: string | null) {
        temps.draggedColumnKey = key as any;
    }

    function handleDraggedOver(key: string | null) {
        temps.draggedOverColumnKey = key as any;
    }

    function handleDrop() {
        move();
        temps.draggedColumnKey = null;
        temps.draggedOverColumnKey = null;
    }

    function order(columns: TableColumn[]) {
        const cm: any = {};
        for (const c of columns) {
            cm[c.key] = c;
        }

        return Array.from(new Set([...state.value, ...Object.keys(cm)]))
            .filter((k) => cm[k])
            .map((k) => cm[k]);
    }

    function move() {
        const columns = state.value;
        const column = temps.draggedColumnKey;
        const after = temps.draggedOverColumnKey;

        if (!after) return;

        const newOrder = columns.reduce(
            (newArr, col) => {
                if (col === after) {
                    newArr.push(col as any, column);
                } else if (col !== column) {
                    newArr.push(col as any);
                }
                return newArr;
            },
            after === FIRST_KEY ? [column] : [],
        );
        state.value = newOrder as any;
    }

    return {
        get draggedOver(): string | null {
            return temps.draggedOverColumnKey;
        },
        get dragged(): string | null {
            return temps.draggedColumnKey;
        },
        order,
        handleDrop,
        handleDraggedOver,
        handleDragged,
        FIRST_KEY,
    };
}
