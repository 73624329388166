import { InferenceJob, InteractiveJob, TrainingJob } from "./../../helpers/const";
import { getCurrentSettingsName } from "@/cluster-ui/helpers/commonFunctions";
import { DeploymentSettings, toDeploymentTemplate } from "@/components/deployments/deploymentParamsUtil";
import { JobSettings, toJobTemplate } from "@/cluster-ui/components/submit/jobParamsUtil";

export type ImagePullPolicy = "Always" | "IfNotPresent" | "Never";

export function parseNestedString(s: string): Array<string> | null {
    const strs: Array<string> = [];
    let quoteStart = -1;
    let neWord = true;
    let currentWord = "";
    for (let i = 0; i < s.length; i++) {
        const c = s[i];
        switch (c) {
            case " ":
                if (quoteStart === -1) {
                    if (currentWord.length > 0) {
                        strs.push(currentWord);
                    }
                    currentWord = "";
                    neWord = true;
                }
                break;
            case '"':
                if (quoteStart === -1) {
                    quoteStart = i;
                } else {
                    currentWord += s.substring(quoteStart, i + 1); // adding 1 to index since quote is part of the word and substring excludes the end index
                    quoteStart = -1;
                }
                break;
            default:
                if (quoteStart === -1) {
                    currentWord += c;
                }
        }
    }
    if (quoteStart === -1) {
        if (currentWord.length > 0) {
            strs.push(currentWord);
        }
    }
    return strs;
}

export function parseCommand(setting: { command: string[] }, jobParams: { [key: string]: any }) {
    const command = parseNestedString(jobParams["command"]);
    if (command != null) {
        setting.command = command;
    }
}

export function mapTtl(v: string): string | undefined {
    return typeof v == "string" ? v.replaceAll(" ", "") : undefined;
}

// TODO: REMOVE
export function fromTemplate(value: JobSettings | DeploymentSettings): Record<string, any> {
    /* switch (getCurrentSettingsName()) {
        case InferenceJob: {
            return fromDeploymentTemplate(<DeploymentSettings>value);
        }
        case InteractiveJob:
        case TrainingJob: {
            return fromJobTemplate(<JobSettings>value);
        }
    }*/
    return {};
}

export function toTemplate(value: JobSettings | DeploymentSettings): Record<string, any> {
    switch (getCurrentSettingsName()) {
        case InferenceJob: {
            return toDeploymentTemplate(<DeploymentSettings>value);
        }
        case InteractiveJob:
        case TrainingJob: {
            return toJobTemplate(<JobSettings>value);
        }
    }
    return {};
}
