import { storageService } from "@/core-ui/data-grid/services";
import {
    LAST_SELECTED_TEMP_NAME,
    TARGET_PROJECT,
    TEMPLATES_LIST,
    workloadToSettings,
} from "../../cluster-ui/helpers/const";
import { getProjectAndNameSpace } from "../../cluster-ui/components/submit/useSubmitFieldsLoader";
import { Project } from "../../cluster-ui/models/project.model";
import { WorkloadError } from "../../cluster-ui/components/submit/types";
import { Template } from "../../cluster-ui/types/template";

const LAST_SELECTED_SETTINGS_KEY = "LAST_SELECTED_SETTINGS_KEY";

const PROJECT_USER_INPUT = "PROJECT_USER_INPUT";
export const deepCopyObject = (objectToCopy: any) => JSON.parse(JSON.stringify(objectToCopy));

export function handelWorkLoadErrors(error: WorkloadError, generalMSG?: string): string {
    let toastErrorMsg = generalMSG || "Something went wrong... please try again later";
    try {
        if (error.response?.data?.details) {
            const errDetails = error.response?.data?.details;
            if (errDetails.includes("Internal error occurred")) {
                toastErrorMsg = "Internal error occurred";
            } else if (errDetails.includes("denied the request:")) {
                toastErrorMsg = errDetails.split("denied the request:")[1];
            } else {
                toastErrorMsg = errDetails;
            }
        } else {
            toastErrorMsg = error.response?.data?.message || JSON.stringify(error.response?.data);
        }
    } catch (e) {
        console.warn(e);
    }
    return toastErrorMsg;
}

export function setCurrentTemplateName(templateName: string): void {
    return storageService.setStr(LAST_SELECTED_TEMP_NAME, templateName);
}

export function setCurrentTemplateList(templateList: Template[]) {
    return storageService.setObj(TEMPLATES_LIST, templateList);
}

export function getCurrentTemplateList() {
    return storageService.getObj(TEMPLATES_LIST);
}

export function getCurrentTemplateName(): string {
    return storageService.getStr(LAST_SELECTED_TEMP_NAME);
}

export function setTargetProject(targetProject: string) {
    return storageService.setStr(TARGET_PROJECT, targetProject);
}

export function getTargetProject(): string {
    return storageService.getStr(TARGET_PROJECT);
}

export function getCurrentSettingsName(): string {
    return storageService.getStr(LAST_SELECTED_SETTINGS_KEY);
}

export function setCurrentSettingsName(value: string): void {
    return storageService.setStr(LAST_SELECTED_SETTINGS_KEY, value);
}

export function workLoadNameToSettingsName(settingsName: string): string {
    for (let settings of workloadToSettings.entries()) {
        if (settingsName === settings[1]) {
            return settings[0];
        }
    }
    return "";
}

export function settingsTypeToWorkLoadKind(settingsName: string): string {
    return workloadToSettings.get(settingsName);
}

export async function projectNameToWorkloadNameSpace(projectName: string): Promise<string> {
    const projectsArray: Project[] = await getProjectAndNameSpace();
    for (const project in projectsArray) {
        if (projectsArray[project].name == projectName) {
            return projectsArray[project].managedNamespace;
        }
    }
    return projectName;
}

export async function projectNameWithoutRunAiString(projectName: string): Promise<string> {
    const projectsArray: Project[] = await getProjectAndNameSpace();
    for (const project in projectsArray) {
        if (projectsArray[project].managedNamespace == projectName) {
            return projectsArray[project].name;
        }
    }
    return projectName;
}
