import { EDashboardName } from "@/models/dashboards.model";
import { Ref } from "vue";

export function useIframeObserver(iframeElm: Ref<HTMLIFrameElement | null>, dashboardName: string) {
    function setupMainViewObserverInIframe(): void {
        const iframeDoc = iframeElm.value?.contentWindow?.document;
        if (!iframeDoc || !iframeElm.value?.contentWindow) return;

        if (!iframeDoc) return;
        const targetNode = iframeDoc.body;
        const config = { childList: true, subtree: true };

        const observer = new MutationObserver((_, observer) => {
            const mainView = iframeDoc.querySelector(".main-view");
            if (mainView) {
                observer.disconnect();
                // this is a hack to enable kiosk mode without losing our entities filters (projects, nodePools etc.)
                if (dashboardName === EDashboardName.Overview || dashboardName === EDashboardName.CpuOverview) {
                    hideGrafanaMainView();
                } else if (
                    dashboardName === EDashboardName.Analytics ||
                    dashboardName === EDashboardName.CpuAnalytics ||
                    dashboardName === EDashboardName.Consumption
                ) {
                    hideGrafanaTopToolBar();
                }
            }
        });

        observer.observe(targetNode, config);
    }

    function hideGrafanaTopToolBar(): void {
        const iframeDoc = iframeElm.value?.contentWindow?.document;
        if (!iframeDoc) return;

        if (!iframeDoc || !iframeElm.value?.contentWindow) {
            console.error("Iframe element or its content window not found.");
            return;
        }

        const topBar = iframeDoc.querySelector("[data-testid='data-testid Nav toolbar']");
        if (topBar) {
            const firstChild = topBar.firstElementChild;
            const secondChild = topBar.children[1];

            if (firstChild) {
                topBar.removeChild(firstChild);
            }
            if (secondChild) {
                topBar.removeChild(secondChild);
            }
        }
    }

    function hideGrafanaMainView(): void {
        try {
            const iframeDoc = iframeElm.value?.contentWindow?.document;
            if (!iframeDoc || !iframeElm?.value?.contentWindow) return;

            const mainView = iframeDoc.querySelector(".main-view");
            if (mainView) {
                const firstChild = mainView.firstChild;
                //this is how we locate grafana top bar
                if (firstChild && firstChild.textContent?.includes("Home")) {
                    mainView.removeChild(firstChild);
                    if (mainView.firstChild) {
                        //@ts-ignore
                        mainView.firstChild.style.padding = "0";
                    }
                }
            }
        } catch (error) {
            //do nothing
        }
    }

    return {
        setupMainViewObserverInIframe,
    };
}
