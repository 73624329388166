
import ResearcherDownloadCli from "@/components/cli/ResearcherDownloadCli.vue";

import Modal from "@/core-ui/Modal.vue";
import Button from "@/core-ui/Button.vue";
export default {
    name: "ResearcherDownloadCliModal",
    emits: ["close"],
    components: {
        ResearcherDownloadCli,
        Modal,
        Button,
    },
};
