
import { Link } from "@/types/link";
import { PAGE_LINKS } from "@/helpers/page-links";
import { mixin as clickaway } from "@/core-ui/directives/clickaway";
import settingsStore from "@/stores/setting-store";
import { defineComponent, ref } from "vue";
import router from "@/router";
import { useDynamicMsg } from "@/core-ui/compositions/DynamicMsg";
import SvgIcon from "@/core-ui/style/icons/SvgIcon.vue";

export enum SidebarModes {
    Open = "open",
    Close = "close",
    Regular = "regular",
    Hover = "hover",
}

export default defineComponent({
    props: {
        lastLogin: String,
        isAdmin: Boolean,
        open: Boolean,
        appVersion: String,
    },
    emits: ["close"],
    components: {
        SvgIcon
    },
    setup: (props, ctx) => {
        const model = useDynamicMsg();
        const links = ref<Link[]>([]);
        settingsStore.setup();
        const self = {
            links,
            filterLink: (l: Link) => (props.isAdmin || !l.adminRequired) && (!l.filter || l.filter()),
            close() {
                ctx.emit("close");
            },

            isEmpty(object: any) {
                return !(object && object.length);
            },

            isDisabled(link: Link) {
                return self.isEmpty(link.subLinks) && !link.pageName;
            },

            topItemClick(key: string) {
                const link: any = links.value.find((link) => link.title === key);
                if (!self.isDisabled(link)) {
                    const hasChildren = !self.isEmpty(link.subLinks);
                    // If open and has sublinks just collapse
                    if (hasChildren && link.isOpen) {
                        link.isOpen = false;
                    } else {
                        if (link.pageName) {
                            router.push({ name: link.pageName });
                        }
                        if (hasChildren) {
                            link.isOpen = true;
                        }
                    }
                }
            },

            lowerItemClick(link: Link) {
                router.push({ name: link.pageName });
                // self.close();
            },

            get filteredLinks(): any {
                return links.value.filter(self.filterLink).map((l) => {
                    if (!l.subLinks) {
                        return l;
                    }
                    const subLinks = l.subLinks.filter(self.filterLink);
                    return {
                        ...l,
                        subLinks: subLinks.length ? subLinks : null,
                    };
                });
            },
        };
        return self;
    },
    mixins: [clickaway],

    created() {
        const currentPageName = this.$route.name;

        this.links = PAGE_LINKS.map((link) => {
            if (link.subLinks) {
                let upperLinkOpened = !!link.subLinks.find((subLink) => subLink.pageName === currentPageName);
                return {
                    ...link,
                    isOpen: upperLinkOpened,
                };
            } else {
                return { ...link };
            }
        });
    },
});
