
import { computed, defineComponent, ref } from "vue";

import { mdbRipple } from "mdb-vue-ui-kit";
import authStore from "@/stores/authStore";
import settingStore, { SettingKeys } from "@/stores/setting-store";
import router from "@/router";
import { ROUTES_ENUM } from "@/router/routes";
import tenantStore from "@/stores/tenantStore";

export default defineComponent({
    directives: {
        mdbRipple,
    },
    setup() {
        const isOpen = ref<boolean>(false as any);
        const tooltip = ref<boolean>(false as any);

        function itemClicked(pageName: string): void {
            router.push({ name: pageName });
            isOpen.value = false;
        }

        const MONTH = 30 * 24 * 60 * 60 * 1000;
        const licensedGPUs = computed(() => {
            return tenantStore.state.licenses.reduce((acc, license) => acc + license.limit, 0);
        });
        const inUseGPUs = computed(() => {
            return tenantStore.state.inUseGPUs;
        });

        const showLicenseBadge = computed(() => {
            return (
                authStore.isAdmin &&
                (tenantStore.state.licenses.some((l) => l.expiry < Date.now() + MONTH) || inUseGPUs.value > licensedGPUs.value)
            );
        });

        return {
            isOpen,
            tooltip,
            itemClicked,
            showLicenseBadge,
            get isAdmin() {
                return authStore.isAdmin;
            },
            get isDepartmentAdmin() {
                return authStore.isDepartmentAdmin;
            },
            get isWorkspaceEnabled() {
                return settingStore.state.kv[SettingKeys.ShowWorkspaces];
            },
            ROUTES_ENUM,
        };
    },
});
