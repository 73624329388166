
import flatMap from "lodash/flatMap";
import Sidebar from "@/components/core/Sidebar.vue";
import { PAGE_LINKS } from "@/helpers/page-links";
import Header from "@/components/core/Header.vue";
import tenantStore from "@/stores/tenantStore";
import clustersStore from "@/stores/clusters-store";
import AdminOnboarding from "@/components/onboarding/AdminOnboarding.vue";
import authStore from "@/stores/authStore";
import adminStore from "@/stores/admin-store";
import ChangePasswordModal from "@/components/core/ChangePasswordModal.vue";
import LicenseModal from "@/components/core/LicenseModal.vue";
import { computed, defineComponent, ref, watch } from "vue";
import settingStore from "@/stores/setting-store";
import { storageService } from "@/core-ui/data-grid/services";
import appStore from "../stores/appStore";
import { useRoute, useRouter } from "vue-router";
import appV2Store, { SidebarState, sideBarOpenKey } from "@/stores/appV2Store";
import grafanaStore from "@/stores/grafanaStore";

import IdleTimer from "@/services/idle-timer";
import { ROUTES_ENUM } from "@/router/routes";
import configService from "@/services/config-service";

export default defineComponent({
    components: {
        AdminOnboarding,
        Header,
        Sidebar,
        ChangePasswordModal,
        LicenseModal,
    },

    created() {
        this.thisComp = this;
    },

    setup() {
        // Patch for grafana login-in on loading the page
        const grafanaLoggedOut = ref(false);

        // setup the settings
        settingStore.setup();
        appStore.setup();
        const router = useRouter();
        const route = useRoute();
        const thisComp = ref<any>();
        const openedModal = ref(false);

        if (route.query.clusterId && clustersStore.isClusterExist(route.query.clusterId.toString())) {
            clustersStore.setActiveCluster(route.query.clusterId.toString());
        }

        watch(
            () => clustersStore.state.clusters,
            (newVal, oldVal) => {
                if (Array.isArray(newVal) && newVal.length === 0) router.push({ name: ROUTES_ENUM.CLUSTERS_NEW });
            },
        );

        function onTimeout() {
            authStore.logout(true);
        }

        watch(
            () => settingStore.state,
            () => {
                if (settingStore.state.kv && settingStore.inactivityMinutesTillLogout) {
                    IdleTimer.track(settingStore.inactivityMinutesTillLogout, onTimeout);
                }
            },
            { immediate: true },
        );

        const TWO_WEEKS = 14 * 24 * 60 * 60 * 1000;
        const licensedGPUs = computed(() => {
            return tenantStore.state.licenses.reduce((acc, license) => acc + license.limit, 0);
        });
        const inUseGPUs = computed(() => {
            return tenantStore.state.inUseGPUs;
        });

        const shouldOpenLicenseModal = computed(() => {
            return (
                tenantStore.state.licenses.some((l) => l.expiry < Date.now() + TWO_WEEKS) ||
                inUseGPUs.value > licensedGPUs.value
            );
        });

        const isExpiredOrExceeded =
            tenantStore.state.licenses.some((license) => license.expiry < Date.now()) ||
            inUseGPUs.value > licensedGPUs.value;

        const self = {
            thisComp,
            shouldOpenLicenseModal,
            openedModal,
            isExpiredOrExceeded,

            get sideBarOpen() {
                return storageService.getBool(sideBarOpenKey, false);
            },
            get fullWidthPage() {
                return appV2Store.fullWidth || !!route.meta.fullWidth;
            },

            get supportBack() {
                return appV2Store.supportBack || !!route.meta.supportBack;
            },

            get closeIcon() {
                return appV2Store.closeIcon || !!route.meta.closeIcon;
            },

            get readOnly() {
                return appV2Store.readOnly || !!route.meta.readOnly;
            },

            grafanaLoggedOut,
            get isAdmin() {
                return adminStore.isAdmin;
            },

            get isSelfHosted() {
                return configService.config.selfHosted
            },

            get isNeedsToChangePassword() {
                return authStore.userNeedsToChangePassword;
            },

            get shouldRenderScreen() {
                return !adminStore.shouldBlockScreen;
            },

            get shouldLoginGrafana() {
                return !tenantStore.state.smgEnabled && authStore.userEmail;
            },

            grafanaFrameLoad(e: Event) {
                // Sometimes an initial load event happens before the iframe content is actually loaded.
                // Identify of the real load event is made by checking src is not empty.
                // More info on https://stackoverflow.com/questions/10781880/dynamically-created-iframe-triggers-onload-event-twice
                if ((e.target as any).src != "") {
                    grafanaLoggedOut.value = true;
                }
            },
            get grafanaPages() {
                return clustersStore.state.dashboards;
            },
            onGrafanaLoad() {
                grafanaStore.loadedGrafanaIFrame();
            },

            get userEmail() {
                return authStore.userEmail;
            },

            get oldAppPageTitle() {
                const currentPageName = thisComp.value.$route.name;

                const flattened = flatMap(PAGE_LINKS, (link) => (link.subLinks ? link.subLinks : link));
                const activeLink = flattened.find((link) => link.pageName === currentPageName);

                const title = activeLink?.title;
                return title ? title.replace(title[0], title[0].toUpperCase()) : undefined;
            },

            get pageTitle() {
                return appV2Store.pageTitle || this.oldAppPageTitle;
            },

            get lastLogin() {
                // Temp static last login
                return appStore.lastLogin;
            },

            get appVersion() {
                // Temp static last login
                return appStore.appVersion;
            },

            get tenantName() {
                return authStore.userInfo.email ? tenantStore.state.displayName : undefined;
            },

            get logo() {
                return tenantStore.state.logo;
            },

            toggleSideBar() {
                const isSideBarOpen = storageService.getBool(sideBarOpenKey, false);
                storageService.setBool(sideBarOpenKey, !isSideBarOpen);
                appV2Store.sidebarState = isSideBarOpen ? SidebarState.Mini : SidebarState.Full;
            },
        };
        return self;
    },
});
