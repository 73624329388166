import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignEula = _resolveComponent("SignEula")!

  return (_ctx.notSignedEula)
    ? (_openBlock(), _createBlock(_component_SignEula, {
        key: 0,
        "contract-type": _ctx.contractType,
        onDone: _ctx.checkTenantData
      }, null, 8, ["contract-type", "onDone"]))
    : _createCommentVNode("", true)
}