import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { routePermissionsGuard } from "./utils";
import { ROUTES_ENUM } from "./routes";
import Main from "../views/Main.vue";
import nonAuthenticatedRoutes from "./not-authenticated";
import oidcRoutes from "@/router/oidc";
import authenticatedRoutes from "./authenticated";
import fulllPageErrors from "./full-page-errors";
import authStore from "@/stores/authStore";
import authService from "@/services/auth-service";
import appStore from "@/stores/appStore";

export const supportBackPaths = [
    ROUTES_ENUM.APPLICATIONS_V2,
    ROUTES_ENUM.ROLES,
    ROUTES_ENUM.ASSIGNMENTS,
    ROUTES_ENUM.LICENSES,
    ROUTES_ENUM.USERS,
    ROUTES_ENUM.ROLES_REVIEW,
    ROUTES_ENUM.ASSIGNMENT_ROLE_VIEW,
];
const routes: Array<RouteRecordRaw> = [
    {
        path: "/login/callback",
        beforeEnter: async (to: any, _from: any, next: any) => {
            const code = to.query.code as string;
            const redirectUri = window.location.origin + window.location.pathname;
            const response = await authService.exchangeCode(code, redirectUri);
            const redirect = await authStore.storeTokensAndGetRedirect(
                response.idToken,
                response.externalToken,
                response.refreshToken,
            );

            if (!redirect) {
                return;
            }

            return next(redirect);
        },
    } as RouteRecordRaw,

    {
        path: "/",
        name: ROUTES_ENUM.MAIN,
        component: Main,
        children: [authenticatedRoutes, nonAuthenticatedRoutes, oidcRoutes, fulllPageErrors],
    },
];

const mainRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

mainRouter.beforeEach((to, from, next) => {
    if (!supportBackPaths.includes(to.name as ROUTES_ENUM)) {
        appStore.lastBackPath = to.path;
    }
    routePermissionsGuard(to, from, next);
});

export default mainRouter;
