import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, setBlockTracking as _setBlockTracking } from "vue"

const _hoisted_1 = ["type", "step", "aid", "required", "readonly", "disabled", "autocomplete"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.inputField)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.inputTitle)
    }, [
      _createTextVNode(_toDisplayString(_ctx.name) + " ", 1),
      (_ctx.validation && _ctx.validation.description)
        ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.infoIcon)
          }, null, 2)), [
            [_directive_tippy, { content: _ctx.validation.description }]
          ])
        : _createCommentVNode("", true)
    ], 2),
    _withDirectives(_createElementVNode("input", _mergeProps({
      type: _ctx.inputType,
      class: {
                [_ctx.$style.textInput]: true,
                [_ctx.$style.textInputError]: _ctx.invalid,
                [_ctx.$style.autoWidth]: _ctx.autoWidth,
            }
    }, _ctx.validation ? _ctx.validation.attrs : null, {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      step: _ctx.step,
      aid: _ctx.aid,
      required: _ctx.required,
      readonly: _ctx.$attrs.readonly,
      disabled: _ctx.$attrs.disabled,
      autocomplete: _ctx.$attrs.autocomplete,
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.checkValidation && _ctx.checkValidation(...args)))
    }), null, 16, _hoisted_1), [
      [_vModelDynamic, _ctx.model]
    ]),
    (_ctx.invalid)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass([_ctx.$style.msg, _ctx.$style.error])
        }, _toDisplayString(_ctx.err), 3))
      : _createCommentVNode("", true),
    _cache[2] || (
      _setBlockTracking(-1),
      _cache[2] = (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(_ctx.$style.msg)
          }, [
            _createTextVNode("* " + _toDisplayString(_ctx.label), 1)
          ], 2))
        : _createCommentVNode("", true),
      _setBlockTracking(1),
      _cache[2]
    )
  ], 2))
}