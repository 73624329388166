import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBModalTitle = _resolveComponent("MDBModalTitle")!
  const _component_MDBModalHeader = _resolveComponent("MDBModalHeader")!
  const _component_MDBModalBody = _resolveComponent("MDBModalBody")!
  const _component_MDBModalFooter = _resolveComponent("MDBModalFooter")!
  const _component_MDBModal = _resolveComponent("MDBModal")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_component_MDBModal, {
      modelValue: _ctx.isOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpen) = $event)),
      class: _normalizeClass(_ctx.modalClass),
      scrollable: _ctx.scrollable,
      size: _ctx.size,
      fullscreen: _ctx.fullscreen,
      centered: "",
      staticBackdrop: _ctx.staticBackdrop,
      transform: _ctx.fullscreen ? null : 'none'
    }, {
      default: _withCtx(() => [
        (_ctx.title)
          ? (_openBlock(), _createBlock(_component_MDBModalHeader, {
              key: 0,
              close: !_ctx.hideClose
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MDBModalTitle, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["close"]))
          : _createCommentVNode("", true),
        _createVNode(_component_MDBModalBody, {
          class: _normalizeClass(_ctx.modalBodyClass)
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "body")
          ]),
          _: 3
        }, 8, ["class"]),
        (_ctx.$slots.buttons || _ctx.$slots.footer)
          ? (_openBlock(), _createBlock(_component_MDBModalFooter, {
              key: 1,
              class: _normalizeClass(_ctx.modalButtonClass)
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "footer"),
                (_ctx.$slots.buttons)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _renderSlot(_ctx.$slots, "buttons")
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 3
            }, 8, ["class"]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["modelValue", "class", "scrollable", "size", "fullscreen", "staticBackdrop", "transform"])
  ]))
}