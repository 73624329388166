import { FieldsByKeys, FieldTypes, ObjComponents } from "@/core-ui/forms/types/declarative-fields";
import {JobCategories} from "@/cluster-ui/components/submit/args";

export enum DeploymentCategories {
    General = "General Settings",
    ResourceAllocation = "Resource Allocation",
    ContainerDefinition = "Container Definition",
    Storage = "Storage",
    AutoScaling = "Auto Scaling",
}

export enum DeploymentGroups {
    All = "All",
}

export const getDeploymentArgs = (): FieldsByKeys => ({
    project: {
        type: FieldTypes.String,
        description: "Associate the deployment with a Project",
        title: "Project Name",
        required: true,
        meta: {
            placeholder: "Select Project",
            category: DeploymentCategories.General,
            groups: [DeploymentGroups.All],
        },
    },

    name: {
        required: true,
        type: FieldTypes.String,
        description: "Provide a name for the new Deployment",
        title: "Name",
        meta: {
            category: DeploymentCategories.General,
            groups: [DeploymentGroups.All],
        },
        validation: {
            format: "kubeName",
        },
    },

    image: {
        type: FieldTypes.String,
        description: "Provide an image name to be used by the Deployment",
        title: "Image",
        required: true,
        meta: {
            category: DeploymentCategories.General,
            groups: [DeploymentGroups.All],
        },
        validation: {
            format: "imageName",
        },
    },

    isPrivateServiceUrl: {
        type: FieldTypes.Bool,
        description: "Set kservice url as private",
        title: "Private Service Url",
        meta: {
            category: DeploymentCategories.General,
            groups: [DeploymentGroups.All],
        },
    },

    // Resource Allocation
    nodePool: {
        type: FieldTypes.String,
        description: "Specify the Node-Pool name on which to schedule this job",
        title: "Node-Pool Name",
        required: true,
        meta: {
            category: JobCategories.ResourceAllocation,
            groups: [DeploymentGroups.All],
        },
    },
    nodePools: {
        type: FieldTypes.Array,
        description: "Set the scheduling order for node pools by priority. If this isn't set, the scheduling will follow the project's default order. For more details, go to the applicable project page.",
        title: "Node pools",
        editable: true,
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "nodePools",
            fields: {
                nodePool: {
                    type: FieldTypes.String,
                },
            },
        },
        meta: {
            category: JobCategories.ResourceAllocation,
            groups: [DeploymentGroups.All],
        },
    },
    gpu: {
        type: FieldTypes.Number,
        description: "Number of GPUs to allocate for the Deployment",
        title: "GPUs",
        meta: {
            category: DeploymentCategories.ResourceAllocation,
            groups: [DeploymentGroups.All],
        },
        validation: {
            min: 0,
            max: 100,
            step: 0.1,
        },
    },
    cpu: {
        type: FieldTypes.Number,
        description: "CPU cores to allocate for the deployment (e.g. 0.5, 1)",
        title: "Requested CPU",
        meta: {
            category: DeploymentCategories.ResourceAllocation,
            groups: [DeploymentGroups.All],
        },
        validation: {
            min: 0,
            max: 100,
            step: 0.1,
        },
    },
    cpuLimit: {
        type: FieldTypes.Number,
        description: "Set a limit for the number of CPUs the deployment can consume (e.g. 0.5, 1)",
        title: "CPU Limit",
        meta: {
            category: DeploymentCategories.ResourceAllocation,
            groups: [DeploymentGroups.All],
        },
        validation: {
            min: 0,
            max: 100,
            step: 0.1,
        },
    },
    gpuMemory: {
        type: FieldTypes.String,
        description: "GPU Memory to allocate for the deployment (e.g. 1G, 120M)",
        title: "GPU Memory",
        meta: {
            category: DeploymentCategories.ResourceAllocation,
            groups: [DeploymentGroups.All],
        },
        validation: {
            format: "memory",
        },
    },
    memory: {
        type: FieldTypes.String,
        description: "CPU Memory to allocate for the deployment (e.g. 1G, 20M)",
        title: "Requested Memory",
        meta: {
            category: DeploymentCategories.ResourceAllocation,
            groups: [DeploymentGroups.All],
        },
        validation: {
            format: "memory",
        },
    },
    memoryLimit: {
        type: FieldTypes.String,
        description: "Set a limit for the amount of Memory the deployment can consume (e.g. 1G, 20M)",
        title: "Memory Limit",
        meta: {
            category: DeploymentCategories.ResourceAllocation,
            groups: [DeploymentGroups.All],
        },
        validation: {
            format: "memory",
        },
    },
    migProfile: {
        type: FieldTypes.String,
        description: "MIG Profile",
        title: "MIG Profile",
        meta: {
            category: DeploymentCategories.ResourceAllocation,
            groups: [DeploymentGroups.All],
            placeholder: "Select Profile",
        },
    },
    // Container Definition
    command: {
        type: FieldTypes.String,
        description: "Provide the container with a command to run",
        title: "Command",
        meta: {
            category: DeploymentCategories.ContainerDefinition,
            groups: [DeploymentGroups.All],
        },
    },
    arguments: {
        type: FieldTypes.String,
        description: "Provide the container with arguments",
        title: "Arguments",
        meta: {
            category: DeploymentCategories.ContainerDefinition,
            groups: [DeploymentGroups.All],
        },
    },

    mps: {
        type: FieldTypes.Bool,
        title: "Multi-Process Service (MPS)",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: [DeploymentGroups.All],
        },
    },


    environment: {
        type: FieldTypes.Array,
        description: "Set environment variables in the container",
        title: "Environment Variables",
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "customMap",
            fields: {
                key: {
                    type: FieldTypes.String,
                    meta: {
                        inputProps: {
                            placeholder: "Name",
                        },
                    },
                },
                value: {
                    type: FieldTypes.String,
                    meta: {
                        inputProps: {
                            placeholder: "Value",
                        },
                    },
                },
            },
        },
        meta: {
            category: DeploymentCategories.ContainerDefinition,
            groups: [DeploymentGroups.All],
        },
    },

    ports: {
        type: FieldTypes.Array,
        description: "The port inferences workload will be exposed from",
        title: "Port",
        // Set the default to "80" (or any other port number), so the port field will always be open
        // If it will not be open (Without "Add" button), there will no invalid red indication on the field and on the "Container Definition" category
        default: ["80"],
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "portMap",
            fields: {
                container: {
                    type: FieldTypes.Number,
                },
                external: {
                    type: FieldTypes.Number,
                },
                autoGenerate: {
                    type: FieldTypes.Bool,
                    title: "Auto-generate",
                },
                protocol:{
                    type: FieldTypes.String,
                    title: "Protocol",
                    meta:{
                        placeholder: "Select Protocol",
                    }
                },
            },
        },
        editable: false,
        validation: {
            min: 1,
            max: 1
        },
        meta: {
            category: DeploymentCategories.ContainerDefinition,
            groups: [DeploymentGroups.All],
        },
    },

    volumes: {
        type: FieldTypes.Array,
        description: "Mount additional volumes into the container. Usually network volumes",
        title: "Volumes",
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "volumes",
            fields: {
                sourcePath: {
                    type: FieldTypes.String,
                    title: "Source Path",
                },
                targetPath: {
                    type: FieldTypes.String,
                    title: "Target Path",
                },
                readOnly: {
                    type: FieldTypes.Bool,
                    title: "Read Only",
                },
                nfsServer: {
                    type: FieldTypes.String,
                    description: "If provided, the volume is mounted from the specified NFS server.",
                    title: "NFS Server (optional)",
                },
            },
        },
        meta: {
            category: DeploymentCategories.Storage,
            groups: [DeploymentGroups.All],
        },
    },
    // Auto Scaling
    enableAutoScaling: {
        type: FieldTypes.Bool,
        description: "Enable Auto Scaling",
        title: "Enable Auto Scaling",
        default: true,
        meta: {
            category: DeploymentCategories.AutoScaling,
            groups: [DeploymentGroups.All],
        },
    },
    minScale: {
        required: true,
        default: 1,
        type: FieldTypes.Number,
        description: "Minimum number of replicas that the deployment requires",
        title: "Minimum Replicas (Range: 0 - Max number of replicas)",
        meta: {
            category: DeploymentCategories.AutoScaling,
            groups: [DeploymentGroups.All],
        },
    },
    maxScale: {
        type: FieldTypes.Number,
        description: "maximum number of replicas that the deployment allows",
        title: "Maximum Number of Replicas",
        meta: {
            category: DeploymentCategories.AutoScaling,
            groups: [DeploymentGroups.All],
        },
    },
    metric: {
        type: FieldTypes.String,
        description: "MISSING",
        title: "Threshold Metric",
        meta: {
            placeholder: "Select scaling metric",
            category: DeploymentCategories.AutoScaling,
            groups: [DeploymentGroups.All],
        },
    },
    metricName: {
        type: FieldTypes.String,
        description: "The name of the custom metric to use",
        title: "Metric Name",
        meta: {
            category: DeploymentCategories.AutoScaling,
            groups: [DeploymentGroups.All],
        },
    },
    target: {
        type: FieldTypes.Number,
        description: "MISSING",
        title: "Threshold Value",
        meta: {
            category: DeploymentCategories.AutoScaling,
            groups: [DeploymentGroups.All],
        },
    },
});
