import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSection = _resolveComponent("FormSection")!

  return (_openBlock(), _createBlock(_component_FormSection, _mergeProps({ title: _ctx.title }, _ctx.$attrs), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderDataOffields, (field) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(field.component), _normalizeProps(_mergeProps({
          key: field.key
        }, field.args)), null, 16))
      }), 128))
    ]),
    _: 1
  }, 16, ["title"]))
}