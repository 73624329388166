export const POST_MESSAGE_EVENTS = {
    INIT_APP: "init-app",
    CURRENT_CLUSTER: "current-cluster",
    CURRENT_CLUSTER_CHANGED: "current-cluster-changed",
    ACCESS_TOKEN: "access-token",
    DO_LOGOUT: "do-logout",
    USER_DATA: "user-data",
    SIDENAV_STATE_CHANGED: "sidenav-state-changed",
    MODAL_MODE: "modal-mode",
    UPDATE_SEARCH_PARAMS: "update-search-params",
    ON_ROUTE_LEAVE: "on-route-leave",
    ROUTE_BACK: "route-back",
    CURRENT_URL_CHANGED: "current-url-changed",
    CURRENT_URL_BACK: "current-url-back",
    IFRAME_LOADED: "iframe-loaded",
    UPDATE_LOCATION: "update-location",
    UPDATE_EXPRIED_TIMEOUT: "update-expried-timeout",
    REFRESH_CLUSTERS_LIST: "refresh-clusters-list",
    NEW_CLUSTER_CREATED: "new-cluster-created",
    LAST_PAGE_TO_BACK: "last-page-to-back",
};
