
import IconButton from "@/core-ui/IconButton.vue";
import { defineComponent } from "vue";
import { objCtrlArgs, variant } from "./common";
import { useObjCtrl } from "../compositions";
import BoxInputCtrl from "./BoxInputCtrl.vue";
import BoxInputMessages from "./BoxInputMessages.vue";
import Box from "./Box.vue";

export default defineComponent({
    props: {
        ctrlArgs: objCtrlArgs(),
        canEdit: Boolean,
        canRemove: Boolean,
        theme: String,
        variant,
    },
    components: { IconButton, BoxInputCtrl, BoxInputMessages, Box },
    setup(props) {
        const ctrl = useObjCtrl(props.ctrlArgs!);
        return {
            itemCanRemove: props.canRemove,
            itemCanEdit: props.canEdit,
            fieldCtrl: ctrl,
            get icon() {
                return ctrl.meta.icon || "raicon-arrow-right";
            },
            key: ctrl.meta.fields.key(ctrl.state, "key"),
            value: ctrl.meta.fields.value(ctrl.state, "value"),
        };
    },
});
