
import { storageService } from "@/core-ui/data-grid/services";
import IconButton from "@/core-ui/IconButton.vue";
import { computed, defineComponent, ref, watch } from "vue";
import Note from "@/core-ui/Note.vue";
import List from "@/core-ui/List.vue";
import ListHeader from "@/core-ui/ListHeader.vue";
import ListItem from "@/core-ui/ListItem.vue";
import { getCurrentSettingsName, getCurrentTemplateName } from "../../../cluster-ui/helpers/commonFunctions";
import { Template } from "../../../cluster-ui/types/template";
import { isEmpty } from "lodash";
import { TEMLATE_OPEN_KEY, JOB_TEMLATE_OPEN_KEY, InferenceJob } from "../../../cluster-ui/helpers/const";
import _ from "lodash";

export default defineComponent({
    components: { List, ListItem, ListHeader, IconButton, Note },
    props: {
        state: Object,
        createNewTemplate: Function,
        updateCurrentTemplate: Function,
        currentProject: String,
    },
    setup(props) {
        const previousListTitle = getCurrentSettingsName() == InferenceJob ? "Previous Deployment" : "Previous Job";
        const showNoSelectedProjects = ref(false);
        watch(
            () => props.currentProject,
            () => {
                showNoSelectedProjects.value = _.isEmpty(props.currentProject);
            },
            { immediate: true },
        );
        const self = {
            showNoSelectedProjects,
            previousListTitle,
            templateOpen: computed({
                get() {
                    return storageService.getBool(TEMLATE_OPEN_KEY, true);
                },
                set(v: boolean) {
                    storageService.setBool(TEMLATE_OPEN_KEY, v);
                },
            }),
            jobOpen: computed({
                get() {
                    return storageService.getBool(JOB_TEMLATE_OPEN_KEY, false);
                },
                set(v: boolean) {
                    storageService.setBool(JOB_TEMLATE_OPEN_KEY, v);
                },
            }),

            get actionsOnTemplates() {
                const currentTemplate = props.state?.templates?.data.find(
                    (template: Template) => template.name === getCurrentTemplateName(),
                );
                return [
                    ...(props.state!.isLastActiveChange && currentTemplate && !isEmpty(props.state!.project)
                        ? [
                              {
                                  label: "Save",
                                  icon: "raicon-save",
                                  func: () => props.state!.templates.save(props.updateCurrentTemplate),
                              },
                          ]
                        : []),
                    {
                        label: "Save as...",
                        icon: "raicon-save-as",
                        func: () => props.state!.templates.addNew(props.createNewTemplate),
                    },
                ];
            },

            actionsOnTemplateItem(item: any) {
                return [
                    ...(!item.id.startsWith("global-")
                        ? [
                              {
                                  label: "Delete",
                                  icon: "raicon-remove",
                                  func: () => {
                                      props.state!.templates.remove(item.project, item.name);
                                  },
                              },
                          ]
                        : []),
                ];
            },
        };
        return self;
    },
});
