
import { useFieldCtrl } from "@/core-ui/forms/compositions";
import { fieldCtrlArgs, InputValueTypes, variant } from "./common";
import { defineComponent } from "vue";
import ButtonSelect from "@/core-ui/forms/components/ButtonSelect.vue";
import Box from "@/core-ui/forms/components/Box.vue";
import BoxInputMessages from "@/core-ui/forms/components/BoxInputMessages.vue";

export default defineComponent({
    components: { BoxInputMessages, Box, ButtonSelect },
    props: {
        variant,
        theme: String,
        ctrlArgs: fieldCtrlArgs<InputValueTypes>(),
    },
    setup: (props) => {
        const fieldCtrl = useFieldCtrl(props.ctrlArgs!);
        return {
            fieldCtrl,
            get options() {
                return (fieldCtrl.meta as any)?.options;
            },
            get invalid() {
                return fieldCtrl.valid == "invalid";
            },
        };
    },
});
