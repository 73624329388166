import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KeyVal = _resolveComponent("KeyVal")!
  const _component_BoxInputMessages = _resolveComponent("BoxInputMessages")!
  const _component_Box = _resolveComponent("Box")!

  return (_openBlock(), _createBlock(_component_Box, {
    ctrl: _ctx.fieldCtrl,
    variant: _ctx.variant
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BoxInputMessages, {
        variant: _ctx.variant,
        ctrl: _ctx.fieldCtrl
      }, {
        default: _withCtx(() => [
          _createVNode(_component_KeyVal, _mergeProps(_ctx.$attrs, {
            invalid: _ctx.invalid,
            icon: _ctx.icon,
            keyOptions: _ctx.keyAutocomplete,
            valueOptions: _ctx.valueAutocomplete,
            keyInputProps: _ctx.keyInputProps,
            valInputProps: _ctx.valInputProps,
            value: _ctx.fieldCtrl.value,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fieldCtrl.value) = $event))
          }), null, 16, ["invalid", "icon", "keyOptions", "valueOptions", "keyInputProps", "valInputProps", "value"])
        ]),
        _: 1
      }, 8, ["variant", "ctrl"])
    ]),
    _: 1
  }, 8, ["ctrl", "variant"]))
}