
import { defineComponent, watch } from "vue";
import { isInitOrUpdate, useCacheFetch } from "@/core-ui/data-grid/compositions";
import projectService from "../../services/project.service";

export default defineComponent({
    props: {
        project: String,
    },
    setup(props, ctx) {
        const projectState = useCacheFetch({
            syncMethod: {
                method: "Polling",
                interval: 10 * 1000,
                stopOnFailure: false,
            },
            fetch: () => {
                if (props.project != undefined) {
                    return projectService.getState(props.project!);
                }
            },
        });

        watch(
            () => props.project,
            () => projectState.fetch(),
            { immediate: true },
        );
        return {
            get data() {
                return projectState.data;
            },
            get isInitOrUpdate() {
                return isInitOrUpdate(projectState.state);
            },
        };
    },
});
