import { StringSource, Or } from "../utils/string-source";
import router from "@/router";
import isEqual from "lodash/isEqual";

class RouterService extends StringSource {
    _next_query: null | { [key: string]: string } = null;

    _get<T>(key: string, or?: Or<T>, parse?: Function, stringify?: Function): T {
        const val: any = router.currentRoute.value.query[key];

        const result: T | undefined = this._parseOr(val, parse, or);

        // set default when val is empty and result not and params parse and stringify or both defined or parse undefined
        if (val == undefined && result != undefined && ((parse && stringify) || !parse)) {
            this._set(key, result, stringify);
        }

        return result as T;
    }

    _set<T>(key: string, val: T, stringify?: Function) {
        this.nextTick(key, this._stringify(val, stringify));
    }

    // We can't set the router multiple times in one tick so we need to accumulated them
    nextTick(key: string, val: string) {
        if (this._next_query) {
            return (this._next_query[key] = val);
        }

        this._next_query = { [key]: val };

        setTimeout(() => {
            const current = router.currentRoute.value.query;
            const query = {
                ...current,
                ...this._next_query,
            };
            // protecting from setting same query as current
            if (!isEqual(current, query)) {
                router.push({ query });
            }
            this._next_query = null;
        });
    }
}

export const routerService = new RouterService();
