import { onUnmounted } from "vue";
import { MapState, useMapState } from "./MapState";
import { getCtrlId } from "./helpers";
import { ContainerCtrl, ContainerCtrlMeta, ContainerState, CtrlArgs, FieldKey } from "./types";
import { ObjCtrlArgs } from "./ObjCtrl";

type MapCtrlPairFunc<T extends CtrlArgs> = (ctrl: ContainerCtrl, key: FieldKey) => T;

export type MapCtrlArgs<D = any> = CtrlArgs & {
    parent?: ContainerState;
    pair: MapCtrlPairFunc<ObjCtrlArgs>;
    default?: Record<string, D>;
    editable?: boolean;
    meta?: ContainerCtrlMeta;
};

export type MapCtrl<T> = ContainerCtrl & {
    removeByKey(key: FieldKey): void;
    pairsCtrlArgs: T[];
    addPairCtrlArgs(): void;
};

type MapCtrlComponentArgs<T = any> = {
    name: string;
    parent: ContainerState;
    label?: string;
    default: Record<string, T>;
    editable?: boolean;
    description?: string;
};

export function useMapCtrlArgsFromProps<T extends ObjCtrlArgs>(args: {
    props: MapCtrlComponentArgs;
    createItemArgs: MapCtrlPairFunc<T>;
}): MapCtrlArgs<T> {
    return {
        get key() {
            return args.props.name;
        },
        get title() {
            return args.props.label || args.props.name;
        },
        get description() {
            return args.props.description;
        },
        get default() {
            return args.props.default;
        },
        get editable() {
            return args.props.editable;
        },
        get parent() {
            return args.props.parent;
        },
        pair: args.createItemArgs as any,
    };
}

export function useMapCtrl<T extends ObjCtrlArgs>(args: MapCtrlArgs<T>): MapCtrl<T> {
    const id = getCtrlId();
    let state: MapState<T>;

    onUnmounted(() => {
        if (args.parent) {
            args.parent.remove(self);
        }
    });

    const self: MapCtrl<T> = {
        type: "array",
        get id() {
            return id;
        },
        get title() {
            return args.title;
        },
        get description() {
            return args.description;
        },
        get declaration() {
            return args;
        },
        get hidden() {
            return state.hidden;
        },
        get editable() {
            return state.editable;
        },

        get value(): any {
            return state.value;
        },
        set value(v: any) {
            state.value = v;
        },
        get valid() {
            return state.valid;
        },
        get meta() {
            return args.meta;
        },
        get key() {
            return args.key;
        },
        get dirty() {
            return state.dirty;
        },
        get context() {
            return state.context;
        },
        get children() {
            return state.children;
        },
        get pairsCtrlArgs() {
            return state.pairsCtrlArgs as T[];
        },
        set state(_state: MapState<T>) {
            state = _state;
        },

        get state() {
            return state;
        },
        clear() {
            state.clear();
        },
        createState(): MapState<T> {
            return useMapState<T>({
                key: args.key,
                get default() {
                    return args.default;
                },
                get pair() {
                    return args.pair;
                },
                get parent() {
                    return args.parent;
                },
                get editable() {
                    return args.editable;
                },
                get defaultEditable() {
                    return args.parent?.defaultEditable || true;
                },
            });
        },
        add: (i: any) => {
            state.add(i);
        },
        remove(...args) {
            return state.remove(...args);
        },
        removeByKey(key: FieldKey) {
            return state.removeByKey(key);
        },
        isChildrenHidden(key) {
            return state.isChildrenHidden(key);
        },
        addPairCtrlArgs() {
            return state.addPairCtrlArgs();
        },
    };
    if (args.parent) {
        args.parent.add(self);
    }

    return self;
}
