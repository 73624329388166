
import IconButton from "@/core-ui/IconButton.vue";
import { defineComponent, ref } from "@vue/runtime-core";

export default defineComponent({
    components: { IconButton },
    props: {
        default: { type: Boolean, default: true },
        showIcon: { type: Boolean, default: true },
        notValid: Boolean,
    },
    setup(props) {
        const expended = ref(props.default);
        return {
            expended,
            toggle() {
                expended.value = !expended.value;
            },
        };
    },
});
