
import KeyVal from "./KeyVal.vue";
import Box from "./Box.vue";
import BoxInputMessages from "./BoxInputMessages.vue";

import { computed, defineComponent, ref, watchEffect } from "vue";
import { useFieldCtrl } from "@/core-ui/forms/compositions";
import { fieldCtrlArgs, InputValueTypes, variant } from "./common";

export default defineComponent({
    props: {
        variant,
        ctrlArgs: fieldCtrlArgs<InputValueTypes>(),
    },
    components: { Box, BoxInputMessages, KeyVal },
    setup(props, ctx) {
        const fieldCtrl = useFieldCtrl(props.ctrlArgs!);
        return {
            fieldCtrl,
            get invalid() {
                return fieldCtrl.valid !== "valid";
            },
            get keyInputProps() {
                return {
                    ...(fieldCtrl.meta?.inputProps || {}),
                    ...(fieldCtrl.meta?.keyInputProps || {}),
                };
            },
            get keyAutocomplete() {
                return [...(fieldCtrl.meta?.autocomplete || []), ...(fieldCtrl.meta?.keyAutocomplete || [])];
            },
            get valueAutocomplete() {
                return [...(fieldCtrl.meta?.autocomplete || []), ...(fieldCtrl.meta?.valAutocomplete || [])];
            },
            get icon() {
                return fieldCtrl.meta?.icon;
            },
            get valInputProps() {
                return {
                    ...(fieldCtrl.meta?.inputProps || {}),
                    ...(fieldCtrl.meta?.valInputProps || {}),
                };
            },
        };
    },
});
