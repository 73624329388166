import authService, {idTokenResponse} from "@/services/auth-service";
import { AuthStore } from "./authStore";
import axios from "axios";
import settingStore, { SettingKeys } from "@/stores/setting-store";


export class oidcAuthStore implements AuthStore {
    private token = "";
    private refreshToken = "";
    private authClientID = "";
    private authRealm = "";
    private authProviderConfig: any;

    private readonly AUTH_PROVIDER_DISCOVERY_PATH = `.well-known/openid-configuration`;

    async init(authUrl: string, authClientId: string, authRealm: string) {
        this.authClientID = authClientId
        this.authRealm = authRealm

        this.authProviderConfig = await this.getAuthProviderConfig(authUrl)
    }

    async login() {
        const signInUrl = this.buildAuthorizationLoginUrl()
        try {
            window.location.replace(signInUrl);
        } catch (e) {
            console.error(e);
        }
    }

    async logout(forceLogout?: boolean) {
        const signOutUrl = this.buildLogoutUrl(forceLogout)
        window.location.replace(signOutUrl);
    }

    async refresh(): Promise<idTokenResponse> {
        return await authService.refreshToken(this.refreshToken);
    }

    async isAuthenticated(): Promise<boolean> {
        return Promise.resolve(this.token != "")
    }

    async setToken(idToken: string, refreshToken: string) {
        this.token = idToken;
        this.refreshToken = refreshToken;
    }

    private async getAuthProviderConfig(authUrl: string): Promise<any> {
        const url = `${authUrl}/${this.AUTH_PROVIDER_DISCOVERY_PATH}`
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (exception) {
            console.log(`ERROR received from ${url}: ${exception}\n`);
            throw exception
        }
    }

    private buildAuthorizationLoginUrl(): string {
        const params = Object({
            response_type: 'code',
            client_id: this.authClientID,
            connection: this.authRealm,
            redirect_uri: `${window.location.origin}/login/callback`,
            scope: 'openid email profile offline_access'
        });

        const queryParams = Object.keys(params).map(k => `${k}=${params[k]}`).join('&');
        return `${this.authProviderConfig['authorization_endpoint']}?${queryParams}`
    }

    private buildLogoutUrl(forceLogout: boolean = true): string {
        const settingsState = settingStore.state?.kv || {}
        const params = Object({
            'client_id': this.authClientID,
            'post_logout_redirect_uri': settingsState[SettingKeys.LogoutURI] || window.location.origin
        })

        if (forceLogout && this.token) {
            params['id_token_hint'] = this.token;
        }
        
        const queryParams = Object.keys(params).map(k => `${k}=${params[k]}`).join('&');
        return `${this.authProviderConfig['end_session_endpoint']}?${queryParams}`
    }
}

const authStore = new oidcAuthStore();
export default authStore;
