import { Project } from "../models/project.model";
import { clusterApi } from "./apiClient";
import { ProjectState } from "../types/project";

export default {
    async get(): Promise<Project[]> {
        return clusterApi.get("/api/v1/projects").then((res) => mapProject(res.data.data));
    },

    async getState(project: string): Promise<ProjectState> {
        return clusterApi.get(`/api/v1/projects/${project}/state`).then((res: any) => res.data);
    },
};

function mapProject(data: Project[]): Project[] {
    data.forEach((p) => {
        p.trainNodeAffinity =
            p.trainNodeAffinity && p.trainNodeAffinity.length ? (p.trainNodeAffinity! as string[]).join(", ") : "none";
        p.interactiveNodeAffinity =
            p.interactiveNodeAffinity && p.interactiveNodeAffinity.length
                ? (p.interactiveNodeAffinity! as string[]).join(", ")
                : "none";
    });

    return data;
}
