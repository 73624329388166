
import { defineComponent } from "vue";
import type { PropType } from "vue";
// Models
import { ILicense } from "@/types/license";
import { ROUTES_ENUM } from "@/router/routes";
// Components
import Modal from "@/core-ui/Modal.vue";
import Button from "@/core-ui/Button.vue";

export default defineComponent({
    emits: ["close"],
    props: {
        isAdmin: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
        isExpiredOrExceeded: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
    },
    components: {
        Modal,
        Button,
    },
    computed: {
        title(): string {
            if (this.isExpiredOrExceeded) {
                return "License Expired";
            } else {
                return "License Exipring Soon";
            }
        },
        msg(): string {
            if (this.isExpiredOrExceeded) {
                return "Your Run:ai software license has expired or exceeded its GPU capacity. We recommend using valid licensed software.";
            } else {
                return "Your Run:ai software license is expiring soon. We recommend using valid licensed software.";
            }
        },
    },
    methods: {
        closeModal() {
            this.$emit("close");
        },
        goToLicenses() {
            this.$router.push({ name: ROUTES_ENUM.LICENSES });
            this.closeModal();
        },
    },
});
