import { ref, Ref, watch } from "vue";
import { searchQuery } from "@/core-ui/helpers/searchQuery";
import { SearchParserOptions } from "search-query-parser";
import { Column } from "@/core-ui/types/column";
import debounce from "lodash/debounce";
import { routerService } from "@/core-ui/data-grid/services";

export interface Querying {
    columns: Column[];
    searchableColumns: Column[];
    query: string;
    emitSearch(value: string): void;
    search<T>(data: T[]): T[];
}

export interface QueryingProps {
    query: string;
    searchOptions?: SearchParserOptions;
    columns: Column[];
    initialSearchQuery?: string;
}

export interface QueryingSourceProps {
    syncUrl: boolean;
    searchOptions?: SearchParserOptions;
    columns: Column[];
    initialSearchQuery?: string;
}

export class QuerySource implements QueryingProps {
    _query: Ref<string>;

    constructor(private props: QueryingSourceProps) {
        const syncQuery = () =>
            (this._query.value = props.syncUrl ? routerService.getStr("query", this.query) : this.query);

        this._query = ref("");
        syncQuery();

        watch(() => props.syncUrl, syncQuery, { immediate: true });
    }

    get searchOptions(): SearchParserOptions {
        return this.props.searchOptions as any;
    }

    get initialSearchQuery(): string | undefined {
        return this.props?.initialSearchQuery;
    }

    get columns() {
        return this.props.columns;
    }

    get query() {
        return this._query.value;
    }

    set query(value: string) {
        if (this.query == value) return;
        if (this.props.syncUrl) routerService.setStr("query", value);
        this._query.value = value;
    }
}

export function useQuerying(props: QueryingProps): Querying {
    const temp = ref<string | undefined>(props.initialSearchQuery);

    function _search<T>(data: T[]): T[] {
        return search(props.columns, props.query, props.searchOptions)(data);
    }

    const setDebounce = debounce(() => {
        props.query = temp.value ?? "";
        temp.value = undefined;
    }, 500);

    return {
        search: _search,
        get columns() {
            return props.columns;
        },
        get searchableColumns() {
            return props.columns.filter(({ key }) => props.searchOptions?.keywords?.find((k) => k == key));
        },
        get query() {
            return temp.value ?? props.query;
        },
        emitSearch(value: string) {
            temp.value = value;
            setDebounce();
        },
    };
}

type searchPipe = <T>(data: T[]) => T[];

export function search(columns: Column[], query: string, searchOptions?: SearchParserOptions): searchPipe {
    return <T>(data: T[]) => {
        return query && query.trim() ? searchQuery(data, columns, query, searchOptions as any) : data;
    };
}
