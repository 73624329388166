
import { defineComponent } from "vue";
import SideActions from "./SideActions.vue";

export default defineComponent({
    components: { SideActions },
    props: {
        label: String,
        icon: String,
        actions: Array,
    },
    setup() {
        return {};
    },
});
