

import Modal from "@/core-ui/Modal.vue";
import authStore from "@/stores/authStore";
export default {
    name: "LogoutClusterModal",
    emits: ["cancel"],
    components: {
        Modal,
    },
    setup() {
        const self = {
            logout() {
                authStore.logout(true);
            }
        };
        return self;
    },
};
