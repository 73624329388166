import { loadView } from "@/router/utils";

const oidcChildren = [
    {
        path: "oauth-code",
        name: "oauth-code",
        meta: { requiresAuth: false },
        component: loadView("OIDC"),
    },
];

const oidcRoutes = {
    path: "/",
    component: loadView("OIDC"),
    children: oidcChildren,
};

export default oidcRoutes;
