import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ccab2a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification-details d-flex align-items-center" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_MDBAlert = _resolveComponent("MDBAlert")!

  return (_ctx.notificationMsg)
    ? (_openBlock(), _createBlock(_component_MDBAlert, {
        key: 0,
        class: "page-notification d-flex justify-content-between rounded-0 m-0",
        static: "",
        color: _ctx.notificationType
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_FontAwesomeIcon, {
              icon: ['far', 'triangle-exclamation'],
              class: _normalizeClass(["page-notification-icon me-2", [_ctx.notificationType]])
            }, null, 8, ["class"]),
            _createElementVNode("div", {
              class: "page-notification-massage pt-1",
              innerHTML: _ctx.notificationMsg
            }, null, 8, _hoisted_2)
          ])
        ]),
        _: 1
      }, 8, ["color"]))
    : _createCommentVNode("", true)
}