
import { ObjCtrl } from "@/core-ui/forms/compositions";
import { Field, FieldsByKeys } from "@/core-ui/forms/types/declarative-fields";
import { computed, defineComponent, PropType, reactive, watchEffect } from "@vue/runtime-core";
import Category from "./Category.vue";
import Expandable from "./Expandable.vue";
import { getFieldRenderData } from "@/core-ui/forms/compositions";
import Expend from "./Expend.vue";

export default defineComponent({
    components: { Category, Expandable, Expend },
    props: {
        ctrl: { type: Object as PropType<ObjCtrl>, required: true },
        fields: { type: Object as PropType<FieldsByKeys>, required: true },
        hideCategoriesTitle: { type: Array, default: () => [] },
        defaultGroup: { type: String },
    },
    setup(props, ctx) {
        let categories = computed(() => {
            const warp = (category: string) => {
                return (): boolean => props.ctrl.isCategoryOrGroupsValid(category) == "invalid";
            };
            const categories = new Set<string>();

            Object.values(props.fields || {}).forEach((field: Field) => {
                const category = field?.meta?.category;
                category && categories.add(category);
            });

            return [...categories].map((c: string) => ({
                key: c,
                title: props.hideCategoriesTitle.every((_c) => _c !== c) ? c : undefined,
                notValid: warp(c),
            }));
        });

        let categoriesOpen = reactive<any>({});
        // init open state
        watchEffect(() => {
            Object.values(categories.value).forEach(({ key }) => {
                if (!categoriesOpen[key]) {
                    categoriesOpen[key] = false;
                }
            });
        });

        const self = {
            categoriesOpen,
            categories,

            get sectionsMeta(): any {
                return Object.fromEntries(categories.value.map((c) => [c.key, c]));
            },

            thereIsHiddenFileds(category: string): boolean {
                return Object.values(props.fields)
                    .filter((field) => (field as any).meta?.category == category)
                    .some((field) => !(field as any).meta?.groups?.some((g: string) => g === props.defaultGroup));
            },

            getCategoryFields(category: string, defaultFields = false) {
                return Object.entries(props.fields)
                    .filter(([_, field]) => (field as any).meta?.category == category)
                    .map(([key, field]) => ({
                        ...getFieldRenderData(field)(props.ctrl.state, key),
                        hidden:
                            defaultFields && !(field as any).meta?.groups?.some((g: string) => g === props.defaultGroup),
                    }));
            },
        };
        return self;
    },
});
