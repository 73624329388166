
import { computed, defineComponent, watchEffect } from "vue";
import { variant, customCtrlArgs } from "@/core-ui/forms/components/common";
import Box from "@/core-ui/forms/components/Box.vue";
import { ContainerCtrlMeta, useObjCtrl } from "@/core-ui/forms/compositions";
import IconButton from "@/core-ui/IconButton.vue";
import BoxInputCtrl from "@/core-ui/forms/components/BoxInputCtrl.vue";
import BoxToggleCtrl from "@/core-ui/forms/components/BoxToggleCtrl.vue";
import { getCurrentSettingsName } from "@/cluster-ui/helpers/commonFunctions";
import { InferenceJob } from "@/cluster-ui/helpers/const";


export default defineComponent({
  props: {
    variant,
    ctrlArgs: customCtrlArgs<any>(),
    canEdit: { type: Boolean, default: true },
    canRemove: { type: Boolean, default: true },
  },
  components: { Box, IconButton, BoxInputCtrl, BoxToggleCtrl },
  setup(props) {
    const ctrl = useObjCtrl(props.ctrlArgs!);

    function getArgs(key: string): any {

      const args = ctrl.meta.fields[key](ctrl.state, key).args;
      return args;
    }

    return {
      itemCanEdit: props.canEdit,
      itemCanRemove: props.canRemove,
      ctrl,
      get dirty() {
        return ctrl.dirty;
      },
      get invalid() {
        return ctrl.valid == "invalid";
      },
      value: getArgs("value"),
      key: getArgs("key"),

    };
  },
});
