import apiClient from "./api-client";
import { ServerError } from "@/types/server-error";

export interface IWandbSweepConfig {
    config: Record<string, any>;
    entity: string;
    project: string;
    apiKey: string;
    baseUrl?: string;
};

class WandbService {
    async getSweepId(wandbSweepConfig: IWandbSweepConfig): Promise<Record<string, any>> {
        const res = await apiClient.asyncRequestWithResponseAndError(`v1/k8s/wandb/sweeps`, "POST", wandbSweepConfig);
        if (res.status) {
            throw new ServerError(res.status, res.description);
        }

        return res;
    }
};

const wandbService = new WandbService();
export default wandbService;
