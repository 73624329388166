import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b74bba7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "codeRef" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("code", {
    class: "code-block",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copy && _ctx.copy(...args)))
  }, [
    _createVNode(_component_FontAwesomeIcon, {
      icon: ['far', 'copy'],
      class: "icon-copy"
    }),
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 512)
  ]))
}