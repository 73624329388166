
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        loading: Boolean,
        text: String,
        clicked: Boolean,
        autofocus: Boolean,
        buttonType: {
            type: String as PropType<"main" | "secondary" | "danger">,
            default: "secondary",
        },
    },
    computed: {
        color() {
            return this.buttonType === "main" ? "primary" : this.buttonType === "secondary" ? "link" : this.buttonType;
        },
    },
    setup: (props, ctx) => ({
        get calculatedAttributes() {
            return {
                type: ctx.attrs.type || (ctx.attrs.form ? "submit" : "button"),
                disabled: props.clicked ? true : ctx.attrs.disabled,
                ...ctx.attrs,
            };
        },
    }),
});
