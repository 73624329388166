
import { defineComponent } from "vue";

// Divided the form into a sections
export default defineComponent({
    emits: ["headerClick"],
    props: {
        title: String,
        description: String,
    },
});
