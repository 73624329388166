
import Modal from "@/core-ui/Modal.vue";
import Button from "@/core-ui/Button.vue";
import tenantService from "@/services/tenant-service";
import { dispatcher, RequestState } from "@/core-ui/services/dispatcher";
import { ContractTypes } from "@/types/contract-types";
import { defineComponent, PropType, ref } from "vue";

export default defineComponent({
    components: {
        Modal,
        Button,
    },
    emits: ["done"],
    props: {
        contractType: {
            type: String as PropType<ContractTypes>,
            default: ContractTypes.Normal,
        },
    },
    setup(props, ctx) {
        const signRequestState = ref<RequestState>(null as any);
        const self = {
            signRequestState,
            get isNormalContract() {
                return props.contractType == ContractTypes.Normal;
            },

            get isFreemiumContract() {
                return props.contractType == ContractTypes.Freemium;
            },

            async sign() {
                signRequestState.value = dispatcher.dispatch(tenantService.signEULA());

                try {
                    await signRequestState.value.promise;
                    // this.$notify({
                    //   type: "success",
                    //   text: "Successfully Signed Terms of Service",
                    // });
                    ctx.emit("done");
                } catch (e) {
                    // this.$notify({
                    //   type: "error",
                    //   text: "Failed To Sign Terms of Service",
                    // });
                    signRequestState.value = null as any;
                }
            },
        };
        return self;
    },
});
