
import Button from "@/core-ui/Button.vue";
import Modal from "@/core-ui/Modal.vue";
import { dispatcher } from "@/core-ui/services/dispatcher";
import InputField from "@/core-ui/forms/components/InputField.vue";
import { passwordValidation } from "@/core-ui/helpers/validations-schemas";
import { Validation } from "@/core-ui/types/validation";
import authService from "@/services/auth-service";
import authStore from "../../stores/authStore";
import { defineComponent, ref } from "vue";
import { useToast } from "vue-toastification";

export default defineComponent({
    components: {
        InputField,
        Modal,
        Button,
    },
    emits: ["cancel", "submit"],
    props: {
        force: Boolean,
    },

    setup(props, ctx) {
        const submitted = ref(false);
        const password = ref("");
        const verifyPassword = ref("");
        const currentPassword = ref("");
        const verifyPasswordValidation = ref<Validation>(null as any);
        const toast = useToast();

        function cancel() {
            ctx.emit("cancel");
        }

        const self = {
            password,
            verifyPassword,
            currentPassword,
            submitted,
            verifyPasswordValidation: {
                inputType: "password",
                test: (value?: string) => {
                    return !value || !password.value || value === password.value;
                },
                errMsg: "* Passwords do not match",
            },
            passwordValidation,
            cancel,
            async submit() {
                submitted.value = true;
                try {
                    await dispatcher.executeWithErrorHandling(authService.changePassword(password.value, currentPassword.value));
                    if (props.force) {
                        await authStore.refreshUserInfo();
                    } else {
                        ctx.emit("submit");
                        cancel();
                        toast.success("Reset password succeeded!");
                    }
                } catch (e) {
                    submitted.value = false;
                    toast.error("Reset password failed!");
                }
            },
        };
        return self;
    },
});
