import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["src", "onload"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, { "display-page-notification": true }, {
    default: _withCtx(() => [
      (!_ctx.grafanaState.grafanaLoggedIn)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.grafanaState.grafanaLoggedIn)
        ? (_openBlock(), _createElementBlock("iframe", {
            key: 1,
            class: _normalizeClass([_ctx.$style.iframe]),
            src: _ctx.url,
            ref: "iframeElm",
            onload: _ctx.onIframeLoaded,
            frameborder: "0"
          }, null, 10, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}