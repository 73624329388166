
import { defineComponent, ref } from "vue";
import Note from "@/core-ui/Note.vue";
import Button from "@/core-ui/Button.vue";

export default defineComponent({
    props: {
        clusterConnectionError: Object,
    },
    components: { Note, Button },
    setup: () => {
        const showDetails = ref<boolean>(false);
        return {
            showDetails,
            toggle() {
                showDetails.value = !showDetails.value;
            },
        };
    },
});
