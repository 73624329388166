import { TableColumn, DataGridColumn, PopupColumn } from "@/core-ui/types/column";
import {
    Sorting,
    useSorting,
    OrderDirection,
    useItemsSelection,
    usePagination,
    Pagination,
    PaginationSource,
    SortingSource,
    ItemsSelection,
} from "./";
import _ from "lodash";

import { useQuerying, Querying, QuerySource, search } from "../../forms/compositions";
import { DataGridAction, useActions, Actions } from "./Actions";
import { watch, ref, watchEffect } from "vue";
import { useFilterCtrl } from "./FilterCtrl";
import { paginate } from "./Pagination";
import { sort, Order } from "./Sorting";
import { useListDisplay, ListDisplay } from "./ListDisplay";
import { isItem, toKeywords } from "../utils";
import { DataGridModel } from "./DataGridModel";
import { useToast } from "vue-toastification";

// future: support multi display types
// enum DataGridDisplay {
//     Table,
//     List,
//     Card,
//     Custom,
// }

interface DataGridProps {
    syncUrl?: boolean;
    model: DataGridModel;
    actions: DataGridAction[];
    initialSearchQuery?: string;
}

export interface DataGrid<T = unknown> {
    sorting: Sorting;
    querying: Querying | undefined;
    selection?: ItemsSelection;
    columns: DataGridColumn[];
    dataKey?: string;
    syncUrl?: boolean;
    actions: Actions<T> | undefined;
    pagination: Pagination;
    display: ListDisplay<T>;
}

export function useDataGrid<T>(props: DataGridProps): DataGrid<T> {
    // init
    const defaultSort = ref<null | Order[]>(null);
    const syncDefaultSort = () => {
        const crn = defaultSort.value;
        const def = toDefaultSort(props.model.columns);
        // todo: solve reactive circle in a better way
        if (!crn || def?.length) {
            defaultSort.value = def;
        }
    };
    syncDefaultSort();
    watchEffect(() => {
        props.model.columns;
        syncDefaultSort();
    });

    // use logic components

    // listDisplay
    const display = useListDisplay<T>({
        get itemId() {
            return props.model.itemId;
        },
    });

    // actions
    const actions = useActions({
        get actions() {
            return props.actions;
        },
    });

    // sorting
    const sortingProps = new SortingSource({
        get defaultSort() {
            return defaultSort.value as Order[];
        },
        get sortableKeys() {
            return toSortable(props.model.columns);
        },
        get syncUrl() {
            return props.syncUrl;
        },
        get tableName() {
            return props.model.tableName;
        },
    });
    const sorting = useSorting(sortingProps);

    // querying
    const queryingProps = new QuerySource({
        get syncUrl() {
            return !!props.syncUrl;
        },
        get columns() {
            return props.model.columns;
        },
        get searchOptions() {
            return {
                get keywords() {
                    return toKeywords(props.model.columns);
                },
            };
        },
        get initialSearchQuery() {
            return props.initialSearchQuery;
        },
    });
    const querying = useQuerying(queryingProps);

    // selection
    // future: support multi selection
    const selection = useItemsSelection({
        multi: false,
        get key() {
            return props.model.dataKey;
        },
        display,
    });

    // pagination
    const paginationProps = new PaginationSource({
        get syncUrl() {
            return !!props.syncUrl;
        },
    });
    const pagination = usePagination(paginationProps);

    // filterCtrl
    const filterCtrl = useFilterCtrl({
        get page() {
            return paginationProps.page;
        },
        get items_per_page() {
            return paginationProps.items_per_page;
        },
        get query() {
            return queryingProps.query;
        },
        get sort_by() {
            return sortingProps.value;
        },
        get emitPage() {
            return pagination.emitPage;
        },
    });

    function pipe() {
        const data = props.model.cache.data.data;
        if (props.model.syncServer) {
            display.set(data as any);
            paginationProps.total = props.model.cache.data.length as number;
            return;
        }

        const { query, items_per_page, page, sort_by } = filterCtrl.filter;
        display.set(
            _pipe(
                data,
                search(props.model.columns, query, queryingProps.searchOptions),
                sort(sort_by),
                // sync total
                (data: any) => {
                    paginationProps.total = data.length;
                    return data;
                },
                paginate(page, items_per_page),
            ),
        );
    }

    function syncUpdate() {
        props.model.update(filterCtrl.filter);
    }

    watch(
        () => filterCtrl.filter,
        (o: any, t: any) => {
            //TODO: it looks that the filter update all the time even if its values are the same
            if (props.model.syncServer && !_.isEqual(o, t)) {
                syncUpdate();
            }
        },
    );
    syncUpdate();
    watch([() => !props.model.syncServer && filterCtrl.filter, () => props.model.cache.data], pipe, { immediate: true });

    return {
        get columns() {
            return props.model.columns;
        },
        get dataKey() {
            return props.model.dataKey;
        },
        get syncUrl() {
            return props.syncUrl;
        },
        get actions() {
            return props.actions && props.actions.length ? actions : undefined;
        },
        sorting,
        get querying() {
            return props.model.options.noSearch ? undefined : querying;
        },
        get selection() {
            return props.model.options.noSelection ? undefined : selection;
        },
        pagination,
        display,
    };
}

/// helpers

export function toSortable(columns: DataGridColumn[]) {
    return columns.filter(isItem("sortable")).map(({ dataKey }) => dataKey);
}

export function toDisplayColumns<T>(
    displayType: null | "table" | "popup",
    columns: DataGridColumn[],
    hiddenKeys: string[] = [""],
): T[] {
    return columns
        .filter(({ display }) => display && (displayType == null || display[displayType]))
        .filter((column) => hiddenKeys?.find((key) => column.key !== key) !== undefined)
        .map(
            ({
                key,
                dataKey,
                reverseKey,
                label,
                dataTransform,
                display: { table, popup, ...generalDisplay } = {} as any,
            }) => ({
                key,
                label,
                dataKey,
                reverseKey,
                dataTransform,
                ...generalDisplay,
                ...(displayType == null ? { table, popup } : { table, popup }[displayType]),
            }),
        );
}

export function toTableColumns(columns: DataGridColumn[], keysToExclude: string[] = [""]): TableColumn[] {
    return toDisplayColumns("table", columns, keysToExclude);
}

export function toPopupColumns(columns: DataGridColumn[], keysToExclude: string[] = [""]): PopupColumn[] {
    return toDisplayColumns("popup", columns, keysToExclude);
}

function toDefaultSort(columns: DataGridColumn[]): Order[] | null {
    const first = columns[0]?.dataKey;
    if (!first) {
        null;
    }
    const col = columns.filter(isItem("defaultSort"));
    if (!col.length) {
        return [{ key: first as string, direction: OrderDirection.ASC }];
    }

    return col.map((c) => ({ key: c.dataKey, direction: c.direction || OrderDirection.ASC }));
}

function _pipe(data: any, ...functions: Function[]) {
    functions.forEach((func) => {
        data = func(data);
    });
    return data;
}
