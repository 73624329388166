import { clusterApi } from "./apiClient";
import {projectNameToWorkloadNameSpace} from "@/cluster-ui/helpers/commonFunctions";

let PvcsListOptions: object[];

export default {

    async restPvcsListOptions(selectedProject: string): Promise<object[]> {
        const namespace = await projectNameToWorkloadNameSpace(selectedProject)
        if (namespace) {
            return PvcsListOptions = await clusterApi.get(`/api/v1/pvcs/${namespace}`)
                .then((res) => mapPvcs(res.data?.pvcs as string[]));
        } else {
            return PvcsListOptions = [];
        }
    },

    get(): object[] {
        return PvcsListOptions;
    },
};

function mapPvcs(pvcs: string[]): object[] {
    const options:object[] = []
    pvcs.forEach((pvc) => {
        options.push({
            name: pvc as string,
            value: pvc as string,
        })
    });

    return options;
}
