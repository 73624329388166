import { onUnmounted } from "vue";
import { ArrayState, useArrayState } from "./ArrayState";
import { getCtrlId } from "./helpers";
import { ArrayCtrlMeta, ContainerCtrl, ContainerCtrlMeta, ContainerState, CtrlArgs, FieldKey } from "./types";

type ArrayCtrlItemFunc<T extends CtrlArgs> = (ctrl: ContainerCtrl, key: FieldKey) => T;

export type ArrayCtrlArgs<T extends CtrlArgs, D = any> = CtrlArgs & {
    parent?: ContainerState;
    item: ArrayCtrlItemFunc<T>;
    default?: D[];
    editable?: boolean;
    meta?: ArrayCtrlMeta;
};

export type ArrayCtrl<T> = ContainerCtrl & {
    removeByKey(key: FieldKey): void;
    itemsCtrlArgs: T[];
    addItemCtrlArgs(): void;
};

type ArrayCtrlComponentArgs<T = any> = {
    name: string;
    parent: ContainerState;
    label?: string;
    default: T[];
    onInputChange: any;
    editable?: boolean;
    description?: string;
};

export function useArrayCtrlArgsFromProps<T extends CtrlArgs>(args: {
    props: ArrayCtrlComponentArgs;
    createItemArgs: ArrayCtrlItemFunc<T>;
}): ArrayCtrlArgs<T> {
    return {
        get key() {
            return args.props.name;
        },
        get title() {
            return args.props.label || args.props.name;
        },
        get description() {
            return args.props.description;
        },
        get default() {
            return args.props.default;
        },
        get onInputChange() {
            return args.props.onInputChange;
        },
        get editable() {
            return args.props.editable;
        },
        get parent() {
            return args.props.parent;
        },
        item: args.createItemArgs as any,
    };
}

export function useArrayCtrl<T extends CtrlArgs>(args: ArrayCtrlArgs<T>): ArrayCtrl<T> {
    const id = getCtrlId();
    let state: ArrayState<T>;

    onUnmounted(() => {
        if (args.parent) {
            args.parent.remove(self);
        }
    });

    const self: ArrayCtrl<T> = {
        type: "array",
        get id() {
            return id;
        },
        get title() {
            return args.title;
        },
        get description() {
            return args.description;
        },
        get onInputChange() {
            return args.onInputChange;
        },
        get declaration() {
            return args;
        },
        get hidden() {
            return state.hidden;
        },
        get editable() {
            return state.editable;
        },
        get value(): any {
            return state.value;
        },
        set value(v: any) {
            state.value = v;
        },
        get valid() {
            return state.valid;
        },
        get meta() {
            return args.meta;
        },
        get key() {
            return args.key;
        },
        get dirty() {
            return state.dirty;
        },
        get context() {
            return state.context;
        },
        get children() {
            return state.children;
        },
        get itemsCtrlArgs() {
            return state.itemsCtrlArgs as T[];
        },
        set state(_state: ArrayState<T>) {
            state = _state;
        },

        get state() {
            return state;
        },
        clear() {
            state.clear();
        },
        createState(): ArrayState<T> {
            return useArrayState<T>({
                key: args.key,
                get default() {
                    return args.default;
                },
                get item() {
                    return args.item;
                },
                get parent() {
                    return args.parent;
                },
                get editable() {
                    return args.editable;
                },
                get defaultEditable() {
                    return args.parent?.defaultEditable || true;
                },
            });
        },
        add: (i: any) => {
            state.add(i);
        },
        remove(...args) {
            return state.remove(...args);
        },
        removeByKey(key: FieldKey) {
            return state.removeByKey(key);
        },
        isChildrenHidden(key) {
            return state.isChildrenHidden(key);
        },
        addItemCtrlArgs() {
            return state.addItemCtrlArgs();
        },
    };
    if (args.parent) {
        args.parent.add(self);
    }

    return self;
}
