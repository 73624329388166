
import clustersStore from "../../stores/clusters-store";
import orderBy from "lodash/orderBy";
import { defineComponent, ref } from "vue";
import { Cluster } from "@/types/cluster";
import { INVALID_CLUSTER_TOKEN, checkCluster } from "@/stores/activeClusterConnectionInfoStore";
import LogoutClusterModal from "@/components/core/LogoutClusterModal.vue"
import authStore from "../../stores/authStore";

export default defineComponent({
    components: {
        LogoutClusterModal,
    },
    created() {
        clustersStore.fetchClusters();
    },
    setup() {
        const dropdownOpened = ref<boolean>(false);
        const showModal = ref<boolean>(false);
        const currentClusterId = ref<string>();

        const self = {
            dropdownOpened,
            showModal,
            currentClusterId,
            get options() {
                const clusters = clustersStore.state.clusters || [];
                return orderBy(
                    clusters.map((cluster: Cluster) => {
                        return {
                            title: cluster.name,
                            key: cluster.uuid,
                        };
                    }),
                    "title",
                );
            },

            get activeCluster() {
                return clustersStore.activeCluster || {};
            },

            set activeCluster(cluster) {
                clustersStore.setActiveCluster(cluster.uuid);
                clustersStore.setClusterDashboards(cluster.uuid);
            },

            async select({ key }: any) {
                currentClusterId.value = clustersStore.activeClusterUUID;
                self.activeCluster = clustersStore.state.clusters.find((cluster: Cluster) => cluster.uuid === key);

                try {
                    await checkCluster();
                } catch (e: any) {
                    if (e.message === INVALID_CLUSTER_TOKEN && authStore.userInfo.identityProvider) {
                        showModal.value = true;
                    }
                }
                this.hide();
            },

            toggle() {
                dropdownOpened.value = !dropdownOpened.value;
            },

            hide() {
                dropdownOpened.value = false;
            },

            onCancel() {
                self.activeCluster = clustersStore.state.clusters.find((cluster: Cluster) => cluster.uuid === currentClusterId.value);
                showModal.value = false
            },
        };

        return self;
    },
});
