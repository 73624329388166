import { reactive } from "vue";
import configService from "@/services/config-service";
import clustersStore from "@/stores/clusters-store";
import authService, { idTokenResponse } from "@/services/auth-service";
import jsonwebtoken from "jsonwebtoken";
import { storageService } from "@/core-ui/data-grid/services";
import apiClient from "../services/api-client";
import tenantStore from "./tenantStore";
import oidcAuthStore from "./oidcAuthStore";
import grafanaStore from "./grafanaStore";
import authStore from "./authStore";
import { ROUTES_ENUM } from "@/router/routes";
import { JOBS_TABLE_FILTER, LOGGED_IN_USER_EMAIL } from "@/cluster-ui/helpers/const";

const MINUTE = 60 * 1000;

const REDIRECT_KEY = "redirect";

export enum LocalKeys {
    token = "token",
    useExternalToken = "useExternalToken",
    accessToken = "access_token",
    externalToken = "externalToken",
    refreshToken = "refreshToken",
}

export enum RolesEnum {
    ADMIN = "admin",
    RESEARCHER = "researcher",
    RESEARCH_MANAGER = "research_manager",
    EDITOR = "editor",
    VIEWER = "viewer",
    ML_ENGINEER = "ml_engineer",
    DEPARTMENT_ADMIN = "department_admin",
}

export const rolesMap = {
    allRoles: [
        RolesEnum.ADMIN,
        RolesEnum.EDITOR,
        RolesEnum.VIEWER,
        RolesEnum.RESEARCHER,
        RolesEnum.RESEARCH_MANAGER,
        RolesEnum.ML_ENGINEER,
        RolesEnum.DEPARTMENT_ADMIN,
    ],
    assetEditRoles: [RolesEnum.DEPARTMENT_ADMIN, RolesEnum.EDITOR, RolesEnum.RESEARCHER, RolesEnum.RESEARCH_MANAGER],
    workspaceEditRoles: [RolesEnum.RESEARCHER, RolesEnum.RESEARCH_MANAGER],
    trainingEditRoles: [RolesEnum.RESEARCHER, RolesEnum.RESEARCH_MANAGER],
};

export interface UserState {
    needToChangePassword: boolean;
    email: string;
    name: string;
    roles: string[];
    entityType?: string;
    identityProvider?: string;
}

export interface AuthStore {
    login(): Promise<void>;
    logout(forceLogout?: boolean): Promise<void>;
    refresh(): Promise<idTokenResponse>;
    isAuthenticated(): Promise<boolean>;
    setToken(token: string, refreshToken: string): Promise<void>;
}

export async function postLogin() {
    setJobsDefaultFilter();
    await clustersStore.fetchClusters(true);
    await clustersStore.setClusterDashboards();
}

function refreshUserInfoPeriodically(authStoreFactory: AuthStoreFactory) {
    setInterval(() => {
        authStoreFactory.refreshUserInfo().catch((e) => {
            console.log(`failed to refresh user info, error: ${e}`);
        });
    }, MINUTE);
}

function setJobsDefaultFilter() {
    const loggedInUserEmail: string | null = localStorage.getItem(LOGGED_IN_USER_EMAIL);
    const userEmail: string = authStore.userInfo.email;

    if (loggedInUserEmail !== userEmail) {
        localStorage.setItem(LOGGED_IN_USER_EMAIL, userEmail);
        localStorage.setItem(JOBS_TABLE_FILTER, userEmail || "");
    }
}

export class AuthStoreFactory {
    userInfo: UserState = reactive({
        email: null,
        name: null,
        needToChangePassword: false,
        roles: [],
        entityType: null,
        identityProvider: null,
    }) as any as UserState;
    authStore!: AuthStore;

    get userNeedsToChangePassword() {
        return this.userInfo.needToChangePassword;
    }

    // currently product definition is that ml_engineer can view everything except jobs, see specific handling of jobs below
    get canViewClusterManagementResources() {
        return (
            this.userInfo?.roles.some(
                (r) =>
                    r === RolesEnum.ADMIN ||
                    r === RolesEnum.VIEWER ||
                    r === RolesEnum.EDITOR ||
                    r === RolesEnum.ML_ENGINEER,
            ) ?? false
        );
    }

    get canViewDepartmentManagementResources() {
        return (
            this.userInfo?.roles.some(
                (r) =>
                    r === RolesEnum.ADMIN ||
                    r === RolesEnum.VIEWER ||
                    r === RolesEnum.EDITOR ||
                    r === RolesEnum.ML_ENGINEER ||
                    r === RolesEnum.DEPARTMENT_ADMIN,
            ) ?? false
        );
    }

    // for explicit role definition and possibly finer grained roles definition
    get canViewDeploymentResources() {
        return this.canViewClusterManagementResources;
    }

    get canViewJobResources() {
        return ((!this.isMLEngineer && this.canViewClusterManagementResources) || this.isResearcher) ?? false;
    }

    get isAdmin() {
        return this.hasRole(RolesEnum.ADMIN);
    }

    get isDepartmentAdmin() {
        return this.hasRole(RolesEnum.DEPARTMENT_ADMIN);
    }

    get isResearcher() {
        return this.hasRole(RolesEnum.RESEARCHER) || this.hasRole(RolesEnum.RESEARCH_MANAGER);
    }

    get isOnlyResearcherOrResearcherManager() {
        const allowedRoles = [RolesEnum.RESEARCHER, RolesEnum.RESEARCH_MANAGER];
        return this.userInfo.roles.every((role) => allowedRoles.includes(<RolesEnum>role));
    }

    get isOnlyResearcherOrResearcherManagerOrMlEngineer() {
        const allowedRoles = [RolesEnum.RESEARCHER, RolesEnum.RESEARCH_MANAGER, RolesEnum.ML_ENGINEER];
        return this.userInfo.roles.every((role) => allowedRoles.includes(<RolesEnum>role));
    }

    get isMLEngineer() {
        return this.hasRole(RolesEnum.ML_ENGINEER);
    }

    get isOnlyMLEngineer() {
        return this.userInfo.roles.every((role) => role === RolesEnum.ML_ENGINEER);
    }

    hasRole(role: RolesEnum) {
        return this.userInfo?.roles.some((r) => r === role) ?? false;
    }

    get userEmail() {
        if (this.userInfo.name && this.userInfo.name != "") {
            return this.userInfo.name;
        }
        return this.userInfo.email || null;
    }

    async initialized(): Promise<boolean> {
        return Promise.resolve(this.authStore != undefined);
    }

    async refreshUserInfo() {
        const authUser = await authService.getUserInfo();
        this.userInfo.roles = authUser.roles;
    }

    private setUserData(
        email: string,
        name: string,
        needToChangePassword: boolean,
        roles: string[],
        entityType?: string,
        identityProvider?: string,
    ) {
        this.userInfo.email = email;
        this.userInfo.name = name;
        this.userInfo.needToChangePassword = needToChangePassword;
        this.userInfo.roles = roles;
        this.userInfo.entityType = entityType;
        this.userInfo.identityProvider = identityProvider;
    }

    async setUserInfo(token: string) {
        const tokenPayload = jsonwebtoken.decode(token) as any;
        const { roles, needToChangePassword, entityType } = await authService.getUserInfo();
        this.userInfo.needToChangePassword = needToChangePassword;
        this.userInfo.roles = roles;
        this.setUserData(
            tokenPayload.email,
            tokenPayload.name,
            needToChangePassword,
            roles,
            entityType,
            tokenPayload.identity_provider,
        );
    }

    async logout(forceLogout: boolean = false) {
        authService.clearCache();
        this.userInfo.email = null as any;
        this.userInfo.name = null as any;
        this.userInfo.roles = [];
        apiClient.setTokens("");
        storageService.clearKeys([
            LocalKeys.token,
            LocalKeys.accessToken,
            LocalKeys.refreshToken,
            LocalKeys.externalToken,
        ]);
        await this.authStore.logout(forceLogout);
    }

    async login(redirect: string) {
        storageService.setStr(REDIRECT_KEY, redirect);
        await this.authStore.login();
    }

    async storeTokensAndGetRedirect(idToken: string, externalToken: string, refreshToken: string) {
        const tokenPayload = jsonwebtoken.decode(idToken) as any;
        if (!("email" in tokenPayload)) {
            return "/no-sso-email-mapper";
        }

        await this.storeTokens(idToken, externalToken, refreshToken);
        await postLogin();
        await this.setUserInfo(idToken);

        if (this.isOnlyMLEngineer) {
            return ROUTES_ENUM.DEPLOYMENTS;
        } else if (this.isOnlyResearcherOrResearcherManager || this.isOnlyResearcherOrResearcherManagerOrMlEngineer) {
            return ROUTES_ENUM.JOBS;
        }

        const redirectAfterLogin = storageService.getStr(REDIRECT_KEY);
        storageService.clearKey(REDIRECT_KEY);

        return redirectAfterLogin;
    }

    async storeTokens(idToken: string, externalToken: string, refreshToken: string) {
        AuthStoreFactory.setTokensInStorage(idToken, refreshToken, externalToken);
        if (idToken != "") {
            apiClient.setTokens(idToken, externalToken);
        }
        await tenantStore.readTenantData();
        await this.authStore.setToken(idToken, refreshToken);
        await this.setUserInfo(idToken);
        // TODO: remove this once understand when and how to refresh cookies normally for smg users
        if (tenantStore.state.smgEnabled) {
            await grafanaStore.smgLogin().catch((e) => console.error(`smg login error ${e}`));
        }
    }

    public setUseExternalTokenInStorage(value: boolean) {
        storageService.setBool(LocalKeys.useExternalToken, value);
    }

    public getUseExternalTokenInStorage(): boolean {
        return storageService.getBool(LocalKeys.useExternalToken);
    }

    private static setTokensInStorage(idToken: string, refreshToken: string, externalToken: string) {
        storageService.setStr(LocalKeys.token, idToken);
        storageService.setStr(LocalKeys.accessToken, idToken);
        storageService.setStr(LocalKeys.refreshToken, refreshToken);
        if (externalToken) {
            storageService.setStr(LocalKeys.externalToken, externalToken);
        }
    }

    private async readFromLocalStorage(): Promise<boolean> {
        const token = storageService.getStr(LocalKeys.token, null);
        if (!token) {
            return false;
        }
        const refreshToken = storageService.getStr(LocalKeys.refreshToken, null);
        if (!refreshToken) {
            console.log("refresh token is missing");
        }

        const tokenPayload = jsonwebtoken.decode(token);
        if (!tokenPayload) {
            return false;
        }
        const externalToken = storageService.getStr(LocalKeys.externalToken, null);
        if (token != "") {
            apiClient.setTokens(token, externalToken);
            await this.authStore.setToken(token, refreshToken);
        }
        await this.setUserInfo(token);
        await tenantStore.readTenantData();
        return true;
    }

    async refreshTokenOnce() {
        try {
            let tokenSet = await this.authStore.refresh();
            await this.storeTokens(tokenSet.idToken, tokenSet.externalToken, tokenSet.refreshToken);
        } catch (e) {
            console.log(`Failed to refresh token, loging out the user: ${e}`);
            await this.authStore.logout();
        }
    }

    async init() {
        if (configService.config.tenantName != undefined) {
            const config = configService.config;

            this.authStore = oidcAuthStore;
            await oidcAuthStore.init(config.authURL, config.authClientID, config.authRealm);

            const foundToken = await this.readFromLocalStorage();
            if (!foundToken) {
                return;
            }
            await postLogin();
            refreshUserInfoPeriodically(this);
        }
    }
}

// We want to refresh the token X seconds before it expires to prevent 401 errors and unexpected behavior
setInterval(async () => {
    if (!!apiClient.access_token) {
        if (authService.isJwtAboutToExpired(apiClient.access_token)) {
            await authStore.refreshTokenOnce();
        }
    }
}, MINUTE);

export default new AuthStoreFactory();
