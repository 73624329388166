import { onUnmounted } from "vue";

type IntervalInvokerProps = {
    func: (isPuller?: boolean) => any;
    interval?: number;
    stopOnFailure?: boolean;
};

type IntervalInvoker = {
    start(): void;
    stop(): void;
    once(): void;
};

/**
 * InternalInvoker invoke an function each x seconds or only once
 *
 * @export
 * @param {IntervalInvokerProps} props
 * @returns {IntervalInvoker}
 */
export function useIntervalInvoker(props: IntervalInvokerProps): IntervalInvoker {
    let intervalNum: number | null = null;
    let activeInvokeKey = 0;

    function start() {
        stop();
        invoke();
    }

    function stop() {
        clearTimeout(intervalNum as number);
        // up the active to make the last invoke inactive
        activeInvokeKey++;
        intervalNum = null;
    }

    function next() {
        intervalNum = setTimeout(() => invoke(false, true), props.interval) as any;
    }

    function once() {
        stop();
        invoke(true);
    }

    async function invoke(once?: boolean, isInterval?: boolean) {
        let isFailed = false;
        const currentInvokeKey = !once ? ++activeInvokeKey : -1;
        try {
            await props.func(isInterval);
        } catch (e) {
            isFailed = true;
        } finally {
            // call next only when this invoke is active and the fetch not failed and config to stop on failure
            if ((!isFailed || !props.stopOnFailure) && currentInvokeKey == activeInvokeKey) {
                next();
            }
        }
    }

    onUnmounted(() => {
        stop();
    });

    return {
        start,
        stop,
        once,
    };
}
