import moment from "moment";
import { stringifyTimePeriod, toTimePeriod } from "./time-period";

const EMPTY = "-";
moment.locale(window.navigator.language);

function isInvalidDate(value: number | string | Date | undefined) {
    return typeof value === "undefined" || value === null || (typeof value === "number" && Number.isNaN(value));
}

function doubleDigit(num: number) {
    if (num > 9) {
        return num.toString();
    }
    return "0" + num;
}

export function camalize(str: string) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function durationFormat2(allMilliSeconds: number | undefined) {
    if (typeof allMilliSeconds === "undefined") {
        return EMPTY;
    }
    return stringifyTimePeriod(toTimePeriod(allMilliSeconds / 1000));
}

export function durationFormat(allMilliSeconds: number | undefined) {
    if (typeof allMilliSeconds === "undefined") {
        return EMPTY;
    }
    const allSeconds = allMilliSeconds / 1000;
    const seconds = Math.floor(allSeconds % 60);
    const allMinutes = allSeconds / 60;
    const minutes = Math.floor(allMinutes % 60);
    const hours = Math.floor(allMinutes / 60);
    return `${doubleDigit(hours)}:${doubleDigit(minutes)}:${doubleDigit(seconds)}`;
}

export function limitDigitsNumber(digitLimit: number, prefix = "") {
    return (n: number | string): string => {
        if (n === "-1" || n === -1 || n === "NA") {
            return "N/A";
        }
        const p = Number.parseFloat(n as any);
        return Number.isNaN(p) ? (n as string) || EMPTY : p.toFixed(digitLimit) + prefix;
    };
}

export function idleGpuDurationFormat(allSeconds: number | undefined) {
    if (typeof allSeconds === "undefined" || allSeconds < 45) {
        return EMPTY;
    }

    const seconds = Math.floor(allSeconds % 60);
    const allMinutes = allSeconds / 60;
    const minutes = Math.floor(allMinutes % 60);
    const hours = Math.floor(allMinutes / 60);
    return `${doubleDigit(hours)}:${doubleDigit(minutes)}:${doubleDigit(seconds)}`;
}

export function dateFormat(value: number | string | Date | undefined) {
    if (isInvalidDate(value)) {
        return EMPTY;
    }
    return moment(value).format("l, LT");
}

/**
 * get numeric1 value or '-' for nulls
 * @param num
 * @return string of integer for integer, double precision for floats, or '-' for falsy value
 */
export const numeric = limitDigitsNumber(2);

export function defValue(func: (val: any, row: Record<string, any>) => any, def = "-") {
    return (val: any, row: any) => {
        if (val == 0 || val == undefined || val == null) {
            return def;
        }
        return func(val, row);
    };
}

export const percentages = limitDigitsNumber(1, "%");

export function inPercentages(num: number, total: number): number | undefined {
    if (!Number.isNaN(total) && !Number.isNaN(num) && num > 0 && total > 0) {
        return (100 * num) / total;
    }
}

export function inPercentagesTo(key: string, translate?: (val: any, row: any) => any) {
    return (val: any, row: any) => {
        if (val == "-1" || val == "N/A") {
            return "N/A";
        }
        const total = Number(row[key]);
        const num = Number(val);
        let p: any = inPercentages(num, total) ?? "";
        if (p) {
            p = ` (${percentages(p)})`;
        }
        return `${translate ? translate(val, row) : val}${p}`;
    };
}
