import { loadView } from "./utils";
import { ROUTES_ENUM } from "./routes";
import FullPageErrors from "@/views/FullPageErrors.vue";
import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";

const children = [
    {
        path: "no-roles",
        name: ROUTES_ENUM.NO_ROLES,
        meta: {},
        component: loadView("NoRolesError"),
    },
    {
        path: "no-sso-email-mapper",
        name: ROUTES_ENUM.NO_SSO_EMAIL_MAPPER,
        meta: {},
        component: loadView("NoSsoEmailMapperError"),
    },
];

const router: RouteRecordRaw = {
    path: "/",
    component: FullPageErrors,
    redirect: "/login",
    children,
};
export default router;
