import { ref, computed } from "vue";

function useConnectionStatusNotification() {
    const message = ref<string>("");

    return function() {
        const errorMessage = computed(() => message.value);
      
        const setConnectionErrorMessage = (msg: string) => {
            message.value = msg;
        };
      
        return {
            errorMessage,
            setConnectionErrorMessage,
        };

    }
}

export default useConnectionStatusNotification()