export type SyncRunner = {
    run(): void;
    isRun: boolean;
};

// Wrap async function in a SyncRunner. will allow to run it only once at given time no matter how many time it called.
// TODO: rename to a better name
export function useSyncRunner(runFunction: (needToRunAgainFunc: () => boolean) => any): SyncRunner {
    let isRun = false;
    let needToRunAgain = false;

    async function run() {
        if (isRun) {
            needToRunAgain = true;
            return;
        }
        isRun = true;
        try {
            await runFunction(() => needToRunAgain);
        } finally {
            isRun = false;
            if (needToRunAgain) {
                needToRunAgain = false;
                run();
            }
        }
    }

    return {
        run,
        get isRun() {
            return isRun;
        },
    };
}
