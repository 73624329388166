
import { defineComponent } from "vue";
import type { PropType } from "vue";
import { clusterApi } from "../../services/apiClient";
import authStore from "@/stores/authStore";

const OPEN_READY_STATE = 1;

export default defineComponent({
    props: {
        pods: {
            type: Array as PropType<string[]>,
            required: true,
        },
        project: {
            type: String,
            required: true,
        },
        tail: {
            type: Number,
            required: false,
        },
        name: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            currentPod: "" as string,
            remainderLine: "" as string,
            lines: [] as Array<string>,
            socket: null as WebSocket | null,
            elLogsContainer: {} as HTMLElement,
            scrollPaused: false,
            lastScrollTop: 0,
        };
    },
    created() {
        this.currentPod = this.pods?.length && this.pods[0] || ''
    },
    mounted() {
        this.elLogsContainer = this.$refs["logs-container"] as HTMLElement;
    },
    methods: {
        selectedPodChanged() {
            this.$emit("pod-selected", this.currentPod);
            this.lines = [];
            this.resumeLatestLogs();
            this.initSocket();
            (this.socket as WebSocket).onopen = () => {
                let request = {
                    project: this.project,
                    jobName: this.name,
                    podName: this.currentPod,
                    tail: this.tail ? this.tail : null,
                };
                if (this.socket && this.socket.readyState !== OPEN_READY_STATE) return;
                (this.socket as WebSocket).send(JSON.stringify(request));
            };
        },
        onError(event: Event): void {
            console.error(event);
        },
        appendLog(event: MessageEvent): void {
            let logs: string[] = event.data.split("\n");
            if(!logs?.length) return;
            logs[0] = this.remainderLine + logs[0];
            this.remainderLine = logs[logs.length - 1];
            logs = logs.slice(0, logs.length - 1);
            this.lines = [...this.lines, ...logs];
            if (!this.scrollPaused) {
                this.autoScroll();
            }
        },
        initSocket() {
            this.closeCurrentSocket();
            const baseUri = clusterApi.baseUrl;
            baseUri.protocol = baseUri.protocol === "https:" ? "wss" : "ws";
            baseUri.pathname += baseUri.pathname.endsWith("/") ? "api/v1/logs" : "/api/v1/logs";
            const logs_url = baseUri.toString();
            if (authStore.getUseExternalTokenInStorage()) {
                this.socket = new WebSocket(logs_url, ["bearer", clusterApi.externalToken]);
            } else if (clusterApi.jwt) {
                this.socket = new WebSocket(logs_url, ["bearer", clusterApi.jwt]);
            } else {
                this.socket = new WebSocket(logs_url);
            }
            this.socket.onmessage = this.appendLog;
            this.socket.onerror = this.onError;
        },
        autoScroll() {
            this.$nextTick(() => {
                this.elLogsContainer.scrollTop = this.elLogsContainer.scrollHeight;
            });
        },
        toggleAutoScroll() {
            if (this.elLogsContainer.scrollTop > this.lastScrollTop) {
                this.lastScrollTop = this.elLogsContainer.scrollTop;
                return;
            }
            this.lastScrollTop = this.elLogsContainer.scrollTop;
            this.scrollPaused = true;
        },
        resumeLatestLogs() {
            this.lastScrollTop = 0;
            this.scrollPaused = false;
            this.autoScroll();
        },
        closeCurrentSocket() {
            if (this.socket && this.socket.readyState === OPEN_READY_STATE) {
                this.socket.close();
            }
        },
    },
    unmounted() {
        this.closeCurrentSocket();
    },
    watch: {
        tail: {
            handler() {
                this.selectedPodChanged();
            },
            deep: true,
        },
        pods: {
            handler() {
                this.selectedPodChanged();
            },
            immediate: true,
            deep: true,
        },
    },
});
