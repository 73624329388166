import { AxiosError } from "axios";

export type ClusterConnectionError = {
    message: string;
    details?: string;
};

const DefaultClusterConnectionErrorMessage =
    "" +
    "<div>" +
    "Invalid server configuration " +
    '(read more about <a href="https://docs.run.ai/admin/runai-setup/authentication/researcher-authentication/#mandatory-kubernetes-configuration">the required server configuration for researcher authentication</a>).' +
    "</div>";

export function buildClusterConnectionError(err?: AxiosError): ClusterConnectionError {
    const clusterConnectionErrorData = err?.response?.data as ClusterConnectionError | undefined;
    if (err && (err.message || clusterConnectionErrorData?.message)) {
        return {
            message: clusterConnectionErrorData?.message || err.message,
            details: clusterConnectionErrorData?.details,
        };
    } else {
        return {
            message: DefaultClusterConnectionErrorMessage,
            details: "",
        };
    }
}
