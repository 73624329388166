
import BoxInput from "@/core-ui/forms/components/BoxInput.vue";
import { useFormCtrl } from "@/core-ui/forms/compositions";
import Modal from "@/core-ui/Modal.vue";
import { defineComponent, ref, watchEffect } from "vue";
import Button from "@/core-ui/Button.vue";
import BoxSelect from "@/core-ui/forms/components/BoxSelect.vue";
import IconButton from "@/core-ui/IconButton.vue";
import projectService from "../../services/project.service";
import * as clipboardy from "clipboardy";
import { isEmpty } from "lodash";
import { Template } from "../../types/template";
import { getCurrentTemplateList } from "../../helpers/commonFunctions";
import { ALL_PROJECTS } from "../../helpers/const";

export default defineComponent({
    components: { Modal, BoxInput, Button, BoxSelect, IconButton },
    props: {
        project: String,
    },

    setup(props, ctx) {
        const formCtrl = useFormCtrl({
            key: "add_template",
            action: (value: any) => {
                ctx.emit("save", { ...value });
            },
        });

        const tempName = ref("");
        const projects = ref([] as any);

        projects.value.push(ALL_PROJECTS);

        projectService.get().then((p) => {
            for (let project in p) {
                //  for all project we list all projects, for specific project only the selected one
                if (!props.project || props.project == p[project].name) {
                    projects.value.push(p[project].name);
                }

                //  for specific project - make it selected
                if (props.project && props.project == p[project].name) {
                    const projectCtrl = formCtrl.children.get("project") as any;
                    if (projectCtrl) {
                        projectCtrl.value = p[project].name;
                    }
                }
            }
        });

        const checkIfTemplateNameAlreadyExistInProject = (name: string, project: string): boolean => {
            const templateList = getCurrentTemplateList();
            if (isEmpty(templateList)) return false;
            return (templateList as Template[])
                .map((item: any) => {
                    return item.name + "_" + item.project;
                })
                .includes(name + "_" + project);
        };

        watchEffect(() => {
            const project = (formCtrl.value as any).project;
            const nameCtrl = formCtrl.children.get("name");
            if (project && nameCtrl && project.value != ALL_PROJECTS) {
                if (checkIfTemplateNameAlreadyExistInProject(nameCtrl.value as string, project)) {
                    nameCtrl.context.error = `Name already exists as a template on project ${project}`;
                } else {
                    nameCtrl.context.error = "";
                }
            }
        });

        function copyCommand() {
            let unixCommandDiv = document.getElementById("unixCommand");
            if (unixCommandDiv?.innerText) {
                clipboardy.write(unixCommandDiv?.innerText);
            }
        }

        return {
            formCtrl,
            projects,
            tempName,
            copyCommand,
            cancel() {
                ctx.emit("cancel");
            },
        };
    },
});
