
import Button from "@/core-ui/Button.vue";
import Modal from "@/core-ui/Modal.vue";
import Card from "@/core-ui/Card.vue";
import FieldsInCategories from "@/core-ui/forms/components/FieldsInCategories.vue";
import Fields from "@/core-ui/forms/components/Fields.vue";
import router from "@/router";
import { useFormCtrl, ArrayCtrl, FormCtrl } from "@/core-ui/forms/compositions";
import { InferenceJob, InteractiveJob, TrainingJob, usageOptions } from "../../helpers/const";
import { extractDefaultSettingsAndValueFromUrl, useSubmitFieldsLoader } from "./useSubmitFieldsLoader";
import NotificationWithActions from "@/core-ui/NotificationWithActions.vue";
import { computed, defineComponent, ref, watchEffect, nextTick, watch } from "vue";
import { useToast } from "vue-toastification";
import Loading from "@/core-ui/Loading.vue";
import { storageService } from "@/core-ui/data-grid/services";
import { selectedProjectKey } from "../../compositions/ProjectKey";
import JobPVC from "./JobPVC.vue";
import { createSubmitTemplateDataForWorkLoadController } from "./submitUtil";
import { RepositoriesListResponse, TagsListResponse, Workload, WorkloadError } from "./types";
import { openSaveAsPopUpAndSaveTemplate, useQuickLoad } from "../../compositions/QuickLoad";
import QuickLoad from "./QuickLoad.vue";
import BoxButtonSelect from "@/core-ui/forms/components/BoxButtonSelect.vue";
import WarnModel from "@/core-ui/WarnModel.vue";
import RaMenu from "@/core-ui/RaMenu.vue";
import IconButton from "@/core-ui/IconButton.vue";
import { useDynamicMsg } from "@/core-ui/compositions/DynamicMsg";
import ButtonSelect from "@/core-ui/forms/components/ButtonSelect.vue";
import ProjectState from "./ProjectState.vue";
import Input from "@/core-ui/forms/components/Input.vue";
import JobVolume from "./JobVolume.vue";
import SubmitFormLoadingError from "./SubmitFormLoadingError.vue";
import workloadService from "../../services/workload.service";
import { tooltipMSG } from "../../helpers/const";
import {
    getCurrentSettingsName,
    handelWorkLoadErrors,
    setCurrentSettingsName,
    setTargetProject,
} from "../../helpers/commonFunctions";
import { useWarnModel } from "@/core-ui/compositions/WarnModel";
import { mapJobValue } from "@/cluster-ui/components/submit/jobParamsUtil";
import { mapDeploymentValue } from "@/components/deployments/deploymentParamsUtil";
import settingStore, { SettingKeys } from "@/stores/setting-store";
import jwt_decode from "jwt-decode";
import pvcsService from "@/cluster-ui/services/pvcs.service";
import BoxAutoComplete from "@/core-ui/forms/components/BoxAutoComplete.vue";
import BoxAsyncWithTwoAutoComplete from "@/core-ui/forms/components/BoxAsyncWithTwoAutoComplete.vue";
import registryIntegrationService from "@/services/registry-integration-service";
import debounce from "lodash/debounce";
import {activeClusterConnectionInfoStore, isSubmitParamAvailableForCluster_2_10} from "@/stores/activeClusterConnectionInfoStore";
import wandbService, { IWandbSweepConfig } from "@/services/wandb-service";
import omit from "lodash/omit";
import { makeLowerCaseAlphabeticID } from "@/core-ui/forms/compositions";
import { SWEEP_ID_PLACEHOLDER, SWEEP_COMMAND, DEFAULT_WANDB_BASE_URL } from "../../helpers/wandb.config";
import { ServerError } from "@/types/server-error";

const OVERRIDE_JOB_FROM_VALUES_WARNING_KEY = "OVERRIDE_JOB_FROM_VALUES_WARNING_KEY";

const switchProjectMsg = {
    title: "Switching Project",
    msg: "Switching to another project will clear your changes. <br/><br/>Are you sure you want to switch to another project?",
};

interface SubmitQueryParams {
    JobName: string;
    JobProject: string;
    JobType: string;
}

interface IEnvironment {
    key: string;
    value: string;
}

interface IAdditionalSubmitData {
    command?: string, 
    environment?: Record<string, IEnvironment>, 
    completions?: number | undefined
}

export default defineComponent({
    components: {
        BoxButtonSelect,
        FieldsInCategories,
        Fields,
        Card,
        Button,
        Modal,
        JobPVC,
        JobVolume,
        Loading,
        QuickLoad,
        WarnModel,
        RaMenu,
        IconButton,
        ButtonSelect,
        ProjectState,
        Input,
        SubmitFormLoadingError,
        BoxAutoComplete,
        BoxAsyncWithTwoAutoComplete,
    },
    props: {
        initJobType: String,
    },
    setup(props) {
        let submitQueryParams = ref({} as SubmitQueryParams);
        submitQueryParams.value = extractDefaultSettingsAndValueFromUrl();
        const toast = useToast();
        const model = useDynamicMsg();
        const renderComponent = ref(true);
        const fileDraged = ref(false);
        const isLoading = ref(false);
        const submitting = ref(false);
        const isWorkloadCanceled = ref(false);
        setCurrentSettingsName(submitQueryParams.value.JobType || props.initJobType!);
        const selectedSettings = ref(submitQueryParams.value.JobType || props.initJobType);
        const showWorkspaces = settingStore.state.kv[SettingKeys.ShowWorkspaces];
        setTargetProject("");
        const title = selectedSettings.value == InferenceJob ? "New Deployment" : "New Job";
        const debouncedImageError = debounce(() => toast.error("Couldn’t retrieve image, contact your admin"), 1000);
        const debouncedTagsError = debounce(() => toast.error("Couldn’t retrieve tags, contact your admin"), 1000);
        function getBackURLBySettings(): string {
            if (selectedSettings.value == InferenceJob) {
                return "/deployments";
            } else if (selectedSettings.value == InteractiveJob && showWorkspaces) {
                return "/workspaces";
            } else {
                return "/jobs";
            }
        }
        const backURL = getBackURLBySettings();
        const actionLabel = selectedSettings.value == InferenceJob ? "Deploy" : "Submit";
        const currentProjectReactive = ref<string>("");


        function getFormCtrlBySettings(): FormCtrl {
            if (getCurrentSettingsName() === InferenceJob) {
                return useFormCtrl({
                    key: "submit-deployment",
                    mapValue: mapDeploymentValue,
                    action: onDeploySubmit,
                });
            }
            return useFormCtrl({
                key: "submit-job",
                mapValue: mapJobValue,
                action: onJobSubmit,
            });
        }

        const formCtrl = getFormCtrlBySettings();

        // this used to refresh QuickLoad (templates/prev Jobs) data list
        const refreshQuickLoadData = () => {
            loader.jobs.refreshData();
            loader.templates.refreshData();
        };

        const onProjectInputChange = async (value: any, oldValue: any) => {
            function revertProject() {
                formCtrl.children.get("project")!.value = oldValue;
                currentProjectReactive.value = oldValue;
            }
            try {
                if (isThereUnsavedChanges()) {
                    const shouldChangeProject = await areYouSure.warn(
                        OVERRIDE_JOB_FROM_VALUES_WARNING_KEY,
                        switchProjectMsg,
                    );
                    // User press cancel
                    if (shouldChangeProject && (shouldChangeProject as { isDialogCanceled: boolean })?.isDialogCanceled) {
                        revertProject();
                        return;
                    }
                }
            } catch (e) {
                revertProject();
                return;
            }

            try {
                await fieldsLoader.loadTemplate("", "", value, true);
                currentProjectReactive.value = value;
                refreshQuickLoadData();
            } catch (e) {
                const err = handelWorkLoadErrors(e as WorkloadError);
                if (err) {
                    toast.error(`Something went wrong! , error: ${err}`);
                    return;
                }
            }
            await getPvcsOptions(value || (submitQueryParams.value.JobProject as string));
        };
        const areYouSure = useWarnModel();

        const forceRerender = () => {
            // remove the my-component component from the DOM
            self.renderComponent.value = false;

            nextTick(() => {
                // add my-component component in DOM
                self.renderComponent.value = true;
            });
        };

        const fieldsLoader = useSubmitFieldsLoader({
            formCtrl,
            isThereUnsavedChanges,
            forceUpdate: forceRerender,
            onInputChange: onProjectInputChange,
        });

        const initForm = (params?: SubmitQueryParams) =>
            fieldsLoader.init().then(() => {
                if (params) {
                    if (params.JobName && params.JobProject && params.JobType) {
                        fieldsLoader
                            .loadTemplate(null, params.JobName, params.JobProject, true, params.JobType)
                            .then(() => {
                                currentProjectReactive.value = params.JobProject;
                                refreshQuickLoadData();
                                self.submitQueryParams.value = {} as SubmitQueryParams;
                            });
                    }
                }

                // this line add to make project error run at the first render of the screen
                // this may make the users start with fill the project field before
                if (formCtrl.children.get("project")!.value == undefined) {
                    formCtrl.children.get("project")!.value = undefined;
                }
            });

        const actionText = (text: string, name?: string) => ({
            type: "action",
            name,
            text,
        });

        async function getPvcsOptions(projectName: string) {
            try {
                const selectedProject = storageService.getStr(selectedProjectKey);
                await pvcsService.restPvcsListOptions(projectName || selectedProject);
            } catch (e) {
                // this is a workaround for clusters that don't have pvcs endpoint on the researcherapi
            }
        }

        async function updateCurrentTemplate(templateName: string, project: string) {
            const selectedProject = storageService.getStr(selectedProjectKey);
            const formData: Record<string, any> = formCtrl.state.changedValue;
            const dataToSubmit = formData && formData.image ? { ...formData, image: formData.image.trim() } : formData;
            return await createSubmitTemplateDataForWorkLoadController(
                dataToSubmit,
                project || selectedProject,
                templateName,
                usageOptions.Template,
                false
            );
        }

        async function createNewTemplate(templateName: string, project: string) {
            const selectedProject = storageService.getStr(selectedProjectKey);
            const formData: Record<string, any> = formCtrl.state.changedValue;
            const dataToSubmit = formData && formData.image ? { ...formData, image: formData.image.trim() } : formData;
            return await createSubmitTemplateDataForWorkLoadController(
                dataToSubmit,
                project || selectedProject,
                templateName,
                usageOptions.Template,
                false
            );
        }

        async function repositoriesSearchQuery(query: string): Promise<string[]> {
            if (query.length < 3) return Promise.resolve([]);
            try {
                const repositories: RepositoriesListResponse = await registryIntegrationService.getRepositories(query);
                return repositories.repositories.sort();
            } catch (e) {
                debouncedImageError();
                console.error(e);
                return Promise.resolve([]);
            }
        }

        async function tagsSearchQuery(repository: string, query: string): Promise<string[]> {
            try {
                const tags: TagsListResponse = await registryIntegrationService.getTags(repository, query);
                return tags.tags;
            } catch (e) {
                debouncedTagsError();
                console.error(e);
                return Promise.resolve([]);
            }
        }

        async function onDeploySubmit() {
            await onSubmit("/deployments", (name: string) => `${name} successfully deployed`);
        }

        function getWandbCommand(formData: Record<string, any>, sweepId: string): string {
            let command = formData.command.replace(SWEEP_ID_PLACEHOLDER, "");
            if (formData.wandbSweepConfig.wandbCount) {
                command = `${command} --count ${formData.wandbSweepConfig.wandbCount}`;
            }

            command = `${command} ${sweepId}`;
            return command;
        }

        function getWandbEnv(formData: Record<string, any>, baseUrl: string): Record<string, any> {
            return {
                [makeLowerCaseAlphabeticID()]: { key: "WANDB_API_KEY", value: formData.wandbSweepConfig.wandbApiKey },
                [makeLowerCaseAlphabeticID()]: { key: "WANDB_BASE_URL", value: baseUrl }
            }
        }

        async function updateSweepId() {
            const MISSING_PARAMETERS_MESSAGE = "Missing required parameters for wandb"
            try {
                const formData: Record<string, any> = formCtrl.state.changedValue;

                if (!formData.wandbSweepConfig || !formData.wandbSweepConfig.wandbEntity || !formData.wandbSweepConfig.yaml || !formData.wandbSweepConfig.wandbApiKey) {
                    throw new Error(MISSING_PARAMETERS_MESSAGE);
                }
                
                const config = JSON.parse(formData.wandbSweepConfig.yaml);
                const baseUrl = settingStore.state.kv[SettingKeys.WandbHost] || DEFAULT_WANDB_BASE_URL;
                const sweepRequest: IWandbSweepConfig = {
                    config,
                    entity: formData.wandbSweepConfig.wandbEntity,
                    project: formData.wandbSweepConfig.wandbProject || "uncategorized",
                    apiKey: formData.wandbSweepConfig.wandbApiKey,
                    baseUrl
                };
                const res = await wandbService.getSweepId(sweepRequest);
                const sweepSubmitData: IAdditionalSubmitData = {
                    command: getWandbCommand(formData, res.sweepId),
                    environment: getWandbEnv(formData, baseUrl),
                    completions: formData.parallelism
                }

                return sweepSubmitData;
            }
            catch (error: unknown) {
                submitting.value = false;
                console.error(error);
                if (error instanceof Error && MISSING_PARAMETERS_MESSAGE === error.message) {
                    toast.error(MISSING_PARAMETERS_MESSAGE);
                } else if (error instanceof ServerError) {
                    toast.error(error.message);
                } else {
                    toast.error("Sweep creation failed");
                }
                return Promise.reject();
            }
        }

        async function onJobSubmit() {
            submitting.value = true;
            const formData: Record<string, any> = formCtrl.state.changedValue;
            let sweepSubmitData: IAdditionalSubmitData | undefined;
            // this code related to the wandb sweep feature.
            // currently we don't support templates/ clone when using the sweep
            // I added a check if formData is not undefiend (ex: when cloning without any change)
            if (formData?.wandbSweepFeature && formData?.wandbSweepConfig) {
                sweepSubmitData = await updateSweepId();
            }

            await onSubmit("/jobs", (name: string) => `Job ${name} submitted successfully`, sweepSubmitData);
        }

        async function onSubmit(backURL: string, successMsg: any, additionalSubmitData?: IAdditionalSubmitData | undefined) {
            try {
                const selectedProject = formCtrl.value.project;
                const formData: Record<string, any> = formCtrl.state.changedValue;
                let dataToSubmit = formData && formData.image ? { ...formData, image: formData.image.trim() } : formData;
                dataToSubmit = omit(dataToSubmit, ["wandbSweepConfig", "wandbSweepFeature"])
                if (additionalSubmitData?.command) {
                    dataToSubmit.command = additionalSubmitData.command;
                }

                if (additionalSubmitData?.environment) {
                    dataToSubmit.environment = {...(dataToSubmit.environment || {}), ...additionalSubmitData.environment};
                }

                if (additionalSubmitData?.hasOwnProperty("completions")) {
                    if (additionalSubmitData.completions) {
                        dataToSubmit.completions = additionalSubmitData.completions;
                    } else {
                        dataToSubmit = omit(dataToSubmit, ["completions"])
                    }
                }

                const mpiCtrl = formCtrl.children.get("mpi");

                let data: Workload;
                // build data to be submitted
                const EnableLegacyMpiFeatureFlag = settingStore.state?.kv[SettingKeys.EnableLegacyMpi];
                if (EnableLegacyMpiFeatureFlag && mpiCtrl && mpiCtrl.value) {
                    // in case of MPIJob is true we will create DistributedWorkload
                    // including the TrainingWorkload spec even if it's from policy level
                    data = await createSubmitTemplateDataForWorkLoadController(
                        dataToSubmit,
                        selectedProject,
                        "",
                        usageOptions.Submit,
                        true,
                    );
                } else {
                    data = await createSubmitTemplateDataForWorkLoadController(
                        dataToSubmit,
                        selectedProject,
                        "",
                        usageOptions.Submit,
                        false
                    );
                }

                let response
                if (EnableLegacyMpiFeatureFlag && data.kind == "TrainingWorkload" && data.spec && data.spec.mpi && data.spec.mpi.value){
                    const cData = JSON.parse(JSON.stringify(data));

                    // update kind
                    cData.kind = "DistributedWorkload"

                    // clean mpi
                    cData.spec.jobType= "MPIJob"
                    delete cData.spec.mpi.value

                    // update workers
                    cData.spec.workers = cData.spec.processes
                    delete cData.spec.processes

                    // update slotsPerWorker
                    cData.spec.mpiJob = {values: {slotsPerWorker : cData.spec.slotsPerWorker}}
                    delete cData.spec.slotsPerWorker

                    response = await workloadService.submitWorkLoad(selectedProject, cData, "Distributed");
                } else {
                    response = await workloadService.submitWorkLoad(selectedProject, data, getCurrentSettingsName());
                }

                // The key should be unique so we add a random string to it. When the key isn't unique, fast subsequent submissions fail.
                const key = `add_or_save_job_as_template_${Math.random().toString(36).substr(2, 5)}`;
                const messageArgs: any = {
                    msg: [successMsg(response.data.spec.name?.value)],
                    onCancel: () => {
                        model.remove(key);
                    },
                };

                messageArgs.msg = [...messageArgs.msg, ". ", actionText("Add", "add"), " as a new template"];

                messageArgs.onSave = async () => {
                    model.remove(key);
                };

                messageArgs.onAdd = async () => {
                    try {
                        await openSaveAsPopUpAndSaveTemplate({
                            model,
                            toast,
                            project: selectedProject,
                            jobToSave: data,
                        });
                    } catch (e) {
                        console.error(e);
                    }
                    model.remove(key);
                };
                model.add(key, NotificationWithActions, messageArgs, 15000);

                await router.push(backURL);
            } catch (error: any) {
                submitting.value = false;
                const toastErrorMsg = handelWorkLoadErrors(error as WorkloadError);
                toast.error(toastErrorMsg);
                return Promise.reject();
            }
        }

        const loader = useQuickLoad({
            get project() {
                return currentProjectReactive.value;
            },
            load: fieldsLoader.loadTemplate,
            get loading() {
                return fieldsLoader.isTemplateLoading;
            },
            get currentValue() {
                return formCtrl.state.changedValue;
            },
        });

        function isThereUnsavedChanges(): boolean {
            const changes: any = formCtrl.state.changedValue;
            const isNotDirty =
                !formCtrl.dirty ||
                (Object.keys(changes).length == 1 && (changes["project"] || changes["project"] == ""));
            return !isNotDirty;
        }

        function isNodePoolsExistInCluster() {
            let nodePoolExistInCluster = false;
            let nodePoolsExistInCluster = false;
            if (!fieldsLoader.settings) {
                return [nodePoolExistInCluster, nodePoolsExistInCluster] as const;
            }

            for (const settings of fieldsLoader.settings) {
                if (settings.fields?.nodePool) {
                    nodePoolExistInCluster = true;
                }
                if (settings.fields?.nodePools) {
                    nodePoolsExistInCluster = true;
                }
            }

            return [nodePoolExistInCluster, nodePoolsExistInCluster] as const;
        }

        // hide nodePool/nodePools using SettingKeys.EnableNodePools value
        watch(
            () => formCtrl.children.get("nodePools") && formCtrl.children.get("nodePool"),
            async () => {
                // "nodePool" is the old field that exists in clusters 2.8+,
                // "nodePools" is the new field that exists in clusters 2.9+.
                const [nodePoolExistInCluster, nodePoolsExistInCluster] = isNodePoolsExistInCluster();

                const showNodePools = settingStore.state.kv[SettingKeys.EnableNodePools];
                const nodePool = formCtrl.children.get("nodePool");
                const nodePools = formCtrl.children.get("nodePools");
                if (nodePool) {
                    // don't show old (single) nodePool field, if new multi-nodePools exist in cluster
                    if (nodePoolsExistInCluster || !showNodePools) {
                        nodePool.context.hidden = true;
                    } else {
                        nodePool.context.hidden = !nodePoolExistInCluster;
                    }
                }
                if (nodePools) {
                    nodePools.context.hidden = !nodePoolsExistInCluster || !showNodePools;
                }
            },
        );

        // hide podAffinity for cluster under 2.10.0
        watch(
            () => formCtrl.children.get("podAffinity"),
            async () => {
                const podAffinityCtrl = formCtrl.children.get("podAffinity");
                if (!podAffinityCtrl) {
                    return;
                }

                const selectedClusterVersion = activeClusterConnectionInfoStore.selectedClusterVersion;
                podAffinityCtrl.context.hidden = !isSubmitParamAvailableForCluster_2_10(selectedClusterVersion);
            },
        );



        //change setting type in storage on ui selection
        watch(
            () => selectedSettings.value,
            async () => {
                // Since the selectedSettings triggered twice after canceling the dialog, here we prevent the second update
                if (isWorkloadCanceled.value) {
                    isWorkloadCanceled.value = false;
                    return;
                }

                if (submitQueryParams.value) {
                    await getPvcsOptions(submitQueryParams.value.JobProject as string);
                }

                setCurrentSettingsName(selectedSettings.value!);
                const shouldFetchWorkload = await fieldsLoader.fetchWorkload(
                    "",
                    formCtrl.children.get("project")?.value,
                    false,
                    "",
                );
                if (shouldFetchWorkload) {
                    await initForm(submitQueryParams.value);
                    if (!submitQueryParams.value.JobProject) {
                        currentProjectReactive.value = "";
                        refreshQuickLoadData();
                    }
                } else {
                    isWorkloadCanceled.value = true;
                    selectedSettings.value = selectedSettings.value === TrainingJob ? InteractiveJob : TrainingJob;
                    setCurrentSettingsName(selectedSettings.value);
                }
            },
            { immediate: true },
        );

        let isLoginWithSSO = settingStore.state.kv[SettingKeys.LoginWithSSO];
        // update the port according to the service type
        watchEffect(() => {
            if (!fieldsLoader.ready) {
                return;
            }
            const serviceTypeCtrl = formCtrl.children.get("serviceType");
            const portsCtrl = formCtrl.children.get("ports");
            if (portsCtrl && serviceTypeCtrl) {
                const current = serviceTypeCtrl.value;
                if (current) {
                    portsCtrl.context.hidden = false;
                    if ((portsCtrl.value as Array<any>).length == 0) {
                        (portsCtrl as ArrayCtrl<any>).addItemCtrlArgs();
                    } else {
                        for (const itemCtrl of (portsCtrl as ArrayCtrl<any>).children.values()) {
                            itemCtrl.context.serviceType = current;
                        }
                    }
                } else {
                    (portsCtrl as ArrayCtrl<any>).clear();
                    portsCtrl.context.hidden = true;
                }
            }
        });

        // update project
        watchEffect(() => {
            if (!fieldsLoader.ready) {
                return;
            }
            const selectedProject = storageService.getStr(selectedProjectKey);
            const ctrl = formCtrl.children.get("project");
            if (ctrl && selectedProject) {
                if (ctrl.value !== selectedProject) {
                    ctrl.context.editable = undefined;
                    ctrl.value = selectedProject;
                }
                ctrl.context.editable = false;
            } else if (ctrl) {
                ctrl.context.editable = true;
            }
        });

        // update job name as disabled when job name set
        watchEffect(() => {
            if (!fieldsLoader.ready) {
                return;
            }
            const ctrl = formCtrl.children.get("jobNamePrefix");
            if ((formCtrl.value as any).name && ctrl) {
                if ((formCtrl.value as any).jobNamePrefix) {
                    (ctrl as any).context.editable = undefined;
                    (formCtrl.value as any).jobNamePrefix = undefined;
                }
                (ctrl as any).context.editable = false;
            } else if (ctrl) {
                (ctrl as any).context.editable = true;
            }
        });

        // update job name validation according to job list
        watchEffect(() => {
            if (!fieldsLoader.ready) {
                return;
            }
            const selectedProject = formCtrl.value.project;
            let nameField = formCtrl.children.get("name");
            if (nameField) {
                const jobName = nameField.value;
                nameField.context.error = loader.jobs.data
                    .filter((j) => j.project == selectedProject)
                    .some((j) => j.originalName == jobName)
                    ? "Job name already exists in project"
                    : "";
            }
        });

        // update mpi paramanters (process)
        watchEffect(() => {
            if (!fieldsLoader.ready) {
                return;
            }
            const mpiCtrl = formCtrl.children.get("mpi");
            const processCtrl = formCtrl.children.get("processes");
            const slotsPerWorkerCtrl = formCtrl.children.get("slotsPerWorker");

            const EnableLegacyMpiFeatureFlag = settingStore.state?.kv[SettingKeys.EnableLegacyMpi];
            if (EnableLegacyMpiFeatureFlag) {
                if (mpiCtrl && processCtrl && slotsPerWorkerCtrl && mpiCtrl.value) {
                    processCtrl.context.hidden = false;
                    slotsPerWorkerCtrl.context.hidden = false;
                } else {
                    if (processCtrl) {
                        processCtrl.context.hidden = true;
                    }
                    if (slotsPerWorkerCtrl) {
                        slotsPerWorkerCtrl.context.hidden = true;
                    }
                }
            } else {
                if (mpiCtrl){
                    mpiCtrl.context.hidden = true;
                }
                if (processCtrl){
                    processCtrl.context.hidden = true;
                }
                if (slotsPerWorkerCtrl){
                    slotsPerWorkerCtrl.context.hidden = true;
                }
            }
        });

        // update podAffinity hidden status
        watchEffect(() => {
            if (!fieldsLoader.ready) {
                return;
            }
            const podAffinityCtrl = formCtrl.children.get("podAffinity");
            const podAffinityTopologyCtrl = formCtrl.children.get("podAffinityTopology");
            const podAffinitySchedulingRuleCtrl = formCtrl.children.get("podAffinitySchedulingRule");

            if (podAffinityCtrl && podAffinityTopologyCtrl && podAffinitySchedulingRuleCtrl && podAffinityCtrl.value) {
                podAffinityTopologyCtrl.context.hidden = false;
                podAffinitySchedulingRuleCtrl.context.hidden = false;
            } else {
                if (podAffinityTopologyCtrl) {
                    podAffinityTopologyCtrl.context.hidden = true;
                }
                if (podAffinitySchedulingRuleCtrl) {
                    podAffinitySchedulingRuleCtrl.context.hidden = true;
                }
            }
        });

        // update field forms depending on job type
        watchEffect(() => {
            if (!fieldsLoader.ready) {
                return;
            }
            // These fields are shown only in interactive
            for (const key of ["jupyter", "tensorboard", "preemptible"]) {
                const ctrl = formCtrl.children.get(key);
                if (ctrl) {
                    ctrl.context.hidden = getCurrentSettingsName() !== InteractiveJob;
                }
            }
            // These fields are hidden in interactive
            for (const key of ["backoffLimit", "parallelism", "completions"]) {
                const ctrl = formCtrl.children.get(key);
                if (ctrl) {
                    ctrl.context.hidden = getCurrentSettingsName() == InteractiveJob;
                }
            }
        });

        const lastEnteredFieldsValues: Record<string, string | number | undefined | unknown> = {
            userCommand: undefined,
            userArguments: undefined,
            completions: undefined
        }

        // update the command and arguments whehn using sweep
        watchEffect(() => {
            const isWandbSweepsFeauturFlagAllowed = settingStore.state?.kv[SettingKeys.EnableWandbSweeps];

            if (!fieldsLoader.ready || !isWandbSweepsFeauturFlagAllowed) {
                return;
            }

            const wandbSweepToggleField = formCtrl.children.get("wandbSweepFeature");
            const wandbSweepConfig = formCtrl.children.get("wandbSweepConfig");
            const commandCtrl = formCtrl.children.get("command");
            const argumentsCtrl = formCtrl.children.get("arguments");
            const completionsCtrl = formCtrl.children.get("completions");
            
            if (wandbSweepToggleField && wandbSweepToggleField.value) {
                if (wandbSweepConfig) {
                    wandbSweepConfig.context.hidden = false;
                    wandbSweepConfig.context.required = true;
                }

                if (commandCtrl) {
                    lastEnteredFieldsValues.userCommand = commandCtrl.value;
                    commandCtrl.value = SWEEP_COMMAND;
                    commandCtrl.context.editable = false;
                }

                if (argumentsCtrl) {
                    lastEnteredFieldsValues.userArguments = argumentsCtrl.value;
                    argumentsCtrl.value = "";
                    argumentsCtrl.context.editable = false;
                }

                if (completionsCtrl) {
                    lastEnteredFieldsValues.completions = completionsCtrl.value;
                    completionsCtrl.value = undefined;
                    completionsCtrl.context.editable = false;
                }
            } else {
                if (wandbSweepConfig) {
                    wandbSweepConfig.context.hidden = true;
                    wandbSweepConfig.context.required = false;
                }

                if (commandCtrl ) {
                    commandCtrl.context.editable = true;
                    if (commandCtrl.value === SWEEP_COMMAND) {
                        commandCtrl.value = lastEnteredFieldsValues.userCommand;
                    }
                }

                if (argumentsCtrl) {
                    argumentsCtrl.context.editable = true;
                    if (!argumentsCtrl.value && lastEnteredFieldsValues.userArguments) { // we want to restor the user values only for first time each time the toggle set top off
                        argumentsCtrl.value = lastEnteredFieldsValues.userArguments;
                        lastEnteredFieldsValues.userArguments = "";
                    }
                }

                if (completionsCtrl) {
                    completionsCtrl.context.editable = true;
                    if (!completionsCtrl.value && lastEnteredFieldsValues.completions) { // we want to restor the user values only for first time each time the toggle set top off
                        completionsCtrl.value = lastEnteredFieldsValues.completions;
                        lastEnteredFieldsValues.completions = undefined;
                    }
                }
            }
        });

        //   runAsUid and runAsGid should appear only if
        //    1) runAsUser is enabled
        //    2) the user does not run in LDAP mode
        //   In LDAP mode the uid and gid are taken directly from the user token, and therefore
        //   he does not require (and should not attempt to) specify them. when we do not have this
        //   information in the token there is no choice but to request the user to type it.
        function getJwtTokenParsed(): { gid: number; uid: number; supplementarygroups: string } | null {
            if (localStorage.token) {
                try {
                    return jwt_decode(localStorage.token);
                } catch (e) {
                    console.error("Failed to decode jwt token", e);
                    return null;
                }
            } else {
                return null;
            }
        }

        watchEffect(() => {
            if (!fieldsLoader.ready) {
                return;
            }
          isLoginWithSSO = settingStore.state.kv[SettingKeys.LoginWithSSO];

            const runAsUser = formCtrl.children.get("runAsUser");
            const uidField = formCtrl.children.get("runAsUid");
            const gidField = formCtrl.children.get("runAsGid");
            const supplementalGroupsField = formCtrl.children.get("supplementalGroups");

            const jwtToken = getJwtTokenParsed();

          if (isLoginWithSSO && runAsUser?.value && jwtToken) {

            let gidValue = jwtToken.gid;
            if (gidField) {
              if (gidValue && Number(gidValue) >= 0) {
                gidField.context.hidden = true;
                gidField.value = Number(gidValue);
              } else {
                gidField.context.hidden = false;
              }
            }

            let uidValue = jwtToken.uid;
            if (uidField) {
              if(uidValue && Number(uidValue) >= 0) {
                uidField.context.hidden = true;
                uidField.value = Number(uidValue);
              } else {
                uidField.context.hidden = false;
              }
            }

            let supplementaryGroupsValue = jwtToken.supplementarygroups;
            if (supplementalGroupsField && supplementaryGroupsValue && String(supplementaryGroupsValue) !== "" && String(supplementaryGroupsValue) !== 'undefined') {
              supplementalGroupsField.context.hidden = true;
              supplementalGroupsField.value = String(jwtToken.supplementarygroups);
            } else if(supplementalGroupsField) {
              // if both uid and gid fields are hidden, we want to hide the supplementalGroupsField as well
              supplementalGroupsField.context.hidden = !!((uidField && uidField.context.hidden) && (gidField && gidField.context.hidden));
            }

          } else {
                const showRusAsUserFields = runAsUser && runAsUser?.value && !fieldsLoader.isLdapMode;

                if (uidField) {
                    // if we show it - it's required
                    uidField.context.hidden = !showRusAsUserFields;
                    uidField.context.required = showRusAsUserFields;
                    if (!showRusAsUserFields && uidField.value !== undefined) {
                        // we don't want them to have "leftover" values
                        uidField.value = undefined;
                    }
                }

                if (gidField) {
                    gidField.context.hidden = !showRusAsUserFields;
                    if (!showRusAsUserFields && gidField.value !== undefined) {
                        // we don't want them to have "leftover" values
                        gidField.value = undefined;
                    }
                }

                if (supplementalGroupsField) {
                    supplementalGroupsField.context.hidden = !showRusAsUserFields;
                    if (!showRusAsUserFields && supplementalGroupsField.value !== undefined) {
                        // we don't want them to have "leftover" values
                        supplementalGroupsField.value = undefined;
                    }
                }
            }
        });

        // show jupyter password if jupyter enabled
        watchEffect(() => {
            if (!fieldsLoader.ready) {
                return;
            }
            const jupyter = formCtrl.children.get("jupyter");
            const notebookToken = formCtrl.children.get("notebookToken");
            const tensorboard = formCtrl.children.get("tensorboard");

            if (notebookToken) {
                notebookToken.context.hidden = !jupyter || !jupyter.value;
                const hideJupyter = !jupyter || !jupyter.value;
                notebookToken!.context.hidden = hideJupyter;
                tensorboard!.context.editable = hideJupyter;
            }
        });

        // show tensorboardLogdir if tensorboard enabled
        watchEffect(() => {
            if (!fieldsLoader.ready) {
                return;
            }
            const tensorboard = formCtrl.children.get("tensorboard");
            const tensorboardLogdir = formCtrl.children.get("tensorboardLogdir");
            const jupyter = formCtrl.children.get("jupyter");
            if (tensorboardLogdir) {
                const hideTensorboardLogdir = !tensorboard || !tensorboard.value;
                tensorboardLogdir.context.hidden = hideTensorboardLogdir;
                jupyter!.context.editable = hideTensorboardLogdir;
            }
        });

        // hide ttl and prefix
        watchEffect(() => {
            for (const field of ["ttlAfterFinish", "jobNamePrefix"]) {
                const ctrl = formCtrl.children.get(field);
                if (ctrl) {
                    ctrl.context.hidden = true;
                }
            }
        });

        // gpu or gpu-memory or mig-profile
        watchEffect(() => {
            const gpuMemoryField = formCtrl.children.get("gpuMemory");
            const gpuField = formCtrl.children.get("gpu");
            const migProfileField = formCtrl.children.get("migProfile");
            const gpuLimitField = formCtrl.children.get("gpuLimit");

            if (!!gpuMemoryField && !!gpuField && !!migProfileField) {
                if (gpuField.value || (!!gpuLimitField && gpuLimitField.value)) {
                    gpuMemoryField.context.editable = false;
                    gpuMemoryField.context.tooltipText = tooltipMSG.gpuMemoryTooltipError;

                    migProfileField.context.editable = false;
                    migProfileField.context.tooltipText = tooltipMSG.migTooltipError;

                    if (!!gpuLimitField && gpuLimitField.value && !gpuField.value) {
                        gpuField.context.tooltipText = tooltipMSG.gpuTooltipMustExist;
                    }
                } else if (gpuMemoryField.value) {
                    gpuField.context.editable = false;
                    gpuField.context.tooltipText = tooltipMSG.gpuTooltipError;

                    migProfileField.context.editable = false;
                    migProfileField.context.tooltipText = tooltipMSG.migTooltipError;

                    if (!!gpuLimitField) {
                        gpuLimitField.context.editable = false;
                        gpuLimitField.context.tooltipText = tooltipMSG.gpuLimitTooltipError;
                    }
                } else if (migProfileField.value) {
                    gpuMemoryField.context.editable = false;
                    gpuMemoryField.context.tooltipText = tooltipMSG.gpuMemoryTooltipError;

                    gpuField.context.editable = false;
                    gpuField.context.tooltipText = tooltipMSG.gpuTooltipError;

                    if (!!gpuLimitField) {
                        gpuLimitField.context.editable = false;
                        gpuLimitField.context.tooltipText = tooltipMSG.gpuLimitTooltipError;
                    }
                } else {
                    gpuField.context.editable = true;
                    gpuField.context.tooltipText = "";
                    gpuMemoryField.context.editable = true;
                    gpuMemoryField.context.tooltipText = "";

                    migProfileField.context.editable = true;
                    migProfileField.context.tooltipText = "";

                    if (!!gpuLimitField) {
                        gpuLimitField.context.editable = true;
                        gpuLimitField.context.tooltipText = "";
                    }
                }
            }
        });

        const shouldShowImageAutoCompleteField = computed(() => {
            const isRegistryEnabled = settingStore.state.kv[SettingKeys.RegistryIntegration];
            return isRegistryEnabled && !fieldsLoader.fields.image?.validation?.enum?.length;
        });

        let jobOptions = computed(() =>
            fieldsLoader.settings
                .filter((j) => j.name != InferenceJob)
                .map(({ name: value }: any) => ({
                    value,
                    title: value,
                })),
        );
        let needTostopDraged = false;

        const firstLineKeys = new Set(["name", "project", "gpu"]);

        // ----- for deployments -----

        // set form depending on "enableAutoScaling" status
        watchEffect(() => {
            if (!fieldsLoader.ready) {
                return;
            }
            let enableAutoScalingCtrl = formCtrl.children.get("enableAutoScaling");

            if (enableAutoScalingCtrl) {
                // These fields are required if autoscaling on
                ["metric", "target"].forEach((key) => {
                    const ctrl = formCtrl.children.get(key);
                    if (ctrl) {
                        ctrl.context.required = enableAutoScalingCtrl?.value;
                    }
                });

                // These fields are shown only if autoscaling on
                ["maxScale", "metric", "target"].forEach((key) => {
                    const ctrl = formCtrl.children.get(key);
                    if (ctrl) {
                        if (!enableAutoScalingCtrl?.value && !ctrl.context.hidden) {
                            ctrl.value = undefined;
                        }
                        ctrl.context.hidden = !enableAutoScalingCtrl?.value;
                    }
                });
            }
        });

        // enable inserting of customMetric when option selected
        watchEffect(() => {
            if (!fieldsLoader.ready) {
                return;
            }
            const metricCtrl = formCtrl.children.get("metric");
            const customMetricCtrl = formCtrl.children.get("metricName");
            if (metricCtrl && customMetricCtrl && metricCtrl.value == "custom") {
                customMetricCtrl.context.hidden = false;
                customMetricCtrl.context.required = true;
            } else if (customMetricCtrl) {
                customMetricCtrl.context.hidden = true;
                customMetricCtrl.context.required = false;
            }
        });

        // TODO: add watcher that makes sure min in smaller than big. for now not sure how...

        // ----- end deployments -----

        const self = {
            submitQueryParams,
            currentProjectReactive,
            title,
            backURL,
            actionLabel,
            initForm,
            fieldsLoader,
            loader,
            createNewTemplate,
            updateCurrentTemplate,
            renderComponent,
            isLoading,
            fileDraged,
            formCtrl,
            selectedSettings,
            showWorkspaces,
            tagsSearchQuery,
            repositoriesSearchQuery,
            shouldShowImageAutoCompleteField,
            submitting,
            get value() {
                return self.formCtrl.value;
            },
            jobOptions,
            dragoverFile(de: DragEvent) {
                needTostopDraged = false;
                fileDraged.value = true;
            },
            dragend(de: DragEvent) {
                needTostopDraged = true;
                // prevent jitter
                setTimeout(() => {
                    if (needTostopDraged) {
                        fileDraged.value = false;
                    }
                });
            },
            addFileData(de: DragEvent) {
                fileDraged.value = false;
                loader.file.load(de.dataTransfer!.files.item(0)!);
            },

            filterFirstLine(fields: any[]) {
                return fields.filter(({ key }) => !firstLineKeys.has(key));
            },

            filterNotFirstLine(fields: any[]) {
                return fields.filter(({ key }) => firstLineKeys.has(key));
            },
            onCancel() {
                return router.push(backURL);
            },
        };
        return self;
    },
});
