import apiClient from "./api-client";
import { UserCore, AuthUser } from "../types/user";
import { UserState } from "@/stores/authStore";
import { TenantStoreState } from "@/stores/tenantStore";

declare const pendo: any;

class PendoService {
    initUser(userState: UserState, tenantStore: TenantStoreState) {
        // in your authentication promise handler or callback
        pendo.initialize({
            visitor: {
                id: userState.email, // Required if user is logged in
                email: userState.email, // Recommended if using Pendo Feedback, or NPS Email
                full_name: userState.name, // Recommended if using Pendo Feedback
                role: userState.roles.join(","),

                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
            },

            account: {
                id: tenantStore.id, // Highly recommended
                name: tenantStore.name, // Optional
                // is_paying:    // Recommended if using Pendo Feedback
                // monthly_value:// Recommended if using Pendo Feedback
                planLevel: tenantStore.contractType,
                signedEula: tenantStore.signedEula,
                // planPrice:    // Optional
                // creationDate: // Optional

                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
            },
        });
    }
}

const pendoService = new PendoService();
export default pendoService;
