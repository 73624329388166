
import { defineComponent, ref, watch } from "vue";
import { customCtrlArgs, variant } from "@/core-ui/forms/components/common";
import Box from "@/core-ui/forms/components/Box.vue";
import IconButton from "@/core-ui/IconButton.vue";
import { ContainerCtrlMeta, useObjCtrl } from "@/core-ui/forms/compositions";
import BoxInputCtrl from "@/core-ui/forms/components/BoxInputCtrl.vue";
import BoxToggleCtrl from "@/core-ui/forms/components/BoxToggleCtrl.vue";
import BoxSelectCtrl from "@/core-ui/forms/components/BoxSelectCtrl.vue";
import pvcsService from "@/cluster-ui/services/pvcs.service";
import {
    activeClusterConnectionInfoStore,
    isSubmitParamAvailableForCluster_2_10
} from "@/stores/activeClusterConnectionInfoStore";

type meta = ContainerCtrlMeta;

export default defineComponent({
    props: {
        variant,
        ctrlArgs: customCtrlArgs<meta>(),
        canEdit: { type: Boolean, default: true },
        canRemove: { type: Boolean, default: true },
    },
    components: { Box, IconButton, BoxInputCtrl, BoxToggleCtrl, BoxSelectCtrl },
    setup(props) {
        const ctrl = useObjCtrl(props.ctrlArgs!);
        const existingPvcFlag = ref<boolean|undefined>(undefined);
        const showEphemeral = ref<boolean>(false);
        const pvcListEmpty = ref<boolean>(false);
        function getArgs(key: string): any {
            return ctrl.meta.fields[key](ctrl.state, key).args;
        }

        const self = {
            itemCanEdit: props.canEdit,
            itemCanRemove: props.canRemove,
            ctrl,
            storageClass: getArgs("storageClass"),
            size: getArgs("size"),
            path: getArgs("path"),
            readOnly: getArgs("readOnly"),
            claimName: getArgs("claimName"),
            existingPvc: getArgs("existingPvc"),
            ephemeral: getArgs("ephemeral"),
            readWriteOnce: getArgs("readWriteOnce"),
            readOnlyMany: getArgs("readOnlyMany"),
            readWriteMany: getArgs("readWriteMany"),
            existingPvcFlag,
            showEphemeral,
            pvcListEmpty,
            get showSrc() {
                return true;
            },
        };

      const pvcList = pvcsService.get();
      self.claimName.ctrlArgs.meta.options = pvcList
      pvcListEmpty.value = !pvcList || pvcList.length == 0;

      watch(
          () => ctrl.state.children.get("existingPvc"),
          async () => {
              existingPvcFlag.value = (ctrl.state.children.get("existingPvc") && ctrl.state.children.get("existingPvc")?.value) as boolean;
          },
          {immediate: true, deep: true},
      );

        // hide ephemeral for cluster under 2.10.0
        watch(
            () => activeClusterConnectionInfoStore.selectedClusterVersion,
            async () => {
                const selectedClusterVersion = activeClusterConnectionInfoStore.selectedClusterVersion;
                showEphemeral.value = isSubmitParamAvailableForCluster_2_10(selectedClusterVersion);
            },
            {immediate: true, deep: true},
        );

        return self
    },
});
