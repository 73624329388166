
import ToggleButton from "@/core-ui/forms/components/Toggle/ToggleButton.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        ToggleButton,
    },
    emits: ["update:modelValue"],
    props: {
        modelValue: Boolean,
    },
    methods: {
        onValueChange(value: boolean) {
            if (typeof value == "boolean") {
                this.$emit("update:modelValue", value);
            }
        },
    },
});
