import apiClient from "../services/api-client";
import moment from "moment";
import { reactive } from "vue";

type AppState = {
    lastLogin: string;
    appVersion: string;
    loading: boolean;
    lastBackPath: string;
};

class AppStore {
    private state = reactive<AppState>({
        lastLogin: "",
        appVersion: "",
        loading: false,
        lastBackPath: "/",
    });

    async setup() {
        try {
            this.state.loading = true;
            this.state.lastLogin = moment().local().format("MM/DD/YY, hh:mma");
            const response = await apiClient.asyncRequestWithResponseAndError(`_version`, "GET");
            this.state.appVersion = response.version;
        } catch (err) {
            console.error(err);
        } finally {
            this.state.loading = false;
        }
    }

    get lastLogin(): string {
        return this.state.lastLogin;
    }

    get appVersion(): string {
        return this.state.appVersion;
    }

    get isLoading(): boolean {
        return this.state.loading;
    }

    get lastBackPath(): string {
        return this.state.lastBackPath;
    }

    set lastBackPath(path: string) {
        this.state.lastBackPath = path;
    }
}

const appStore = new AppStore();
export default appStore;
