import apiClient from "@/services/api-client";

const DYNAMIC_PARAMETER_KEY = "allReporterPushGatewayParameters";
const DYNAMIC_METRIC_KEY = "allReporterPushGatewayMetrics";
const vShouldReturnFilteredResult = true;
const DAY = 24 * 60 * 60 * 1000;

type CustomExtractor = Record<
    string,
    (key: string, valueKey: string, row: any, metric: any, value: any) => any | undefined
>;

function getItemKey(item: any, uniqueKey: string | Array<string>) {
    if (typeof uniqueKey === "string") {
        return item[uniqueKey];
    } else {
        return uniqueKey.map((key) => item[key]).join(".");
    }
}

class PrometheusService {
    query(params: any) {
        const qs = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join("&");
        return apiClient.asyncRequestWithResponse(`v1/k8s/prom/proxy/api/v1/query?${qs}`, "GET");
    }

    async rangeQueryExtended({
        query,
        start: startDate = Date.now() - 7 * DAY,
        end: endDate = Date.now(),
        step,
    }: {
        query: string;
        start?: Date | number;
        end?: Date | number;
        step: number;
    }): Promise<any> {
        const start =
            startDate instanceof Date
                ? Math.floor((isNaN(startDate as any) ? Date.now() - 7 * DAY : +startDate) / 1000)
                : startDate / 1000;
        const end =
            endDate instanceof Date
                ? Math.floor((isNaN(endDate as any) ? Date.now() : +endDate) / 1000)
                : endDate / 1000;

        if (start) {
            const range = ((+end || Date.now()) - +start) * 1000;
            // Prometheus can't send us so much data, so in more than 60 days it'll be better to reduce step size.
            if (range > 60 * DAY) {
                step = Math.max(step, 1200);
            } else if (range < DAY) {
                step = Math.min(step, 200);
            }
        }

        const params: any = { query, start, end, step };

        const qs = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent((params as any)[key])}`)
            .join("&");
        try {
            return Promise.resolve([
                await apiClient.asyncRequestWithResponse(`v1/k8s/prom/proxy/api/v1/query_range?${qs}`, "GET"),
                step,
            ]);
        } catch (err: any) {
            if (err.message?.startsWith("exceeded maximum resolution") || err.error?.startsWith("exceeded maximum resolution")) {
                return this.rangeQueryExtended({
                    query,
                    start: startDate,
                    end: endDate,
                    step: step + 100,
                });
            }
            return Promise.resolve([err, step]);
        }
    }

    async rangeQuery({
        query,
        start,
        end,
        step,
    }: {
        query: string;
        start?: Date | number;
        end?: Date | number;
        step: number;
    }): Promise<any> {
        const rangeQueryExtended = await this.rangeQueryExtended({ query, start, end, step });
        return Promise.resolve(rangeQueryExtended[0]);
    }

    getMultipleRangeQueries(
        promQueries: Array<string>,
        start: Date | number = Date.now() - 7 * DAY, // 7 days ago
        end?: Date | number,
        step = 600,
    ): Promise<any[]> {
        if (!end) {
            end = Date.now(); // now
        }

        return Promise.all(
            promQueries.map((query) =>
                this.rangeQuery({ query, start, end, step })
                    .then((res: any) => (res.data ? res.data : Promise.reject(new Error("Failed to retrieve data."))))
                    .then((data: any) => {
                        data.query = query;
                        return data;
                    }),
            ),
        );
    }

    getMetadataResultMultipleQueries<T = any>(
        promQueries: { [key: string]: string },
        uniqueKey: string | Array<string>,
        time = Math.floor(Date.now() / 1000),
        customExtractor?: CustomExtractor,
    ) {
        return this.getMultipleQueriesResults(promQueries, time).then((results) =>
            this.getMapMultipleQueriesFinalResult<T>(results, uniqueKey, !vShouldReturnFilteredResult, customExtractor),
        );
    }

    getFilteredResultMultipleQueries<T = any>(
        promQueries: { [key: string]: string },
        uniqueKey: string | Array<string>,
        time = Math.floor(Date.now() / 1000),
        customExtractor?: CustomExtractor,
        forgiveness = 0, // how metrics can be missing
    ) {
        return this.getMultipleQueriesResults(promQueries, time, forgiveness).then(
            (results) =>
                Object.values(
                    this.getMapMultipleQueriesFinalResult(
                        results,
                        uniqueKey,
                        vShouldReturnFilteredResult,
                        customExtractor,
                    ),
                ) as T[],
        );
    }

    private getMapMultipleQueriesFinalResult<T = any>(
        results: any[],
        uniqueKey: string | Array<string>,
        shouldReturnFilteredResult: boolean,
        customExtractor?: CustomExtractor,
    ) {
        const rows: Record<string, any> = {};
        results
            .filter((d) => d)
            .forEach((data: { query: any; result: Array<{ metric: any; value: any }> }) => {
                data.result.forEach(({ metric, value }, index) => {
                    const key = getItemKey(metric, uniqueKey);
                    const valueKey = data.query.key;
                    if (typeof key === "undefined") {
                        return;
                    }

                    if (!rows[key]) {
                        rows[key] = {
                            id: key ? key : index,
                            dynamicData: {},
                        };
                    }

                    const row = rows[key];
                    Object.assign(row, metric);

                    if (customExtractor && customExtractor[valueKey]) {
                        customExtractor[valueKey](key, valueKey, row, metric, value);
                        return;
                    } else if (shouldReturnFilteredResult) {
                        row[valueKey] = value[1];
                        return;
                    }

                    const isDynamicField =
                        data.query.key === DYNAMIC_PARAMETER_KEY || data.query.key === DYNAMIC_METRIC_KEY;
                    const rowKey = this.getKey(metric, data.query.key);
                    const propValue = this.getValue(metric, data.query.key, value);
                    if (isDynamicField) {
                        row.dynamicData[rowKey] = {
                            value: propValue,
                            type: data.query.key === DYNAMIC_PARAMETER_KEY ? "parameter" : "metric",
                        };
                    } else {
                        row[rowKey] = propValue;
                    }
                });
            });

        return rows;
    }

    private getMultipleQueriesResults(
        promQueries: { [key: string]: string },
        time = Math.floor(Date.now() / 1000),
        forgiveness = 0, // how metrics can be missing
    ) {
        let failures = 0;
        return Promise.all(
            Object.keys(promQueries).map((key) =>
                this.query({ query: promQueries[key], time })
                    .then((res) => (res.data ? res.data : Promise.reject(new Error("Failed to retrieve data."))))
                    .then((data) => {
                        data.query = { key, query: promQueries[key] };
                        return data;
                    })
                    .catch((err) => {
                        failures++;
                        if (failures > forgiveness) {
                            return Promise.reject(err);
                        }
                        return false;
                    }),
            ),
        );
    }

    private getValue(metric: any, key: any, value: any) {
        switch (key) {
            case DYNAMIC_PARAMETER_KEY:
                return metric.param_value;

            default:
                return value[1];
        }
    }

    private getKey(metric: any, key: any) {
        switch (key) {
            case DYNAMIC_PARAMETER_KEY:
                return metric.param_name;

            case DYNAMIC_METRIC_KEY:
                return metric.metric_name;

            default:
                return key;
        }
    }
}

const prometheusService = new PrometheusService();
export default prometheusService;
