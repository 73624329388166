import eventEmitter from "./event-emitter";

const RUNAI_APP_NAME = "runai-apps";

class FrontendV2Service {
    private _eventEmitter = eventEmitter;
    private baseUrl: string =
        process.env.NODE_ENV === "development" && process.env.VUE_APP_FRONT_NEXT_URL
            ? process.env.VUE_APP_FRONT_NEXT_URL
            : `/frontend-v2`;

    private defaultOrigin: string =
        process.env.NODE_ENV === "development" && process.env.VUE_APP_FRONT_NEXT_URL
            ? process.env.VUE_APP_FRONT_NEXT_URL
            : document.location.origin;
    private iframeId: string = "runai-new-app";

    constructor() {
        const appListener = (event: any) => {
            const { data } = event;
            if (data.app !== RUNAI_APP_NAME) return;

            const { eventy, appData } = data;
            this._eventEmitter.emit(eventy, appData ? JSON.parse(appData) : appData, event);
        };

        window.addEventListener("message", appListener);
    }

    addListener(event: string, cb: Function): string {
        return this._eventEmitter.addListener(event, cb);
    }

    removeListener(event: string, listenerId: string): void {
        this._eventEmitter.removeListener(event, listenerId);
    }

    send(event: string, data: any, origin?: string): void {
        const iframeWin: HTMLIFrameElement | null = document?.getElementById(this.iframeId) as HTMLIFrameElement;
        iframeWin?.contentWindow?.postMessage(
            { app: RUNAI_APP_NAME, appData: data ? JSON.stringify(data) : data, eventy: event },
            origin || this.defaultOrigin,
        );
    }

    async get(event: string, data?: any) {
        return new Promise((resolve, reject) => {
            const listenerId = this.addListener(event, (data: any) => {
                this.removeListener(event, listenerId);
                resolve(data);
            });
            this.send(event, data);
        });
    }

    get url() {
        return this.baseUrl;
    }
}

const frontendV2Service = new FrontendV2Service();
export default frontendV2Service;
