
import ProgressBar from "@/core-ui/ProgressBar.vue";
import Box from "./Box.vue";
import BoxInputMessages from "./BoxInputMessages.vue";
import { useFieldCtrl } from "@/core-ui/forms/compositions";
import { fieldCtrlArgs, variant } from "./common";
import { defineComponent, ref } from "vue";

type InputValueTypes = string | number;
type LoaderStatus = "loading" | "ready" | "empty";

export default defineComponent({
    components: { Box, BoxInputMessages, ProgressBar },
    props: {
        variant,
        ctrlArgs: fieldCtrlArgs<InputValueTypes>(),
        primaryFilterAsync: { type: Function, default: null},
        secondaryFilterAsync: { type: Function, default: null },
        primaryNoResult: { type: String, default: 'No results found' },
        secondaryNoResult: { type: String, default: 'No results found' },
        primaryLabel: { type: String, default: '' },
        secondaryLabel: { type: String, default: '' },
    },
    setup: (props) => {
        const optionsStatus = ref<LoaderStatus>("empty");
        const fieldCtrl = useFieldCtrl(props.ctrlArgs!);
        const mainSearchTerm: any = ref((fieldCtrl.value as string)?.split(":")[0] || "");
        const secondarySearchTerm: any = ref((fieldCtrl.value as string)?.split(":")[1] || "");

        let oldValue = ref<string>("");

        const mainOptions = ref<string[]>([]);
        const secondaryOptions = ref<string[]>([]);

        async function mainFilterOptions(): Promise<string[]> {
            const values: string[] = await props.primaryFilterAsync(mainSearchTerm.value);
            mainOptions.value = values;
            return values;
        }

        async function secondaryFilterOptions(): Promise<string[]> {
            if(!mainSearchTerm.value) return [];
            const values: string[] = await props.secondaryFilterAsync(mainSearchTerm.value, secondarySearchTerm.value);
            secondaryOptions.value = values;
            return values;
        }

        function onMainSelect() {
            fieldCtrl.value = "";
            secondarySearchTerm.value = ""
        }

        function onSecondarySelect(secondaryFieldValue: string) {
            if (secondaryFieldValue && mainSearchTerm.value) {
                fieldCtrl.value = mainSearchTerm.value + ":" + secondaryFieldValue;
            } else {
                fieldCtrl.value = "";
            }
        }

        function onMainClose() {
            if (!mainOptions.value.includes(mainSearchTerm.value) || mainSearchTerm.value == ""){
                mainSearchTerm.value = ""
                fieldCtrl.value = "";
                secondarySearchTerm.value = ""
                fieldCtrl.value = "";
            }
        }

        function onSecondaryClose () {
            if (!secondaryOptions.value.includes(secondarySearchTerm.value)){
                secondarySearchTerm.value = ""
                fieldCtrl.value = "";
            } else {
                fieldCtrl.value = mainSearchTerm.value + ":" + secondarySearchTerm.value;
                fieldCtrl.onInputChange ? fieldCtrl.onInputChange(fieldCtrl.value): undefined;
            }
        }

        return {
            mainSearchTerm,
            secondarySearchTerm,
            fieldCtrl,
            onSecondarySelect,
            onMainSelect,
            onMainClose,
            onSecondaryClose,
            onFocus(event: any) {
                oldValue = event.target.value;
            },
            get isLoading() {
                return optionsStatus.value == "loading";
            },
            mainFilterOptions,
            secondaryFilterOptions
        };
    },
});
