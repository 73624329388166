
import BoxInputCtrl from "./BoxInputCtrl.vue";

import { useFieldCtrlArgsFromProps, useInputPipeAndMeta, calcInputFormatAndType } from "@/core-ui/forms/compositions";
import { computed, defineComponent, PropType, ref, watch } from "vue";
import { containerCtrl, variant, InputValueTypes } from "./common";

// todo move to helper
function emitEmpty(obj: Record<string, unknown>) {
    return Object.fromEntries(Object.entries(obj).filter(([_, val]) => val !== undefined));
}

export default defineComponent({
    components: { BoxInputCtrl },
    props: {
        variant,
        theme: String,
        autocomplete: Object as PropType<InputValueTypes[]>,
        ctrl: containerCtrl,
        format: String,
        type: String,
        name: { type: String, required: true },
        label: String,
        description: String,
        default: [String, Number],
        required: Boolean,
        editable: Boolean,
        maxLength: Number,
        minLength: Number,
        step: Number,
        pattern: String,
        category: String,
        validation: Function
    },
    created() {
        this.thisComp = this;
    },
    setup: (props: any, ctx: any) => {
        const thisComp = ref<any>(null);
        watch(
            () => ctx.attrs,
            () => {
                console.log("args changes");
            },
        );
        return {
            thisComp,
            attrs: computed(() =>
                emitEmpty({
                    name: props.name,
                    required: !!props.required,
                    maxLength: props.maxLength,
                    minLength: props.minLength,
                    step: props.step,
                    type: props.type,
                    ...(thisComp.value?.$attrs || {}),
                }),
            ),
            ctrlArgs: useFieldCtrlArgsFromProps({
                get type() {
                    const [type] = calcInputFormatAndType(props.type, props.format);
                    return type;
                },
                get props() {
                    return props;
                },
                pipeAndMeta: useInputPipeAndMeta(props),
            }),
        };
    },
});
