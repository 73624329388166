export function isDisplayNone(e: HTMLInputElement | HTMLElement | HTMLFormElement | null, root: HTMLElement) {
    do {
        if (e!.style.display == "none") {
            return true;
        } else {
            e = e!.parentElement;
        }
    } while (e && e !== root);
}

export function focusOnElement(el: HTMLInputElement[] | HTMLFormElement) {
    try {
        for (const input of el) {
            if (!(input as HTMLInputElement)?.disabled && !isDisplayNone(input as HTMLInputElement, el as any)) {
                (input as HTMLInputElement).focus();
                break;
            }
        }
    } catch (e) {
        //??
    }
}

// Register a global custom directive called `v-focus`
export default {
    // When the bound element is inserted into the DOM...
    mounted: focusOnElement,
};
