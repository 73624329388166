import {
    FieldTypes,
    ObjField,
    ArrayField as ArrayField,
    StringField,
    StringComponents,
    NumberField,
    BooleanField,
    NumberComponents,
    BoolComponents,
    ObjComponents,
    StringArrayComponents,
    MapComponents,
    PairComponents,
    Field,
    CustomField,
    MapField,
    PairField,
} from "@/core-ui/forms/types/declarative-fields";
import {
    FieldCtrlArgs,
    FieldKey,
    ObjCtrlArgs,
    ArrayCtrlArgs,
    prepareNumberInputCtrl,
    prepareStringInputCtrl,
    StringFormatEnum,
    FieldCtrlMeta,
    CustomCtrlArgs,
    ContainerState,
} from "./";
import BoxInputCtrl from "@/core-ui/forms/components/BoxInputCtrl.vue";
import BoxToggleCtrl from "@/core-ui/forms/components/BoxToggleCtrl.vue";
import BoxSelectCtrl from "@/core-ui/forms/components/BoxSelectCtrl.vue";
import BoxArrayInputCtrl from "@/core-ui/forms/components/BoxArrayInputCtrl.vue";
import BoxMapCtrl from "@/core-ui/forms/components/BoxMapCtrl.vue";

import BoxKeyValCtrl from "@/core-ui/forms/components/BoxKeyValCtrl.vue";
import BoxPairCtrl from "@/core-ui/forms/components/BoxPairCtrl.vue";

import { Component } from "vue";
import { isObject } from "lodash";
import BoxButtonSelectCtrl from "@/core-ui/forms/components/BoxButtonSelectCtrl.vue";
import { MapCtrlArgs } from "./MapCtrl";

function mergeMeta(meta1?: FieldCtrlMeta, meta2?: FieldCtrlMeta) {
    const result: any = { ...(meta1 || {}) };
    for (const [key, val] of Object.entries(meta2 || {})) {
        const resultVal: any = result[key];
        if (!Array.isArray(resultVal) && !isObject(resultVal)) {
            result[key] = val; // override
        } else if (Array.isArray(val) && Array.isArray(resultVal)) {
            result[key] = [...(resultVal || []), ...val];
        } else if (isObject(val) && isObject(resultVal)) {
            result[key] = { ...(resultVal || {}), ...val };
        } else {
            result[key] = val;
        }
    }
    return result;
}

type RenderFieldComponentData<F = any> = (
    state: ContainerState,
    key: FieldKey,
) => {
    key: FieldKey;
    component: Component;
    args: { ctrlArgs: F };
};

type GetRenderData<T, F> = (field: T) => RenderFieldComponentData<F>;

type componentHandler<T, F> = GetRenderData<T, F>;

type ComponentHandlerByTypes = {
    any: (field: Field) => RenderFieldComponentData;
    [FieldTypes.Custom]: componentHandler<CustomField, CustomCtrlArgs<unknown>>;
    [FieldTypes.String]: {
        [key in StringComponents]: componentHandler<StringField, FieldCtrlArgs<string>>;
    };
    [FieldTypes.Number]: {
        [key in NumberComponents]: componentHandler<NumberField, FieldCtrlArgs<number>>;
    };
    [FieldTypes.Bool]: {
        [key in BoolComponents]: componentHandler<BooleanField, FieldCtrlArgs<boolean>>;
    };
    [FieldTypes.Obj]: {
        [key in ObjComponents]: componentHandler<ObjField<Record<string, any>>, ObjCtrlArgs>;
    };
    [FieldTypes.Array]: {
        [key in StringComponents]: componentHandler<ArrayField, ArrayCtrlArgs<FieldCtrlArgs<string | number | boolean>>>;
    };
    [FieldTypes.Map]: {
        [key in MapComponents]: componentHandler<MapField, MapCtrlArgs>;
    };
    [FieldTypes.Pair]: {
        [key in PairComponents]: componentHandler<PairField, ObjCtrlArgs>;
    };
};

const defaultComponents = {
    [FieldTypes.String]: StringComponents.Box,
    [FieldTypes.Number]: NumberComponents.Box,
    [FieldTypes.Bool]: BoolComponents.Toggle,
    [FieldTypes.Array]: StringArrayComponents.Box,
    [FieldTypes.Map]: MapComponents.Box,
    [FieldTypes.Pair]: PairComponents.Box,
    [FieldTypes.Obj]: ObjComponents.ObjInTabs,
};

const customDeclarativeField: Record<string, (f: CustomField) => RenderFieldComponentData<CustomCtrlArgs<any>>> = {};

const customComponent: Record<string, Component> = {};

const componentHandlerByTypes: ComponentHandlerByTypes = {
    any: (field: Field): RenderFieldComponentData => {
        let handler;
        if (field.type == FieldTypes.Custom) {
            handler = componentHandlerByTypes[field.type];
        } else {
            handler = componentHandlerByTypes[field.type][field.display || defaultComponents[field.type]];
        }

        return handler(field as any) as RenderFieldComponentData;
    },
    [FieldTypes.String]: {
        [StringComponents.Box]: (field: StringField) => {
            const [validationFunc, meta] = prepareStringInputCtrl(
                field.validation,
                field.meta && field.meta.placeholder,
            );
            const props: any = {};
            let component: any = BoxInputCtrl;
            if (field.validation?.enum && field.validation?.enum.length) {
                component = BoxSelectCtrl;
                if (field.meta?.options?.length) {
                    delete meta.options;
                }
            } else if (field.validation?.format == StringFormatEnum.volume) {
                component = BoxKeyValCtrl;
            } else if (field.validation?.format == StringFormatEnum.envVar) {
                component = BoxKeyValCtrl;
            }
            return (state: ContainerState, key: FieldKey) => ({
                key,
                component,
                args: {
                    ...props,
                    ctrlArgs: {
                        type: "string",
                        parent: state,
                        title: field.title,
                        description: field.description,
                        onInputChange: key == "project" && field.onInputChange,
                        default: field.default,
                        editable: field.editable,
                        key,
                        pipe: validationFunc ? [validationFunc] : undefined,
                        required: field.required,
                        meta: {
                            ...mergeMeta(field.meta, meta),
                        },
                    },
                },
            });
        },
        [StringComponents.ButtonSelect]: (field: StringField) => {
            const [validationFunc, meta] = prepareStringInputCtrl(field.validation);
            const props: any = {};
            return (state: ContainerState, key: FieldKey) => ({
                key,
                component: BoxButtonSelectCtrl,
                args: {
                    ...props,
                    ctrlArgs: {
                        type: "string",
                        parent: state,
                        title: field.title,
                        description: field.description,
                        default: field.default,
                        editable: field.editable,
                        key,
                        pipe: validationFunc ? [validationFunc] : undefined,
                        required: field.required,
                        meta: field.meta,
                    },
                },
            });
        },
    },
    [FieldTypes.Number]: {
        [NumberComponents.Box]: (field: NumberField) => {
            const [validationFunc, meta] = prepareNumberInputCtrl(field.validation);

            return (state: ContainerState, key: FieldKey) => ({
                key,
                component: BoxInputCtrl,
                args: {
                    ctrlArgs: {
                        type: "number",
                        parent: state,
                        title: field.title,
                        description: field.description,
                        default: field.default,
                        editable: field.editable,
                        key,
                        pipe: validationFunc ? [validationFunc] : undefined,
                        required: field.required,
                        meta: {
                            ...mergeMeta(field.meta, meta),
                        },
                    },
                },
            });
        },
    },
    [FieldTypes.Bool]: {
        [BoolComponents.Toggle]: (field: BooleanField) => {
            return (state: ContainerState, key: FieldKey) => ({
                key,
                component: BoxToggleCtrl,
                args: {
                    ctrlArgs: {
                        type: "boolean",
                        key,
                        parent: state,
                        title: field.title,
                        editable: field.editable,
                        description: field.description,
                        default: field.default,
                        pipe: [],
                        meta: {
                            ...(field.meta || {}),
                        },
                    },
                },
            });
        },
    },
    [FieldTypes.Obj]: {
        [ObjComponents.Custom]: (field: ObjField<Record<string, any>>) => {
            const component = customComponent[field.customDisplay!];
            if (!component) {
                throw new Error(`Custom component named: ${field.customDisplay} is not found`);
            }
            const fields = Object.fromEntries(
                Object.entries(field.fields).map(([key, f]) => {
                    return [key, componentHandlerByTypes.any(f)];
                }),
            );
            return (state: ContainerState, key: FieldKey) => ({
                key,
                component,
                args: {
                    ctrlArgs: {
                        key,
                        title: field.title,
                        description: field.description,
                        editable: field.editable,
                        parent: state,
                        meta: {
                            fields,
                            ...(field.meta || {}),
                        },
                    },
                },
            });
        },
        [ObjComponents.ObjInTabs]: (field: ObjField<Record<string, any>>) => {
            const fields = Object.fromEntries(
                Object.entries(field.fields).map(([key, f]) => {
                    return [key, componentHandlerByTypes.any(f)];
                }),
            );
            return (state: ContainerState, key: FieldKey) => ({
                key,
                component: BoxInputCtrl,
                args: {
                    ctrlArgs: {
                        key,
                        title: field.title,
                        description: field.description,
                        editable: field.editable,
                        parent: state,
                        meta: {
                            fields,
                            ...(field.meta || {}),
                        },
                    },
                },
            });
        },
    },
    [FieldTypes.Array]: {
        [StringArrayComponents.Box]: (field: ArrayField) => {
            const item = componentHandlerByTypes.any(field.item);
            return (state: ContainerState, key: FieldKey) => ({
                key,
                component: BoxArrayInputCtrl,
                args: {
                    ctrlArgs: {
                        key,
                        title: field.title,
                        description: field.description,
                        default: field.default,
                        editable: field.editable,
                        defaultRules: field.defaultRules,
                        parent: state,
                        item: (state: any, key) => item(state, key).args.ctrlArgs,
                        meta: {
                            maxItems: field.validation?.max,
                            item,
                            ...(field.meta || {}),
                        },
                    },
                },
            });
        },
    },
    [FieldTypes.Map]: {
        [MapComponents.Box]: (field: MapField) => {
            const pair = componentHandlerByTypes.any(field.pair);
            return (state: ContainerState, key: FieldKey) => ({
                key,
                component: BoxMapCtrl,
                args: {
                    ctrlArgs: {
                        key,
                        title: field.title,
                        description: field.description,
                        default: field.default,
                        editable: field.editable,
                        defaultRules: field.defaultRules,
                        parent: state,
                        pair: (state: any, key) => pair(state, key).args.ctrlArgs,
                        meta: {
                            pair,
                            ...(field.meta || {}),
                        },
                    },
                },
            });
        },
    },
    [FieldTypes.Pair]: {
        [StringArrayComponents.Box]: (field: PairField) => {
            const fields = Object.fromEntries(
                Object.entries({ key: field.key, value: field.value }).map(([key, f]) => {
                    return [key, componentHandlerByTypes.any(f)];
                }),
            );
            return (state: ContainerState, key: FieldKey) => ({
                key,
                component: BoxPairCtrl,
                args: {
                    ctrlArgs: {
                        key,
                        title: field.title,
                        description: field.description,
                        // default: field.default,
                        defaultRules: field.defaultRules,
                        editable: field.editable,
                        parent: state,
                        meta: {
                            fields,
                            ...(field.meta || {}),
                        },
                    },
                },
            });
        },
    },
    [FieldTypes.Custom]: (field: CustomField) => {
        const handler = customDeclarativeField[field.customType];
        if (!handler) {
            throw new Error(`[Custom declarative filed handler] Unknown custom type: ${field.customType}`);
        }
        return handler(field);
    },
};
export const registerCustomDeclarativeField = <M>(
    name: string,
    handler: <M>(f: CustomField) => RenderFieldComponentData<CustomCtrlArgs<M>>,
) => {
    customDeclarativeField[name] = handler;
};
export const registerCustomComponent = <M>(name: string, component: Component) => {
    customComponent[name] = component;
};
export const getFieldRenderData = componentHandlerByTypes.any;
