import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBBtn = _resolveComponent("MDBBtn")!
  const _component_MDBPopover = _resolveComponent("MDBPopover")!

  return (_ctx.action.handler)
    ? (_openBlock(), _createBlock(_component_MDBBtn, {
        key: 0,
        size: "sm",
        color: "link",
        onClick: _ctx.action.handler
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.action.name), 1)
        ]),
        _: 1
      }, 8, ["onClick"]))
    : (_openBlock(), _createBlock(_component_MDBPopover, {
        key: 1,
        modelValue: _ctx.popover,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.popover) = $event)),
        dismissible: ""
      }, {
        reference: _withCtx(() => [
          _createVNode(_component_MDBBtn, {
            size: "sm",
            color: "link",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.popover = !_ctx.popover))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.action.name), 1)
            ]),
            _: 1
          })
        ]),
        body: _withCtx(() => [
          _createElementVNode("div", {
            class: "d-flex align-items-center",
            innerHTML: _ctx.actionInfo
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["modelValue"]))
}