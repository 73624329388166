import { ref, watch } from "vue";
import { ListData } from "../types";

export type Cache<T = unknown> = {
    set(data: any): void;
    clear(): void;
    isInitialize: boolean;
    data: T;
};

export type ListCache<T> = Cache<ListData<T>>;

function defaultListData<T>(): ListData<T> {
    return {
        filter: undefined,
        data: [],
        length: 0,
    };
}

export function useListCache<T>(): ListCache<T> {
    const listCache = ref<ListData<T>>(defaultListData<T>());
    const isInitialize = ref(false);

    return {
        get isInitialize() {
            return isInitialize.value;
        },
        set(d: ListData<T>) {
            isInitialize.value = true;
            listCache.value = d as any;
        },
        get data(): ListData<T> {
            return listCache.value as ListData<T>;
        },
        clear() {
            listCache.value = defaultListData<T>() as any;
        },
    };
}

export type useCacheProps<T> = {
    key?: string;
    defaultValue: T | null;
    map?: (value: any) => T;
};

export function useCache<T>(props: useCacheProps<T> = { defaultValue: null }): Cache<T> {
    const isInitialize = ref(false);
    const cache = ref<T>();

    watch(
        () => props.key,
        () => clear(),
        { immediate: true },
    );

    function clear() {
        cache.value = props.defaultValue as any;
    }

    return {
        get isInitialize() {
            return isInitialize.value;
        },
        set(d: T) {
            isInitialize.value = true;
            cache.value = props.map ? props.map(d) : (d as any);
        },
        get data(): T {
            return cache.value as T;
        },
        clear,
    };
}
