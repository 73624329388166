export const TrainingJob = "Training";
export const InteractiveJob = "Interactive";
export const InferenceJob = "Inference";
export const LAST_SELECTED_TEMP_NAME = "LAST_SELECTED_TEMP_NAME";
export const TARGET_PROJECT = "TARGET_PROJECT";
export const TEMPLATES_LIST = "TEMPLATES_LIST";
export const STARE_KEY = "STARE_KEY";
export const ALL_PROJECTS = "All Projects";
export const TEMLATE_OPEN_KEY = "TEMLATE_OPEN_KEY";
export const JOB_TEMLATE_OPEN_KEY = "JOB_TEMLATE_OPEN_KEY";
export const JOBS_TABLE_FILTER = "jobsTableFilter";
export const LOGGED_IN_USER_EMAIL = "loggedInUserEmail";
export const usageOptions = {
    Template: "Template",
    Submit: "Submit",
};
export const complicatedFields = ["ports", "pvcs", "gitSync", "volumes", "environment", "annotations", "labels", "s3", "tolerations", "extendedResources", "capabilities", "exposedUrls", "nodePools"];
export const basicFields = [
    "arguments",
    "name",
    "type",
    "project",
    "image",
    "gpu",
    "gpuLimit",
    "mpi",
    "processes",
    "slotsPerWorker",
    "jupyter",
    "tensorboard",
    "notebookToken",
    "tensorboardLogdir",
    "nodeType",
    "jobNamePrefix",
    "command",
    "imagePullPolicy",
    "nodePool",
    "runAsUser",
    "supplementalGroups",
    "runAsUid",
    "runAsGid",
    "stdin",
    "tty",
    "workingDir",
    "createHomeDir",
    "hostIpc",
    "hostNetwork",
    "serviceType",
    "allowPrivilegeEscalation",
    "cpu",
    "gpuMemory",
    "migProfile",
    "podAffinityTopology",
    "podAffinitySchedulingRule",
    "podAffinity",
    "cpuLimit",
    "memory",
    "memoryLimit",
    "largeShm",
    "completions",
    "parallelism",
    "backoffLimit",
    "preemptible",
    "ttlAfterFinish",
    "ingressUrl",
    // for deployments
    "enableAutoScaling",
    "minScale",
    "maxScale",
    "metric",
    "metricName",
    "target",
    "isPrivateServiceUrl",
    "mps",
];

//TODO: can remove?
export const notMappedKeys = new Set([
    "name",
    "type",
    "mpi",
    "processes",
    "slotsPerWorker",
    "jobNamePrefix",
    "jupyter",
    "tensorboard",
    "notebookToken",
    "tensorboardLogdir",
    "project",
    "image",
    "imagePullPolicy",
    "nodePool",
    "ports",
    "environment",
    "annotations",
    "labels",
    "tty",
    "stdin",
    "runAsUser",
    "supplementalGroups",
    "runAsUid",
    "runAsGid",
    "workingDir",
    "createHomeDir",
    "gpu",
    "gpuLimit",
    "cpu",
    "cpuLimit",
    "gpuMemory",
    "migProfile",
    "podAffinityTopology",
    "podAffinitySchedulingRule",
    "podAffinity",
    "memory",
    "memoryLimit",
    "largeShm",
    "hostIpc",
    "hostNetwork",
    "backoffLimit",
    "completions",
    "parallelism",
    "preemptible",
    "serviceType",
    "allowPrivilegeEscalation",
    "nodeType",
    "arguments",

    // inference fields
    "enableAutoScaling",
    "minScale",
    "maxScale",
    "metric",
    "metricName",
    "target",
    "isPrivateServiceUrl",
    "mps",

    // wandb
    "wandbSweepFeature",
    "wandbSweepConfig",
    "wandbProject",
    "wandbEntity",
]);

export const jsonParseKeys = ["capabilities", "tolerations", "extendedResources", "gitSync", "s3", "pvcs", "volumes", "exposedUrls", "nodePools"];

export const ignoredKeys = new Set([
    "wandbApiKey",
]);

export const tooltipMSG = {
    gpuMemoryTooltipError: "GPU Memory cannot be used in conjunction with GPUs or MIG Profile",
    gpuTooltipError: "GPUs cannot be used in conjunction with GPU Memory or MIG Profile",
    gpuTooltipMustExist: "In order to use GPU Limit, you have to set a fractional GPU value",
    gpuLimitTooltipError: "GPU Limit cannot be used in conjunction with GPU Memory or MIG Profile",
    migTooltipError: "MIG Profile cannot be used in conjunction with GPUS or GPU Memory",
};

export const workloadTemplateLevel = { workload: "workload" };

export const numericWorkLoadFields = [
    "processes",
    "slotsPerWorker",
    "runAsUid",
    "runAsGid",
    "completions",
    "parallelism",
    "backoffLimit",
    "target",
    "minScale",
    "maxScale",
    "wandbCount"
];

export const mapItemsWorkLoadFields = ["ports", "environment","annotations", "labels", "pvcs", "gitSync", "volumes", "s3", "tolerations", "extendedResources", "capabilities", "exposedUrls", "nodePools"];
export const arrayJoinInnerMapItemsWorkloadFields = ["nodePools"];

export const workloadToSettings = new Map();
workloadToSettings.set("Training", "TrainingWorkload");
workloadToSettings.set("Inference", "InferenceWorkload");
workloadToSettings.set("Interactive", "InteractiveWorkload");
workloadToSettings.set("Distributed", "DistributedWorkload");
