import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b312f21"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "submit-form-loading-error-details" }
const _hoisted_3 = {
  key: 0,
  class: "submit-form-loading-error-show-details-button-wrapper"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_note = _resolveComponent("note")!

  return (_openBlock(), _createBlock(_component_note, {
    class: "submit-form-loading-error",
    type: "error",
    style: {"margin":"24px"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "submit-form-loading-error-message",
        innerHTML: _ctx.clusterConnectionError.message
      }, null, 8, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.clusterConnectionError.details)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_Button, {
                class: "submit-form-loading-error-show-details-button",
                text: _ctx.showDetails ? 'Hide details' : 'Show more details',
                "button-type": "secondary",
                onClick: _ctx.toggle,
                aid: "show-more-error-details"
              }, null, 8, ["text", "onClick"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.showDetails)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "submit-form-loading-error-details-text",
              innerHTML: _ctx.clusterConnectionError.details
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}