import { ref, watch } from "vue";
import { useIntervalInvoker } from "./IntervalInvoker";
import { Cache } from "./Cache";
import { showError } from "@/core-ui/helpers/notifications";
import { useToast } from "vue-toastification";

export type FetcherState = "initialize" | "failure" | "success" | "idle" | "refresh" | "update";

export type SyncMethod = {
    method: "Polling";
    interval: number;
    stopOnFailure?: boolean;
};

type CacheFetcherProps<T> = {
    key?: string;
    fetch: () => Promise<T> | T;
    cache: Cache;
    syncMethod?: SyncMethod;
};

export type Fetcher = {
    state: FetcherState;
    fetch: () => void;
    lastUpdate: number | null;
    lastFailure: number | null;

    // future:
    // subscribe(key: any),
    // unsubscribe(key: any)
};

export function useFetcher<T>(props: CacheFetcherProps<T>): Fetcher {
    let isInit = false;
    const status = ref<FetcherState>("idle");
    const lastUpdate = ref<number | null>(null);
    const lastFailure = ref<number | null>(null);

    let needsToLoad = false;
    let lastFetchKey = props.key;

    // currently we supported only pulling method
    const puller = useIntervalInvoker({
        func: fetch,
        interval: props.syncMethod?.interval,
        stopOnFailure: props.syncMethod?.stopOnFailure,
    });

    watch(
        () => props.key,
        () => {
            // reset the status
            status.value = "idle";
            lastUpdate.value = null;
            // check if the fetcher init
            isInit && invoke();
        },
        { immediate: true },
    );

    function invoke() {
        // if sync method not defined stop the puller (it will fetch the data once)
        props.syncMethod ? puller.start() : puller.once();
    }

    function updateStatus(isRefresh: boolean) {
        const current = status.value;
        if (current == "idle") {
            status.value = "initialize";
        } else {
            isRefresh ? (status.value = "refresh") : (status.value = "update");
        }
    }

    async function fetch(isRefresh?: boolean) {
        // if loading the current fetch key wait until the request finished
        if (isLoading(status.value) && lastFetchKey == props.key) {
            needsToLoad = true;
            return;
        }
        // reset and init
        needsToLoad = false;
        const fetchKey = (lastFetchKey = props.key);
        try {
            updateStatus(!!isRefresh);
            const result = await props.fetch();
            // if the key of the data change while the fetcher, not updating the cache and status
            if (fetchKey != props.key) return;
            props.cache.set(result);
            status.value = "success";
            lastUpdate.value = Date.now();
        } catch (err) {
            if (fetchKey != props.key) return;
            lastFailure.value = Date.now();
            status.value = "failure";
            console.error(`Failed to fetch data`, err);
            return Promise.reject();
        } finally {
            if (needsToLoad) invoke();
        }
    }

    return {
        get lastUpdate() {
            return lastUpdate.value;
        },
        get lastFailure() {
            return lastFailure.value;
        },

        get state() {
            return status.value as FetcherState;
        },
        fetch() {
            isInit = true;
            invoke();
        },
    };
}

export function isInitOrUpdate(status: FetcherState) {
    return status == "initialize" || status == "update";
}

export function isLoading(status: FetcherState) {
    return isInitOrUpdate(status) || status == "refresh";
}

export function isLazyCacheNeedToRefresh(
    cache: {
        lastUpdate: null | number;
        lastFailure: null | number;
        state: FetcherState;
    },
    expTimestamp = 10000,
) {
    return (
        !isLoading(cache.state) &&
        (!cache.lastUpdate || cache.lastUpdate < Date.now() - expTimestamp) &&
        (!cache.lastFailure || cache.lastFailure < Date.now() - expTimestamp)
    );
}
