import { handleAction } from "./Action";
import { ModelMeta } from "./DataGridModel";
import { ActionTriggerResult } from "../types";
import { useToast } from "vue-toastification";

export enum ActionOn {
    None = "none",
    Item = "item",

    // future:
    // Items = "items"
}

type GeneralActionFunc = () => ActionTriggerResult;

type OnItemActionFunc = (item: unknown) => ActionTriggerResult;

type IsPermitted = boolean | (() => boolean);

export interface Action {
    key: string;
    label: string;
    icon?: string;
    permitted?: IsPermitted;
    disabled?: boolean | string;
}

type ActionDisabledFunc = (item?: any) => boolean | string;

export type ItemAction = Action & {
    on: ActionOn.Item;
    func: OnItemActionFunc;
    filter?: (item?: any) => boolean;
    disabled?: ActionDisabledFunc;
    aid?: string;
};
export type GlobalAction = Action & {
    on: ActionOn.None;
    func: GeneralActionFunc;
    filter?: () => boolean;
    disabled?: ActionDisabledFunc;
    aid?: string;
};

export type DataGridAction = GlobalAction | ItemAction;

export interface ActionsProps {
    actions: DataGridAction[];
}

interface CRUDActions {
    create?: GeneralActionFunc;
    update?: OnItemActionFunc;
    remove?: OnItemActionFunc;
}

const presets = {
    create: ({ entity }: { entity: string }) => ({
        on: ActionOn.None,
        key: "create",
        label: `+ New ${entity}`,
        aid: `add-new-${entity}`,
    }),

    update: ({ entity }: { entity: string }) => ({
        on: ActionOn.Item,
        key: "update",
        icon: "raicon-edit",
        label: `Edit ${entity}`,
        aid: `edit-${entity}`,
    }),

    remove: ({ entity }: { entity: string }) => ({
        on: ActionOn.Item,
        key: "remove",
        icon: "raicon-remove",
        label: `Delete ${entity}`,
        aid: `delete-${entity}`,
    }),
};

function getPreset<T>(name: string, modelMeta: ModelMeta<T>) {
    const preset = (presets as any)[name];
    return typeof preset == "function" ? preset({ entity: toCamelCase(modelMeta.singular) }) : null;
}

export function createCRUDActions<T>(
    modelMeta: ModelMeta<T>,
    crudActions: CRUDActions,
    isPermitted: IsPermitted,
): DataGridAction[] {
    const actions = [];
    for (const [action, func] of Object.entries(crudActions)) {
        const preset = getPreset(action, modelMeta);
        if (!preset) throw Error(`[createCRUDActions] unknown action: ${action}`);

        actions.push({
            ...preset,
            permitted: isPermitted,
            func,
        });
    }
    return actions;
}

type ActionContext<T> = undefined | T | T[];

export interface Actions<T = unknown> {
    emit(name: string, context: ActionContext<T>): void;
    onItem: Action[];
    global: Action[];
}

export function useActions<T>(props: ActionsProps): Actions<T> {
    const toast = useToast();

    function emit(actionKey: string, context?: ActionContext<T>) {
        const action = props.actions.find(({ key }) => key == actionKey);
        // todo: send message to the user
        if (!action) {
            return;
        }

        handleAction(action.label, action.func.bind(null, context), toast);
    }

    return {
        emit,
        get onItem() {
            return props.actions
                .filter(({ on, permitted }) => on == ActionOn.Item && checkPermission(permitted))
                .map(mapAction);
        },
        get global() {
            return props.actions
                .filter(({ on, permitted }) => on == ActionOn.None && checkPermission(permitted))
                .map(mapAction);
        },
    };
}

// helpers
function checkPermission(permitted: any) {
    return permitted == undefined || typeof permitted == "function" ? permitted() : permitted;
}

function mapAction({ key, label, icon, filter, disabled, aid }: DataGridAction): DataGridAction | Action {
    return { key, label, icon, filter, disabled, aid };
}

function toCamelCase(str = ""): string {
    if (str[0]) {
        return str[0].toUpperCase() + str.substr(1);
    }
    return str;
}
