import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e183d260"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "side-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_button = _resolveComponent("icon-button")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
      return (_openBlock(), _createBlock(_component_icon_button, {
        style: {"background":"unset","box-shadow":"unset"},
        type: "secondary",
        key: action.key,
        disabled: action.disabled,
        onClick: _withModifiers(action.func, ["prevent"])
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("i", {
            class: _normalizeClass(action.icon)
          }, null, 2), [
            [_directive_tippy, { content: action.label }]
          ])
        ]),
        _: 2
      }, 1032, ["disabled", "onClick"]))
    }), 128))
  ]))
}