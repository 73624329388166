import { computed, nextTick, onMounted, onUnmounted, ref, Ref, watch } from "vue";

export type AutocompleteArgs = {
    onSelect?(item: any): void;
    suggestions: any[];
};

const SUGGESTIONS_SCROLL_CLASS = "autocomplete_suggestions_scroll";
const SUGGESTION_CLASS = "autocomplete_suggestion";

export type Autocomplete = {
    SUGGESTIONS_SCROLL_CLASS: string;
    SUGGESTION_CLASS: string;
    suggestions: any[];
    open: boolean;
    query: string;
    activeSuggestionIndex: number;
    queryRef: Ref<HTMLInputElement | undefined>;
    queryContainerRef: Ref<HTMLBaseElement | undefined>;
    containerRef: Ref<HTMLBaseElement | undefined>;
    selectByIndex(i: number): void;
};

export function useAutocomplete(args: AutocompleteArgs): Autocomplete {
    const query = ref();
    const activeIndex = ref(-1);
    const focus = ref(false);
    const close = ref(false);

    const queryRef = ref<HTMLInputElement>();
    const queryContainerRef = ref<HTMLBaseElement>();
    const containerRef = ref<HTMLBaseElement>();
    const suggestions = computed(() => {
        return (query.value ? args.suggestions!.filter(createFilter(query.value)) : args.suggestions).map(
            mapSuggestion(query.value),
        );
    });

    watch(query, async (n, p) => {
        // handle undefine that change to ""
        if (!n == !p && !n == true) {
            return;
        }
        close.value = false;
        resetActiveOpenAndScroll();
        await nextTick();
        locateContainer();
    });
    watch(queryRef, listenToQueryEvents);
    watch([queryRef, queryContainerRef, containerRef], locateContainer);

    onMounted(() => {
        document.addEventListener("scroll", locateContainer, true);
    });

    onUnmounted(() => {
        document.removeEventListener("scroll", locateContainer, true);
    });

    function listenToQueryEvents() {
        const qElm = queryRef.value;
        if (!qElm) {
            return;
        }

        qElm.addEventListener("blur", async () => {
            await wait(200);
            resetActiveOpenAndScroll();
        });
        qElm.addEventListener("keydown", onKeyDown);
        qElm.addEventListener("focus", async () => {
            close.value = false;
            resetActiveOpenAndScroll();
            await nextTick();
            locateContainer();
        });
        qElm.addEventListener("keyup", (e) => {
            query.value = (e.target! as HTMLInputElement).value;
        });
        qElm.addEventListener("change", (e) => {
            query.value = (e.target! as HTMLInputElement).value;
        });
    }

    function locateContainer() {
        const refElm = queryContainerRef.value || queryRef.value;
        const popupElm = containerRef.value;
        if (!popupElm || !refElm) {
            return;
        }
        const winSize = window.innerHeight;
        const popupHeight = popupElm.clientHeight;
        //const top =   getOffsetTop(triggerOn.current);
        const elemRect = refElm.getBoundingClientRect();
        let top, bottom;
        // there is different between chrome and edge be careful with this object (elemRect)
        top = elemRect.top + elemRect.height;

        // if the screen size smaller then popper change the position of the popper up from the target
        if (typeof popupHeight == "number" && top + popupHeight > winSize) {
            top = undefined;
            bottom = winSize - elemRect.y;
        }
        popupElm.style.top = pxOrUndefined(top) as string;
        popupElm.style.bottom = pxOrUndefined(bottom) as string;
        popupElm.style.position = "fixed";
        popupElm.style.minWidth = elemRect.width + "px";
    }

    function resetActiveOpenAndScroll() {
        // console.log("reset from index value", activeIndex.value)
        activeIndex.value = -1;
        if (containerRef.value) {
            containerRef.value.scrollTop = 0;
        }
        // is input in focus
        focus.value = !!queryRef.value && queryRef.value == document.activeElement;
    }

    function onKeyDown(e: KeyboardEvent) {
        switch (e.key) {
            case "ArrowUp":
                move(-1);
                break;
            case "ArrowDown":
                move(1);
                break;
            case "Enter":
                selectByIndex(activeIndex.value);
                break;
            case "Backspace":
                break;
            case "Escape":
                close.value = true;
        }
    }

    async function selectByIndex(i: number) {
        if (args.onSelect && i >= 0 && i < suggestions.value.length) {
            args.onSelect(suggestions.value[i]);
            await wait(200);
            close.value = true;
        }
    }

    function move(steps: number) {
        const desired = activeIndex.value + steps;
        if (!containerRef.value) {
            return;
        }
        const suggestion = containerRef.value.querySelector(`.${SUGGESTIONS_SCROLL_CLASS}`) || containerRef.value;

        if (desired < -1) {
            activeIndex.value = suggestions.value.length - 1;
            suggestion.scrollTop = suggestion.scrollHeight - suggestion.clientHeight;
            return;
        } else if (suggestions.value.length <= desired) {
            activeIndex.value = -1;
            suggestion.scrollTop = 0;
            return;
        } else {
            activeIndex.value = desired;
        }
        const index = activeIndex.value;
        if (index == -1) {
            return;
        }
        const suggestionList = suggestion.querySelectorAll(`.${SUGGESTION_CLASS}`);
        const highlightItem = suggestionList[index];
        const scrollTop = suggestion.scrollTop;
        const offsetTop = (highlightItem! as any).offsetTop;
        if (offsetTop + highlightItem.scrollHeight > scrollTop + suggestion.clientHeight) {
            suggestion.scrollTop += highlightItem.scrollHeight;
        }
        if (offsetTop < scrollTop) {
            suggestion.scrollTop -= highlightItem.scrollHeight;
        }
    }

    return {
        SUGGESTIONS_SCROLL_CLASS,
        SUGGESTION_CLASS,
        get suggestions() {
            return suggestions.value;
        },
        get open() {
            return focus.value && !close.value;
        },
        get query() {
            return query.value;
        },
        get activeSuggestionIndex() {
            return activeIndex.value;
        },
        selectByIndex,
        queryRef,
        containerRef,
        queryContainerRef,
    };
}

// utils

const mapSuggestion = (q: string) => (s: any) => {
    const o = typeof s == "object" ? { ...s } : { value: s };
    o.html = q ? highlight(q, o.value) : o.value;
    return o;
};

const createFilter = (queryString: string) => {
    return (o: any) => {
        return (typeof o == "object" ? o.value : o).toLowerCase().includes(queryString.toLowerCase());
    };
};

function pxOrUndefined(n?: number): string | undefined {
    if (n === undefined) {
        return;
    }
    return `${n}px`;
}

function wait(time: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, time);
    });
}

function highlight(q: string, v: string) {
    let result = "";
    const start = v.indexOf(q, 0);
    const end = start + q.length;
    if (start) {
        result = htmlEscape(v.substr(0, start));
    }
    result += `<b>${htmlEscape(v.substr(start, end - start))}</b>`;
    result += htmlEscape(v.substr(end));
    return result;
}

function htmlEscape(str: string) {
    return str.replace(/&/g, "&").replace(/'/g, "'").replace(/"/g, '"').replace(/>/g, ">").replace(/</g, "<");
}
