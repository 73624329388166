
import { ref, defineComponent, watch, watchEffect, computed } from "vue";
import { customCtrlArgs, variant } from "@/core-ui/forms/components/common";
import Box from "@/core-ui/forms/components/Box.vue";
import IconButton from "@/core-ui/IconButton.vue";
import { ContainerCtrlMeta, useObjCtrl } from "@/core-ui/forms/compositions";
import BoxInputCtrl from "@/core-ui/forms/components/BoxInputCtrl.vue";
import BoxSelectCtrl from "@/core-ui/forms/components/BoxSelectCtrl.vue";

type meta = ContainerCtrlMeta;

export default defineComponent({
    props: {
        variant,
        ctrlArgs: customCtrlArgs<meta>(),
        canEdit: { type: Boolean, default: true },
        canRemove: { type: Boolean, default: true },
    },
    components: { Box, IconButton, BoxInputCtrl, BoxSelectCtrl },
    setup(props) {
        const ctrl = useObjCtrl(props.ctrlArgs!);
        function getArgs(key: string): any {
            return ctrl.meta.fields[key](ctrl.state, key).args;
        }

        const temporaryDisabled = ref(true);

        const self = {
            itemCanEdit: props.canEdit,
            itemCanRemove: props.canRemove,

            ctrl,
            effect: getArgs("effect"),
            operator: getArgs("operator"),
            seconds: getArgs("seconds"),
            tolerationKey: getArgs("tolerationKey"),
            tolerationValue: getArgs("tolerationValue"),
            temporaryDisabled,
        };

        watch(
            () => (ctrl.value as any).operator,
            (newVal, oldVal) => {
                if (newVal === "Exists") {
                    temporaryDisabled.value = true;
                    if (ctrl.children.get("tolerationValue")) {
                        ctrl.children.get("tolerationValue")!.value = undefined;
                    }
                } else {
                    temporaryDisabled.value = false;
                }
            },
            { immediate: true },
        );

        return self;
    },
});
