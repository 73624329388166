import { FetcherState, useCache, SyncMethod, useFetcher } from "@/core-ui/data-grid/compositions";
import { ref, watch } from "vue";

export type UsingCacheFetch<T, F = unknown> = {
    data: T;
    filter: F;
    state: FetcherState;
    lastUpdate: number | null;
    lastFailure: number | null;

    fetch: (filter?: F) => void;
    refresh: () => void;
};

export type CatchFetchProps<T, F = unknown> = {
    fetch: (filter?: F) => Promise<T> | T;
    key?: string;
    defaultData?: T | null;
    defaultFilter?: F | null;
    map?: (val: any) => T;
    syncMethod?: SyncMethod;
};

export function useCacheFetch<T = unknown, F = unknown>(props: CatchFetchProps<T, F>): UsingCacheFetch<T, F> {
    const filter = ref();

    watch(
        () => props.key,
        () => (filter.value = props.defaultFilter as any),
        { immediate: true },
    );

    function fetch() {
        return props.fetch(filter.value);
    }

    const cache = useCache({
        get key() {
            return props.key;
        },
        get map() {
            return props.map;
        },
        get defaultValue() {
            return props.defaultData;
        },
    });

    const fetcher = useFetcher({
        get key() {
            return props.key;
        },
        get syncMethod() {
            return props.syncMethod;
        },
        fetch,
        cache,
    });

    return {
        get data(): T {
            return cache.data as T;
        },
        get state() {
            return fetcher.state;
        },
        get lastUpdate() {
            return fetcher.lastUpdate;
        },
        get lastFailure() {
            return fetcher.lastFailure;
        },

        get filter() {
            return filter.value;
        },
        fetch: (newFilter?: F) => {
            filter.value = newFilter as any;
            fetcher.fetch();
        },
        refresh() {
            fetcher.fetch();
        },
    };
}
