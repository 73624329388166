import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dab6f5ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _mergeProps({ class: "from-section-container" }, _ctx.$attrs), [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h2", {
          key: 0,
          innerHTML: _ctx.title,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('headerClick')))
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 16))
}