import { ILicense, ISignedLicense } from "@/types/license";
import apiClient from "./api-client";
import prometheusService from "./prometheus-service";

export const licenseService = {
    list,
    upload,
    getTotalUsedGPUs,
};

const ENDPOINT = `api/v1/tenants`;

async function list(tenantId: string): Promise<Array<ILicense>> {
    return apiClient.asyncRequestWithResponse(`${ENDPOINT}/${tenantId}/license?feature=gpu`, "GET");
}

async function upload(tenantId: string, license: File): Promise<ISignedLicense> {
    return apiClient.asyncRequestWithResponse(`${ENDPOINT}/${tenantId}/license/signed`, "POST", license);
}

async function getTotalUsedGPUs(): Promise<number> {
    const result = await prometheusService.query({
        totalGpus: "sum(runai_gpu_count_per_node) or vector(0)",
    });
    return +result[0].data[0].value[1];
}
