
import { defineComponent, computed } from "vue";
import PageNotificationAction from "./PageNotificationAction.vue";
import { formatMarkdown } from "@/utils/markdownUtil";
import useConnectionStatusNotification from "@/compositions/useConnectionStatusNotification";

export default defineComponent({
    components: { PageNotificationAction },
    setup() {
        const { errorMessage } = useConnectionStatusNotification();
        const notificationMsg = computed(() => (errorMessage.value ? formatMarkdown(errorMessage.value) : null));

        return {
            notificationType: "warning",
            notificationMsg,
        };
    },
});
