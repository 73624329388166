import { ActionService } from "../services";
import { ActionMsg, MessageType, ActionTrigger } from "../types";

// future:
// export function useAction() {
//     //
// }

/**
 * Handle with action message
 *
 * @export
 * @param {ActionService} actionService
 * @param {string} name
 * @param {ActionTrigger} trigger
 */
export async function HandleAction(
    actionService: ActionService,
    name: string,
    trigger: ActionTrigger,
    toast?: Function,
) {
    const display = actionService.start(toast);
    try {
        const triggerResult = await trigger();

        // check the result

        // if generator iterate it
        if (isIterable(triggerResult)) {
            for await (const result of triggerResult as Generator<ActionMsg> | AsyncGenerator<ActionMsg>) {
                if (isActionMsg(result)) {
                    display?.show(result as ActionMsg);
                }
            }
        } else {
            if (isActionMsg(triggerResult)) {
                display.show(triggerResult as ActionMsg);
            }
        }
    } catch (err: any) {
        console.warn(err)
        const errorStr = `Failed to run '${name}'`;
        const lowerCaseErr = err.toLowerCase();
        const isAccessDenied = lowerCaseErr.includes("access denied") || lowerCaseErr.includes("not allowed");
        display.show({
            type: "failure",
            err: err,
            msg: isAccessDenied ? `${errorStr}: Access Denied` : errorStr,
        });
    } finally {
        display?.end();
    }
}

// future: This code is temporary until adding notification that can live a long time and change itself
const ActionServiceMock: ActionService = {
    start(toast: any) {
        return {
            show(msg: ActionMsg) {
                switch (msg.type) {
                    case MessageType.pending:
                        // do nothing
                        // future: hide the action notification
                        break;
                    case MessageType.success:
                        toast.success(msg.msg);
                        break;
                    case MessageType.failure:
                        toast.error(msg.msg);
                        break;
                    case MessageType.info:
                    case MessageType.process:
                        toast.info(msg.msg);
                        break;
                }
            },
            end(duration = 3000) {
                // future: close the notification
            },
        };
    },
};

export const handleAction = HandleAction.bind(null, ActionServiceMock);

// helpers

export function isActionMsg(msg: any) {
    return msg && msg.type in MessageType;
}

function isIterable(obj: any) {
    // checks for null and undefined
    return obj && (typeof obj[Symbol.iterator] === "function" || typeof obj[Symbol.asyncIterator] === "function");
}
