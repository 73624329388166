import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_BoxInputMessages = _resolveComponent("BoxInputMessages")!
  const _component_Box = _resolveComponent("Box")!

  return (_openBlock(), _createBlock(_component_Box, {
    ctrl: _ctx.fieldCtrl,
    theme: _ctx.theme,
    variant: _ctx.variant
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BoxInputMessages, {
        variant: _ctx.variant,
        ctrl: _ctx.fieldCtrl
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Input, _mergeProps({
            theme: _ctx.theme,
            name: _ctx.fieldCtrl.key,
            invalid: _ctx.invalid,
            type: _ctx.inputType
          }, _ctx.attrs, {
            dirty: _ctx.fieldCtrl.dirty,
            required: _ctx.fieldCtrl.required,
            disabled: _ctx.fieldCtrl.disabled || _ctx.temporaryDisabled,
            modelValue: _ctx.fieldCtrl.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fieldCtrl.value) = $event)),
            suggestions: _ctx.autocompleteData.data,
            tooltipText: _ctx.fieldCtrl.context.tooltipText
          }), null, 16, ["theme", "name", "invalid", "type", "dirty", "required", "disabled", "modelValue", "suggestions", "tooltipText"])
        ]),
        _: 1
      }, 8, ["variant", "ctrl"])
    ]),
    _: 1
  }, 8, ["ctrl", "theme", "variant"]))
}