
import IconButton from "@/core-ui/IconButton.vue";
import { defineComponent, PropType } from "vue";

type Msg = { type: string; text?: string } | string;

export default defineComponent({
    components: { IconButton },
    props: {
        msg: Array as PropType<Msg[]>,
    },
    setup(_, ctx) {
        return {
            onSectionClicked(section: any) {
                if (section.type == "action") {
                    ctx.emit(section.name || section.text.trim());
                }
            },
        };
    },
});
