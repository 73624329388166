import { Order } from "@/core-ui/types/order";
import { ref, watch } from "vue";
import isEqual from "lodash/isEqual";
export interface FilterAPI {
    query: { [key: string]: string };
    sort_by: Order;
    page: number;
    items_per_page: number;
}

export interface Filter {
    query: string;
    sort_by: Order[];
    page: number;
    items_per_page: number;
}

interface Props extends Filter {
    emitPage(page: number): void;
}

interface FilterCtrl {
    filter: Filter;
}

/**
 *
 * FilterCtrl control the filter data by resetting the page when sort, query or items_per_page are changed and expose the final filter result.
 * The FilterCtrl keeps its watchers to change only when they are need to.
 *  e.g When the page is 5 and the user change the sort by
 *  we want first to reset the page to 1 and than sync the filter data only once
 *
 * @export
 * @param {Props} props
 * @returns {FilterCtrl}
 */
export function useFilterCtrl(props: Props): FilterCtrl {
    const filter = ref({} as Filter);

    const syncFilter = () => {
        const { query, page, items_per_page, sort_by } = props;
        filter.value = { query, page, items_per_page, sort_by };
    };

    watch(
        [() => props.page, () => props.items_per_page, () => props.query, () => props.sort_by],
        (p: any[], s: any) => {
            const page = p[0] as number;

            // sync filter only if watch init, page change or page is equal to one
            !s || (s && s[0] !== page) || (page as any) == 1 ? syncFilter() : !isEqual(p, s) && props.emitPage(1);
        },
        { immediate: true },
    );

    return {
        get filter() {
            return filter.value;
        },
    };
}
