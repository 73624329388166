import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBAutocomplete = _resolveComponent("MDBAutocomplete")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_BoxInputMessages = _resolveComponent("BoxInputMessages")!
  const _component_Box = _resolveComponent("Box")!

  return (_openBlock(), _createBlock(_component_Box, {
    ctrl: _ctx.fieldCtrl,
    variant: _ctx.variant,
    class: "box-autocomplete"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BoxInputMessages, {
        variant: _ctx.variant,
        ctrl: _ctx.fieldCtrl
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MDBAutocomplete, {
            modelValue: _ctx.searchTerm,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
            filter: _ctx.filterOptions,
            onFocus: _ctx.onFocus,
            onItemSelect: _ctx.onChange,
            onClose: _ctx.onChange
          }, null, 8, ["modelValue", "filter", "onFocus", "onItemSelect", "onClose"]),
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_ProgressBar, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["variant", "ctrl"])
    ]),
    _: 1
  }, 8, ["ctrl", "variant"]))
}