
import { computed, defineComponent, watchEffect } from "vue";
import { variant, customCtrlArgs } from "@/core-ui/forms/components/common";
import Box from "@/core-ui/forms/components/Box.vue";
import { ContainerCtrlMeta, useObjCtrl } from "@/core-ui/forms/compositions";
import IconButton from "@/core-ui/IconButton.vue";
import BoxInputCtrl from "@/core-ui/forms/components/BoxInputCtrl.vue";
import BoxToggleCtrl from "@/core-ui/forms/components/BoxToggleCtrl.vue";
import BoxSelectCtrl from "@/core-ui/forms/components/BoxSelectCtrl.vue";
import { getCurrentSettingsName } from "@/cluster-ui/helpers/commonFunctions";
import { InferenceJob } from "@/cluster-ui/helpers/const";

type ServiceType = "Ingress" | "NodePort" | "LoadBalancer" | "ClusterIP";
type meta = ContainerCtrlMeta & {
    serviceType: ServiceType;
};
type PortValue = {
    external?: number;
    container: number;
    autoGenerate?: boolean;
    protocol?: string;
};

export default defineComponent({
    props: {
        variant,
        ctrlArgs: customCtrlArgs<meta>(),
        canEdit: { type: Boolean, default: true },
        canRemove: { type: Boolean, default: true },
    },
    components: { Box, IconButton, BoxInputCtrl, BoxToggleCtrl, BoxSelectCtrl },
    setup(props) {
        const ctrl = useObjCtrl(props.ctrlArgs!);

        ctrl.context.serviceType = ctrl.context.serviceType || ctrl.meta?.serviceType || "ingress";

        const cache: Partial<PortValue> = {
            container: undefined,
            external: undefined,
            autoGenerate: true,
            protocol: undefined,
        };

        const config = computed(() => {
            let justKey = false,
                defaultValueEqualKey = false,
                showAutoGenerate = false,
                showProtocol = false;
            switch (ctrl.context.serviceType as ServiceType) {
                case "Ingress":
                    justKey = true;
                    break;
                case "NodePort":
                    showAutoGenerate = true;
                    break;
                case "LoadBalancer":
                    defaultValueEqualKey = true;
                    break;
                case "ClusterIP":
                    defaultValueEqualKey = true;
                    break;
            }
            if (getCurrentSettingsName() == InferenceJob) {
                justKey = true;
                showProtocol = true;
            }
            return { justKey, defaultValueEqualKey, showAutoGenerate, showProtocol };
        });

        watchEffect(() => {
            const externalPortCtrl = ctrl.children.get("external");
            const autoGenerateCtrl = ctrl.children.get("autoGenerate");
            //  "!ctrl.value.autoGenerate && !config.defaultValueEqualKey"

            if (externalPortCtrl && autoGenerateCtrl && autoGenerateCtrl.value) {
                externalPortCtrl.context.editable = false;
                externalPortCtrl.context.required = false;

                cache.external = externalPortCtrl.value as number;
                externalPortCtrl.value = undefined;
            } else if (externalPortCtrl) {
                externalPortCtrl.context.editable = true;
                externalPortCtrl.context.required = !autoGenerateCtrl?.value && !config.value.defaultValueEqualKey;

                if (cache.external !== undefined) {
                    externalPortCtrl.value = cache.external;
                    cache.external = undefined;
                }
            }
        });

        function getArgs(key: string): any {
            if(ctrl.meta.fields[key]){
            const args = ctrl.meta.fields[key](ctrl.state, key).args;
            return args;
            }
        }

        return {
            itemCanEdit: props.canEdit,
            itemCanRemove: props.canRemove,
            ctrl,
            config,
            get dirty() {
                return ctrl.dirty;
            },
            get invalid() {
                return ctrl.valid == "invalid";
            },
            autoGenerate: getArgs("autoGenerate"),
            container: getArgs("container"),
            external: getArgs("external"),
            protocol: getArgs("protocol"),
            externalPortPlaceholder: computed(() => {
                if (
                    config.value.defaultValueEqualKey &&
                    (ctrl.value as PortValue).container &&
                    !(ctrl.value as PortValue).external
                ) {
                    return "" + (ctrl.value as PortValue).container;
                } else {
                    return "External Port" + ((ctrl.value as PortValue).autoGenerate ? " (Auto-generate)" : "");
                }
            }),
        };
    },
});
