
import { defineComponent } from "vue";
import SideActions from "./SideActions.vue";

export default defineComponent({
    emits: ["click"],
    components: { SideActions },
    props: {
        selected: Boolean,
        label: String,
        actions: Array,
    },
    setup(props) {
        return {
            get tooltip() {
                if (typeof props.label == "string" && props.label.length > 22) {
                    return { content: props.label };
                }
                return undefined;
            },
        };
    },
});
