import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputField = _resolveComponent("InputField")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_form_focus = _resolveDirective("form-focus")!

  return (_openBlock(), _createBlock(_component_Modal, {
    title: "Reset Password",
    onCancel: _ctx.cancel
  }, {
    body: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("form", {
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
        id: "change-password-form",
        autocomplete: "off"
      }, [
        _createVNode(_component_InputField, {
          type: "password",
          name: "Current Password",
          modelValue: _ctx.currentPassword,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPassword) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_InputField, {
          type: "password",
          name: "New Password",
          validation: _ctx.passwordValidation,
          modelValue: _ctx.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
        }, null, 8, ["validation", "modelValue"]),
        _createVNode(_component_InputField, {
          type: "password",
          name: "Confirm Password",
          validation: _ctx.verifyPasswordValidation,
          modelValue: _ctx.verifyPassword,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.verifyPassword) = $event))
        }, null, 8, ["validation", "modelValue"])
      ], 32)), [
        [_directive_form_focus]
      ])
    ]),
    buttons: _withCtx(() => [
      _createVNode(_component_Button, {
        text: "Reset Password",
        "button-type": "main",
        form: "change-password-form",
        type: "submit",
        disabled: !_ctx.currentPassword || !_ctx.password || _ctx.password !== _ctx.verifyPassword || _ctx.submitted,
        clicked: _ctx.submitted,
        aid: "change-password-modal-reset-btn"
      }, null, 8, ["disabled", "clicked"]),
      (!_ctx.force)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: "cancel-button",
            text: "Cancel",
            "button-type": "secondary",
            onClick: _ctx.cancel,
            clicked: _ctx.submitted,
            aid: "change-password-modal-cancel-btn"
          }, null, 8, ["onClick", "clicked"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onCancel"]))
}