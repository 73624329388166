
import BoxSelectCtrl from "@/core-ui/forms/components/BoxSelectCtrl.vue";
import { useFieldCtrlArgsFromProps, useInputPipeAndMeta } from "@/core-ui/forms/compositions";
import { defineComponent, PropType, ref } from "vue";
import { fieldCtrl, inputName, variant } from "./common";

type Option = { name: string; value: string | number };
type O = Option[] | string[] | number[];
type OptionsArg = O | (() => O | Promise<O>);

export default defineComponent({
    components: { BoxSelectCtrl },
    props: {
        variant,
        options: [String, Number, Object] as PropType<OptionsArg>,
        ctrl: fieldCtrl<string>(),
        name: inputName,
        label: String,
        description: String,
        default: String,
        required: Boolean,
        onInputChange: Function,
        placeholder: String,
        category: String,
    },

    setup: (props, ctx) => {
        const ctrlArgs = useFieldCtrlArgsFromProps({
            props,
            pipeAndMeta: useInputPipeAndMeta(props as any),
        });

        return {
            ctrlArgs,
            get attrs(): any {
                return {
                    name: props.name,
                    required: props.required,
                    ...ctx.attrs,
                };
            },
        };
    },
});
