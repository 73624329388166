import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3116bd76"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "editor-container"
}
const _hoisted_2 = {
  key: 0,
  class: "error-section"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_YamlEditor = _resolveComponent("YamlEditor")!
  const _component_BoxInputCtrl = _resolveComponent("BoxInputCtrl")!
  const _component_Box = _resolveComponent("Box")!

  return (_openBlock(), _createBlock(_component_Box, _mergeProps({
    ctrl: _ctx.ctrl,
    variant: _ctx.variant
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      (!_ctx.ctrl.context.hidden)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            _createVNode(_component_YamlEditor, {
              ref: "editorCmpRef",
              onChanged: _ctx.handleConfigChange,
              yaml: _ctx.yaml,
              onValidationStatus: _ctx.handleValidation
            }, null, 8, ["onChanged", "yaml", "onValidationStatus"]),
            (_ctx.editorInvalidReason)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.editorInvalidReason), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_BoxInputCtrl, _mergeProps({
              class: "flex",
              variant: "column"
            }, _ctx.entity, { "item-can-edit": "" }), null, 16),
            _createVNode(_component_BoxInputCtrl, _mergeProps({
              class: "flex",
              variant: "column"
            }, _ctx.project, { "item-can-edit": "" }), null, 16),
            _createVNode(_component_BoxInputCtrl, _mergeProps({
              class: "flex",
              variant: "column"
            }, _ctx.apiKey, { "item-can-edit": "" }), null, 16),
            _createVNode(_component_BoxInputCtrl, _mergeProps({
              class: "flex",
              variant: "column"
            }, _ctx.wandbCount, { "item-can-edit": "" }), null, 16)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["ctrl", "variant"]))
}