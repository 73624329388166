import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4a6a8b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ra-list-container" }
const _hoisted_2 = { class: "ra-list-header" }
const _hoisted_3 = {
  key: 1,
  style: {"min-height":"4px"}
}
const _hoisted_4 = {
  key: 2,
  class: "ra-list-body"
}
const _hoisted_5 = ["data-key"]
const _hoisted_6 = ["data-key"]
const _hoisted_7 = {
  key: 3,
  "data-key": "not_items_found"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_progress_bar = _resolveComponent("progress-bar")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_icon_button, {
        style: {"box-shadow":"unset"},
        onClick: _ctx.toggle
      }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: _normalizeClass(["raicon-slide-down icon", { 'list-icon-close': !_ctx.open }])
          }, null, 2)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _renderSlot(_ctx.$slots, "header", {}, undefined, true)
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_progress_bar, {
          key: 0,
          style: {"max-height":"4px","min-height":"4px","overflow":"hidden"}
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_3)),
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group) => {
            return (_openBlock(), _createElementBlock("section", {
              key: group.key
            }, [
              (group.title)
                ? (_openBlock(), _createElementBlock("label", {
                    key: 0,
                    class: "ra-list-group-label",
                    "data-key": group.title
                  }, _toDisplayString(group.title) + ": ", 9, _hoisted_5))
                : _createCommentVNode("", true),
              _createVNode(_TransitionGroup, {
                onBeforeEnter: _ctx.beforeEnter,
                onEnter: _ctx.enter,
                onLeave: _ctx.leave,
                name: "list",
                tag: "section",
                class: _normalizeClass(["ra-list-group", { open: _ctx.open, 'list-group-close': !_ctx.open }])
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, (item) => {
                    return (_openBlock(), _createElementBlock("section", {
                      class: "ra-list-item",
                      "data-key": item[_ctx.itemId],
                      key: item[_ctx.itemId]
                    }, [
                      _renderSlot(_ctx.$slots, "item", {
                        selected: _ctx.selected == item[_ctx.itemId],
                        item: item
                      }, undefined, true)
                    ], 8, _hoisted_6))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["onBeforeEnter", "onEnter", "onLeave", "class"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.open && !_ctx.isLoading && !_ctx.isZeroItems && _ctx.$slots['no-items'])
      ? (_openBlock(), _createElementBlock("section", _hoisted_7, [
          _renderSlot(_ctx.$slots, "no-items", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}