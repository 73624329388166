import searchQueryParser, { SearchParserOptions, SearchParserResult } from "search-query-parser";
import { Column } from "@/core-ui/types/column";

export function getCompareValue(value: any, row?: unknown, dataTransform?: (val: any, row: any) => any) {
    if (dataTransform) {
        return dataTransform(value, row);
    } else if (typeof value !== "string") {
        return typeof value === "undefined" ? "" : value.toString();
    } else {
        return value;
    }
}

function compare(text: string, target: string | Array<string>): boolean {
    if (target instanceof Array) {
        return !!target.find((s) => new RegExp(s, "i").test(text));
    } else {
        return new RegExp(target, "i").test(text);
    }
}

export function getSearchObject(search: string, options: SearchParserOptions): SearchParserResult {
    const result: any = searchQueryParser.parse(search.trim(), options);
    if (typeof result === "string") {
        return { text: result };
    } else {
        const filter: any = result.offsets.reduce((o: any, { keyword, value }: any) => {
            if (value) {
                o[keyword] = value;
            }
            return o;
        }, {});
        if (result.text) {
            filter.text = result.text;
        }
        return filter;
    }
}

export function searchQuery<T = any>(
    items: Array<T>,
    columns: Column[],
    search: string,
    searchOptions: SearchParserOptions,
): Array<T> {
    const searchObj: SearchParserResult = getSearchObject(search, searchOptions);

    const relevantFilters = columns
        .filter((c) => !!searchObj[c.key])
        .map(({ key, dataKey, dataTransform }) => {
            const value =
                searchObj[key] instanceof Array ? searchObj[key].map((s: string) => s.trim()) : searchObj[key].trim();
            return {
                key,
                dataKey,
                dataTransform,
                value,
            };
        });

    return items.filter((item: any) => {
        if (relevantFilters.length) {
            const matchedColumns = relevantFilters.filter((f) =>
                compare(getCompareValue(item[f.dataKey], item, f.dataTransform), f.value),
            );
            return matchedColumns.length === relevantFilters.length; // must match by all filtered columns
        } else if (searchObj.text) {
            return !!columns.find((c) =>
                compare(getCompareValue(item[c.dataKey], item, c.dataTransform), searchObj.text as any),
            );
        }
        return true;
    });
}
