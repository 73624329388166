
import IFrame from "@/components/iframe.vue";
import clustersStore from "@/stores/clusters-store";

export default {
    components: {
        IFrame,
    },
    setup: () => ({
        get query(): any {
            return {
                "var-cluster": clustersStore.state.activeClusterUUID,
            };
        },
    }),
};
