import {
    Field,
    FieldsByKeys,
    FieldTypes,
    ObjComponents,
} from "@/core-ui/forms/types/declarative-fields";
import { deepCopyObject } from "../../helpers/commonFunctions";
import settingStore, { SettingKeys } from "@/stores/setting-store";

export enum JobCategories {
    Meta = "Meta",
    ContainerDefinition = "Container Definition",
    ResourceAllocation = "Resource Allocation",
    Storage = "Storage",
    JobLifecycle = "Scheduling & Lifecycle",
    WandbSweep = "Sweep by Weights & Biases"
}

export enum JobGroups {
    HPO = "HPO",
    Train = "Train",
    Simple = "Simple",
    MPI = "MPI",
}

export function applyJobSettings(fields: FieldsByKeys, settings: Record<string, any>) {
    for (const [key, field] of Object.entries(fields)) {
        if (settings[key]) {
            try {
                applySettingsToField(key, field, settings[key], settings);
            } catch (e) {
                console.warn("--error--------");
                console.warn("e >", e);
            }
        }
    }
}

/*
* some fields in "settings" have inner fields ("nodePools", "capabilities", etc.).
* for some of them (for example, for "nodePools"),
* we would want to take the inner fields' settings from "allSettings" (allSettings["nodePool"]["rules"]).
* while some other fields (for example, "capabilities") have the inner fields' settings
* within "settings" (=allSettings["capabilities"]) as "itemRules".
* */
function applySettingsToField(key: string, field: Field, settings: any, allSettings: Record<string, any>) {
    switch (field.type) {
        /////////////////////////////////////////////// Bool
        case FieldTypes.Bool:
            field.default = settings?.value;
            field.editable = settings?.rules?.canEdit != undefined ? settings?.rules?.canEdit : settings?.canEdit;
            break;

        /////////////////////////////////////////////// String
        case FieldTypes.String:
            field.required = settings?.rules?.required || settings?.required;
            field.default = settings?.value;
            field.editable = settings?.rules?.canEdit != undefined ? settings?.rules?.canEdit : settings?.canEdit;
            field.meta = field.meta ?? {};
            field.onInputChange = settings?.onInputChange;
            if (Array.isArray(settings?.rules?.options)) {
                field.meta!.options = settings?.rules?.options;
            } else if (settings?.suggestions) {
                field.meta.autocomplete = settings?.suggestions;
            } else if (Array.isArray(settings?.options)) {
                field.meta!.options = settings?.options;
                settings.rules = { options: settings?.options };
            }

            field.validation = field.validation || {};
            if (settings?.rules?.format) {
                field.validation!.format = settings?.rules.format;
            }

            if (settings?.rules?.options && !field.validation?.enum) {
                field.validation!.enum = settings.rules?.options.map((s: string | { value: string }) =>
                    typeof s == "string" ? s : s.value,
                );
            }
            break;

        /////////////////////////////////////////////// Number
        case FieldTypes.Number:
            let customStep
            if (key == 'gpu' || key == 'cpuLimit' || key == 'cpu') {
                customStep = "any"
            }
            field.required = settings?.rules?.required || settings?.required;
            field.default = settings?.value;
            field.editable = settings?.rules?.canEdit != undefined ? settings?.rules?.canEdit : settings?.canEdit;
            field.validation = field.validation || {};
            Object.assign(field.validation!, {
                max: Number(settings?.max) || Number(settings?.rules?.max),
                min: Number(settings?.min) || Number(settings?.rules?.min),
                step: Number(settings?.step) || Number(settings?.rules?.step) || customStep,
                format: settings?.rules?.format ?? field.validation!.format,
                //;//settings?.rules?.format ?? field.validation!.format,
            } as any);

            break;

        /////////////////////////////////////////////// Array
        // arguments, ports, gitSync, pvc, volumes, s3(s3Storage), environment, nodePools, annotations, labels
        case FieldTypes.Array:
            if (Array.isArray(settings?.value) || typeof settings?.items == "object") {
                field.default = extractDefaultValue(settings);
            }
            field.editable = settings?.rules?.canAdd;
            if (settings?.items) {
                field.defaultRules = Object.fromEntries(
                    Object.entries(settings?.items).map(([k, v]: any) => [
                        k,
                        {
                            canEdit: v?.rules?.canEdit == undefined ? true : v?.rules?.canEdit,
                            canRemove: v?.rules?.canRemove == undefined ? true : v?.rules?.canRemove,
                        },
                    ]),
                );
            }
            if (settings?.items || settings?.itemRules) {
                const sharedSettings = settings.itemRules;
                applySettingsToField("", field.item, sharedSettings, allSettings);
            } else if (settings && !settings.rules) {
                // this means that for each inner field, we will take its settings from 'allSettings'
                applySettingsToField("", field.item, undefined, allSettings);
            }

            break;

        /////////////////////////////////////////////// Map
        case FieldTypes.Map:
            if (settings?.items && Object.keys(settings?.items).length > 0) {
                field.default = Object.fromEntries(
                    Object.entries(settings?.items).map(([k, v]: any) => [k, v?.value || v]),
                );

                field.defaultRules = Object.fromEntries(
                    Object.entries(settings?.items).map(([k, v]: any) => [
                        k,
                        {
                            canEdit: v?.rules?.canEdit == undefined ? true : v?.rules?.canEdit,
                            canRemove: v?.rules?.canRemove == undefined ? true : v?.rules?.canRemove,
                        },
                    ]),
                );
            }
            applySettingsToField("", field.pair.key, settings?.keyRules || {}, allSettings);
            applySettingsToField("", field.pair.value, settings?.keyRules || {}, allSettings);

            break;

        /////////////////////////////////////////////// Obj
        // ports >> item, gitSync >> item, s3(s3Storage) >> item, pvc >> item, volumes >> item
        case FieldTypes.Obj:
            if (typeof settings == "object" && Object.keys(settings).length == 0) {
                return;
            }
            if (!settings) {
                // this means that for each inner field, we should take its settings from 'allSettings'
                for (const key1 of Object.keys(field.fields)) {
                    const itemField = field.fields[key1];
                    const innerFieldSettings = deepCopyObject(allSettings[key1]);
                    if (innerFieldSettings) {
                        // when it's an inner field within an Obj field - if opened, it's required
                        innerFieldSettings.required = true;
                    }
                    applySettingsToField("", itemField, innerFieldSettings || {}, allSettings);
                }
                break;
            }
            for (const key1 of Object.keys(settings)) {
                const itemField = field.fields[key1];
                const settingsField = settings[key1];
                if (!itemField) {
                    continue;
                }
                applySettingsToField("", itemField, settingsField || {}, allSettings);
            }
            break;

        case FieldTypes.Custom:
            break;
    }
}

function extractDefaultValue(val: any): any {
    if (Array.isArray(val?.value)) {
        return val.value.map(extractDefaultValue);
    } else if (typeof val?.items == "object") {
        const result: any = {};
        for (const key in val.items) {
            result[key] = extractDefaultValue(val.items[key]);
        }
        return result;
    }

    return val?.value;
}

function isGpuLimitAllowed(): boolean {
    return settingStore.state?.kv[SettingKeys.GPULimitForOpportunisticJobs];
}

function isWandbSweepsAllowed(): boolean {
    return settingStore.state?.kv[SettingKeys.EnableWandbSweeps];
}

const AllGroups = [JobGroups.Train, JobGroups.MPI, JobGroups.HPO];

const AllGroupsAndSimple = [...AllGroups, JobGroups.Simple];

interface ArgsOptions {
    sweep?: boolean
}

export function getJobArgs(options: ArgsOptions = {}): FieldsByKeys {
    let result: FieldsByKeys = {
    // Meta
    project: {
        type: FieldTypes.String,
        description: "Associate the Job with a Project",
        title: "Project",
        meta: {
            placeholder: "Select Project",
            category: JobCategories.Meta,
            groups: AllGroupsAndSimple,
        } as any,
    },

    name: {
        type: FieldTypes.String,
        description: "Provide a name for the new Job",
        title: "Name",
        meta: {
            category: JobCategories.Meta,
            groups: AllGroupsAndSimple,
        },
    },

    image: {
        type: FieldTypes.String,
        description: "Provide an image name to be used by the Job",
        title: "Image",
        meta: {
            category: JobCategories.Meta,
            groups: AllGroupsAndSimple,
        },
    },

    // Resource Allocation
    gpu: {
        type: FieldTypes.Number,
        description: "Number of GPUs to allocate per pod for this Job",
        title: "GPUs",
        meta: {
            category: JobCategories.Meta,
            groups: AllGroupsAndSimple,
        },
    },

    mpi: {
        type: FieldTypes.Bool,
        description: "Run distributed (MPI) job",
        title: "Distributed Training (MPI)",
        meta: {
            category: JobCategories.Meta,
            groups: AllGroupsAndSimple,
        },
    },

    processes: {
        type: FieldTypes.Number,
        description: "Number of distributed training processes",
        title: "Processes",
        meta: {
            category: JobCategories.Meta,
            groups: [JobGroups.MPI, JobGroups.Simple],
        },
    },

    slotsPerWorker: {
        type: FieldTypes.Number,
        description: "Number of slots for each process",
        title: "Slots per process",
        meta: {
            category: JobCategories.Meta,
            groups: [JobGroups.MPI, JobGroups.Simple],
        },
    },

    jupyter: {
        type: FieldTypes.Bool,
        description: "Launch a jupyter notebook",
        title: "Jupyter Notebook",
        meta: {
            category: JobCategories.Meta,
            groups: AllGroupsAndSimple,
        },
    },

    notebookToken: {
        type: FieldTypes.String,
        description: "Set password for your Jupyter notebook (keep empty for a passwordless notebook)",
        title: "Jupyter Notebook Password",
        meta: {
            category: JobCategories.Meta,
            groups: AllGroupsAndSimple,
        },
    },

    tensorboard: {
        type: FieldTypes.Bool,
        description: "Launch TensorBoard (TensorFlow's visualization toolkit)",
        title: "TensorBoard",
        meta: {
            category: JobCategories.Meta,
            groups: AllGroupsAndSimple,
        },
    },

    tensorboardLogdir: {
        type: FieldTypes.String,
        description: "TensorBoard Logs Directory",
        title: "TensorBoard Logs Directory",
        meta: {
            category: JobCategories.Meta,
            groups: AllGroupsAndSimple,
        },
    },

    nodePool: {
        type: FieldTypes.String,
        description: "Specify the Node-Pool name on which to schedule this job",
        title: "Node-Pool Name",
        meta: {
            category: JobCategories.ResourceAllocation,
            groups: AllGroups,
        },
    },

   nodePools: {
        type: FieldTypes.Array,
        description: "Set the scheduling order for node pools by priority. If this isn't set, the scheduling will follow the project's default order. For more details, go to the applicable project page.",
        title: "Node pools",
        editable: true,
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "nodePools",
            fields: {
                nodePool: {
                    type: FieldTypes.String,
                },
            },
        },
        meta: {
            category: JobCategories.ResourceAllocation,
            groups: AllGroups,
        },
    },



    jobNamePrefix: {
        type: FieldTypes.String,
        description:
            "Use this prefix for a job name if a job name has not been set. The job name will be set as the prefix, plus a running index number",
        title: "Job Name Prefix",
        meta: {
            category: JobCategories.Meta,
            groups: AllGroups,
        },
    },

    // Container Definition
    command: {
        type: FieldTypes.String,
        description: "Provide the container with a command to run",
        title: "Command",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    arguments: {
        type: FieldTypes.String,
        description: "Provide the container with arguments",
        title: "Arguments",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    environment: {
        type: FieldTypes.Array,
        description: "Set environment variables in the container",
        title: "Environment Variables",
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "customMap",
            fields: {
                key: {
                    type: FieldTypes.String,
                },
                value: {
                    type: FieldTypes.String,
                },
            },
        },
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    annotations : {
        type: FieldTypes.Array,
        description: "Set annotations for the job",
        title: "Annotations",
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "customMap",
            fields: {
                key: {
                    type: FieldTypes.String,
                },
                value: {
                    type: FieldTypes.String,
                },
            },
        },
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    labels: {
        type: FieldTypes.Array,
        description: "Set labels for the job",
        title: "Labels",
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "customMap",
            fields: {
                key: {
                    type: FieldTypes.String,
                },
                value: {
                    type: FieldTypes.String,
                },
            },
        },
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    imagePullPolicy: {
        type: FieldTypes.String,
        description: "Define whether to use a cached image or pull again from the image registry",
        title: "Image Pull Policy",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    runAsUser: {
        type: FieldTypes.Bool,
        description: "Run in the context of a user other than root",
        title: "Override UID & GID",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },




    runAsUid: {
        type: FieldTypes.Number,
        description: "The ID of the user on behalf the container should run",
        title: "UID",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    runAsGid: {
        type: FieldTypes.Number,
        description: "The ID of the group on behalf the container should run",
        title: "GID",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    supplementalGroups: {
        type: FieldTypes.String,
        description: "A list of groups applied to the first process run in each container, in addition to the container's primary GID.",
        title: "Supplemental groups",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    capabilities: {
        type: FieldTypes.Array,
        title: "Linux Capabilities",
        editable: true,
        validation: {
            max: 10,
        },
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "capabilities",
            fields: {
                capability: {
                    type: FieldTypes.String,

                },
            },
        },
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    stdin: {
        type: FieldTypes.Bool,
        description: "Keep stdin open on the container(s) in the pod, even if nothing is attached",
        title: "Set Stdin",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    tty: {
        type: FieldTypes.Bool,
        description: "Allocate a TTY for the container",
        title: "Allocate TTY",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    workingDir: {
        type: FieldTypes.String,
        description: "Set the container's working directory",
        title: "Working Directory Path",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    createHomeDir: {
        type: FieldTypes.Bool,
        title: "Create Home Directory",
        description: "Create a temporary home directory",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    hostIpc: {
        type: FieldTypes.Bool,
        description: "Use the host's ipc namespace",
        title: "Host IPC",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    hostNetwork: {
        type: FieldTypes.Bool,
        description: "Use the host's network stack inside the container",
        title: "Host Network",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    serviceType: {
        type: FieldTypes.String,
        description: "Provide external access to jobs",
        title: "Service Type",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    ports: {
        type: FieldTypes.Array,
        description: "Expose ports from the job's container",
        title: "Port",
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "portMap",
            fields: {
                container: {
                    type: FieldTypes.Number,
                },
                external: {
                    type: FieldTypes.Number,
                },
                autoGenerate: {
                    type: FieldTypes.Bool,
                    title: "Auto-generate",
                },
            },
        },
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    exposedUrls: {
        type: FieldTypes.Array,
        description: "Exposes external HTTP access to the job. The URL will be provided upon job submission (unless choosing custom URL which is not recommended).",
        title: "Job URL",
        editable: true,
        validation: {
            max: 10,
        },
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "exposedUrls",
            fields: {
                containerPort: {
                    type: FieldTypes.Number,
                    title: "Container Port",
                },
                url: {
                    type: FieldTypes.String,
                    meta: {
                        placeholder: "<Full URL accessible from outside the cluster>",
                    },
                },
                customUrl: {
                    type: FieldTypes.Bool,
                    title: "Custom URL",
                },
            },
        },
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
        },

    allowPrivilegeEscalation: {
        type: FieldTypes.Bool,
        title: "Allow Privilege Escalation",
        description: "Allow the job’s container to gain additional privileges after it starts",
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    gitSync: {
        type: FieldTypes.Array,
        description: "Pull Git Repository",
        title: "Pull Git Repository ",
        editable: false,
        validation: {
            max: 10,
        },
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "gitSync",
            fields: {
                repository: {
                    type: FieldTypes.String,
                    title: "Source Repository",
                    meta: {
                        placeholder: "e.g https://github.com/kubernetes/kubernetes.git",
                    },
                },
                branch: {
                    type: FieldTypes.String,
                    title: "Branch",
                    meta: {
                        placeholder: "e.g master",
                    },
                },
                rev: {
                    type: FieldTypes.String,
                    title: "Revision",
                },
                username: {
                    type: FieldTypes.String,
                    title: "Username",
                },
                password: {
                    type: FieldTypes.String,
                    title: "Password",
                },
                directory: {
                    type: FieldTypes.String,
                    title: "Target Directory",
                    meta: {
                        placeholder: "e.g /home/dir",
                    },
                },
            },
        },
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    tolerations: {
        type: FieldTypes.Array,
        description: "Tolerations allow workloads to schedule onto nodes with matching taints",
        title: "Tolerations",
        editable: true,
        validation: {
            max: 10,
        },
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "toleration",
            fields: {
                effect: {
                    type: FieldTypes.String,
                    title: "Effect",
                },
                operator: {
                    type: FieldTypes.String,
                    title: "Operator",
                },
                seconds: {
                    type: FieldTypes.Number,
                    title: "Toleration Seconds",
                },
                tolerationKey: {
                    type: FieldTypes.String,
                    title: "Key",
                },
                tolerationValue: {
                    type: FieldTypes.String,
                    title: "Value",
                },
            },
        },
        meta: {
            category: JobCategories.ContainerDefinition,
            groups: AllGroups,
        },
    },

    cpu: {
        type: FieldTypes.Number,
        description: "CPU cores to allocate for the job (e.g. 0.5, 1)",
        title: "Requested CPU",
        meta: {
            category: JobCategories.ResourceAllocation,
            groups: AllGroups,
        },
    },

    gpuMemory: {
        type: FieldTypes.String,
        description: "GPU Memory to allocate for the job (e.g. 1G, 120M)",
        title: "GPU Memory",
        meta: {
            category: JobCategories.ResourceAllocation,
            groups: AllGroups,
        },
    },

    migProfile: {
        type: FieldTypes.String,
        description: "MIG profile to allocate for the job (e.g. 1g.5gb, 3g.20gb)",
        title: "MIG Profile",
        meta: {
            placeholder: "Select Profile",
            category: JobCategories.ResourceAllocation,
            groups: AllGroups,
        },
    },

    cpuLimit: {
        type: FieldTypes.Number,
        description: "Set a limit for the number of CPUs the job can consume (e.g. 0.5, 1)",
        title: "CPU Limit",
        meta: {
            category: JobCategories.ResourceAllocation,
            groups: AllGroups,
        },
    },

    memory: {
        type: FieldTypes.String,
        description: "CPU Memory to allocate for the job (e.g. 1G, 20M)",
        title: "Requested Memory",
        meta: {
            category: JobCategories.ResourceAllocation,
            groups: AllGroups,
        },
    },

    memoryLimit: {
        type: FieldTypes.String,
        description: "Set a limit for the amount of Memory the job can consume (e.g. 1G, 20M)",
        title: "Memory Limit",
        meta: {
            category: JobCategories.ResourceAllocation,
            groups: AllGroups,
        },
    },

    extendedResources: {
        type: FieldTypes.Array,
        description: "Request access to extended resources advertised by the cluster administrator",
        title: "Extended Resources",
        editable: true,
        validation: {
            max: 10,
        },
        item: {
            type: FieldTypes.Obj,
            display: ObjComponents.Custom,
            customDisplay: "extendedResources",
            fields: {
                name: {
                    type: FieldTypes.String,
                    title: "name",
                },
                quantity: {
                    type: FieldTypes.String,
                    title: "quantity",
                },
            },
        },
        meta: {
            category: JobCategories.ResourceAllocation,
            groups: AllGroups,
        },
    },

    largeShm: {
        type: FieldTypes.Bool,
        description: "Mount a large /dev/shm device",
        title: "Large SHM",
        meta: {
            category: JobCategories.ResourceAllocation,
            groups: AllGroups,
        },
    },

    // Job Lifecycle
    completions: {
        type: FieldTypes.Number,
        description:
            "For Hyperparameter optimization. Choose the number of successful pods required for this job to be completed",
        title: "Completions",
        meta: {
            category: JobCategories.JobLifecycle,
            groups: [JobGroups.HPO],
        },
    },

    parallelism: {
        type: FieldTypes.Number,
        description: "For hyperparameter optimization. Choose the number of pods to run in parallel at any given time",
        title: "Parallelism",
        meta: {
            category: JobCategories.JobLifecycle,
            groups: [JobGroups.HPO],
        },
    },

    backoffLimit: {
        type: FieldTypes.Number,
        description: "Number of times the job will be retried before failing. Default is 6",
        title: "Backoff Limit",
        meta: {
            category: JobCategories.JobLifecycle,
            groups: AllGroups,
        },
    },

     nodeType: {
      type: FieldTypes.String,
      title: "Node Type (Affinity)",
      description: "Force the job to run on a specific set of machines by setting a node-type label",
      meta: {
       category: JobCategories.JobLifecycle,
       groups: AllGroups,
      },
     },

     podAffinity: {
      type: FieldTypes.Bool,
      description: "Schedule pods onto nodes belonging to the same topology domain (e.g region, zone, placement group, or any other topology that you define)",
      title: "Topology Aware Scheduling",
      meta: {
       category: JobCategories.JobLifecycle,
       groups: AllGroupsAndSimple,
      },
     },

     podAffinityTopology: {
      type: FieldTypes.String,
      description: "Nodes that have a label with this key and identical values are considered to be in the same topology",
      title: "Topology Key",
      meta: {
       category: JobCategories.JobLifecycle,
       groups: AllGroupsAndSimple,
      },
     },

     podAffinitySchedulingRule: {
      type: FieldTypes.String,
      title: "Scheduling Rule",
      description: "Choose whether to schedule pods even if a suitable node in the same topology cannot be found\n",
      meta: {
       category: JobCategories.JobLifecycle,
       groups: AllGroups,
      },
     },

    preemptible: {
        type: FieldTypes.Bool,
        description:
            "Mark an interactive job as preemptible. Preemptible jobs can be scheduled above guaranteed quota and may be reclaimed at any time",
        title: "Preemptible",
        meta: {
            category: JobCategories.JobLifecycle,
            groups: AllGroups,
        },
    },

    ttlAfterFinish: {
        type: FieldTypes.String,
        description: "The duration, after which a finished job is automatically deleted (e.g. 5s, 2m, 3h)",
        title: "TTL After Finish",
        meta: {
            category: JobCategories.JobLifecycle,
            groups: AllGroups,
        },
    },


     // Storage
     pvcs: {
      type: FieldTypes.Array,
      description: "Mount a persistent volume",
      title: "Persistent Volume Claims",
      item: {
       type: FieldTypes.Obj,
       display: ObjComponents.Custom,
       customDisplay: "pvcs",
       fields: {
        storageClass: {
         type: FieldTypes.String,
         title: "Storage Class - optional",
        },
        size: {
         type: FieldTypes.String,
         title: "Size (e.g 2Gi, 100Mi)",
        },
        path: {
         type: FieldTypes.String,
         title: "Container Target Path",
        },
        claimName: {
         type: FieldTypes.String,
         title: "Name",
        },
        readOnly: {
         type: FieldTypes.Bool,
         description: "When enabled, the volume will be mounted into the container with read-only permissions",
         title: "Read Only",
        },
        existingPvc: {
         type: FieldTypes.Bool,
         description: "Existing PVC",
         title: "Existing PVC",
        },
        ephemeral: {
         type: FieldTypes.Bool,
         description: "When enabled, the new PVC will be deleted when the job is deleted",
         title: "Ephemeral",
        },
        readWriteOnce: {
            default: true,
         type: FieldTypes.Bool,
         description: "The volume can be mounted as read-write by a single node",
         title: "ReadWriteOnce",
        },
        readOnlyMany: {
         type: FieldTypes.Bool,
         description: "The volume can be mounted as read-only by many nodes",
         title: "ReadOnlyMany",
        },
        readWriteMany: {
         type: FieldTypes.Bool,
         description: "The volume can be mounted as read-write by many nodes",
         title: "ReadWriteMany",
        },
       },
      },
      meta: {
       category: JobCategories.Storage,
       groups: AllGroups,
      },
     },

     volumes: {
      type: FieldTypes.Array,
      description: "Mount additional volumes into the container. Usually network volumes",
      title: "Volumes",
      item: {
       type: FieldTypes.Obj,
       display: ObjComponents.Custom,
       customDisplay: "volumes",
       fields: {
        sourcePath: {
         type: FieldTypes.String,
         title: "Source Path",
        },
        targetPath: {
         type: FieldTypes.String,
         title: "Target Path",
        },
        readOnly: {
         type: FieldTypes.Bool,
         title: "Read Only",
        },
        nfsServer: {
         type: FieldTypes.String,
         description: "If provided, the volume is mounted from the specified NFS server.",
         title: "NFS Server (optional)",
        },
       },
      },
      meta: {
       category: JobCategories.Storage,
       groups: AllGroups,
      },
     },

     s3: {
      type: FieldTypes.Array,
      description: "Mount an S3 compatible storage into the container running the job",
      title: "S3 Storage",
      validation: {
       max: 10,
      },
      item: {
       type: FieldTypes.Obj,
       display: ObjComponents.Custom,
       customDisplay: "s3Storage",
       fields: {
        key: {
         type: FieldTypes.String,
         title: "Access Key",
        },
        secret: {
         type: FieldTypes.String,
         title: "Secret Key",
        },
        bucket: {
         type: FieldTypes.String,
         title: "Bucket Name",
        },
        url: {
         type: FieldTypes.String,
         title: "Endpoint URL",
        },
        path: {
         type: FieldTypes.String,
         title: "Target Path",
        },
       },
      },
      meta: {
       category: JobCategories.Storage,
       groups: AllGroups,
      },
     },
    };

    if (isGpuLimitAllowed()) {
        result.gpuLimit = {
            type: FieldTypes.Number,
            description: "Set a limit for the number of GPUs the job can consume (e.g. 0.5, 1)",
            title: "GPU Limit",
            meta: {
                category: JobCategories.ResourceAllocation,
                groups: AllGroups,
            },
        };
    }

    // SWEEP
    if (options.sweep && isWandbSweepsAllowed()) {
        result.wandbSweepFeature = {
            type: FieldTypes.Bool,
            description: "If enabled, you will be allowed to set a sweep configuration and the command field will be overriden with the sweep id",
            title: "Sweep configuration",
            meta: {
                category: JobCategories.WandbSweep,
                groups: AllGroups,
            },
        }


        result.wandbSweepConfig = {
            type: FieldTypes.Obj,
            customDisplay: "wandbSweep",
            display: ObjComponents.Custom,
            fields: {
                yaml: {
                    type: FieldTypes.String,
                },
                wandbEntity: {
                    type: FieldTypes.String,
                    title: "Weight & Biases entity name",        
                    description: "Entity: Your W&B username or team name.",
                    required: true
                },
                wandbProject: {
                    type: FieldTypes.String,
                    title: "Weights & Biases project",        
                    description: 'If left empty, the result will be under a project called "uncategorized"',
                },
                wandbApiKey: {
                    type: FieldTypes.String,
                    title: "Weights & Biases API Key",
                    description: "Provide your private API key for authentication",
                    required: true
                },
                wandbCount: {
                    type: FieldTypes.Number,
                    title: "Count",
                    description: `The number of trials to run per sweep agent. If the count field is left empty the number of trials to run is unlimited. 
                    Note: the number of sweep agents is equivalent to the number of parallel pods controlled by the field "parallelism" parameter in the 'Scheduling & Lifecycle' section`,
                    validation: {
                        min: 1
                    }
                },
            },
            meta: {
                category: JobCategories.WandbSweep,
            },
        }
    }

    return result;
}

export default getJobArgs;
