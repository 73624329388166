import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoxInputCtrl = _resolveComponent("BoxInputCtrl")!

  return (_openBlock(), _createBlock(_component_BoxInputCtrl, _mergeProps({
    theme: _ctx.theme,
    variant: _ctx.variant,
    "ctrl-args": _ctx.ctrlArgs,
    required: !!_ctx.required
  }, _ctx.attrs), null, 16, ["theme", "variant", "ctrl-args", "required"]))
}