
import ProgressBar from "@/core-ui/ProgressBar.vue";
import Box from "./Box.vue";
import BoxInputMessages from "./BoxInputMessages.vue";
import { useFieldCtrl } from "@/core-ui/forms/compositions";
import { fieldCtrlArgs, variant } from "./common";
import { defineComponent, ref } from "vue";

type InputValueTypes = string | number;
type Option = { name: string; value: string | number };
type O = Option[] | string[] | number[];
type LoaderStatus = "loading" | "falied" | "ready" | "empty";

export default defineComponent({
    components: { Box, BoxInputMessages, ProgressBar },
    props: {
        variant,
        ctrlArgs: fieldCtrlArgs<InputValueTypes>(),
    },
    setup: (props, ctx) => {
        const optionsStatus = ref<LoaderStatus>("empty");
        const fieldCtrl = useFieldCtrl(props.ctrlArgs!);
        const searchTerm: any = ref(fieldCtrl.value);

        let oldValue = ref<string>("");
        const options = fieldCtrl.meta!.options!.map((o: any) => o.name);

        function filterOptions(searchTerm: string) {
            return options.filter((option: string) => option.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        function onChange(projectName: string) {
            if (!options.includes(projectName)) {
                searchTerm.value = fieldCtrl.value;
            } else {
                fieldCtrl.value = projectName;
                const onInputChange = props.ctrlArgs!.onInputChange;
                if (onInputChange) {
                    onInputChange(projectName, oldValue);
                }
            }
        }

        return {
            searchTerm,
            fieldCtrl,
            onChange,
            filterOptions,
            onFocus(event: any) {
                oldValue = event.target.value;
            },
            get isLoading() {
                return optionsStatus.value == "loading";
            },
        };
    },
});
