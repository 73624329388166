import { makeID } from "@/core-ui/forms/compositions/helpers";

type ListenerObj = {
    cb: Function;
    id: string;
};

type EventMap = Map<string, Array<ListenerObj>>;

class EventEmitter {
    private _listeners: EventMap = new Map<string, Array<ListenerObj>>();

    addListener(event: string, cb: Function): string {
        const id = makeID();
        const listeners: Array<ListenerObj> = this._listeners.get(event) || [];
        listeners.push({ cb, id });
        this._listeners.set(event, listeners);
        return id;
    }

    removeListener(event: string, listenerId: string): void {
        let listeners: Array<ListenerObj> | undefined = this._listeners.get(event);
        if (!listeners) return;

        listeners = listeners.filter((listener: ListenerObj) => listener.id !== listenerId);
        if (listeners.length === 0) {
            this._listeners.delete(event);
        } else {
            this._listeners.set(event, listeners);
        }
    }

    emit(event: string, data: any, fullEvent?: any): void {
        const listeners: Array<ListenerObj> | undefined = this._listeners.get(event) || [];
        listeners.forEach((listener) => listener.cb(data, fullEvent));
    }
}

const eventEmitter = new EventEmitter();
export default eventEmitter;
