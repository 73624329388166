export enum ResearcherCookie {
    RESEARCHER_BASE_URL = "RESEARCHER_BASE_URL",
}

// this is vanilla-ts cookie client (partially) copied from this github gist:
// https://gist.github.com/joduplessis/7b3b4340353760e945f972a69e855d11
// we could use an npm external lib for cookie management but that's probably an overkill for our modest cookies use.
export default {
    get(name: ResearcherCookie): string {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");

        if (parts.length == 2) {
            return parts.pop()!.split(";")!.shift()!;
        } else {
            console.warn(`missing cookie: ${name}`);
            return "";
        }
    },
};
