import { Ref, ref, watch } from "vue";
import { storageService } from "../services";

const MIN_COLUMN_WIDTH = 52;
const MAX_COLUMN_WIDTH = 1500;
const DEFAULT_SIZE = 150;
const DEFAULT_KEY = "DEFAULT_KEY";

type Size = { [key: string]: number };
type MultipleLine = { [key: string]: boolean };

/**
 * The interface to the columns size state state (mutations and data)
 *
 * @export
 * @interface ColumnsSizeState
 */
export interface ColumnsSizeState {
    get(key: string): number;
    set(key: string, val: number): void;
}

export interface ColumnsSizeStateProps {
    key?: string;
    sizes?: Size;
    multipleLine?: MultipleLine;
    defaultSize?: number;
}

export class ColumnsSizeSource implements ColumnsSizeState {
    state: Ref<Size>;

    constructor(public props: ColumnsSizeStateProps = {}) {
        const syncSizes = () =>
            (this.state.value = {
                ...(props.sizes || {}),
                ...storageService.getObj(this.key, {}),
            });

        this.state = ref<Size>({});

        watch([() => props.sizes, () => props.key], syncSizes, { immediate: true });
    }

    get key(): string {
        return `table-columns-size/v1/${this.props.key || DEFAULT_KEY}`;
    }

    get defaultSize(): number {
        return this.props.defaultSize || DEFAULT_SIZE;
    }

    get(key: string): number {
        return this.state.value[key] || this.defaultSize;
    }

    set(key: string, val: number) {
        this.state.value[key] = val;
        storageService.updateObj(this.key, (p = {}) => ({ ...p, [key]: val }));
    }
}

export interface ColumnsSize {
    get(key: string): number;
    onResizeStart(ev: MouseEvent, elm: HTMLElement, key: string): void;
    activeKey: string;
    isResizing: boolean;
}

export function useColumnsSize({ state = new ColumnsSizeSource() }: { state?: ColumnsSizeState } = {}): ColumnsSize {
    // temp variables
    const _key = ref<null | string>(null);
    const _size = ref<null | number>(null);
    const _resizeX = ref<null | number>(null);

    function get(key: string): number {
        if (key == _key.value) {
            return _size.value as number;
        }
        return state.get(key);
    }

    function onResizeStart(ev: MouseEvent, elm: HTMLElement, key: string) {
        if (!ev || !elm || !key) {
            throw new Error("[ResizeStore::onResizeStart] You must to pass the event, resize element and the key");
        }

        _size.value = elm.clientWidth;
        _key.value = key;
        _resizeX.value = ev.clientX;
        document.addEventListener("mousemove", onResizeMove);
        document.addEventListener("mouseup", onResizeEnd);
    }

    function onResizeMove(e: MouseEvent) {
        const newSize = (_size?.value || 0) + e.clientX - (_resizeX?.value || 0);
        if (MAX_COLUMN_WIDTH < newSize || MIN_COLUMN_WIDTH > newSize) return;
        _resizeX.value = e.clientX;
        _size.value = newSize;
    }

    function onResizeEnd(e: MouseEvent) {
        // set the final value to the state
        state.set(_key.value as string, _size.value as number);
        // reset the temp variables
        _key.value = null;
        _size.value = null;
        _resizeX.value = null;
        // remove listeners from the events listener
        document.removeEventListener("mousemove", onResizeMove);
        document.removeEventListener("mouseup", onResizeEnd);
    }

    return {
        onResizeStart,
        get,
        get activeKey() {
            return _key.value as string;
        },
        get isResizing() {
            return !!_key.value;
        },
    };
}
