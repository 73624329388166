import { ServerError } from "@/types/server-error";
import authStore from "../stores/authStore";

type METHOD = "POST" | "GET" | "DELETE" | "PUT";

function urlencodeFormData(fd: FormData): string {
    const params = new URLSearchParams();
    for (const pair of fd.entries()) {
        typeof pair[1] == "string" && params.append(pair[0], pair[1]);
    }
    return params.toString();
}

export class K8sApiClient {
    access_token = "";
    external_token = "";

    constructor(public backendURL: string = location.origin) {}

    async request(path: string, method: METHOD, payload: any = {}) {
        const authHeaders = this.access_token ? { Authorization: `Bearer ${this.access_token}` } : {};
        let contentType: string;
        let decodePayload: any;
        if (payload instanceof FormData) {
            contentType = "application/x-www-form-urlencoded";
            decodePayload = urlencodeFormData(payload);
        } else {
            contentType = "application/json";
            decodePayload = JSON.stringify(payload);
        }

        
        try {
            return await fetch(`${this.backendURL}/${path}`, {
                method,
                body: method === "GET" || method === "DELETE" ? undefined : decodePayload,
                headers: { "content-type": contentType, ...authHeaders } as any,
            });
        } catch (error) {
            console.log('=====>> path:: ',path)
            console.log('=====>> method:: ',method)
            console.log('=====>> payload:: ',payload)
            console.log('=====>> authHeaders:: ',authHeaders)
            console.log('=====>> error:: ',error)
            throw new Error("Error accessing the server");
        }
    }

    async asyncRequestWithoutResponse(path: string, method: METHOD, payload: any = {}, retry: boolean = true): Promise<any> {
        const response = await this.request(path, method, payload);
        if (response.status > 400) {
            if (response.status == 401) {
                if(retry) {
                    await authStore.refreshTokenOnce()
                    return await this.asyncRequestWithoutResponse(path, method, payload, false)
                } else {
                    await authStore.logout()
                }
            }
            let message;
            try {
                message = await response.text();
            } catch (error) {
                message = "Error accessing the server";
            }

            throw new ServerError(response.status, message);
        }
    }

    async asyncRequestWithResponseAndError(path: string, method: METHOD, payload: any = {}, retry: boolean = true): Promise<any> {
        const response = await this.request(path, method, payload);
        if (response.status > 400) {
            if (response.status == 401) {
                if(retry) {
                    await authStore.refreshTokenOnce()
                    return await this.asyncRequestWithResponseAndError(path, method, payload, false)
                } else {
                    await authStore.logout()
                }
            }
            let message;
            try {
                message = await response.text();
            } catch (error) {
                message = "Error accessing the server";
            }

            throw new ServerError(response.status, message);
        }

        return this.responseJson(response);
    }

    async asyncRequestWithResponse<T = any>(path: string, method: METHOD, payload = {}, retry: boolean = true): Promise<T> {
        const response = await this.request(path, method, payload);
        if (response.status >= 400) {
            if (response.status == 401) {
                if(retry) {
                    await authStore.refreshTokenOnce()
                    return await this.asyncRequestWithResponse(path, method, payload, false)
                } else {
                    await authStore.logout()
                }
            }
            const contentType = response.headers.get("content-type") || null;
            if (contentType === null || contentType.startsWith("text/plain;")) {
                throw new Error(await response.text());
            }
            throw await this.responseJson(response);
        }
        return await this.responseJson(response);
    }

    async asyncRequestWithEmptyResponse<T = any>(path: string, method: METHOD, payload = {}, retry: boolean = true): Promise<T> {
        let res;
        const response = await this.request(path, method, payload);
        if (response.status >= 400) {
            if (response.status == 401) {
                if(retry) {
                    await authStore.refreshTokenOnce()
                    return await this.asyncRequestWithResponse(path, method, payload, false)
                } else {
                    await authStore.logout()
                }
            }
            const contentType = response.headers.get("content-type") || null;
            if (contentType === null || contentType.startsWith("text/plain;")) {
                const responseText = await response.text();
                console.log("missing content type, ", responseText);
            }
            const responseJson =  await this.responseJson(response);
            console.log("error, ", responseJson);            
        }
        else if (response.status === 204) {
            res = {};
        } else {
            res = await this.responseJson(response);
        }
        return res
    }


    setTokens(access_token: string, external_token = "") {
        this.access_token = access_token;
        this.external_token = external_token;
    }

    private async responseJson(res: Response) {
        try {
            return await res.json();
        } catch (e) {
            const err = new Error("Error accessing the server") as any & Error;
            err.response = res;
            throw err;
        }
    }
}

const apiClient = new K8sApiClient();
export default apiClient;
