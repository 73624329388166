import { onUnmounted } from "vue";
import { getCtrlId } from "./helpers";
import { ObjState, useObjState } from "./ObjState";
import {
    ContainerCtrl,
    ContainerCtrlMeta,
    ContainerState,
    Ctrl,
    CtrlArgs,
    DisplayGroupsSelector,
    FieldKey,
    FieldValidStatus,
} from "./types";

export type ObjCtrlArgs = CtrlArgs & {
    parent?: ContainerState<unknown>;
    editable?: boolean;
    groupsSelector?: DisplayGroupsSelector;
    meta?: ContainerCtrlMeta;
};

export type ObjCtrl<T extends Record<string, any> = Record<string, any>> = ContainerCtrl<T> & {
    childrenCtrlArgs: Map<FieldKey, CtrlArgs>;
    cachedValue: Map<FieldKey, any>;
    addDeclaration(args: CtrlArgs): void;
    isChildrenValid(key: FieldKey): FieldValidStatus;
    isCategoryOrGroupsValid(category?: string, groups?: string[]): FieldValidStatus;
    clear(): void;
};

export function useObjCtrl<T>(args: ObjCtrlArgs): ObjCtrl<T> {
    const id = getCtrlId();
    let state: ObjState<T>;

    const self: ObjCtrl<T> = {
        type: "obj",
        get id() {
            return id;
        },
        get key() {
            return args.key;
        },
        get title() {
            return args.title;
        },
        get description() {
            return args.description;
        },
        get declaration() {
            return args;
        },
        get cachedValue() {
            return state.cachedValue;
        },

        get context() {
            return state.context;
        },

        get hidden() {
            return state.hidden;
        },
        get editable() {
            return state.editable;
        },
        get children() {
            return state.children;
        },
        get childrenCtrlArgs() {
            return state.childrenCtrlArgs;
        },
        get value(): T {
            return state.value;
        },
        set value(v: T) {
            state.value = v;
        },
        get valid() {
            return state.valid;
        },
        get dirty() {
            return state.dirty;
        },

        clear() {
            return state.clear();
        },

        isChildrenHidden(key: FieldKey) {
            return state.isChildrenHidden(key);
        },
        isChildrenValid(key: FieldKey) {
            return state.isChildrenValid(key);
        },
        isCategoryOrGroupsValid(category: string, groups: string[]) {
            return state.isCategoryOrGroupsValid(category, groups) as any;
        },
        add(ctrl: Ctrl<unknown>) {
            state.add(ctrl);
        },
        remove(ctrl: Ctrl<unknown>): boolean {
            return state.remove(ctrl);
        },
        addDeclaration(args: CtrlArgs) {
            state.addDeclaration(args);
        },

        createState(): ObjState<T> {
            return useObjState({
                get key() {
                    return args.key;
                },
                get parent() {
                    return args.parent;
                },
                get groupsSelector() {
                    return args.groupsSelector;
                },
                get editable() {
                    return args.editable;
                },
                get defaultEditable() {
                    return args.parent?.defaultEditable ?? true;
                },
            });
        },
        set state(_state: ObjState<T>) {
            state = _state;
        },
        get state() {
            return state;
        },
        get meta() {
            return args.meta;
        },
    };

    onUnmounted(() => {
        if (args.parent) {
            args.parent.remove(self);
        }
    });

    if (args.parent) {
        args.parent.add(self);
    }

    return self;
}
