
import { defineComponent, PropType, ref, watch, SetupContext } from "vue";

import { isString, isObject, isBoolean, has, get, translate, px } from "./utils";

import { ColorObject, SwitchColorObject, LabelsObject, Labels, Checked, Unchecked, SwitchColor, Color } from "./types";

type Props = {
    modelValue: boolean;
    name: string;
    disabled: boolean;
    tag: number;
    sync: boolean;
    speed: number;
    color: Color;
    switchColor: SwitchColor;
    cssColors: boolean;
    labels: Labels;
    height: number;
    width: number;
    margin: number;
    fontSize: number;
};

const DEFAULT_COLOR_CHECKED = "#75c791";
const DEFAULT_COLOR_UNCHECKED = "#bfcbd9";
const DEFAULT_LABEL_CHECKED = "on";
const DEFAULT_LABEL_UNCHECKED = "off";
const DEFAULT_SWITCH_COLOR = "#fff";

export default defineComponent({
    name: "ToggleButton",
    emits: ["update:modelValue", "change"],
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tag: {
            type: String,
        },
        sync: {
            type: Boolean,
            default: false,
        },
        speed: {
            type: Number,
            default: 300,
        },
        color: {
            type: [String, Object] as PropType<Color>,
            validator(value: Record<string, unknown> | string) {
                return (
                    isString(value) ||
                    has(value as Record<string, unknown>, "checked") ||
                    has(value as Record<string, unknown>, "unchecked") ||
                    has(value as Record<string, unknown>, "disabled")
                );
            },
        },
        switchColor: {
            type: [String, Object] as PropType<SwitchColor>,
            validator(value: SwitchColorObject | string) {
                return (
                    isString(value) ||
                    has(value as SwitchColorObject, "checked") ||
                    has(value as SwitchColorObject, "unchecked")
                );
            },
        },
        cssColors: {
            type: Boolean,
            default: false,
        },
        labels: {
            type: [Boolean, Object] as PropType<Labels>,
            default: false,
            validator(value: Labels) {
                return typeof value === "object"
                    ? Boolean((value as Checked<string>).checked) || Boolean((value as Unchecked<string>).unchecked)
                    : typeof value === "boolean";
            },
        },
        height: {
            type: Number,
            default: 22,
        },
        width: {
            type: Number,
            default: 50,
        },
        margin: {
            type: Number,
            default: 3,
        },
        fontSize: {
            type: Number,
        },
    },

    setup: ((props: Props, ctx: SetupContext) => {
        const toggled = ref<boolean>(props.modelValue);

        watch(
            () => props.modelValue,
            (value: boolean) => {
                if (props.sync) {
                    toggled.value = !!value;
                }
            },
        );
        const self = {
            toggled,

            keyToggle(event: Event) {
                // the key event happens whether the control is disabled or not
                // nothing should be done if disabled is true
                if (props.disabled) {
                    return;
                }
                self.toggle(event);
            },
            toggle(event: Event) {
                const _toggled = !toggled.value;
                if (!props.sync) {
                    toggled.value = _toggled;
                }
                ctx.emit("update:modelValue", _toggled);

                ctx.emit("change", {
                    value: _toggled,
                    tag: props.tag,
                    srcEvent: event,
                });
            },
            get className() {
                const { disabled } = props;
                return [
                    "vue-js-switch",
                    {
                        toggled: toggled.value,
                        disabled,
                    },
                ];
            },
            get coreStyle() {
                return {
                    width: px(props.width),
                    height: px(props.height),
                    backgroundColor: props.cssColors ? null : props.disabled ? self.colorDisabled : self.colorCurrent,
                    borderRadius: px(Math.round(props.height / 2)),
                };
            },
            get buttonRadius() {
                return props.height - props.margin * 2;
            },
            get distance() {
                return px(props.width - props.height + props.margin);
            },
            get buttonStyle() {
                const transition = `transform ${props.speed}ms`;
                const margin = px(props.margin);
                const transform = toggled.value ? translate(self.distance, margin) : translate(margin, margin);
                const background = props.switchColor ? self.switchColorCurrent : null;
                return {
                    width: px(self.buttonRadius),
                    height: px(self.buttonRadius),
                    transition,
                    transform,
                    background,
                };
            },
            get labelStyle() {
                return {
                    lineHeight: px(props.height),
                    fontSize: props.fontSize ? px(props.fontSize) : null,
                };
            },
            get colorChecked() {
                const { color } = props;
                if (!isObject(color)) {
                    return color || DEFAULT_COLOR_CHECKED;
                }
                return get(color as ColorObject, "checked", DEFAULT_COLOR_CHECKED);
            },
            get colorUnchecked() {
                return get(props.color as ColorObject, "unchecked", DEFAULT_COLOR_UNCHECKED);
            },
            get colorDisabled() {
                return get(props.color as ColorObject, "disabled", self.colorCurrent);
            },
            get colorCurrent() {
                return toggled.value ? self.colorChecked : self.colorUnchecked;
            },
            get labelChecked() {
                return get(props.labels as LabelsObject, "checked", DEFAULT_LABEL_CHECKED);
            },
            get labelUnchecked() {
                return get(props.labels as LabelsObject, "unchecked", DEFAULT_LABEL_UNCHECKED);
            },
            get switchColorChecked() {
                return get(props.switchColor as SwitchColorObject, "checked", DEFAULT_SWITCH_COLOR);
            },
            get switchColorUnchecked() {
                return get(props.switchColor as SwitchColorObject, "unchecked", DEFAULT_SWITCH_COLOR);
            },
            get switchColorCurrent() {
                const { switchColor } = props;
                if (!isObject(props.switchColor)) {
                    return props.switchColor || DEFAULT_SWITCH_COLOR;
                }
                return toggled.value ? self.switchColorChecked : self.switchColorUnchecked;
            },
        };
        return self;
    }) as any,
});
