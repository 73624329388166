import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBBtn = _resolveComponent("MDBBtn")!
  const _component_MDBBtnGroup = _resolveComponent("MDBBtnGroup")!

  return (_openBlock(), _createBlock(_component_MDBBtnGroup, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createBlock(_component_MDBBtn, {
          key: option.value,
          class: "button-select",
          onClick: ($event: any) => (_ctx.select(option.value)),
          color: _ctx.modelValue == option.value ? 'primary' : undefined
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(option.value), 1)
          ]),
          _: 2
        }, 1032, ["onClick", "color"]))
      }), 128))
    ]),
    _: 1
  }))
}