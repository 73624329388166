<template>
    <section class="ra-card" v-bind="$attrs">
        <header :class="headerClass" v-if="$slots.header">
            <slot name="header" />
        </header>
        <main :class="bodyClass">
            <slot name="body" />
        </main>
        <footer :class="footerClass" v-if="$slots.footer">
            <slot name="footer" />
        </footer>
    </section>
</template>

<script>
export default {
    props: ["footerClass", "headerClass", "bodyClass"],
};
</script>

<style lang="scss" scoped>
@import "src/core-ui/style/colors";

.ra-card {
    background: #ffffff;
    display: flex;
    flex-direction: column;

    /* Card */
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.08), 4px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 4px;

    main {
        display: flex;
        overflow: auto;
        flex: 1;
        flex-direction: column;
    }

    footer,
    header {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 68px;
        padding: 0px 32px;
    }

    header {
        border-bottom: $border-style;
    }

    footer {
        border-top: $border-style;
    }
}
</style>
