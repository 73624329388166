
import Box from "./Box.vue";
import Toggle from "@/core-ui/Toggle.vue";
import BoxInputMessages from "./BoxInputMessages.vue";
import { useFieldCtrl, FieldCtrl, FieldCtrlArgs } from "@/core-ui/forms/compositions";
import { defineComponent } from "vue";
import { variant, fieldCtrlArgs } from "./common";

export default defineComponent({
    components: { Toggle, Box, BoxInputMessages },
    setup: (props: any) => {
        const fieldCtrl = useFieldCtrl(props.ctrlArgs);
        return { fieldCtrl, itemCanEdit: props.itemCanEdit };
    },
    props: {
        variant,
        valueFirst: Boolean,
        ctrlArgs: fieldCtrlArgs<boolean>(),
        itemCanEdit: { type: Boolean, default: true },
    },
});
