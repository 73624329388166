import { reactive } from "vue";
import { showError } from "@/core-ui/helpers/notifications";

export interface DispatchOptions {
    logToErrorStore?: boolean;
    logErrorMessage?: (error: any) => string;
}

class Dispatcher {
    dispatch<T = any>(promise: Promise<any>, dispatchOptions: DispatchOptions = {}): RequestState<T> {
        const logToErrorStore = !!("logToErrorStore" in dispatchOptions ? dispatchOptions.logToErrorStore : true);
        const request = new Request(promise, logToErrorStore, dispatchOptions.logErrorMessage as any);
        request.fireRequest();
        return request.state;
    }

    async executeWithErrorHandling<T>(promise: Promise<T>) {
        try {
            return await promise;
        } catch (error) {
            const error_any = error as any; // ts workaround
            showError(new Error(error_any?.message || error_any?.description));
            throw error;
        }
    }
}

export interface RequestState<T = any> {
    results: T;
    error: Error;
    isLoading: boolean;
    success: boolean;
    promise: Promise<T>;
}

export interface Request<T> {
    promise: Promise<T>;
    state: RequestState<T>;
}

export class Request<T> {
    state = reactive({
        results: null,
        error: null,
        isLoading: false,
        success: null,
        promise: null,
    }) as any as RequestState<T>;

    constructor(promise: Promise<T>, private logErrors: boolean, private logErrorMessage: (error: any) => string) {
        this.state.promise = promise;
    }

    async fireRequest() {
        const state = this.state;

        state.isLoading = true;
        try {
            state.results = await state.promise;
            state.success = true;
        } catch (error) {
            if (this.logErrors) {
                if (this.logErrorMessage) {
                    const message = this.logErrorMessage(error);
                    showError(new Error(message));
                } else {
                    showError(error as Error);
                }
            }
            state.error = error as Error;
            state.success = false;
        } finally {
            state.isLoading = false;
        }
    }
}

export const dispatcher = new Dispatcher();
