
import IconButton from "@/core-ui/IconButton.vue";
import { defineComponent, ref } from "vue";

// Divided the form into a sections
export default defineComponent({
    components: { IconButton },
    emits: ["headerClick"],
    props: {
        title: String,
        description: String,
        default: Boolean,
        notValid: Boolean,
    },
    setup(props) {
        const expended = ref(props.default);
        return {
            expended,
            toggle() {
                expended.value = !expended.value;
            },
        };
    },
});
