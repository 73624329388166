import { DataGridColumn } from "@/core-ui/types/column";
import { columnKeyMap, mapUiColumnsToApi } from "./mapUiColumnsToApi";
import { getSearchObject } from "@/core-ui/helpers/searchQuery";
import { FilterAPI, Filter } from "../compositions";

export * from "./get-page-items";
export * from "./mapUiColumnsToApi";
export * from "./get-cell-props";

export function toFilterAPI(columns: Array<any>, filter: Filter, preWhere = {}): FilterAPI {
    const f = { ...filter } as any as FilterAPI;
    const cm = columnKeyMap(columns);
    f.query = mapUiColumnsToApi(cm, {
        ...getSearchObject(filter.query, { keywords: toKeywords(columns) }),
        ...preWhere,
    });
    f.sort_by = filter.sort_by.map(({ key, direction }) => ({
        key: cm[key],
        direction,
    }))[0];
    return f;
}

export function filterQueryToUrlQuery(o: Object) {
    return Object.entries(o)
        .map(([k, v]) => `${k}:${v}`)
        .join(" ");
}

export function filterAPIToUrlQuery(filter: FilterAPI) {
    const params = [];
    filter.query && params.push(`filter=${filterQueryToUrlQuery(filter.query)}`);
    filter.sort_by && params.push(`sortBy=${filter.sort_by.key}&sortDirection=${filter.sort_by.direction || "ASC"}`);
    const { items_per_page, page } = filter;
    if (page && items_per_page) {
        const from = items_per_page * (page - 1);
        params.push(`from=${from}`);
        params.push(`limit=${items_per_page}`);
    }
    return params.length ? `?${params.join("&")}` : "";
}

export function toKeywords(columns: DataGridColumn[]) {
    return columns.filter(isItem("searchable")).map(({ key }) => key);
}

export function between(val: number, min: number, max: number) {
    return val >= min && val <= max;
}

export function isItem(key: string, val?: any) {
    return (item: any) => (val == undefined ? item[key] : item[key] === val);
}
