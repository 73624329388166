import { isEmpty, isNumber, isBoolean, values, flatten } from "lodash";
import {
    basicFields,
    complicatedFields,
    notMappedKeys,
    ignoredKeys,
    workloadTemplateLevel,
    numericWorkLoadFields,
    mapItemsWorkLoadFields,
    arrayJoinInnerMapItemsWorkloadFields,
    InferenceJob,
} from "../../helpers/const";
import {
    getCurrentSettingsName,
    projectNameToWorkloadNameSpace,
    settingsTypeToWorkLoadKind,
    deepCopyObject,
} from "../../helpers/commonFunctions";
import { storageService } from "@/core-ui/data-grid/services";
import { Workload } from "../../components/submit/types";
import { makeLowerCaseAlphabeticID } from "@/core-ui/forms/compositions";
import authStore from "@/stores/authStore";
const JOB_NAME_PREFIX = "job-";
const INFERENCE_NAME_PREFIX = "inference-";
const ARRAY_JOIN_FROM_MAP_SEPARATOR = " ";

function mapItems(portsKeys: any): any | undefined {
    const res = { items: {} };
    for (const property in portsKeys) {
        const newValue = portsKeys[property];
        if (isEmpty(newValue)) {
            (res.items as any)[property] = { deleted: true };
        } else {
            (res.items as any)[property] = { value: portsKeys[property] };
        }
    }
    return res;
}

function arrayJoinMapItems(properties: Record<string, Record<string, Record<string, any>>>): any | undefined {
    if (!properties) return;

    const arr = flatten(values(properties).map(innerMap => {
        if (!innerMap) return;
        return values(innerMap).map(valuesMap => {
            if (!valuesMap) return;
            return values(valuesMap).filter(value => !isEmpty(value));
        }).filter(value => !isEmpty(value))
    }).filter(value => !isEmpty(value)));
    return arr.join(ARRAY_JOIN_FROM_MAP_SEPARATOR);
}

function mapTtl(v: string): string | undefined {
    return typeof v == "string" ? v.replaceAll(" ", "") : undefined;
}

function createNameTimestamp() {
    return new Date(Date.now())
        .toISOString()
        .split(".")[0]
        .replaceAll(" ", "-")
        .replaceAll(":", "-")
        .replaceAll("t", "-")
        .toLowerCase();
}

function convertStringToNumberForNumberFields(templatePlaceHolder: Workload) {
    Object.keys(templatePlaceHolder.spec).forEach((key: any) => {
        if (numericWorkLoadFields.includes(key)) {
            const keyValueToCheck = templatePlaceHolder.spec[key]?.value;
            if (typeof keyValueToCheck == "string") {
                templatePlaceHolder.spec[key].value = Number(keyValueToCheck);
            }
        }
    });
}

export function convertBackendResponseEnvParamAsFieldTypeArray(res: any) {
    const keys = ['environment', 'annotations', 'labels']
    keys.forEach((key) => {
        if (res.data[key]) {
            let envAsFieldTypeArray = {
                itemRules: {},
                items: {} as any,
            }
            if (res.data[key].items) {
                const items = res.data[key].items
                Object.keys(items).forEach((key) => {
                        const keyObject = items[key];
                        if (!keyObject.deleted) {
                            envAsFieldTypeArray.items[key] = {
                                level: keyObject.level,
                                rules: keyObject.rules,
                                value: {
                                    key: key,
                                    value: keyObject.value
                                }
                            }
                        }
                    }
                )
                res.data[key] = {
                    ...envAsFieldTypeArray,
                    rules: {
                        canAdd: res.data[key]?.rules?.canAdd
                    }
                }
            } else {
                const target = {};
                envAsFieldTypeArray.itemRules = {
                    key: new Proxy(target, {}),
                    value: new Proxy(target, {})
                }
                res.data[key] = envAsFieldTypeArray
            }
        }

        arrayJoinInnerMapItemsWorkloadFields.forEach((field) => {
            if (res.data[field]) {
                const value = res.data[field]["value"] as string;
                const level = res.data[field]["level"] as string;
                const singularFieldName = field.slice(0, field.length - 1);
                if (value && level) {
                    const actualValues = value.split(ARRAY_JOIN_FROM_MAP_SEPARATOR);
                    const fieldObj = {"items": new Map()};
                    actualValues.forEach((actualValue) => {
                        const id = makeLowerCaseAlphabeticID();
                        const innerField = {[id]: {"level": level, "value": {[singularFieldName]: actualValue}}};
                        Object.assign(fieldObj["items"], innerField);
                    });

                    res.data[field] = fieldObj;
                }
            }
        });
    })
}

function convertEnvToBackendStruct(templatePlaceHolder: Workload) {
    const keys = ['environment', 'annotations', 'labels']
    keys.forEach((key) => {
        if (templatePlaceHolder.spec && templatePlaceHolder.spec[key]) {
            let envAsFieldTypeMap = {
                items: {}
            }

            const items = templatePlaceHolder.spec[key].items
            if (items) {

                Object.keys(items).forEach((key) => {
                    const objectKey = items[key]
                    if (objectKey.deleted == true) {
                        envAsFieldTypeMap.items = {
                            ...envAsFieldTypeMap.items,
                            [key]: {
                                deleted: true
                            }
                        }
                    } else if (objectKey.value.key == key) {
                        envAsFieldTypeMap.items = {
                            ...envAsFieldTypeMap.items,
                            [objectKey.value.key]: {
                                level: objectKey.value.level,
                                value: objectKey.value.value,

                            }
                        }
                    } else {
                        envAsFieldTypeMap.items = {
                            ...envAsFieldTypeMap.items,
                            [key]: {
                                deleted: true
                            }
                        }

                        envAsFieldTypeMap.items = {
                            ...envAsFieldTypeMap.items,
                            [objectKey.value.key]: {
                                level: objectKey.value.level,
                                value: objectKey.value.value,

                            }
                        }
                    }
                })
                templatePlaceHolder.spec[key] = envAsFieldTypeMap
            } else {
                templatePlaceHolder.spec[key] = envAsFieldTypeMap
            }

        }
    })
}

function convertJoinedArrayFieldsToWorkloadControllerType(templatePlaceHolder: Workload) {
    if (!templatePlaceHolder.spec) {
        return;
    }

    arrayJoinInnerMapItemsWorkloadFields.forEach((field) => {
        if (!templatePlaceHolder.spec[field]) {
            return;
        }

        templatePlaceHolder.spec[field] = { value: arrayJoinMapItems(templatePlaceHolder.spec[field].items) || undefined };
    });
}

// TODO: support inference here!!
function convertOldStructOfSubmitToFitWorkLoadController(
    settings: { [key: string]: any },
    value: { [key: string]: any },
) {
    Object.keys(value || {}).forEach((k) => {
        if (mapItemsWorkLoadFields.includes(k)) {
            const v = mapItems(value[k]);
            if (v) {
                settings[k] = v;
            }
        } else if (notMappedKeys.has(k)) {
            if (typeof value[k] == "number") {
                value[k] = (value[k] as number).toString();
            } else if (typeof value[k] !== "boolean") {
                // the 'undefined' value will be used
                // to replace '' (empty strings) and 'null' value to match the backend
                value[k] = value[k] || undefined;
            }
            (settings as any)[k] = { value: value[k] }
        } else if (k == "command") {
            settings[k] = { value: value[k] };
        } else if (k == "ttlAfterFinish") {
            const ttl = mapTtl(value[k]);
            if (ttl) {
                settings[k] = { value: ttl };
            }
        } else if (ignoredKeys.has(k)) {
            // ignore this key
        } else {
            console.warn(`Unidentified key: ${k}`);
        }
    });
}

function mergeComplicatedFields(ItemFromSourceTemplate: any, ItemFromChangedValues: any, useAllLevels: boolean) {
    // clean up the rules and itemRules
    delete ItemFromSourceTemplate["itemRules"];
    delete ItemFromSourceTemplate["rules"];

    try {
        if ( ItemFromSourceTemplate && ItemFromSourceTemplate.items ) {
            Object.keys(ItemFromSourceTemplate.items).forEach((itemKey) => {
                if (ItemFromSourceTemplate.items[itemKey].rules) {
                    delete ItemFromSourceTemplate.items[itemKey].rules;
                }
            });
        }
    } catch (e) {
        console.warn("clean up the rules failed", e)
    }


    const needToCopyItemsFromSourceTemplate =
        isEmpty(ItemFromChangedValues) && !isEmpty(ItemFromSourceTemplate) && !isEmpty(ItemFromSourceTemplate["items"]);
    const needToMergeItemsFromSourceTemplateAndChangedValues =
        !isEmpty(ItemFromChangedValues) && !isEmpty(ItemFromSourceTemplate) && !isEmpty(ItemFromSourceTemplate["items"]);
    if (needToCopyItemsFromSourceTemplate) {
        ItemFromChangedValues = {};
        ItemFromChangedValues["items"] = ItemFromSourceTemplate["items"];
        Object.keys(ItemFromSourceTemplate["items"]).forEach((itemKey) => {
            if (useAllLevels || ItemFromSourceTemplate["items"][itemKey]["level"] == workloadTemplateLevel.workload) {
                delete ItemFromChangedValues?.items[itemKey]?.level;
            } else {
                delete ItemFromChangedValues?.items[itemKey];
            }
            if (isEmpty(ItemFromChangedValues["items"])) {
                delete ItemFromChangedValues?.items;
            }
        });
    } else if (needToMergeItemsFromSourceTemplateAndChangedValues) {
        const result = ItemFromSourceTemplate;
        Object.keys(ItemFromSourceTemplate["items"]).forEach((itemKey) => {
            if (Object.keys(ItemFromChangedValues["items"]).includes(itemKey)) {
                if (ItemFromChangedValues["items"][itemKey]["deleted"]) {
                    result["items"][itemKey] = ItemFromChangedValues["items"][itemKey];
                    delete result?.items[itemKey]?.value;
                } else {
                    delete result?.items[itemKey]?.level;
                    result["items"][itemKey]["value"] = {
                        ...ItemFromSourceTemplate["items"][itemKey]["value"],
                        ...ItemFromChangedValues["items"][itemKey]["value"],
                    };
                }
            } else {
                if (!isEmpty(ItemFromSourceTemplate["items"][itemKey]["value"])) {
                    if (useAllLevels || ItemFromSourceTemplate["items"][itemKey]["level"] == workloadTemplateLevel.workload) {
                        delete result?.items[itemKey]?.level;
                    }
                }
            }
        });
        // add newly added items from ItemFromChangedValues -
        // we want to keep order, so it's important to do it here
        Object.keys(ItemFromChangedValues["items"]).forEach((itemKey) => {
            if (!(Object.keys(ItemFromSourceTemplate["items"]).includes(itemKey))) {
                result["items"][itemKey] =  ItemFromChangedValues["items"][itemKey];
            }
        });
        return result;
    }
    return ItemFromChangedValues;
}

function mergeBasicFields(ItemFromSourceTemplate: any, ItemFromChangedValues: any, isIngressUrl: boolean, useAllLevels: boolean) {
    // clean up the rules and itemRules
    delete ItemFromSourceTemplate["itemRules"];
    delete ItemFromSourceTemplate["rules"];
    if (
        !isEmpty(ItemFromSourceTemplate) &&
        (!isEmpty(ItemFromSourceTemplate.value) ||
            isBoolean(ItemFromSourceTemplate.value) ||
            isNumber(ItemFromSourceTemplate.value)) &&
        isEmpty(ItemFromChangedValues)
    ) {
        if (useAllLevels || ItemFromSourceTemplate.level === workloadTemplateLevel.workload || isIngressUrl) {
            ItemFromChangedValues = { value: ItemFromSourceTemplate.value };
        }
    } else if (
        !isEmpty(ItemFromSourceTemplate) &&
        !isEmpty(ItemFromSourceTemplate.value) &&
        !isEmpty(ItemFromChangedValues) &&
        isEmpty(ItemFromChangedValues.value)
    ) {
        ItemFromChangedValues = { deleted: true };
    }
    return ItemFromChangedValues;
}

function mergeOriginalTemplateFieldsWithChangedValues(templatePlaceHolder: Workload, currentSettingsName: string, useAllLevels: boolean) {
    // get the original source template
    const originalFieldsFromStorage = deepCopyObject(storageService.getStr(currentSettingsName.toLowerCase()));
    // start merging the original source template and the changed values
    Object.keys(originalFieldsFromStorage).forEach((key) => {
        let mergeRes;
        if (complicatedFields.includes(key)) {
            const ItemFromSourceTemplate = originalFieldsFromStorage[key];
            const ItemFromChangedValues = templatePlaceHolder.spec[key];
            mergeRes = mergeComplicatedFields(ItemFromSourceTemplate, ItemFromChangedValues, useAllLevels);
        } else if (basicFields.includes(key)) {
            const ItemFromSourceTemplate = originalFieldsFromStorage[key];
            const ItemFromChangedValues = templatePlaceHolder.spec[key];
            mergeRes = mergeBasicFields(ItemFromSourceTemplate, ItemFromChangedValues, key === "ingressUrl", useAllLevels);
        }
        (templatePlaceHolder.spec as any)[key] = mergeRes;
    });
    return templatePlaceHolder;
}

export async function createSubmitTemplateDataForWorkLoadController(
    value: { [key: string]: any },
    selectedProject: string,
    templateName: string,
    usage: string,
    useAllLevels: boolean,
): Promise<Workload> {
    const currentSettingsName = getCurrentSettingsName();
    const nameTimestamp = createNameTimestamp();
    const settings: { [key: string]: any } = {};

    // 1. convert the changed values from the form to fit the workload template
    convertOldStructOfSubmitToFitWorkLoadController(settings, value);

    let metadataName: string = "";

    try {
        // 2. add job name if user didn't add job name
        if (isEmpty(settings["name"])) {
            let generatedName =
                (getCurrentSettingsName() == InferenceJob ? INFERENCE_NAME_PREFIX : JOB_NAME_PREFIX) + nameTimestamp;
            settings["name"] = { value: generatedName };
            metadataName = generatedName;
        } else {
            metadataName = settings["name"].value;
        }
    } catch (e) {
        console.log("e", e);
    }

    // 3. create the basic template that will be submitted
    const templatePlaceHolder: Workload = {
        apiVersion: "run.ai/v2alpha1",
        kind: settingsTypeToWorkLoadKind(currentSettingsName),
        metadata: {
            name: templateName || metadataName,
            namespace: await projectNameToWorkloadNameSpace(selectedProject),
        },
        spec: {
            usage: usage,
            username: {
                value: authStore.userEmail,
            },
        },
    };

    // 4. add changed values to the basic template from stp 3
    templatePlaceHolder.spec = { ...templatePlaceHolder.spec, ...settings };

    // 5. merge the changed values vs the source template by the currentSettingsName
    mergeOriginalTemplateFieldsWithChangedValues(templatePlaceHolder, currentSettingsName, useAllLevels);

    // 6. convert number values from string to number
    convertStringToNumberForNumberFields(templatePlaceHolder);

    // 7. convert env to backend struct
    convertEnvToBackendStruct(templatePlaceHolder)

    // 8. convert joined array fields to workloadk-controller type (current fields: nodePools)
    convertJoinedArrayFieldsToWorkloadControllerType(templatePlaceHolder)

    return templatePlaceHolder;
}
