
export const SWEEP_ID_PLACEHOLDER = "<sweep-id>";
export const SWEEP_COMMAND = `wandb agent ${SWEEP_ID_PLACEHOLDER}`;
export const DEFAULT_WANDB_BASE_URL = "https://api.wandb.ai";

export const WANDB_YAML_TEMPLATE = `method: bayes
metric:
  goal: minimize
  name: loss
parameters:
  learning_rate:
    max: 0.1
    min: 0.001
  optimizer:
    values:
      - adam
      - sgd
program: /code/trainer.py`