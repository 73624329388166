
import Input from "./Input.vue";
import IconButton from "@/core-ui/IconButton.vue";
import { computed, defineComponent, ref, watchEffect } from "vue";

export default defineComponent({
    props: {
        value: { type: String, default: "" },
        icon: { type: String, default: "raicon-arrow-right" },
        invalid: Boolean,
        keyOptions: Array,
        valueOptions: Array,
        keyInputProps: Object,
        valInputProps: Object,
    },
    components: { Input, IconButton },
    setup(props, ctx) {
        const key = ref();
        const val = ref();

        watchEffect(() => {
            const p = props.value?.split(":");
            key.value = p ? p[0] : "";
            val.value = p ? p[1] : "";
        });

        watchEffect(() => {
            let sum = `${key.value || ""}`;
            if (val.value) {
                sum += `:${val.value}`;
            }
            sum !== props.value && ctx.emit("update:value", sum);
        });
        return {
            key,
            val,
        };
    },
});
