export function getPageItems(list: Array<unknown> | Record<string, any>, page: number, itemsPerPage: number) {
    if (list instanceof Array) {
        return ifArray(list, page, itemsPerPage);
    } else if (list instanceof Object) {
        return ifObject(list, page, itemsPerPage);
    }
    throw new TypeError("Pagination can only be done on an Array or an Object");
}

function ifArray(list: Array<unknown>, page: number, itemsPerPage: number) {
    const listLen = list.length;
    if (listLen <= itemsPerPage) return list;

    const from = itemsPerPage * (page - 1);
    const to = itemsPerPage * page < listLen ? itemsPerPage * page : listLen;
    if (listLen < from) return [];

    return list.slice(from, to);
}

function ifObject(object: any, page: number, itemsPerPage: number): any {
    const keysList = Object.keys(object);
    const objectLen = keysList.length;
    if (objectLen <= itemsPerPage) return object;

    const from = itemsPerPage * (page - 1);
    const to = itemsPerPage * page < objectLen ? itemsPerPage * page : objectLen;
    if (objectLen < from) return {};

    const returnObject: any = {};
    const slicedKeysList = keysList.slice(from, to);
    slicedKeysList.forEach((key) => {
        returnObject[key] = object[key];
    });
    return returnObject;
}
