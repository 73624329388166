import { ROUTES_ENUM } from "@/router/routes";
import IFrame from "@/components/iframe.vue";
import clustersStore from "@/stores/clusters-store";
import LongTermDashboard from "@/views/AnalyticsDashboard.vue";
import { loadView } from "./utils";

import { RolesEnum, rolesMap } from "@/stores/authStore";

export const dashboardsRoutes = [
    {
        path: "now",
        redirect: "dashboards/now",
    },
    {
        path: "long-term",
        redirect: "dashboards/analytics",
    },
    {
        path: "dashboards/now",
        name: ROUTES_ENUM.NOW_DASHBOARD,
        component: IFrame,
        meta: { requiresAuth: true, 
            allowedRoles: rolesMap.allRoles },
        props() {
            return {
                query: {
                    "var-cluster": clustersStore.state.activeClusterUUID,
                },
                dashboardName:"nowDashboard",
            };
        },
    },
    {
        path: "dashboards/analytics",
        name: ROUTES_ENUM.ANALYTICS_DASHBOARD,
        component: LongTermDashboard,
        meta: { requiresAuth: true, 
            allowedRoles: rolesMap.allRoles },
    },
    {
        path: "dashboards/consumption",
        name: ROUTES_ENUM.CONSUMPTION_DASHBOARD,
        component: IFrame,
        meta: { requiresAuth: true, allowedRoles: [RolesEnum.ADMIN] },
        props() {
            return {
                query: {
                    "var-cluster": clustersStore.state.activeClusterUUID,
                },
                dashboardName: "consumptionDashboard",
            }
        }
    },
    {
        path: "dashboards/clusters",
        name: ROUTES_ENUM.CLUSTERS_DASHBOARD,
        component: IFrame,
        meta: { requiresAuth: true, allowedRoles: [RolesEnum.ADMIN] },
        props() {
            return {
                dashboardName: "clustersDashboard",
            };
        },
    },
    {
        path: "dashboards/analytics-old",
        name: ROUTES_ENUM.OLD_ANALYTICS,
        component: loadView("AnalyticsOldDashboard"),
        meta: { requiresAuth: true, allowedRoles: [RolesEnum.ADMIN] },
    },
];
