// general types

import { ContainerCtrlMeta, FieldCtrlMeta, StringFormatTypes } from "@/core-ui/forms/compositions";

export type Option =
    | string
    | {
          value: string;
          name: string;
      };

export enum FieldTypes {
    String = "string",
    Custom = "custom",
    Number = "number",
    Bool = "bool",
    Array = "array",
    Map = "map",
    Pair = "pair",
    Obj = "obj",
}

// validations

export type StringValidation = {
    format?: StringFormatTypes;
    pattern?: string;
    maxLength?: number;
    minLength?: number;
    enum?: string[];
};

export type NumberValidation = {
    max?: number;
    min?: number;
    step?: number;
    format?: "port";
};

export type ArrayValidation<I> = {
    min?: number;
    max?: number;
    unique?: boolean;
};

// components
export enum GeneralComponents {
    Box = "box",
    Toggle = "toggle",
    Checkbox = "checkbox",
    ObjInTabs = "objInTabs",
    ToggleButton = "ToggleButton",
    Custom = "Custom",
}

export enum StringComponents {
    Box = GeneralComponents.Box,
    ButtonSelect = GeneralComponents.ToggleButton,
}

export enum StringArrayComponents {
    Box = GeneralComponents.Box,
}

export enum MapComponents {
    Box = GeneralComponents.Box,
}

export enum PairComponents {
    Box = GeneralComponents.Box,
}

export enum NumberComponents {
    Box = GeneralComponents.Box,
}

export enum BoolComponents {
    Toggle = GeneralComponents.Toggle,
    Checkbox = GeneralComponents.Checkbox,
}

export enum ObjComponents {
    Custom = GeneralComponents.Custom,
    ObjInTabs = GeneralComponents.ObjInTabs,
}

// fields features

type BaseField<FT extends FieldTypes, T, V, D, M> = {
    type: FT;
    title?: string;
    description?: string;
    onInputChange?: any;
    validation?: V;
    display?: D;
    meta?: M;
    editable?: boolean;
    canEdit?: boolean;
    defaultRules?: Record<string, T>; //in use
};

type Requitable = {
    required?: boolean;
};

type BaseArrayField<FT extends FieldTypes, T, V, D> = BaseField<FT, T, V, D, FieldCtrlMeta> & {
    default?: T[];
};

type BaseMapField<FT extends FieldTypes, T, V, D> = BaseField<FT, T, V, D, FieldCtrlMeta> & {
    default?: Record<string, T>;
};

type BaseSingleField<FT extends FieldTypes, T, V, D> = BaseField<FT, T, V, D, FieldCtrlMeta> &
    Requitable & {
        default?: T;
    };

type BaseContainerField<FT extends FieldTypes, T, V, D> = BaseField<FT, T, V, D, ContainerCtrlMeta>;

// fields
export type StringField = BaseSingleField<FieldTypes.String, string, StringValidation, StringComponents> & {
    options?: Option[] | (() => Option[]) | (() => Promise<Option[]>);
    autocomplete?: Option[] | (() => Option[]) | (() => Promise<Option[]>);
};
export type CustomField = BaseField<FieldTypes.Custom, any, any, unknown, any> & {
    customType: string;
};
export type NumberField = BaseSingleField<FieldTypes.Number, number, NumberValidation, NumberComponents>;
export type ArrayField = BaseArrayField<
    FieldTypes.Array,
    string,
    ArrayValidation<StringField | NumberField | BooleanField | any>,
    StringArrayComponents
> & {
    item: Field;
};
export type MapField = BaseMapField<
    FieldTypes.Map,
    string,
    ArrayValidation<StringField | NumberField | BooleanField | any>,
    StringArrayComponents
> & {
    pair: PairField;
};
export type BooleanField = BaseField<FieldTypes.Bool, boolean, undefined, BoolComponents, FieldCtrlMeta> & {
    default?: boolean;
};
export type Field =
    | CustomField
    | ObjField<Record<string, any>>
    | StringField
    | NumberField
    | BooleanField
    | ArrayField
    | MapField
    | PairField;

export type FieldsByKeys = {
    [key: string]: Field;
};

export type PairField = BaseContainerField<
    FieldTypes.Pair,
    { key: string | number; value: unknown },
    undefined,
    PairComponents
> & {
    key: NumberField | StringField;
    value: Field;
};

export type ObjField<T extends Record<string, unknown>> = BaseContainerField<
    FieldTypes.Obj,
    T,
    undefined,
    ObjComponents
> & {
    fields: FieldsByKeys;
    customDisplay?: string;
};
