import {
    FieldCtrl,
    FieldCtrlArgs,
    CtrlArgs,
    ArrayCtrl,
    ArrayCtrlArgs,
    CustomCtrlArgs,
    ObjCtrlArgs,
} from "@/core-ui/forms/compositions";
import { PropType } from "vue";
import { MapCtrlArgs } from "../compositions/MapCtrl";

// types
export type Variant = "row" | "column" | "clean";
export type InputValueTypes = string | number;
export type LoaderStatus = "loading" | "falied" | "ready" | "empty";

// props
export function customCtrlArgs<T>() {
    return {
        type: Object as PropType<CustomCtrlArgs<T>>,
        required: true,
    };
}

export function arrayCtrl<T>() {
    return {
        type: Object as PropType<ArrayCtrl<T>>,
        required: true,
    };
}
export function mapCtrlArgs() {
    return {
        type: Object as PropType<MapCtrlArgs>,
        required: true,
    };
}
export function arrayCtrlArgs<T extends CtrlArgs>() {
    return {
        type: Object as PropType<ArrayCtrlArgs<T>>,
        required: true,
    };
}
export function objCtrlArgs() {
    return {
        type: Object as PropType<ObjCtrlArgs>,
        required: true,
    };
}
export function fieldCtrlArgs<T>() {
    return {
        type: Object as PropType<FieldCtrlArgs<T>>,
        required: true,
    };
}
export const variant = {
    type: String as PropType<Variant>,
    default: "row",
};

export const containerCtrl = { type: Object, required: true };
export function fieldCtrl<T>() {
    return {
        type: Object as PropType<FieldCtrl<T>>,
        required: true,
    };
}
export const inputName = { type: String, required: true };
