import { StringValidation, NumberValidation } from "@/core-ui/forms/types/declarative-fields";
import { FieldCtrlMeta } from "./FieldCtrl";
import { ValuePipe } from "./types";
let ctrlIdCounter = 1;

export function getCtrlId() {
    return ctrlIdCounter++;
}

export function isEmpty(value: any): boolean {
    return value === "" || value === undefined || value === null;
}

export const inputTypeToType = {
    port: "number",
    text: "string",
    email: "string",
    string: "string",
    password: "string",
    number: "number",
    boolean: "boolean",
};

export const inputTypeToFormat = {
    email: "email",
    port: "port",
    string: undefined,
    password: "password",
};

export const formatToInputType = {
    email: "email",
    password: "password",
    port: "number",
};

export type StringFormatTypes =
    | "password"
    | "email"
    | "volume"
    | "kubeName"
    | "name"
    | "duration"
    | "port"
    | "memory"
    | "envVar"
    | "imageName"
    | "shellCommand"
    | StringFormatEnum;

/*
Unit:
    1. string => long  unit name
    2. string => short unit name
    3. string => value of the unit
 */
export type Unit = [string, string, number];

export const durationUnits: Unit[] = [
    ["Days", "d", 24 * 60 * 60],
    ["Hours", "h", 60 * 60],
    ["Minutes", "m", 60],
    ["Seconds", "s", 1],
];

export const memoryUnits: Unit[] = [
    ["Terabyte", "Pi", 1024 * 1024 * 1024 * 1024 * 1024],
    ["Terabyte", "Ti", 1024 * 1024 * 1024 * 1024],
    ["Gigabyte", "Gi", 1024 * 1024 * 1024],
    ["Megabyte", "Mi", 1024 * 1024],
    ["Megabyte", "mi", 1024 * 1024],
    ["Kilobyte", "ki", 1024],
    ["Terabyte", "P", 1000 * 1000 * 1000 * 1000 * 1000],
    ["Terabyte", "T", 1000 * 1000 * 1000 * 1000],
    ["Gigabyte", "G", 1000 * 1000 * 1000],
    ["Megabyte", "M", 1000 * 1000],
    ["Megabyte", "m", 1000 * 1000],
    ["Kilobyte", "k", 1000],
    ["Byte", "b", 1],
];

export const durationExample = "e.g 3d 4h";
export const memoryExample = "e.g 1.5G";
export const gpuMemoryExample = "e.g 512M";
export const durationError = "Please use the following format: <number>(d|h|m|s) - e.g 2m 40s";
export const memoryError = "Please use the following format: <number>(b|k|m|M|G|T|P) - e.g 2.5G";
export const gpuMemoryError = "Please use the following format: <number>(b|k|m|M|G|T|P) - e.g 512M";
export const gpuMemoryLengthError = "GPU Memory must be 100M or higher";

export const buildUnitStringTemplate = (units: Unit[]): string => {
    const unitsShortName = units.map(([_, s]) => s).join("|") + "|";
    return `^(\\d+\\.{0,1}\\d+|\\d+)(${unitsShortName})$`;
};

export const buildUnitMinMaxValidator = (input: any, min?: number, max?: number) => {
    if (!min && !max) return true;
    let inputUnit = "",
        multiplier = 0;
    const inputNumber = input.match(new RegExp(/\d+\.{0,1}\d+|\d+/gi));
    inputUnit = input.replace(inputNumber[0], "");
    memoryUnits.forEach((memoryUnit) => {
        if (memoryUnit[1] === inputUnit) {
            multiplier = memoryUnit[2];
        }
    });
    const inputNumberInBytes: number = Number(inputNumber) * multiplier;
    if ((min && inputNumberInBytes >= min) || (max && inputNumberInBytes <= max)) return true;
};

export const buildUnitStringValidator = (units: Unit[], errorMsg: string, min?: number, max?: number) => {
    const template = new RegExp(buildUnitStringTemplate(units));
    return (v: string): string => {
        if (v) {
            if (!template.test(v)) return errorMsg;
            if (!buildUnitMinMaxValidator(v, min, max)) return gpuMemoryLengthError; // min/max used for now only for gpu memory
        }
        return "";
    };
};

export const buildUnitsStringValidator = (units: Unit[], errorMsg: string) => {
    const unitValidator = buildUnitStringValidator(units, errorMsg);
    return (v: string): string => {
        if (!v) return "";
        const units = v.split(" ").filter((v) => v !== ""); // 3d 1m
        if (units.some(unitValidator)) {
            return errorMsg;
        }
        return "";
    };
};

export enum StringFormatEnum {
    topologyKey = "topologyKey",
    envVar = "envVar",
    password = "password",
    email = "email",
    volume = "volume",
    groups = "groups",
    kubeName = "kubeName",
    name = "name",
    duration = "duration",
    port = "port",
    memory = "memory",
    gpuMemory = "gpuMemory",
    migProfile = "migProfile",
    imageName = "imageName",
    shellCommand = "shellCommand",
}

const PORT_MIN = 0;
const PORT_MAX = 65353;

const handlerNumberFormats: { [key: string]: ValuePipe<number> } = {
    [StringFormatEnum.port]: (v: number) => {
        if (v) {
            const p = parseInt(v as any);
            if (isNaN(p) == false) {
                if (p > PORT_MIN && p < PORT_MAX) {
                    return "";
                }
            }
            return `Please use only numbers between ${PORT_MIN} to ${PORT_MAX}`;
        }
        return "";
    },
};

export const StringFormatHandlers: {
    [key in StringFormatEnum]: ValuePipe<string>;
} = {
    [StringFormatEnum.password]: (v: string) => {
        return v && !v.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/)
            ? "Please use lower case (a-z), upper case (A-Z), numbers (0-9) and special characters (!@#$%^&*)"
            : "";
    },
    [StringFormatEnum.groups]: (v: string) => {
        return v && !v.match(/^[0-9]+([,;][0-9]+)*$/)
         ? "Please use only numbers and comma or semicolon as separator"
         : "";
    },
    [StringFormatEnum.topologyKey]: (v: string) => {
        return v && !v.match(/^([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]((\/)(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]))?$/)
            ? "Please use alphanumeric characters, '-', '_', '.' or '/' allowed only once, and must start and end with an alphanumeric character"
            : "";
    },
    [StringFormatEnum.volume]: (_: string) => {
        return "";
    },
    [StringFormatEnum.envVar]: (_: string) => {
        return "";
    },
    [StringFormatEnum.kubeName]: (v: string) => {
        return v && !v.match(/^[a-z][-a-z0-9]*[a-z0-9]$/)
            ? `Name must start with a lowercase letter. Valid characters are [a-z, 0-9,  - ].`
            : "";
    },
    [StringFormatEnum.email]: (v: string) => {
        return v && !v.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/) ? "Please enter a valid email" : "";
    },
    [StringFormatEnum.name]: (v: string) => {
        return v && !v.match(/^[A-z0-9]*((-|\s)*[A-z0-9])*$/)
            ? "Please use only standard characters: a-z, 0-9, - or spaces"
            : "";
    },
    [StringFormatEnum.duration]: buildUnitsStringValidator(durationUnits, durationError),
    [StringFormatEnum.port]: (v: string) => {
        if (v) {
            const p = parseInt(v);
            if (isNaN(p) == false) {
                if (p > PORT_MIN && p < PORT_MAX) {
                    return "";
                }
            }
            return `Please use only numbers between ${PORT_MIN} to ${PORT_MAX}`;
        }
        return "";
    },
    [StringFormatEnum.memory]: buildUnitStringValidator(memoryUnits, memoryError),
    [StringFormatEnum.gpuMemory]: buildUnitStringValidator(memoryUnits, gpuMemoryError, 100 * 1000 * 1000),
    [StringFormatEnum.migProfile]: (v: string) => {
        return "";
    },
    [StringFormatEnum.imageName]: (v: string) => {
        // todo
        return "";
    },
    [StringFormatEnum.shellCommand]: (v: string) => {
        // todo
        return "";
    },
};

export function createStringValidation(validation?: StringValidation): ValuePipe<string> | null {
    if (!validation) {
        return null;
    }
    let optionsString = "";
    const { enum: options = [], maxLength, minLength, pattern } = validation;
    const format = validation.format as StringFormatEnum;
    let patternReg: RegExp | null = null;
    if (pattern && typeof pattern == "string") {
        patternReg = RegExp(pattern);
    }
    if (format && !StringFormatHandlers[format]) {
        console.warn(`Unknown format: ${format}`);
    }
    let optionsSet: Set<string> | null = null;
    if (options.length) {
        optionsString = `(${options.join(", ")})`;
        optionsSet = new Set(options);
    }
    return (v: string) => {
        const formatErr = format && StringFormatHandlers[format] ? StringFormatHandlers[format](v) : undefined;
        if (formatErr) {
            return formatErr;
        }

        if (v && patternReg && !patternReg.test(v)) {
            return `Incompatible pattern`;
        }

        if (v && maxLength && v.length > maxLength) {
            return `More than ${maxLength} letters`;
        }

        if (v && minLength && v.length < minLength) {
            return `Less than ${minLength} letters`;
        }

        if (v && optionsSet && !optionsSet.has(v)) {
            return `Please insert one of ${optionsString}`;
        }

        return "";
    };
}

export function createNumberValidation(validation?: NumberValidation): ValuePipe<number> | null {
    if (!validation) {
        return null;
    }
    const { min, max, step = 1, format } = validation;

    const stepLength = String(step - parseInt(step as any)).length - 2 || 0;
    function formatFloat(f: number) {
        return f.toFixed(stepLength);
    }
    return (num: number) => {
        const formatErr = format && handlerNumberFormats[format] ? handlerNumberFormats[format](num) : undefined;
        if (formatErr) {
            return formatErr;
        }
        if (min !== undefined && num < min) {
            return `Number must be no smaller than ${min}`;
        }

        const minNumber = min || 0;
        const number = num - minNumber;
        if (max !== undefined && num > max) {
            return `Number must be no greater than ${max}`;
        } else if (Math.abs(Math.round(number / step) - number / step) > 0.000000000001) {
            if (step != 1) {
                return `Please use number divisible by ${step} (e.g ${minNumber + step}, ${formatFloat(
                    minNumber + step * 2,
                )}, ${formatFloat(minNumber + step * 3)} ...)`;
            }
            return `Value must be integer`;
        }
        return "";
    };
}

export function prepareStringInputCtrl(
    validation?: StringValidation,
    placeholder?: string,
): [ValuePipe<string> | null, FieldCtrlMeta] {
    const pipeFunc = createStringValidation(validation);
    const { maxLength, minLength, format, pattern, enum: options } = validation || ({} as StringValidation);
    const props: any = {};
    const keyInputProps: any = { name: "key" },
        valInputProps: any = { name: "value" };
    let type = "text";
    if (format && (formatToInputType as any)[format]) {
        type = (formatToInputType as any)[format];
    }
    const inputProps: any = {
        maxlength: maxLength,
        minlength: minLength,
        type,
        pattern,
    };
    if (format == "envVar") {
        keyInputProps.placeholder = "Name";
        valInputProps.placeholder = "Value";
        props.icon = "raicon-equals";
    }

    if (format == "volume") {
        keyInputProps.placeholder = "Source Path";
        valInputProps.placeholder = "Target Path";
    }
    if (format == "duration") {
        inputProps.placeholder = durationExample;
    }
    if (format == "memory") {
        inputProps.placeholder = memoryExample;
    }
    if (format == "gpuMemory") {
        inputProps.placeholder = gpuMemoryExample;
    }

    if (placeholder) {
        inputProps.placeholder = placeholder;
    }

    const meta = {
        options,
        format,
        inputProps,
        keyInputProps,
        valInputProps,
        ...props,
    };

    return [pipeFunc, meta];
}

export function prepareNumberInputCtrl(validation?: NumberValidation): [ValuePipe<number> | null, FieldCtrlMeta] {
    const pipeFunc = createNumberValidation(validation);

    const { max, min, step = 1 } = validation || ({} as any);
    const inputProps = { max, min, step: String(step), type: "number" };
    const meta = { inputProps };

    return [pipeFunc, meta];
}

export const createValueProxy = <T extends Record<string, any> = any>(
    controllers: Map<PropertyKey, any>,
    cacheValue: Map<PropertyKey, any>,
    onGetObj?: () => void,
    onSetObj?: () => void,
): T => {
    function enumerate(_: any) {
        onGetObj && onGetObj();
        const keys: any = new Set();
        for (const key of controllers.keys()) {
            keys.add(key);
        }
        for (const key of cacheValue.keys()) {
            keys.add(key);
        }
        return [...keys] as any[];
    }

    const handlers: ProxyHandler<T> = {
        get: function (target, prop, receiver) {
            const ctrl = controllers.get(prop);
            if (ctrl) {
                return ctrl.value;
            }
            onGetObj && onGetObj();
            if (cacheValue.has(prop)) {
                return cacheValue.get(prop);
            } else {
                return Reflect.get(target, prop, receiver);
            }
        },
        set: function (target, prop, value) {
            const ctrl = controllers.get(prop);
            if (ctrl) {
                ctrl.value = value;
                return true;
            }
            if (!cacheValue.has(prop)) {
                onSetObj && onSetObj();
            }
            cacheValue.set(prop, value);
            return true;
        },
        has: function (_, k) {
            return controllers.has(k) || cacheValue.has(k);
        },
        getOwnPropertyDescriptor: function (t, prop) {
            if (handlers.has!(t, prop)) {
                return {
                    get: () => handlers.get!(t, prop, {} as T),
                    set: (v) => handlers.set!(t, prop, v, {} as T),
                    enumerable: true,
                    configurable: true,
                };
            } else {
                return Reflect.getOwnPropertyDescriptor(t, prop);
            }
        },
        deleteProperty: function () {
            throw new Error("[Form::ProxyValue] Not supported deleteProperty");
        },
        defineProperty: function () {
            throw new Error("[Form::ProxyValue] Not supported defineProperty");
        },

        ownKeys: function (target) {
            return enumerate!(target);
        },
        apply: function () {
            throw new Error("[Form::ProxyValue] Not supported apply");
        },
        construct: function () {
            throw new Error("[Form::ProxyValue] Not supported construct");
        },
    };
    return new Proxy({} as T, handlers);
};

export const extendProxy = <T extends Record<string, any> = any>(proxy: typeof Proxy, extendedData: () => any): T => {
    const handlers: ProxyHandler<any> = {
        // get: function (target, prop, receiver) {
        //     const e = extendedData();
        //     if (e[prop] !== undefined) {
        //         return Reflect.get(e, prop, receiver)
        //     }
        //     return Reflect.get(target, prop, receiver)
        // },
        // has: function (target, k) {
        //     const e = extendedData();
        //     return (e[k] !== undefined) || Reflect.has(target, k)
        // },
        // getOwnPropertyDescriptor: function(t, prop) {
        //     if ( handlers.has!(t, prop)) {
        //         return {
        //             get: ()=> handlers.get!(t,prop, {} as T),
        //             enumerable: true,
        //             configurable: false,
        //         }
        //     } else {
        //         return Reflect.getOwnPropertyDescriptor(t, prop)
        //     }
        // },
        // ownKeys: function(target) {
        //     return [...Reflect.ownKeys(target), ...Reflect.ownKeys(extendedData())]
        // },
    };
    return new Proxy(proxy, handlers) as any as T;
};

export function makeID(length = 8) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function makeAlphabeticID(length = 8) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function makeLowerCaseAlphabeticID(length = 12) {
    let result = "";
    const characters = "abcdefghijklmnopqrstuvwxyz";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

