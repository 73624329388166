import { TableColumn } from "@/core-ui/types/column";
import { useColumnsOrderer, ColumnsOrderer, ColumnsOrderSource } from "./ColumnsOrderer";
import { Sorting } from "./Sorting";
import { useColumnsSize, ColumnsSizeSource, ColumnsSize } from "./ColumnsSize";
import { useShownColumns, ShownColumnsSource, ShownColumns } from "./ShownColumns";
import { DisplayDensity, useDisplayDensity } from "./DisplayDensity";

export interface TableColumnsProps {
    key: string;
    columns: TableColumn[];
    syncUrl: boolean;
    sorting?: Sorting;
}

export interface TableColumns {
    sorting: Sorting;
    orderer: ColumnsOrderer;
    sizer: ColumnsSize;
    multipleLine: any;
    shown: ShownColumns;
    density: DisplayDensity;
    get(key: string): TableColumn;
    columns: Array<TableColumn | Array<TableColumn>>;
    flatColumns: TableColumn[];
}

/**
 * useColumns contains the data and logic about column such as reorder of the columns
 *
 * @export
 */
export function useTableColumns(props: TableColumnsProps): TableColumns {
    const orderer = useColumnsOrderer({
        state: new ColumnsOrderSource({
            get key() {
                return props.key;
            },
            get ordering() {
                return props.columns.map(({ key }) => key);
            },
        }),
    });

    const sizer = useColumnsSize({
        state: new ColumnsSizeSource({
            get key() {
                return props.key;
            },
            get sizes() {
                return toSize(props.columns);
            },
        }),
    });

    const multipleLine = toMultipleLine(props.columns)

    const shown = useShownColumns({
        state: new ShownColumnsSource({
            get checked() {
                return toChecked(props.columns);
            },
        }),
    });

    const density = useDisplayDensity();

    return {
        orderer,
        sizer,
        multipleLine,
        shown,
        density,
        get sorting() {
            return props.sorting as Sorting;
        },
        get flatColumns(): TableColumn[] {
            return orderer.order(
                this.columns.reduce((res: TableColumn[], c: TableColumn | TableColumn[]) => {
                    if (c instanceof Array) {
                        return res.concat(c);
                    } else {
                        res.push(c);
                        return res;
                    }
                }, []),
            ) as any;
        },
        get columns() {
            return props.columns;
        },
        get(_key: string) {
            return this.flatColumns.find(({ key }) => key == _key) as TableColumn;
        },
    };
}

function toSize(columns: TableColumn[]) {
    return columns
        .filter(({ width }) => width)
        .reduce((o: any, { key, width }) => {
            o[key] = width;
            return o;
        }, {});
}

function toMultipleLine(columns: TableColumn[]) {
    return columns
        .filter(({ multipleLine }) => multipleLine)
        .reduce((o: any, { key, multipleLine }) => {
            o[key] = multipleLine;
            return o;
        }, {});
}

function toChecked(columns: TableColumn[]) {
    return columns.reduce((o: any, { key, hidden }) => {
        o[key] = !hidden;
        return o;
    }, {});
}
