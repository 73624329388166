
import { defineComponent, ref, watch, onBeforeUnmount } from "vue";
import { customCtrlArgs, variant } from "@/core-ui/forms/components/common";
import { ContainerCtrlMeta, useObjCtrl } from "@/core-ui/forms/compositions";
import { useFieldCtrl } from "@/core-ui/forms/compositions";
import { useDetectOutsideClick } from "@/core-ui/compositions/detectOutsideClick";

import Box from "@/core-ui/forms/components/Box.vue";
import BoxInputCtrl from "@/core-ui/forms/components/BoxInputCtrl.vue";
import Toggle from "@/core-ui/Toggle.vue";
import YamlEditor from "@/core-ui/YamlEditor/YamlEditor.vue";

import { WANDB_YAML_TEMPLATE } from "../../helpers/wandb.config";
import YAML from "js-yaml";

type meta = ContainerCtrlMeta;

export default defineComponent({
    props: {
        variant,
        ctrlArgs: customCtrlArgs<meta>(),
    },
    components: { Box, BoxInputCtrl, Toggle, YamlEditor },
    setup(props) {
        const ctrl = useObjCtrl(props.ctrlArgs!);
        const editorInvalidReason = ref(undefined as string | undefined);

        function getArgs(key: string) {
            return ctrl.meta.fields[key](ctrl.state, key).args;
        }

        const configFieldCtrl = useFieldCtrl(getArgs("yaml").ctrlArgs!);
        const yaml = ref("");
        let isSweepToggled = false;

        const editorCmpRef = ref();
        const clickOutside = useDetectOutsideClick(editorCmpRef, () => {
            if (!configFieldCtrl.value) {
                configFieldCtrl.context.error = true;
                editorInvalidReason.value = "* Required";
            } 
        });

        function handleConfigChange(code: string) {
            try {
                yaml.value = code;
                const obj = YAML.safeLoad(code);
                configFieldCtrl.value = JSON.stringify(obj);
            } catch (err) {
                // do nothing
            }
        }

        function handleValidation(isValid: boolean, message: string | undefined) {
            configFieldCtrl.context.error = !isValid;
            editorInvalidReason.value = message;
        }

        watch(
            () => ctrl.context.hidden,
            () => {
                if (!ctrl.context.hidden) {
                    clickOutside?.add();
                    handleConfigChange(WANDB_YAML_TEMPLATE);
                    if (!isSweepToggled) {
                        isSweepToggled = true;
                    }
                } else {
                    clickOutside?.remove();

                    if (isSweepToggled) {
                        handleConfigChange("");
                    }
                }
            },
        );

        onBeforeUnmount(() => clickOutside?.remove());

        return {
            ctrl,
            handleConfigChange,
            handleValidation,
            editorCmpRef,
            editorInvalidReason,
            yaml,
            entity: getArgs("wandbEntity"),
            project: getArgs("wandbProject"),
            apiKey: getArgs("wandbApiKey"),
            wandbCount: getArgs("wandbCount"),
        };
    },
});
