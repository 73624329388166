
import { ObjCtrl, getFieldRenderData } from "@/core-ui/forms/compositions";
import { FieldsByKeys } from "@/core-ui/forms/types/declarative-fields";
import FormSection from "@/core-ui/forms/components/FormSection.vue";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
    components: { FormSection },
    props: {
        ctrl: { type: Object as PropType<ObjCtrl>, required: true },
        fields: { type: Object as PropType<FieldsByKeys>, required: true },
        title: String,
    },
    setup: (props, ctx) => ({
        get renderDataOffields(): any {
            return Object.entries(props.fields).map(([key, field]) => getFieldRenderData(field)(props.ctrl.state, key));
        },
    }),
});
