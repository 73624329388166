import apiClient from "./api-client";
import { Cluster } from "@/types/cluster";
import prometheusService from "./prometheus-service";
import { Env } from "@/helpers/is-production-env";

export enum ClusterStatus {
    Connected = "Connected",
    Disconnected = "Disconnected",
}

export interface Dashboards {
    promProxy?: string;
    nowDashboard?: string;
    loginPage?: string;
    logoutPage?: string;
    longtermDashboard?: string;
    clustersDashboard?: string;
    consumptionDashboard?: string;
    deploymentUtilization?: string;
    deploymentPerformance?: string;
}

class ClustersService {
    async query(q?: any): Promise<Cluster[]> {
        const clusters = await apiClient.asyncRequestWithResponse(
            `v1/k8s/clusters?${q ? new URLSearchParams(q).toString() : ""}`,
            "GET",
        );

        if (clusters.length === 0) {
            return clusters;
        }

        const clustersStatus = await this.checkClusters();
        clusters.forEach(
            (c: Cluster) => (c.status = clustersStatus[c.uuid] ? ClusterStatus.Connected : ClusterStatus.Disconnected),
        );
        return clusters;
    }

    create(name: string): Promise<any> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/clusters`, "POST", {
            name,
        });
    }

    update(uuid: string, name: string): Promise<any> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/clusters/${uuid}`, "PUT", { name });
    }

    remove(uuid: string): Promise<any> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/clusters/${uuid}`, "DELETE");
    }

    getByUUID(uuid: string): Promise<Cluster> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/clusters/${uuid}`, "GET");
    }

    getOperatorVersions(chartEnv?: Env): Promise<any> {
        const path = `v1/k8s/clusters/versions` + (chartEnv ? `?chartEnv=${chartEnv}` : ``);
        return apiClient.asyncRequestWithResponse(path, "GET");
    }

    async getGrafanaDashboards(clusterId?: string): Promise<Dashboards> {
        let dashboards: Dashboards = {};
        let dashboardsApiUrl = "v1/k8s/dashboards";
        dashboardsApiUrl = clusterId ? dashboardsApiUrl + `?clusterId=${clusterId}` : dashboardsApiUrl;
        try {
            dashboards = await apiClient.asyncRequestWithResponse(dashboardsApiUrl, "GET");
        } catch (e) {
            console.error(e);
        }
        return dashboards;
    }
    async checkClusters(clusterId?: string): Promise<any> {
        try {
            const connectedCluster = await prometheusService.getFilteredResultMultipleQueries(
                {
                    general: `count(kube_node_status_condition${
                        clusterId ? `{clusterId='${clusterId}'}` : ""
                    }) by(clusterId)`,
                },
                "clusterId",
            );

            return Object.fromEntries(connectedCluster.map((c) => [c.clusterId, c]));
        } catch (e) {
            console.error(e);
            return {};
        }
    }
}

const clustersService = new ClustersService();
export default clustersService;
