
import Box from "./Box.vue";
import Input from "./Input.vue";
import BoxInputMessages from "./BoxInputMessages.vue";
import { useFieldCtrl } from "@/core-ui/forms/compositions";
import { useSyncRunner } from "@/core-ui/forms/compositions/SyncRunner";
import { defineComponent, onMounted, ref } from "vue";
import { fieldCtrlArgs, variant, InputValueTypes, LoaderStatus } from "./common";

export default defineComponent({
    components: { Box, BoxInputMessages, Input },
    props: {
        ctrlArgs: fieldCtrlArgs<InputValueTypes>(),
        theme: String,
        itemCanEdit: { type: Boolean, default: undefined },
        temporaryDisabled: { type: Boolean, default: false },
        variant,
    },
    created() {
        this.thisComp = this;
    },
    setup: (props) => {
        if (props.itemCanEdit != undefined) {
            props.ctrlArgs!.editable = props.itemCanEdit;
        }

        const options = ref<string[]>([]);
        const optionsStatus = ref<LoaderStatus>("empty");
        const thisComp = ref<any>(null);

        function setOptions(optionsRow: string[]) {
            if (Array.isArray(optionsRow)) {
                options.value = optionsRow;
                optionsStatus.value = "ready";
            } else {
                options.value = [];
                optionsStatus.value = "empty";
            }
        }

        const fieldCtrl = useFieldCtrl(props.ctrlArgs!);

        const autocompleteLoader = useSyncRunner(async () => {
            let options = (fieldCtrl.meta as any)?.autocomplete;
            if (typeof options == "function") {
                try {
                    optionsStatus.value = "loading";
                    options = await options();
                } catch {
                    optionsStatus.value = "falied";
                }
            }
            setOptions(options);
        });

        onMounted(() => {
            autocompleteLoader.run();
        });

        const value = ref("");

        const self = {
            value,
            fieldCtrl,
            thisComp,
            get attrs(): any {
                const inputProps = this.fieldCtrl?.meta?.inputProps || {};

                return { ...thisComp.value.$attrs, ...(inputProps || {}) };
            },
            autocompleteData: {
                get data() {
                    return options.value || [];
                },
                get status() {
                    return optionsStatus.value;
                },
            },
            get invalid(): boolean {
                return this.fieldCtrl.valid == "invalid";
            },

            get inputType(): string {
                return self.fieldCtrl?.meta?.inputProps?.type || props.ctrlArgs!.type;
            },
        };
        return self;
    },
});
