import { ref, watch } from "vue";
import { ID } from "../types";
import { ListDisplay } from "./ListDisplay";

export type ItemsSelectionMode = "none" | "single" | "multi" | "all";

export interface ItemsSelectionProps<T> {
    key?: string;
    multi: boolean;
    display: ListDisplay<T>;
}

export interface ItemsSelection<T = unknown> {
    toggle(id: ID): void;
    isSelected(id: ID): boolean;
    mode: ItemsSelectionMode;
    firstItem: T | null;
    selectedData: T[];
    selected: ID[];
    selectedLen: number;
    clear(): void;
    // future:
    //selectAll(query: any)
}

/**
 *
 * RowsSelection response to control and mange selected items
 *
 * @export
 * @template T
 * @param {ItemsSelectionProps} props
 * @returns {ItemsSelection<T>}
 */
export function useItemsSelection<T = unknown>(props: ItemsSelectionProps<T>): ItemsSelection<T> {
    // future: multi modes (all selected and  all expect)
    // const mode = ref<RowsSelectionMode>("none");

    const marked = ref<{ [key: string]: any }>({});
    watch(() => props.key, clear, { immediate: true });
    watch(() => props.display.items, syncSelected, { immediate: true });
    function syncSelected() {
        // currently the user can't select item that it is not display
        // but we save stile save the item for the future
        // (In multi selection case you can select from many pages and wants your selection item to saved some ware)
        const curMode = mode();
        if (curMode == "none") return;

        for (const id in marked.value) {
            if (!props.display.has(id)) {
                unselect(id);
            } else {
                select(id);
            }
        }
    }

    function clear() {
        marked.value = {};
    }

    function toggle(id: ID) {
        if (isSelected(id)) {
            unselect(id);
        } else {
            select(id);
        }
    }

    function select(id: ID) {
        const item = props.display.getItem(id);
        // currently we are not selecting
        if (!item) return;
        if (!props.multi) {
            marked.value = { [id]: item };
        } else {
            marked.value = { ...marked.value, [id]: item };
        }
    }

    function unselect(id: ID) {
        if (!props.multi) {
            marked.value = {};
        } else {
            // if the id is number
            id = id.toString();
            // remove the item by filtering out the key == id
            marked.value = Object.fromEntries(Object.entries(marked.value!).filter(([key]) => key != id));
        }
    }

    function isSelected(id: ID): boolean {
        return !!marked.value![id];
    }

    function selectedLen() {
        return Object.keys(marked.value || {}).length;
    }

    function mode() {
        const len = selectedLen();
        return len ? (len == 1 ? "single" : "multi") : "none";
    }

    return {
        get selectedLen() {
            return selectedLen();
        },
        get mode() {
            return mode();
        },
        isSelected,
        toggle,
        get firstItem() {
            if (this.mode == "single" || this.mode == "multi") {
                return this.selectedData[0];
            }
            return null;
        },
        get selected() {
            return Object.keys(marked.value!);
        },
        get selectedData() {
            return Object.values(marked.value!) as T[];
        },
        clear,
    };
}
