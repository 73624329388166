export enum ROUTES_ENUM {
    MAIN = "main",
    NOW_DASHBOARD = "now",
    LOGIN = "login",
    ANALYTICS_DASHBOARD = "analytics-dashboard",
    CONSUMPTION_DASHBOARD = "consumption-dashboard",
    CLUSTERS_DASHBOARD = "clusters-dashboard",
    OLD_ANALYTICS = "analytics-old",
    JOBS = "jobs",
    JOBS_HISTORY = "jobs/history",
    WORKSPACES = "workspaces",
    DEPLOYMENTS = "deployments",
    DEPLOYMENTS_HISTORY = "deployments/history",
    DEPLOYMENT = "deployment",
    //SUBMIT = "jobs/submit",
    SUBMIT_DEPLOYMENTS = "deployments/new",
    SUBMIT_WORKSPACES = "workspaces/new",
    SUBMIT_TRAINING = "trainings/new",
    SUBMIT_JOB = "jobs/submit",
    NODES = "nodes",
    DEPARTMENTS = "departments",
    SUBMIT_DEPARTMENT = "departments/new",
    EDIT_DEPARTMENT = "departments/edit",
    PROJECTS = "projects",
    PERMISSIONS = "permissions",
    APPLICATIONS = "applications",
    APPLICATIONS_V2 = "applications-v2",
    GENERAL_SETTINGS = "general-settings",
    CLUSTERS = "clusters",
    CLUSTERS_NEW = "cluster/new",
    AUDIT = "audit",
    TEST = "test",
    NO_ROLES = "no-roles",
    NO_SSO_EMAIL_MAPPER = "no-sso-email-mapper",
    ENVIRONMENTS = "environments",
    ENVIRONMENTS_NEW = "environments/new",
    DATA_SOURCES = "data-sources",
    DATA_SOURCES_NFS_NEW = "data-sources/nfs/new",
    DATA_SOURCES_PVC_NEW = "data-sources/pvc/new",
    DATA_SOURCES_GIT_NEW = "data-sources/git/new",
    DATA_SOURCES_S3_NEW = "data-sources/s3/new",
    DATA_SOURCES_HOST_PATH_NEW = "data-sources/hostpath/new",
    WORKSPACE_TEMPLATES = "workspace-templates",
    WORKSPACE_TEMPLATES_NEW = "workspace-templates/new",
    COMPUTE_RESOURCE = "compute-resources",
    COMPUTE_RESOURCE_NEW = "compute-resources/new",
    CREDENTIAL = "credentials",
    CREDENTIAL_USERPASS_NEW = "credentials/userpass/new",
    CREDENTIAL_ACCESS_KEY_NEW = "credentials/access-key/new",
    CREDENTIAL_DOCKER_REGISTRY_NEW = "credentials/docker-registry/new",
    ROLES = "roles",
    ROLES_REVIEW = "roles/review",
    ASSIGNMENTS = "roles/assignments",
    ASSIGNMENT_ROLE_VIEW = "roles/assignments/role-review",
    TRAININGS = "trainings",
    USERS = "users",
    LICENSES = "licenses",
    RESOURCES = "resources",
}
