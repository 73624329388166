import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            [_ctx.$style.arrowContainerBottom]: _ctx.position === 'bottom',
            [_ctx.$style.arrowContainerLeft]: _ctx.position === 'left',
        })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({
                [_ctx.$style.dropDownArrowBottom]: _ctx.position === 'bottom',
                [_ctx.$style.dropDownArrowLeft]: _ctx.position === 'left',
            })
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass({
                [_ctx.$style.dropDownArrowBorderBottom]: _ctx.position === 'bottom',
                [_ctx.$style.dropDownArrowBorderLeft]: _ctx.position === 'left',
            })
    }, null, 2)
  ], 2))
}