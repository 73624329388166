import { DataGridColumn } from "@/core-ui/types/column";
import { ID, ListData } from "@/core-ui/data-grid/types";
import { Filter } from "@/core-ui/data-grid/compositions";
import { ref, watch } from "vue";
import { useFetcher, FetcherState } from "./Fetcher";
import { useListCache, ListCache } from "./Cache";
import { isItem } from "../utils";
import { camalize } from "@/core-ui/helpers/data-formats";

export type ModelMeta<T = unknown> = {
    icon: string | ((item: T) => string | { type: "img"; src: string });
    type: string;
    singular: string;
    plural: string;
    display: (item: T) => string;
};

// Adding props to an entry element container e.g - if some row is archived you can change the container element's style
export type EntryProps<T = unknown> = { tableRow: (e: T) => unknown };

export type ModelOptions = {
    noId?: boolean;
    noSelection?: boolean;
    noSearch?: boolean;
    initialSearchQuery?: boolean;
};

export type DataGridModelProps<T = unknown> = {
    meta: ModelMeta<T>;
    tableName: string;
    dataKey?: string;
    entryProps?: EntryProps<T>;
    columns: DataGridColumn[];
    syncServer?: boolean;
    fetchInterval?: number;
    fetch: (props?: Filter) => Promise<ListData<T>> | ListData<T>;
    options?: ModelOptions;
};

export type DataGridModel<T = unknown> = {
    meta: ModelMeta<T>;
    itemId: ID;
    dataKey: string;
    tableName: string;
    columns: DataGridColumn[];
    entryProps?: EntryProps<T>;
    syncServer?: boolean;
    cache: ListCache<T>;
    state: FetcherState;
    options: ModelOptions;
    update: (props?: Filter) => void;
    refresh: () => void;
};

export function useDataGridModel<T>(props: DataGridModelProps<T>): DataGridModel<T> {
    let isInit = false;
    const id = ref<ID>();
    const lastFilter = ref<Filter>();
    const cache = useListCache<T>();

    const filteredColumns = () => props.columns.filter((c) => !c.filter || c.filter());

    const fetcher = useFetcher({
        get key() {
            return props.dataKey;
        },
        fetch: () => props.fetch(lastFilter.value),
        cache,
        syncMethod:
            props.fetchInterval == undefined
                ? undefined
                : {
                      method: "Polling",
                      interval: props.fetchInterval,
                      stopOnFailure: true,
                  },
    });

    watch(
        () => props.dataKey,
        () => isInit && cache.clear(),
        { immediate: true },
    );
    watch(
        () => filteredColumns(),
        () => {
            id.value = filteredColumns().find(isItem("primary"))?.dataKey;
            if (!id.value && filteredColumns().length && !props.options?.noId) {
                throw new Error(
                    `[DataGridModel]:: Not found any column with primary = true on columns: ${JSON.stringify(
                        filteredColumns(),
                    )}`,
                );
            }
        },
        { immediate: true },
    );

    return {
        cache,
        get tableName() {
            return props.tableName;
        },
        get meta() {
            return props.meta;
        },
        get state() {
            return fetcher.state;
        },
        get itemId() {
            return id.value as ID;
        },
        get dataKey() {
            return props.dataKey as string;
        },
        get columns() {
            return filteredColumns();
        },
        get entryProps() {
            return props.entryProps;
        },
        get syncServer() {
            return props.syncServer;
        },
        get options() {
            return props.options || {};
        },
        update(filter?: Filter) {
            isInit = true;
            lastFilter.value = filter;
            fetcher.fetch();
        },
        refresh() {
            isInit = true;
            fetcher.fetch();
        },
    };
}

//// helpers

export function createModelMeta<T>(props: {
    icon: string | ((item: T) => string | { type: "img"; src: string });
    type: string;
    plural?: string;
    singular?: string;
    display: (item: T) => string;
}): ModelMeta<T> {
    const type = props.type;
    const { icon, display, plural = camalize(type) + "s", singular = camalize(type) } = props;

    return {
        icon,
        type,
        plural,
        singular,
        display,
    };
}
