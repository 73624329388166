import { notMappedKeys } from "../../cluster-ui/helpers/const";
import { parseCommand } from "@/cluster-ui/components/submit/paramsUtil";

type BasicSettings = {
    name: string;
    project: string;
    image: string;
};

type ResourceAllocationSettings = {
    gpu: number;
    cpu: number;
    cpuLimit: number;
    gpuMemory: string;
    migProfile: string;
    memory: string;
    memoryLimit: string;
};

type ContainerDefinitionSettings = {
    command: Array<string>;
    arguments: string;
    port: number;
};

type StorageSettings = {
    volumes: { [key: string]: string };
};

type AutoScalingSettings = {
    minScale: number;
    maxScale: number;
    metric: string;
    metricName: string;
    target: number;
};

export type DeploymentSettings = BasicSettings &
    ResourceAllocationSettings &
    ContainerDefinitionSettings &
    StorageSettings &
    AutoScalingSettings;

export type SubmitDeploymentParams = {
    deployment: DeploymentSettings;
};

// TODO: missing logic to set toggle
// TODO: missing new keys in "not mapped keys"
export function mapDeploymentValue(value: { [key: string]: any }): SubmitDeploymentParams {
    const deployment: DeploymentSettings = {} as DeploymentSettings;
    Object.keys(value || {}).forEach((k) => {
        if (notMappedKeys.has(k)) {
            (deployment as any)[k] = value[k];
        }

        // convert from """arg1", "arg2", ..." to [arg1, arg2, ...]
        else if (k == "command") {
            if (value[k]) {
                parseCommand(deployment, value);
            }
        }

        if (k == "volumes") {
            deployment[k] = JSON.parse(JSON.stringify(value[k]));
        }
    });
    return {
        deployment,
    };
}

export function fromDeploymentTemplate(value: DeploymentSettings) {
    const result: any = {};
    Object.keys(value || {}).forEach((k) => {
        if (notMappedKeys.has(k)) {
            (result as any)[k] = (value as any)[k];
        }
        // convert from array of <key>:<value> strings to map object
        else if (k == "command") {
            result[k] = value[k];
        } else {
            console.warn(`Unidentified key: ${k}`);
        }
    });
    return result;
}

export function toDeploymentTemplate(value: { [key: string]: any }): {
    [key: string]: any;
} {
    const temp: { [key: string]: any } = {} as DeploymentSettings;
    Object.keys(value || {}).forEach((k) => {
        if (notMappedKeys.has(k)) {
            (temp as any)[k] = value[k];
        }
        // convert from array of <key>:<value> strings to map object
        else if (k == "command") {
            temp[k] = value[k];
        } else if (k == "volumes") {
            temp[k] = JSON.parse(JSON.stringify(value[k]));
        } else {
            console.warn(`Unidentified key: ${k}`);
        }
    });
    return temp;
}
