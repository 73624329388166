import { Ref } from "vue";

export function useDetectOutsideClick(component: Ref, callback: Function) {
  if (!component) return;
  const listener = (event: Event) => {
      if (event.target !== component.value && event.composedPath().includes(component.value)) {
          return;
      }
      if (typeof callback === "function") {
          callback();
      }
  };

  const remove = () => { window.removeEventListener("click", listener); };
  const add = () => { window.addEventListener("click", listener); };

  return { listener, add, remove };
}