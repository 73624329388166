import { RouteRecordRaw } from "vue-router";
import Logs from "./Logs.vue";
import Submit from "./../../views/submitJobContainer.vue";

export const jobLogs = ({ base = "/jobs", name = "Job Logs" } = {}): RouteRecordRaw => ({
    path: `${base}/:project/:name/:jobId/log`,
    name,
    component: Logs,
});

export const submit = ({ base = "", name = "Submit" } = {}): RouteRecordRaw => ({
    path: `${base}/submit`,
    name,
    component: Submit,
});
