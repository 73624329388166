import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toggle = _resolveComponent("Toggle")!
  const _component_BoxInputMessages = _resolveComponent("BoxInputMessages")!
  const _component_Box = _resolveComponent("Box")!

  return (_openBlock(), _createBlock(_component_Box, {
    ctrl: _ctx.fieldCtrl,
    variant: "row",
    valueFirst: _ctx.valueFirst
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BoxInputMessages, {
        variant: "row",
        ctrl: _ctx.fieldCtrl
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_Toggle, {
              modelValue: _ctx.fieldCtrl.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fieldCtrl.value) = $event)),
              disabled: _ctx.fieldCtrl.disabled || !_ctx.itemCanEdit,
              name: _ctx.fieldCtrl.key
            }, null, 8, ["modelValue", "disabled", "name"])
          ])
        ]),
        _: 1
      }, 8, ["ctrl"])
    ]),
    _: 1
  }, 8, ["ctrl", "valueFirst"]))
}