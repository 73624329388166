
import { PropType, defineComponent } from "vue";
import YAML from "js-yaml";
import { YAMLException } from "js-yaml";
import * as monaco from "monaco-editor";
import { setDiagnosticsOptions } from "monaco-yaml";

setDiagnosticsOptions({
    hover: true,
    validate: true,
    format: true,
    isKubernetes: true,
    enableSchemaRequest: false,
});


export default defineComponent({
    emits: ["changed", "validation-status"],
    name: "YamlEditor",
    props: {
        yaml: {
            type: String,
            default: "",
        },
        options: {
            type: Object as PropType<monaco.editor.IStandaloneEditorConstructionOptions>,
            default: () => ({}),
        },
        disable: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.initEditor();
        });
    },
    methods: {
        initEditor() {
            const options = Object.assign(
                {
                    value: this.yaml,
                    theme: "vs-dark",
                },
                this.options,
                { language: "yaml" },
            );

            const editor = monaco.editor.create(this.$refs.container as HTMLElement, options);

            editor.onDidChangeModelContent((event) => {
                const value = editor.getValue();
                this.emitValidState(value);
                if (this.yaml !== value) {
                    this.$emit("changed", value, event);
                }
            });
        },
        emitValidState(code: string) {
            try {
                YAML.safeLoad(code);
                this.$emit("validation-status", true);
            } catch (err: unknown) {
                if (err instanceof YAMLException) {
                    this.$emit("validation-status", false, err.message);
                } else {
                    this.$emit("validation-status", false, err);
                }

            }
        },
    },
});
