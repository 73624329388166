
import { defineComponent, ref } from "vue";

export default defineComponent({
    props: {
        modelValue: {},
        required: { type: Boolean, default: () => true },
        type: String,
        name: String,
        label: String,
        errorMsg: String,
        validation: Object,
        autoWidth: Boolean,
        step: String,
        aid: String,
    },
    setup(props, ctx) {
        const updatedValue = ref<any>(null);
        const valid = ref(true);

        const self = {
            valid,
            updatedValue,
            get model() {
                return updatedValue.value !== null ? updatedValue.value : props.modelValue;
            },

            set model(value: string) {
                updatedValue.value = self.inputType === "number" ? Number(value) : value;
                self.checkValidation();
                valid.value = true; // reset validity until blur
            },

            get inputType() {
                return props.type || props.validation?.inputType;
            },

            get err(): string {
                return (
                    props.errorMsg ||
                    props.validation?.errMsg ||
                    `* Bad ${self.inputType === "number" ? "number of " : ""}${props.name}`
                );
            },

            get invalid() {
                return !this.valid;
            },
            checkValidation() {
                const model = self.model;
                const validated = typeof model !== "undefined" && props.validation ? props.validation.test(model) : true;
                valid.value = (model === "" && !props.required) || validated;
                ctx.emit("update:modelValue", valid.value ? model : null);
            },
        };
        return self;
    },
});
