
import Button from "@/core-ui/Button.vue";
import IconButton from "@/core-ui/IconButton.vue";
import Box from "./Box.vue";
import BoxInputCtrl from "./BoxInputCtrl.vue";

import { isDisplayNone } from "@/core-ui/data-grid/directives/form-focus";
import { useArrayCtrl, FieldCtrlArgs, FieldKey } from "@/core-ui/forms/compositions";

import { arrayCtrlArgs } from "./common";
import { computed, defineComponent, ref } from "vue";

type InputValueTypes = string | number;

function scanNodes(nodes: any[], root: any) {
    for (const input of nodes) {
        if (!(input as HTMLInputElement)?.disabled && !isDisplayNone(input as HTMLInputElement, root as any)) {
            if (input.nodeName == "INPUT") {
                (input as HTMLInputElement).focus();
                return true;
            } else {
                if (scanNodes(Array.from(input.children), root)) {
                    return true;
                }
            }
        }
    }
}

export default defineComponent({
    components: {
        Box,
        Button,
        IconButton,
    },
    props: {
        ctrlArgs: arrayCtrlArgs<FieldCtrlArgs<InputValueTypes>>(),
    },
    created() {
        this.thisComp = this;
    },
    setup: (props) => {
        const ctrl = useArrayCtrl(props.ctrlArgs!);
        const itemsRef = ref<HTMLElement>();
        const thisComp = ref<any>();
        const self = {
            aidSection: ctrl.key ? `add-${ctrl.key}-section` : undefined,
            aidBtn: ctrl.key ? `add-${ctrl.key}-btn` : undefined,
            itemsRef,
            thisComp,
            getDefaultCanEdit(key: FieldKey) {
                const defaultRules = props.ctrlArgs?.defaultRules;
                if (defaultRules && defaultRules[key]) {
                    return defaultRules[key]?.canEdit;
                }
                return true;
            },
            getDefaultCanRemove(key: FieldKey) {
                const defaultRules = props.ctrlArgs?.defaultRules;
                if (defaultRules && defaultRules[key]) {
                    return defaultRules[key]?.canRemove;
                }
                return true;
            },
            async addItem() {
                ctrl.addItemCtrlArgs();
                await thisComp.value.$nextTick();
                let node = itemsRef.value!.children[itemsRef.value!.children.length - 2];
                if (node) {
                    if ((node as any).focus) {
                        (node as any).focus();
                    }

                    scanNodes(Array.from(node.children), node);
                }
            },
            get invalid() {
                return self.ctrl?.valid == "invalid";
            },
            get showAddButton() {
                const maxItems = props.ctrlArgs!.meta?.maxItems;
                if (!maxItems || !ctrl.itemsCtrlArgs.length) return true;
                return ctrl.itemsCtrlArgs.length < maxItems;
            },
            get ItemComponent() {
                const item = ctrl.meta?.item;
                return item ? item().component : BoxInputCtrl;
            },
            ctrl,
        };
        return self;
    },
});
