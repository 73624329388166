import { registerCustomComponent } from "@/core-ui/forms/compositions";

// import custom components
import JobGitSync from "../components/submit/JobGitSync.vue";
import Toleration from "../components/submit/Toleration.vue";
import ExtendedResources from "../components/submit/ExtendedResources.vue";
import Capabilities from "../components/submit/Capabilities.vue";
import NodePools from "../components/submit/NodePools.vue";
import S3Storage from "../components/submit/s3Storage.vue";
import JobPVC from "../components/submit/JobPVC.vue";
import JobVolume from "../components/submit/JobVolume.vue";
import JobPort from "../components/submit/JobPort.vue";
import CustomMap from "../components/submit/CustomMap.vue";
import ExposedUrls from "../components/submit/ExposedUrls.vue";
import WandbSweep from "../components/submit/WandbSweep.vue";

export function registerCustomComponents() {
    registerCustomComponent("gitSync", JobGitSync);
    registerCustomComponent("toleration", Toleration);
    registerCustomComponent("capabilities", Capabilities);
    registerCustomComponent("nodePools", NodePools);
    registerCustomComponent("extendedResources", ExtendedResources);
    registerCustomComponent("s3Storage", S3Storage);
    registerCustomComponent("pvcs", JobPVC);
    registerCustomComponent("volumes", JobVolume);
    registerCustomComponent("portMap", JobPort);
    registerCustomComponent("customMap", CustomMap);
    registerCustomComponent("exposedUrls", ExposedUrls);
    registerCustomComponent("wandbSweep", WandbSweep);
}
