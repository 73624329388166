import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style[_ctx.variant], _ctx.$style.input])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (!!_ctx.ctrl.err && (_ctx.ctrl.dirty || !!_ctx.ctrl.value))
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass([_ctx.$style.error, _ctx.$style.note])
        }, _toDisplayString(_ctx.ctrl.err), 3))
      : _createCommentVNode("", true),
    (!!_ctx.ctrl.info)
      ? (_openBlock(), _createElementBlock("label", {
          key: 1,
          class: _normalizeClass([_ctx.$style.info, _ctx.$style.note])
        }, _toDisplayString(_ctx.ctrl.info), 3))
      : _createCommentVNode("", true)
  ], 2))
}