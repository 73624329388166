
import grafanaStore from "@/stores/grafanaStore";
import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import Loading from "@/core-ui/Loading.vue";
import tenantStore from "../stores/tenantStore";
import { defineComponent, ref, onUnmounted, watch } from "vue";
import Page from "@/core-ui/Page.vue";
import { Dashboards, ClusterStatus } from "@/services/clusters-service";
import clustersStore from "@/stores/clusters-store";
import useConnectionStatusNotification from "@/compositions/useConnectionStatusNotification";
import IdleTimer from "@/services/idle-timer";
import { useIframeObserver } from "@/compositions/useIframeObserver";
export default defineComponent({
    components: {
        Loading,
        Page,
    },
    props: {
        dashboardName: String,
        query: { default: () => ({}) },
    },
    setup(props, ctx) {
        const iframeElm = ref(null as HTMLIFrameElement | null);
        let iframeDoc: Document | undefined | null = null;
        const IDLE_TIMER_NAME = "dashboard";
        const { setupMainViewObserverInIframe } = useIframeObserver(iframeElm, props.dashboardName || "");

        const { setConnectionErrorMessage } = useConnectionStatusNotification();

        watch(
            () => clustersStore.state.activeClusterUUID,
            () => {
                let msg = "";
                if (clustersStore.activeCluster?.status === ClusterStatus.Disconnected) {
                    msg =
                        "*Some metrics aren't available right now.* You can continue to use all platform operations as usual. If the issue persists, contact your admin.";
                }

                setConnectionErrorMessage(msg);
            },
        );

        function preventKeys(event: any) {
            const KEY_CODES: Record<string, number> = { ESC: 27, F: 70, I: 73, P: 80, S: 83, V: 86 };
            const keys: Array<number> = [KEY_CODES.ESC, KEY_CODES.F, KEY_CODES.I, KEY_CODES.P, KEY_CODES.S, KEY_CODES.V];

            if (keys.includes(event.keyCode)) {
                event.preventDefault();
                event.stopPropagation();
                return false;
            }
        }

        // Block user from exiting grafana ui easily (esc and F keys)
        function preventEventKeys(iframeDoc: Document): void {
            iframeDoc.addEventListener("keydown", preventKeys, true);
        }

        // styles to remove the filters in the table , the down-arrow in each cube , and the header in the analytics page.
        function hideGrafanaElements(iframeDoc: Document): void {
            const elStyle: HTMLElement = document.createElement("style");
            elStyle.innerText = `.panel-title > :nth-child(2) {display: none;} .cell-filter-actions svg {display: none;} .page-toolbar nav {display: none;} .page-toolbar > div > div > svg {display: none;} .panel-header {pointer-events: none;} .grafana-app .sidemenu {display: none;}`;
            iframeDoc.head.appendChild(elStyle);
        }

        function onIframeLoaded() {
            try {
                iframeDoc = iframeElm.value?.contentWindow?.document;
                if (!iframeDoc) return;

                IdleTimer.addListener(IDLE_TIMER_NAME, iframeDoc);
                hideGrafanaElements(iframeDoc);
                preventEventKeys(iframeDoc);
                // Add a call to set up the MutationObserver inside the iframe
                setupMainViewObserverInIframe();
            } catch (e) {
                // do nothing
            }
        }

        onUnmounted(() => {
            if (!iframeDoc) return;
            iframeDoc.removeEventListener("keydown", preventKeys, true);
            IdleTimer.removeListener(IDLE_TIMER_NAME, iframeDoc);
        });

        function mergeUrls({ hostUrl, pathUrl }: { hostUrl: string; pathUrl: string }): string {
            const hostUrlObj = new URL(hostUrl);
            let pathUrlObj = new URL(pathUrl);
            pathUrlObj.protocol = hostUrlObj.protocol;
            pathUrlObj.hostname = hostUrlObj.hostname;
            pathUrlObj.port = hostUrlObj.port;
            return pathUrlObj.toString();
        }
        function getUrl(propsQuery: object): string {
            let pageUrl = undefined;
            if (!props.dashboardName) {
                console.error("missing dashboard to load");
                return "";
            }

            pageUrl = clustersStore.state.dashboards[props.dashboardName as keyof Dashboards];
            if (!pageUrl) {
                console.error("failed to load dashboard:" + props.dashboardName);
                return "";
            }
            const { url, query } = queryString.parseUrl(pageUrl);
            const extendedQuery: any = {
                ...query,
                ...propsQuery,
            };

            const extendedQueryString: any = queryString.stringify(extendedQuery);
            let grafanaUrl = url + (isEmpty(extendedQueryString) ? "" : "?" + extendedQueryString);
            if (tenantStore.state.smgEnabled) {
                grafanaUrl = mergeUrls({ hostUrl: window.location.toString(), pathUrl: grafanaUrl });
            }

            return grafanaUrl;
        }

        const url = ref(getUrl(props.query));
        watch(
            () => [clustersStore.state.dashboards, props.query],
            (newQuery: Array<any>) => {
                url.value = getUrl(props.query);
            },
        );

        return {
            grafanaState: grafanaStore.state,
            url,
            iframeElm,
            onIframeLoaded,
        };
    },
});
