import configService from "@/services/config-service";
import settingStore, { SettingKeys } from "@/stores/setting-store";

export function isProductionEnv(): boolean {
    return !["localhost", "test.run.ai"].some((h) => location.hostname.startsWith(h));
}

export enum Env {
    Local = "local",
    OnPremise = "on-premise",
    Staging = "staging",
    Test = "test",
    CustomerTest = "customer-test",
    CustomerStaging = "customer-staging",
    CustomerCloud = "customer-cloud",
    Cloud = "cloud",
    Unknown = "unknown",
}
export function identifyEnvByHostname(hostname = location.hostname): Env {
    if (hostname.startsWith("localhost")) {
        return Env.Local;
    }
    if (!hostname.endsWith(".run.ai")) {
        return Env.OnPremise;
    }
    if (hostname == "test.run.ai") {
        return Env.Test;
    }
    if (hostname.endsWith(".test.run.ai")) {
        return Env.CustomerTest;
    }
    if (hostname.endsWith(".staging.run.ai")) {
        return Env.CustomerStaging;
    }
    if (hostname == "staging.run.ai") {
        return Env.Staging;
    }
    if (hostname == "app.run.ai") {
        return Env.Cloud;
    }

    if (hostname.endsWith(".run.ai")) {
        return Env.CustomerCloud;
    }
    return Env.Unknown;
}

export function getTenantNameFromHostname(hostname: string = location.hostname): string | undefined {
    const env = identifyEnvByHostname(hostname);
    let tenantName = "";
    switch (env) {
        case Env.CustomerCloud:
        case Env.CustomerStaging:
        case Env.CustomerTest:
            tenantName = location.hostname.split(".")[0];
    }

    return tenantName;
}

export function getTenantDomain(tenant: string): string {
    return tenant.concat(".", window.location.hostname).replace("app.run.ai", "run.ai");
}

export function isSaas(): boolean {
    return [Env.CustomerCloud, Env.Cloud, Env.CustomerStaging, Env.Staging, Env.CustomerTest, Env.Test].includes(
        identifyEnvByHostname(),
    );
}

export function isStagingEnv() {
    return identifyEnvByHostname() === Env.Staging || identifyEnvByHostname() === Env.CustomerStaging || configService.config.isStaging;
}

export function isTestEnv() {
    identifyEnvByHostname() === Env.Test || identifyEnvByHostname() === Env.CustomerTest;
}

export function isLocalEnv() {
    return identifyEnvByHostname() === Env.Local;
}

export function isStagingOrTestOrLocalEnv() {
    return isStagingEnv() || isTestEnv() || isLocalEnv();
}
