import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, mergeProps as _mergeProps, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f488c96"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "name"]
const _hoisted_2 = ["disabled", "selected"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_BoxInputMessages = _resolveComponent("BoxInputMessages")!
  const _component_Box = _resolveComponent("Box")!

  return (_openBlock(), _createBlock(_component_Box, {
    ctrl: _ctx.fieldCtrl,
    variant: _ctx.variant
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BoxInputMessages, {
        variant: _ctx.variant,
        ctrl: _ctx.fieldCtrl
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("select", _mergeProps({
            disabled: _ctx.fieldCtrl.disabled,
            name: _ctx.fieldCtrl.key,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fieldCtrl.value) = $event))
          }, _ctx.$attrs, {
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
            onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
            style: { color: _ctx.fieldCtrl.value ? '#404040' : '#afafaf' }
          }), [
            _createElementVNode("option", {
              style: {"color":"#afafaf"},
              value: undefined,
              disabled: _ctx.fieldCtrl.required,
              selected: _ctx.fieldCtrl.required
            }, _toDisplayString(_ctx.placeholder || (_ctx.fieldCtrl.required ? "Select" : "None")), 9, _hoisted_2),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsData, (option) => {
              return (_openBlock(), _createElementBlock("option", {
                style: {"color":"#404040"},
                key: option.value,
                value: option.value
              }, _toDisplayString(option.name), 9, _hoisted_3))
            }), 128))
          ], 16, _hoisted_1), [
            [_vModelSelect, _ctx.fieldCtrl.value]
          ]),
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_ProgressBar, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["variant", "ctrl"])
    ]),
    _: 1
  }, 8, ["ctrl", "variant"]))
}