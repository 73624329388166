import { between } from "../utils";
import { reactive, watch } from "vue";
import { getPageItems } from "../utils/get-page-items";
import { ArrayPipe } from "../types";
import { routerService, storageService } from "../services";

const ITEMS_PER_PAGE_DEFAULT = 20;
const MIN_ITEMS_PER_PAGE = 1;
const MAX_ITEMS_PER_PAGE = 200;

enum QueryKeys {
    items_per_page = "items_per_page",
    page = "page",
}

enum LSKeys {
    items_per_page = "RA_ITEMS_PER_PAGE_KEY",
}

interface PaginationProps {
    items_per_page: number;
    page: number;
    total: number;
}

export interface Pagination {
    state: PaginationProps;
    totalPages: number;
    displayedPages: number[];
    showFirst: boolean;
    showLast: boolean;
    itemsPerPage: number;
    MIN_ITEMS_PER_PAGE: number;
    MAX_ITEMS_PER_PAGE: number;
    page<T>(data: T[]): T[];
    emitItemsPerPage(ipp: number): void;
    emitPage(page: number): void;
    isCurrentPage(page: number): boolean;
}

export class PaginationSource implements PaginationProps {
    _state: PaginationProps;

    constructor(private props: { syncUrl: boolean }) {
        const syncData = () => {
            this._state.page = props.syncUrl ? routerService.getNum(QueryKeys.page, () => this.page) : this.page;
            this._state.items_per_page = props.syncUrl
                ? routerService.getNum(QueryKeys.items_per_page, this.items_per_page)
                : this.items_per_page;
        };

        // set default value
        this._state = reactive({
            page: 1,
            items_per_page: storageService.getNum(LSKeys.items_per_page, ITEMS_PER_PAGE_DEFAULT),
            total: 0,
        });

        watch(() => props.syncUrl, syncData, { immediate: true });
    }

    get total(): number {
        return this._state.total;
    }

    set total(total: number) {
        this._state.total = total;
    }

    get page(): number {
        return this._state.page;
    }

    set page(val: number) {
        if (val == this.page) return;
        if (this.props.syncUrl) {
            routerService.setNum(QueryKeys.page, val);
        }
        this._state.page = val;
    }

    get items_per_page(): number {
        return this._state.items_per_page;
    }

    set items_per_page(val: number) {
        if (!between(val, MIN_ITEMS_PER_PAGE, MAX_ITEMS_PER_PAGE)) return;
        storageService.setNum(LSKeys.items_per_page, val);
        if (this.props.syncUrl) {
            routerService.setNum(QueryKeys.items_per_page, val);
        }
        this._state.items_per_page = val;
    }
}

/**
 * Pagination contains the data and logic about the current page and items per page
 *
 * @export
 */
export function usePagination(props: PaginationProps): Pagination {
    return {
        page<T>(data: T[]) {
            props.total = data.length;
            return paginate(props.page, props.items_per_page)(data);
        },
        state: props,
        isCurrentPage(page: number) {
            return props.page == page;
        },
        emitItemsPerPage(n: number) {
            props.items_per_page = n;
        },
        emitPage(n: number) {
            props.page = n;
        },
        MIN_ITEMS_PER_PAGE,
        MAX_ITEMS_PER_PAGE,
        get itemsPerPage() {
            return props.items_per_page;
        },
        get totalPages() {
            return Math.ceil(props.total / props.items_per_page);
        },
        get displayedPages() {
            const MARGIN = 4;
            const currentPage = props.page;
            const totalPages = this.totalPages;
            const startPage = currentPage > MARGIN ? currentPage - MARGIN : 1;
            const endPage = totalPages > currentPage + MARGIN ? currentPage + MARGIN : totalPages;

            const pages = [];
            let page = startPage;
            while (page <= endPage) {
                pages.push(page);
                page++;
            }
            return pages;
        },

        get showFirst() {
            return this.displayedPages[0] !== 1;
        },

        get showLast() {
            return this.displayedPages[this.displayedPages.length - 1] !== this.totalPages;
        },
    };
}

export function paginate<T>(page: number, items_per_page: number): ArrayPipe<T> {
    return (data) => {
        return getPageItems(data, page, items_per_page);
    };
}
