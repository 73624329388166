
import IconButton from "@/core-ui/IconButton.vue";
import { defineComponent, PropType } from "vue";

type Action = {
    label: string;
    func: () => void;
    icon: string;
    disabled?: boolean;
};

export default defineComponent({
    components: { IconButton },
    props: {
        actions: Array as PropType<Action[]>,
    },
    setup() {
        return {};
    },
});
