import { reactive } from "vue";
import { storageService } from "@/core-ui/data-grid/services";
import frontendV2Service from "@/services/frontend-v2-service";
import { POST_MESSAGE_EVENTS } from "@/utils/postmessageEvents";

interface AppV2State {
    pageTitle: string,
    fullWidth: boolean,
    supportBack: boolean,
    readOnly: boolean,
    closeIcon: boolean,
    sidebarState: SidebarState
}

export enum SidebarState {
    Mini = "mini",
    Full = "full",
    Hidden = "hidden",
}

export const sideBarOpenKey = "sideBarOpenKey";

export function getSidebarState(fullWidth: boolean): SidebarState {
    if (fullWidth) return SidebarState.Hidden;
    const currentOpenState = storageService.getBool(sideBarOpenKey, false);
    return currentOpenState ? SidebarState.Full : SidebarState.Mini;
}
class AppV2Store {
    state: AppV2State = reactive({
        pageTitle: "",
        fullWidth: false,
        supportBack: false,
        closeIcon: false,
        readOnly: false,
        sidebarState: getSidebarState(false)
    });

    get pageTitle(): string {
        return this.state.pageTitle;
    }

    set pageTitle(pageTitle: string) {
        this.state.pageTitle = pageTitle;
    }

    get fullWidth(): boolean {
        return this.state.fullWidth;
    }

    set fullWidth(fullWidth: boolean) {
        this.state.fullWidth = fullWidth
    }

    get supportBack(): boolean {
        return this.state.supportBack;
    }

    set supportBack(supportBack: boolean) {
        this.state.supportBack = supportBack
    }

    get closeIcon(): boolean {
        return this.state.closeIcon;
    }

    set closeIcon(closeIcon: boolean) {
        this.state.closeIcon = closeIcon
    }

    get readOnly(): boolean {
        return this.state.readOnly;
    }

    set readOnly(readOnly: boolean) {
        this.state.readOnly = readOnly
    }

    get sidebarState(): SidebarState {
        return this.state.sidebarState
    }

    set sidebarState(sidebarState: SidebarState) {
        this.state.sidebarState = sidebarState;
        frontendV2Service.send(POST_MESSAGE_EVENTS.SIDENAV_STATE_CHANGED, this.state.sidebarState);
    }

    reset(): void {
        this.state.pageTitle = "";
        this.state.fullWidth = false;
        this.state.supportBack = false;
        this.state.closeIcon = false;
        this.state.readOnly = false;
        this.state.sidebarState = getSidebarState(this.state.fullWidth);
    }
}

const appV2Store = new AppV2Store();
export default appV2Store;
