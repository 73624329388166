import { ref, Ref, watch } from "vue";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import { routerService } from "../services";
import { ArrayPipe } from "../types";
import {DataGridColumn} from "@/core-ui/types/column";

export const SortLocalStorage = {
    job: {
        sortDirection: "sortDirectionJob",
        sortKey: "sortKeyJob",
    },
    project: {
        sortDirection: "sortDirectionProject",
        sortKey: "sortKeyProject",
    },
    cluster: {
        sortDirection: "sortDirectionCluster",
        sortKey: "sortKeyCluster",
    },
    'work-space': {
        sortDirection: "sortDirectionWorkSpace",
        sortKey: "sortKeyWorkSpace",
    },
    user: {
        sortDirection: "sortDirectionUser",
        sortKey: "sortKeyUser",
    },
    pod: {
        sortDirection: "sortDirectionPod",
        sortKey: "sortKeyPod",
    },
    node: {
        sortDirection: "sortDirectionNode",
        sortKey: "sortKeyNode",
    },
    group: {
        sortDirection: "sortDirectionGroup",
        sortKey: "sortKeyGroup",
    },
    gpu: {
        sortDirection: "sortDirectionGpu",
        sortKey: "sortKeyGpu",
    },
    event: {
        sortDirection: "sortDirectionEvent",
        sortKey: "sortKeyEvent",
    },
    deployment: {
        sortDirection: "sortDirectionDeployment",
        sortKey: "sortKeyDeployment",
    },
    department: {
        sortDirection: "sortDirectionDepartment",
        sortKey: "sortKeyDepartment",
    },
    application: {
        sortDirection: "sortDirectionApplication",
        sortKey: "sortKeyApplication",
    },
}

type SortType = 'job' | 'project' | 'cluster' | 'work-space'| 'user' | 'pod' | 'node' | 'group' | 'gpu'| 'event' | 'deployment' | 'department' | 'application';

const updateLocalStorageWithCurrentSortState = (type: SortType, key: string, direction: OrderDirection) => {
    localStorage.setItem(SortLocalStorage[type].sortKey, key);
    localStorage.setItem(SortLocalStorage[type].sortDirection, direction);
}

export const AddDefaultSort = (columns: DataGridColumn[], type: SortType, defaultSortKey: string = ""): DataGridColumn[] => {
    try {
        const sortKey = localStorage.getItem(SortLocalStorage[type].sortKey) || defaultSortKey;
        const sortDirection = ((localStorage.getItem(SortLocalStorage[type].sortDirection) as OrderDirection) || OrderDirection.ASC);
        columns.forEach((column) => {
            if (column.dataKey === sortKey) {
                column.defaultSort = true;
                column.direction = sortDirection;
            }
        });
    } catch (err) {
        console.warn(err);
    }
    return columns
}

export enum OrderDirection {
    ASC = "asc",
    DESC = "desc",
}

export interface Order {
    direction: OrderDirection;
    key: string;
}

export interface OrderStatus extends Order {
    index: number;
}

export interface SortingProps {
    multi: boolean;
    value: Order[];
    defaultSort: Order[];
    isSortable(key: string): boolean;
}

interface SortingSourceProps {
    defaultSort: Order[];
    multi?: boolean;
    syncUrl?: boolean;
    tableName: string;
    sortableKeys?: string[];
}

export class SortingSource implements SortingProps {
    state: Ref<Order[]>;
    multi!: boolean;
    syncUrl!: boolean;
    _sortableKeys?: Set<string>;
    _defaultSort!: Order[];

    constructor(private props: SortingSourceProps) {
        const syncSort = () =>
            (this.state.value = props.syncUrl
                ? routerService.getArray("sortBy", props.defaultSort)
                : [...props.defaultSort]);

        const initSortable = () => (this._sortableKeys = new Set(props.sortableKeys));

        // init
        this.state = ref([]);

        // watch
        watch([() => props.defaultSort, () => props.syncUrl], syncSort, {
            immediate: true,
        });
        watch(() => props.sortableKeys, initSortable, { immediate: true });
    }

    get defaultSort() {
        return this.props.defaultSort;
    }

    get value() {
        return this.state.value;
    }

    set value(value: Order[]) {
        if (this.props.syncUrl) {
            routerService.setArray("sortBy", value);
        }
        this.state.value = value;
    }

    isSortable(key: string) {
        return !this._sortableKeys || this._sortableKeys.has(key);
    }
}

export interface Sorting {
    // toggle have tow state (according to configuration (multiSort))
    // 1. Sort by only one column and toggle its order
    // 2. first time add the new one to be the first sorted columns, second change its order, third remove it)
    toggle(key: string): void;
    columnStatus(key: string): null | OrderStatus;
    sort<T>(data: T[]): T[];
    isSortable(key: string): boolean;
}

export function useSorting(props: SortingProps): Sorting {
    const type = (props as any).props.tableName as string
    function toggle(key: string) {
        if (props.multi) {
            // future: implements multi columns sorted
        } else {
            const s = columnStatus(key);
            if (!s) {
                props.value = [
                    { key, direction: OrderDirection.ASC },
                    ...ifNeedAddDefaultAsSecondSort(key, props.defaultSort),
                ];

                updateLocalStorageWithCurrentSortState(type as SortType, key, OrderDirection.ASC)
            } else {
                const direction = s.direction == OrderDirection.ASC ? OrderDirection.DESC : OrderDirection.ASC;
                props.value = [
                    {
                        key,
                        direction,
                    },
                    ...ifNeedAddDefaultAsSecondSort(key, props.defaultSort),
                ];

                updateLocalStorageWithCurrentSortState(type as SortType, key, direction)
            }
        }
    }

    function columnStatus(_key: string): null | OrderStatus {
        // Currently we are not supporting multi sort in our table so we hiding the rest sorts
        // by slicing the value to only the first one
        const index = props.value.slice(0, 1).findIndex(({ key }) => key == _key);
        return index === -1 ? null : { index: index + 1, ...props.value[index] };
    }

    function _sort<T>(items: Array<T>) {
        return sort(props.value)<T>(items);
    }

    return {
        sort: _sort,
        toggle,
        columnStatus,
        isSortable: (key: string) => props.isSortable(key),
    };
}

export function sort<T>(sortBy: Order[] = []): ArrayPipe<T> {
    return (data) => {
        return orderBy(
            data,
            sortBy.map(
                ({ key }) =>
                    (o) =>
                        toLowerCase(get(o, key)),
            ),
            sortBy.map(({ direction }) => direction),
        );
    };
}

/// helpers

function toLowerCase(val: any) {
    return typeof val == "string" ? val.toLowerCase() : val;
}

function ifNeedAddDefaultAsSecondSort(key: string, defaultSort: Order[]): Order[] {
    return defaultSort.filter((ds) => ds.key != key);
}
