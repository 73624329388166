
import ResearcherDownloadCliModal from "@/components/cli/ResearcherDownloadCliModal.vue";
import { getSupportLink } from "@/helpers/support-link";
import { defineComponent, ref, watchEffect } from "vue";

import { mdbRipple } from "mdb-vue-ui-kit";
import { clusterApi } from "@/cluster-ui/services/apiClient";
import { activeClusterConnectionInfoStore } from "@/stores/activeClusterConnectionInfoStore";
import authStore from "@/stores/authStore";

export default defineComponent({
    directives: {
        mdbRipple,
    },
    components: {
        ResearcherDownloadCliModal,
    },
    setup() {
        const isOpen = ref<boolean>(false as any);
        const tooltip = ref<boolean>(false as any);
        const openModal = ref<string | null>(null as any);
        const isClusterConnected = ref<boolean>(false as any);

        function itemClicked(modalId: string) {
            openModal.value = modalId;
        }

        watchEffect(() => {
            isClusterConnected.value = !!(
                activeClusterConnectionInfoStore.domain &&
                activeClusterConnectionInfoStore.cliAvailable
            );
        });

        return {
            isOpen,
            tooltip,
            openModal,
            itemClicked,
            supportLink: getSupportLink(),
            researcherCliTitle: "Researcher Command Line Interface",
            isClusterConnected,
            get isAdmin() {
                return authStore.isAdmin;
            },
        };
    },
});
