import apiClient from "./api-client";
import clustersStore from "@/stores/clusters-store";
import { promises } from "dns";
import { Pod } from "@/models/pods.model";

class PodsService {
    constructor(private props: { clusterUuid: string }) {}

    get(jobId: string, deploymentId: string, clusterUuid = this.props.clusterUuid): Promise<Pod[]> {
        if (deploymentId) {
            return apiClient.asyncRequestWithResponse(
                `v1/k8s/clusters/${clusterUuid}/deployments/${deploymentId}/pods`,
                "GET",
            );
        } else {
            return apiClient.asyncRequestWithResponse(
                `v1/k8s/clusters/${clusterUuid}/jobs/${jobId}/pods`
                , "GET");
        }
    }
}
const podsService = new PodsService({
    get clusterUuid() {
        return clustersStore.state.activeClusterUUID;
    },
});
export default podsService;
