import { Component, inject, provide, Ref, ref, shallowRef } from "vue";

type Key = string;
type Messages = Record<Key, { comp: Component; args: any }>;

const key = Symbol();

export type DynamicMsg = {
    add(key: Key, comp: Component, args: any, timeout?: number): void;
    remove(key: Key): void;
};

export function createDynamicMsg(): any {
    const messages: Ref<Messages> = shallowRef({});

    const self = {
        get messages() {
            return messages.value as Messages;
        },
        add(key: string, comp: Component, args: any, timeout?: number) {
            if (messages.value[key]) {
                throw new Error(`DynamicMsg:: the key: ${String(key)} already existed`);
            }
            messages.value = { ...messages.value, [key]: { comp, args } };
            if (typeof timeout == "number") {
                setTimeout(() => {
                    self.remove(key);
                }, timeout);
            }
        },
        remove(key: string) {
            messages.value = Object.fromEntries(Object.entries(messages.value).filter(([k]) => k != key));
        },
    };

    provide(key, self);

    return self;
}

export function useDynamicMsg(): DynamicMsg {
    return inject(key) as DynamicMsg;
}
