export type Or<T> = (() => T | null) | T | null;

export abstract class StringSource {
    // default stringify functions
    stringifyObj = JSON.stringify;
    stringifyArray = JSON.stringify;
    stringifyBool = String;
    stringifyNum = String;
    stringifyStr = undefined;

    // default parsing functions
    parseObj = JSON.parse;
    parseArray = JSON.parse;
    parseBool = (b: string) => b !== "false";
    parseNum = parseFloat;
    parseStr = undefined;

    abstract _get<T>(key: string, or: Or<T>, parse?: Function, stringify?: Function): T;
    abstract _set<T>(key: string, val: T, stringify?: Function): void;

    // utils function
    _parseOr<T>(val: T, parse?: Function, or?: Or<T>): T {
        try {
            return val ? (parse ? parse(val) : val) : typeof or == "function" ? (or as Function)() : or;
        } catch (err) {
            return typeof or == "function" ? (or as Function)() : or;
        }
    }

    _stringify(val: any, stringify?: Function): string {
        try {
            return stringify ? stringify(val) : val;
        } catch (err) {
            return String(val);
        }
    }

    // set functions
    setBool(key: string, val: boolean) {
        this._set(key, val, String);
    }

    setStr(key: string, val: string) {
        this._set(key, val, this.parseStr);
    }

    setNum(key: string, val: number) {
        this._set(key, val, this.stringifyNum);
    }

    setObj<T extends Record<string, any>>(key: string, val: T) {
        this._set(key, val, this.stringifyObj);
    }

    setArray<T>(key: string, val: T[]) {
        this._set(key, val, this.stringifyArray);
    }

    // update functions
    updateObj<T extends Record<string, any>>(key: string, update: (o: T) => T) {
        this.setObj(key, update(this.getObj(key)));
    }

    updateArray<T>(key: string, update: (o: T[]) => T[]) {
        this.setArray(key, update(this.getArray(key)));
    }

    // get functions
    getNum(key: string, or: Or<number>): number {
        return this._get(key, or, this.parseNum, this.stringifyNum);
    }

    getObj<T>(key: string, or?: T): T {
        return this._get(key, or, this.parseObj, this.stringifyObj) as T;
    }

    getArray<T>(key: string, or?: T[]): T[] {
        return this._get(key, or, this.parseArray, this.stringifyArray) as T[];
    }

    getStr(key: string, or?: Or<string>): string {
        return this._get(key, or, this.parseStr, this.stringifyStr) as string;
    }
    getBool(key: string, or?: Or<boolean>): boolean {
        return this._get(key, or, this.parseBool, this.stringifyBool) as boolean;
    }
}
