
import Modal from "@/core-ui/Modal.vue";
import Button from "@/core-ui/Button.vue";
import { Component, defineComponent, onMounted, ref } from "vue";
import Toggle from "./Toggle.vue";

export default defineComponent({
    components: { Modal, Button, Toggle },
    props: {
        title: String,
        msg: String,
        hideDontShowAgain: Boolean,
        ok: { type: String, default: "Continue" },
        cancel: { type: String, default: "Cancel" },
    },
    setup(props, ctx) {
        const clicked = ref(false);
        const self = {
            clicked,
            dontShowAgain: ref(false),
            onCancel() {
                ctx.emit("cancel");
            },
        };
        return self;
    },
});
