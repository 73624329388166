
import { copy } from "@/core-ui/helpers/copy";
import { useToast } from "vue-toastification";
import { defineComponent, ref } from "vue";

export default defineComponent({
    setup: () => {
        const codeRef = ref<HTMLElement | null>(null);
        const toast = useToast();

        return {
            codeRef,
            copy() {
                const text = (codeRef.value as HTMLElement).innerText as string;
                copy(text);
                toast.info("Copied!");
            },
        };
    },
});
