import { nextTick, ref } from "@vue/runtime-core";
import {makeAlphabeticID, makeID, makeLowerCaseAlphabeticID} from "./helpers";
import { useObjState } from "./ObjState";
import { Ctrl, CtrlArgs, FieldKey, FieldValidStatus, DisplayGroupsSelector, ContainerState } from "./types";

export const DEFAULT_ITEM_PREFIX = "DEFAULT_ITEM_";

export type ArrayState<T> = ContainerState<T[]> & {
    removeByKey(key: FieldKey): void;
    itemsCtrlArgs: T[];
    addItemCtrlArgs(): void;
};

export type ArrayStateArgs<T> = {
    key: FieldKey;
    default?: T[];
    parent?: ContainerState;
    editable?: boolean;
    defaultEditable: boolean;
    item: any; // todo
};

export function useArrayState<T>(args: ArrayStateArgs<any>): ArrayState<T> {
    const objState = useObjState(args);
    let lastId: null | string = null;
    let self: ArrayState<T> = {} as any; // declare self as any to avoid error. it assigned in the next lines
    const removedDefault = new Set<string>();
    const addedItem = new Set<string>();
    const removedDefaultCount = ref(0);
    const addedItemCount = ref(0);

    function onItemRemoved(key: string) {
        if (!addedItem.has(key as string)) {
            removedDefault.add(key as string);
            removedDefaultCount.value = removedDefault.size;
        } else {
            addedItem.delete(key);
        }
    }

    function onItemAdded(key: string, quite = false) {
        if (quite) {
            return;
        }
        addedItem.add(key);
        addedItemCount.value = removedDefault.size;
    }

    function addItemCtrlArgs(key = "", quite = false) {
        lastId = makeLowerCaseAlphabeticID();
        key = key ? key : lastId;
        objState.addDeclaration(args.item(self, key));
        onItemAdded(key, quite);
    }

    function sync(data: Record<string, T> | T[], quite = false) {
        if (Array.isArray(data)) {
            for (const item of data) {
                addItemCtrlArgs(undefined, quite);
                objState.cachedValue.set(lastId as string, item);
            }
        }
        if (typeof data == "object") {
            for (let [key, item] of Object.entries(data)) {
                if (item === null || (item as any) === "") {
                    item = undefined as any;
                }
                if (objState.value.hasOwnProperty(key) && item == undefined) {
                    // delete
                    self.removeByKey(key);
                } else if (objState.value.hasOwnProperty(key)) {
                    // update
                    (objState.value as any)[key] = item;
                } else if (item !== undefined) {
                    // add
                    addItemCtrlArgs(key, quite);
                    objState.cachedValue.set(key, item);
                    if (quite) {
                        objState.isCachedValueQuite.add(key);
                    }
                }
            }
        }
    }

    function addDefault() {
        if (Array.isArray(args.default)) {
            sync(Object.fromEntries(args.default.map((v, i) => [DEFAULT_ITEM_PREFIX + i, v])), true);
        } else if (typeof args.default == "object") {
            sync(args.default, true);
        }
    }
    function clear() {
        lastId = null;
        removedDefault.clear();
        removedDefaultCount.value = 0;
        addedItem.clear();
        addedItemCount.value = 0;

        objState.clear();
    }
    self = {
        type: "array",
        get hidden() {
            return !!(objState.hidden || args.parent?.isChildrenHidden(args.key));
        },
        get value(): T[] {
            return Object.values(objState.value as Array<any>) as any;
        },
        get dirty() {
            removedDefaultCount.value;
            addedItemCount.value;

            return objState.dirty || !!removedDefault.size || !!addedItem.size;
        },
        set value(v: T[]) {
            clear();
            for (const item of (v as any) || []) {
                addItemCtrlArgs();
                objState.cachedValue.set(lastId as string, item);
            }
        },
        setQuite(v) {
            // todo
            self.value = v;
        },
        get valid() {
            return objState.valid;
        },

        get editable() {
            return objState.editable;
        },

        get defaultEditable() {
            return objState.defaultEditable;
        },

        context: objState.context,
        get changedValue(): any {
            if (!self.dirty) {
                return undefined;
            }

            const data: any = objState.changedValue || {};
            // mark the removed default items with null
            for (const key of [...removedDefault]) {
                data[key] = null;
            }
            for (const addedKey of [...addedItem]) {
                data[addedKey] = (objState.value as any)[addedKey] || null;
            }
            if (!Object.keys(data).length) {
                return undefined;
            }
            return data;
        },
        apply(value?: any) {
            if (value == undefined) {
                return;
            }
            sync(value as any[]);
        },
        async reset() {
            self.clear();
            await objState.reset();
            await nextTick();
            addDefault();
        },
        clear,
        addDeclaration: objState.addDeclaration,
        isChildrenHidden: objState.isChildrenHidden,

        add: (ctrl: any) => {
            const lastCount = objState.children.size;
            const isStoreInTheCache = objState.cachedValue.has(ctrl.key);
            lastId = makeID();
            objState.add(ctrl);
            // check if added a new state and it did not existed in the cache
            if (lastCount != objState.children.size && !isStoreInTheCache) {
                onItemAdded(ctrl.key);
            }
        },
        remove: (ctrl) => {
            const key = ctrl.key;
            const removed = objState.remove(ctrl);
            if (removed && !objState.children.has(key)) {
                onItemRemoved(key as string);
            }
            return removed;
        },
        removeByKey: (key: string) => {
            objState.removeByKey(key);
            onItemRemoved(key);
        },
        get children() {
            return objState.children;
        },
        get itemsCtrlArgs() {
            return [...objState.childrenCtrlArgs.values()] as any as T[];
        },
        addItemCtrlArgs,
    };
    addDefault();

    return self;
}
