import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["aid"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["aid", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", {
      class: _normalizeClass({ [_ctx.$style.placeholder]: true, [_ctx.$style.open]: _ctx.open })
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass({ [_ctx.$style.leftPane]: true, [_ctx.$style.open]: _ctx.open }),
      ref: "leftPane"
    }, [
      _createElementVNode("section", {
        class: _normalizeClass(_ctx.$style.sideBarMenu)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredLinks, (link) => {
          return (_openBlock(), _createElementBlock("div", {
            key: link.title,
            class: _normalizeClass(_ctx.$style.topMenuItemGroup),
            aid: link.aid
          }, [
            _createElementVNode("div", {
              class: _normalizeClass({
                        [_ctx.$style.menuItem]: true,
                        [_ctx.$style.selectedMenuItem]: _ctx.$route.name?.startsWith(link.pageName) && _ctx.isEmpty(link.subLinks),
                        [_ctx.$style.disabled]: _ctx.isDisabled(link),
                        [_ctx.$style.nonDisabled]: !_ctx.isDisabled(link),
                        [_ctx.$style.borderTop]: link.topBorder,
                    }),
              onClick: ($event: any) => (_ctx.topItemClick(link.title))
            }, [
              _createVNode(_component_SvgIcon, {
                "icon-class": link.icon,
                "class-name": _ctx.$style.menuItemIcon
              }, null, 8, ["icon-class", "class-name"]),
              (link.subLinks)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: _normalizeClass(["raicon-play", [_ctx.$style.extendBtn, link.isOpen ? _ctx.$style.linkOpen : '']])
                  }, null, 2))
                : _createCommentVNode("", true),
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.extended),
                style: {"font-size":"16px"}
              }, _toDisplayString(link.title), 3)
            ], 10, _hoisted_2),
            _createVNode(_Transition, { name: "slide" }, {
              default: _withCtx(() => [
                (link.isOpen)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.$style.lowerContainer)
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(link.subLinks, (link) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: link.title,
                          aid: link.aid,
                          class: _normalizeClass({
                                [_ctx.$style.lowerMenuItem]: true,
                                [_ctx.$style.selectedMenuItem]: link.pageName === _ctx.$route.name,
                                [_ctx.$style.disabled]: _ctx.isDisabled(link),
                                [_ctx.$style.nonDisabled]: !_ctx.isDisabled(link),
                                [_ctx.$style.borderTop]: link.topBorder,
                                [_ctx.$style.borderBottom]: link.bottomBorder,
                            }),
                          onClick: ($event: any) => (_ctx.lowerItemClick(link))
                        }, [
                          _createVNode(_component_SvgIcon, {
                            "icon-class": link.icon,
                            "class-name": _ctx.$style.menuItemIcon
                          }, null, 8, ["icon-class", "class-name"]),
                          _createElementVNode("span", {
                            class: _normalizeClass([_ctx.$style.extended, _ctx.$style.linkText])
                          }, _toDisplayString(link.title), 3)
                        ], 10, _hoisted_3))
                      }), 128))
                    ], 2))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ], 10, _hoisted_1))
        }), 128))
      ], 2),
      (_ctx.appVersion)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([_ctx.$style.extended, _ctx.$style.bottomItem])
          }, "Version: " + _toDisplayString(_ctx.appVersion), 3))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.extended, _ctx.$style.bottomItem])
      }, "Last login: " + _toDisplayString(_ctx.lastLogin), 3)
    ], 2)
  ], 64))
}