export class ExportService {
    /**
     * download file from any data.
     * @param {String} fileName
     * @param {String} fileType
     * @param {any} data
     */
    toDownload(fileName: string, fileType: string, data: any) {
        // download the file
        const fileFullName = `${fileName}.${fileType}`;
        const a: any = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        const blob = new Blob([data], { type: "octet/stream" });
        a.href = window.URL.createObjectURL(blob);
        a.download = fileFullName;
        a.click();
        // Remove the added a tag
        a.remove();
    }
}

export default new ExportService();
