import { Ref, ref, watch } from "vue";
import { storageService } from "../services";

const DEFAULT_KEY = "DEFAULT_KEY";

type Checked = { [key: string]: boolean };

export interface ShownColumnsState {
    get(key: string): boolean;
    set(key: string, value: boolean): void;
}

export interface ShownColumnsSourceProps {
    key?: string;
    checked?: Checked;
    defaultChecked?: boolean;
}

export class ShownColumnsSource implements ShownColumnsState {
    state: Ref<Checked>;
    defaultChecked: boolean | undefined = undefined;

    constructor(private props: ShownColumnsSourceProps) {
        const syncChecked = () =>
            (this.state.value = {
                ...(props.checked || {}),
                ...storageService.getObj(this.key, {}),
            });
        this.state = ref<Checked>({});

        watch([() => props.checked, () => props.key], syncChecked, {
            immediate: true,
        });
    }

    get key(): string {
        return `table-shown-columns/v1/${this.props.key || DEFAULT_KEY}`;
    }

    get(key: string): boolean {
        return this.state.value[key] ?? this.defaultChecked;
    }

    set(key: string, val: boolean) {
        this.state.value[key] = val;
        storageService.updateObj(this.key, (p = {}) => ({ ...p, [key]: val }));
    }
}

export interface ShownColumns {
    toggle(key: string): void;
    show(key: string): boolean;
    //checked: {[key: string]: boolean}
}

/**
 * ShowColumns contain the state and logic which columns needs to be displayed
 *
 * @export
 */
export function useShownColumns({ state }: { state: ShownColumnsState }): ShownColumns {
    const fake_num = ref(0);
    return {
        toggle(key: string) {
            fake_num.value++;
            state.set(key, !state.get(key));
        },
        show(key) {
            // do this to keep vue reactive
            const _ = fake_num.value;
            return state.get(key);
        },
    };
}
