import { renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuArrow = _resolveComponent("MenuArrow")!
  const _directive_on_clickaway = _resolveDirective("on-clickaway")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ [_ctx.$style.absoluteWrapper]: _ctx.useAbsolute })
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)), ["stop"])),
      class: _normalizeClass(_ctx.$style.opener),
      ref: "openerRef"
    }, [
      _renderSlot(_ctx.$slots, "opener")
    ], 2),
    (_ctx.opened)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 0,
          ref: "menuRef",
          class: _normalizeClass(_ctx.$style.menuWrapper),
          style: _normalizeStyle(_ctx.positions),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.menuClicked && _ctx.menuClicked(...args)))
        }, [
          _createVNode(_component_MenuArrow, {
            class: _normalizeClass(_ctx.$style.menuArrow),
            position: "bottom",
            style: _normalizeStyle({ left: _ctx.arrowLeft })
          }, null, 8, ["class", "style"]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.menu)
          }, [
            _renderSlot(_ctx.$slots, "menu")
          ], 2)
        ], 6)), [
          [_directive_on_clickaway, _ctx.toggleMenu]
        ])
      : _createCommentVNode("", true)
  ], 2))
}