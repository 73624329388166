import { WhoAmI, Me } from "../models/whoami.model";
import { clusterApi } from "./apiClient";

let cachedInfo: Promise<WhoAmI>;

export default {
    async me(): Promise<Me> {
        return clusterApi.get("/api/v1/me").then((res: any) => res.data);
    },
    async get(): Promise<WhoAmI> {
        if (cachedInfo == null) {
            cachedInfo = clusterApi.get("/api/v1/whoami").then((res: any) => res.data);
        }
        return cachedInfo;
    },
};
