import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBSpinner = _resolveComponent("MDBSpinner")!
  const _component_MDBBtn = _resolveComponent("MDBBtn")!

  return (_openBlock(), _createBlock(_component_MDBBtn, _mergeProps({ class: "core-ui-button" }, _ctx.calculatedAttributes, {
    autofocus: _ctx.autofocus,
    color: _ctx.color
  }), {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_MDBSpinner, {
            key: 0,
            tag: "span",
            class: "loader",
            size: "sm"
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
    ]),
    _: 3
  }, 16, ["autofocus", "color"]))
}