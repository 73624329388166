
import authStore from "../../stores/authStore";
import ChangePasswordModal from "@/components/core/ChangePasswordModal.vue";
import { defineComponent, ref } from "vue";
import { EntityType } from "@/types/user";

const ProfileOptionsItems = {
    SIGN_OUT: {
        id: "signOut",
        title: "Sign out",
    },
    CHANGE_PASSWORD: {
        id: "changePassword",
        title: "Change password",
    },
};
export default defineComponent({
    components: { ChangePasswordModal },
    props: {
        userEmail: String,
    },
    created() {
        this.thisComp = this;
    },
    setup(props: any) {
        const userOptionsOpened = ref(false);
        const changePasswordOpened = ref(false);
        const thisComp = ref<any>();
        const isOpen = ref(false);
        const tooltip = ref(false);
        let profileOptions: { id: string; title: string; }[];

        if (authStore.userInfo.entityType === EntityType.SSO_USER) {
            profileOptions= [ProfileOptionsItems.SIGN_OUT];
        } else {
            profileOptions = [ProfileOptionsItems.CHANGE_PASSWORD, ProfileOptionsItems.SIGN_OUT];
        }

        const self = {
            isOpen,
            tooltip,
            profileOptions,
            optionClicked(optionId: any) {
                if (optionId === ProfileOptionsItems.SIGN_OUT.id) {
                    authStore.logout(true);
                } else {
                    self.openChangePasswordModal();
                    self.isOpen.value = !self.isOpen.value;
                }
            },
            open() {
                isOpen.value = !isOpen.value;
            },
            shortName: props.userEmail.substring(0, 1).toUpperCase(),
            userOptionsOpened,
            changePasswordOpened,
            thisComp,
            hideUserOptions() {
                userOptionsOpened.value = false;
            },
            async openChangePasswordModal() {
                await thisComp.value.$nextTick();
                self.hideUserOptions();
                changePasswordOpened.value = true;
            },
        };

        return self;
    },
});
